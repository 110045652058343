import React, { useEffect, useState } from "react";
import Search from "../../../../assets/img/icons/lupa.svg";
import excelIcon from "../../../../assets/img/icons/excelIcon.svg";
import backArrow from "../../../../assets/img/icons/atras-icon.svg";
import avion from "../../../../assets/img/icons/avion.svg";
import note from "../../../../assets/img/icons/ordSugess.svg";
import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid, Button, Tooltip } from "@mui/material";
import { convertMoneyFormat, downloadExcel, loader, message } from "../../../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../../../Hooks/useFetch";
import { customSwaltAlert } from "../../../../helpers/customSwaltAlert";
import ModalNew from "../../../Layouts/ModalNew";
import TableScroll from "../../../Layouts/TableScroll/TableScroll";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import DynamicNumberFormat from "../../../../shared/DynamicNumberFormat";

export const BonusSheetDetail = ({ backPage, idTemplate, status, dataPayroll }) => {
    const store = useSelector((state) => state);
    const token = store.loginReducer.Authorization;
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "lawBonus");
    const idAccount = store.loginReducer.currentAccount.id;
    const history = useHistory();
    const [data, setData] = useState({
        trigger: 0,
        filters: {
            search: "",
            entity_account: idAccount,
            law_bonus_id: idTemplate,
        },
    });

    const [dataDetail, setDataDetail] = useState([]);
    const { filters, trigger } = data;
    const { load: premiumScheduleLoader, trigger: getPremiumSchedule } = useGetMethod();
    const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();
    const { load: excelMethodLoader, trigger: getExcelMethod } = useGetMethod();

    useEffect(() => {
        if (!!!myPermission?.read) {
            history.push("/nomina/inicio");
        }
        getPremiumSchedule({
            url: "/payroll/law_bonus_detail/",
            objFilters: filters,
            token: token,
            doAfterSuccess: ({ results }) => {
                setDataDetail(results);
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    const getExcelFunction = () => {
        getExcelMethod({
            url: "/payroll/law_bouns_excel/",
            objFilters: {
                law_bonus_id: idTemplate,
                entity_account: idAccount
            },
            token: token,
            doAfterSuccess: (res) => {
                downloadExcel(res.results.base64, res.results.filename, true);

            },
        });
    };

    const onChangeInputFunction = (value, id) => {
        let tempArr = [...dataDetail];
        let employeeRowIndex = tempArr.findIndex((x) => x.id === id);
        let employeeRow = tempArr.find((x) => x.id === id);
        employeeRow.total_amount_incent = value;
        tempArr[employeeRowIndex] = employeeRow;
        setDataDetail(tempArr);
    };

    const bodyTable = () => {
        let tempArr = [];
        dataDetail?.length > 0 &&
            dataDetail?.forEach((elem, ind) => {
                tempArr.push(
                    <tr key={elem.id}>
                        <td className={`col-6 px-3 text-start fw-bold`}>
                            <Row className="d-flex" >
                                <Col xs={1} className="px-2 text-center " title="ddfdf"> {ind + 1}</Col>
                                <Col xs={5} className="px-2 text-start textHide" title={elem.full_name}> {elem.full_name ?? "-"} </Col>
                                <Col xs={3} className="px-2 text-center ">{elem.doc_number ?? "-"} </Col>
                                <Col xs={3} className="px-2 text-center textHide" title={elem.position}>{elem.position ?? "-"} </Col>
                            </Row>
                        </td>
                        <td className="col-2 text-center px-2">
                            {elem.start_date ?? "-"}
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.january ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.february ?? "-")}
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.march ?? "-")}
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.april ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.may ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.june ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.july ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.august ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.september ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.october ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.november ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.december ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.total ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.perk_value ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.base_salary ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat(elem?.total_amount_bonus ?? "-")}

                        </td>
                        <td className="col-2 text-end px-2">
                            {status !== "loaded" ?
                                convertMoneyFormat(elem?.total_amount_incent ?? "-")
                                :
                                <div style={{ width: 150 }}>
                                    <DynamicNumberFormat
                                        inputType={"money"}
                                        allowNegative={false}
                                        className={`register-inputs`}
                                        thousandsGroupStyle="thousand"
                                        defaultValue={elem?.total_amount_incent}
                                        onValueChange={(values) => {
                                            onChangeInputFunction(values?.floatValue, elem?.id);
                                        }}
                                    />
                                </div>
                            }
                        </td>
                        <td className="col-2 text-end px-2">
                            {convertMoneyFormat((Number(elem?.total_amount_bonus ?? 0) + Number(elem?.total_amount_incent ?? 0)).toFixed(2))}
                        </td>

                    </tr>
                );
            });
        return tempArr;
    };
    const renderHeaders = [
        <th key={1} className="text-start col-6 px-3">
            <Row className="d-flex" style={{ width: 600 }}>
                <Col xs={1} className="px-2 text-center"> No.</Col>
                <Col xs={5} className="px-2 text-start">Empleado</Col>
                <Col xs={3} className="px-2 text-center">ID</Col>
                <Col xs={3} className="px-2 text-center">Cargo</Col>
            </Row>
        </th>,
        <th key={2} className="text-center col-2 pl-0" style={{ paddingLeft: 0 }}>
            F. Ingreso
        </th>,
        <th key={2} className="text-end col-2">
            Enero
        </th>,
        <th key={3} className="text-end col-2">
            Febrero
        </th>,
        <th key={4} className="text-end col-2">
            Marzo
        </th>,
        <th key={5} className="text-end col-2">
            Abril
        </th>,
        <th key={6} className="text-end col-2">
            Mayo
        </th>,
        <th key={7} className="text-end col-2">
            Junio
        </th>,
        <th key={9} className="text-end col-2">
            Julio
        </th>,
        <th key={10} className="text-end col-2">
            Agosto
        </th>,
        <th key={11} className="text-end col-2">
            Septiembre
        </th>,
        <th key={12} className="text-end col-2">
            Octubre
        </th>,
        <th key={13} className="text-end col-2">
            Noviembre
        </th>,
        <th key={14} className="text-end col-2">
            Diciembre
        </th>,
        <th key={15} className="text-end col-2">
            Total
        </th>,
        <th key={16} className="text-end col-2">
            Reg. Pascual
        </th>,
        <th key={17} className="text-end col-2">
            Salario base
        </th>,
        <th key={18} className="text-end col-2">
            bonificación
        </th>,
        <th key={19} className={`col-3 ${status !== "loaded" ? "text-end" : "text-center px-2"}`}>
            Incentivo
        </th>,
        <th key={20} className="text-end col-2">
            T. a percibir
        </th>,


    ];
    const sendToAccountingFunction = () => {
        customSwaltAlert({
            icon: "warning",
            title: `¿Está seguro?`,
            text: `Se enviará a contabilidad la planilla de regalía pascual del año ${dataPayroll?.payment_year}`,
        }).then((result) => {
            if (result.isConfirmed) {
                updatePayroll({
                    url: "/payroll/law_bonus_detail/",
                    token: token,
                    method: "PUT",
                    body: {
                        law_bonus_id: idTemplate,
                        entity_account: idAccount,
                        status: "review",
                        detail: dataDetail
                    },
                    succesAction: (info) => {
                        backPage.setData((state) => ({
                            ...state,
                            generatePremiumSchedule: false,
                            trigger: state.trigger + 1,
                        }));
                        customSwaltAlert({
                            title: `Enviada exitosamente`,
                            text: `Se ha enviado a contabilidad planilla del año ${dataPayroll?.payment_year} - Periodo ${dataPayroll?.period}. `,
                            icon: "success",
                            confirmButtonText: "Aceptar",
                            showCancelButton: false,
                        });
                    },
                    doAfterException: (error) => {
                        message("warning", "Intenta de nuevo", error.message, undefined, true);
                    }
                });
            }
        });
    };
    const [commentModal, setCommentModal] = useState({
        show: false,
        comment: "",
    });
    const closeCommentModal = () => {
        setCommentModal({
            ...commentModal,
            show: false,
            cancelledReason: ""
        });
    };
    return (
        <>
            {(excelMethodLoader || premiumScheduleLoader || updatePayrollLoader) && loader}
            <ModalNew
                title="Comentarios"
                show={commentModal.show}
                // btnYesName={showModalRejected.justify !== "" && "Guardar"}
                btnNoName={"Cerrar"}
                size="400"
                btnYesEvent={null}
                btnYesDisabled={true}
                onHide={() =>
                    closeCommentModal()
                }
                btnNoEvent={() =>
                    closeCommentModal()
                }
                btnNoDisabled={false}
            >
                <p className={tableStyles.crudModalLabel}>
                    Comentarios de empleado
                </p>
                <textarea
                    placeholder={"Escribir..."}
                    id="txtArea"
                    rows="10"
                    cols="10"
                    disabled={true}
                    defaultValue={commentModal.comment}
                    style={{
                        height: "4rem", borderRadius: "5px", border: "1.5px solid #01A0F6", padding: "0 0.05rem",
                        width: "100%"
                    }}
                    className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
                ></textarea>
            </ModalNew>
            <div className="w-90 mx-auto">
                <h1
                    className={`${tableStyles.title} d-flex gap-2 align-items-center`}
                    style={{ marginLeft: "-30px" }}
                >
                    <img
                        onClick={() => {
                            backPage.setData(state => ({ ...state, generatePremiumSchedule: false, trigger: state.trigger + 1 }));
                        }}
                        src={backArrow}
                        alt="backArrow"
                        className={`pointer`}
                        height="30px"
                    />
                    <span className={`${tableStyles.titleAdministrative}`}>Detalle planilla de bonificación</span>
                    {status === "rejected" && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={() => <Tooltip>
                                <span> Añadir comentario </span>
                            </Tooltip>}
                        >
                            <img
                                alt="goal"
                                src={note}
                                className={`align-self-center mx-2 ${tableStyles.svgFilterDarkBlue} cursorPointer`}
                                onClick={() => {
                                    setCommentModal({ ...commentModal, show: true, comment: dataPayroll?.comment ?? "" });
                                }}
                                height="30px"
                                width="30px"
                            />
                        </OverlayTrigger>)}
                </h1>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={6}
                        className="d-flex align-items-end justify-content-end"
                    >
                        <input
                            className={`${tableStyles.blueSearch} mr-2`} //${tableStyles.clearBlueSearch}
                            style={{ flex: 1, color: `#73a6dc` }}
                            name="search"
                            type="text"
                            placeholder="Buscar..."
                            value={filters.search}
                            onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, search: e.target.value } }))}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                                }
                            }}
                        />

                        <img
                            src={Search}
                            style={{ cursor: "pointer" }}
                            alt="Search icon"
                            onClick={() => {
                                setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className="d-flex align-items-end justify-content-end"
                    >
                        <img
                            src={excelIcon}
                            style={{ cursor: "pointer" }}
                            alt="Search icon"
                            onClick={() => {
                                getExcelFunction();
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className="py-4">
                    <Grid
                        item
                        xs={12}
                    >
                        <TableScroll
                            body={dataDetail.length > 0 && bodyTable()}
                            headers={renderHeaders}
                        ></TableScroll>
                    </Grid>
                </Grid>
                <Grid className="d-flex justify-content-end gap-3 mt-3">
                    <Button
                        style={{ textTransform: "none" }}
                        className={tableStyles.btnCancelStyle}
                        onClick={() => {
                            backPage.setData(state => ({ ...state, generatePremiumSchedule: false, trigger: state.trigger + 1 }));
                        }}
                    >
                        Cancelar
                    </Button>
                    {status === "loaded" && (
                        <Button
                            style={{ textTransform: "none" }}
                            className={tableStyles.btnYesStyle}
                            onClick={() => sendToAccountingFunction()}
                        >
                            <div className="d-flex gap-2 px-2">
                                <img
                                    src={avion}
                                    alt="avion"
                                />
                                <span className="">Enviar a contabilidad</span>
                            </div>
                        </Button>
                    )}
                </Grid>
            </div>
        </>
    );
};
