import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import threeDots from "../../../assets/img/icons/threeDots.svg";
import deleteIcon from "../../../assets/img/icons/cleanCloseIcon.svg";
import send from "../../../assets/img/icons/paperplane.svg";
import detail from "../../../assets/img/icons/lupaTransparent.svg";
import iconProvisions from "../../../assets/img/icons/cloneIcon.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import {  useSelector,  } from "react-redux";
import "reactjs-popup/dist/index.css";
import Styles2 from "../../GeneratePayroll/generatePayroll.module.scss";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import CustomPopupExtend from "../../Popup/customPopUpExtends";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid } from "@mui/material";
import tableStyles2 from "../../../components/Layouts/tableStyle.module.scss";
import CustomPopupStyles from "../../../components/Popup/customPopup.module.scss";
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import {convertMoneyFormat, formatToRcSelect, loader, message, today, validateEmptyString} from '../../../helpers/helpers'
import { GeneratePremiumSchedule } from "./GeneratePremiumSchedule";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { customSelectNew } from "../../Layouts/react-select-custom";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";

export const PremiumSchedule = () => {
  const store = useSelector((state) => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "planPrim");
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [data, setData] = useState({
    trigger:0,
    generatePremiumSchedule:null,
    idTemplateDetail: "",
    statusTemplate: "",
    templateDetailActive: false,
    dataPayroll: "",
    filters:{
      year: "",
      search: "",
      page: 1,
      perpage: 10,
      eaccount: idAccount,
    },
    modal:{
      show:false,
      isEditing:false,
      datePayment: "",
      bonusPayPeriod: "-undefined"
    }
  })
  const {filters,trigger,modal,generatePremiumSchedule} = data
  const { results: PremiumScheduleResults, load: premiumScheduleLoader, trigger: getPremiumSchedule} = useGetMethod()
  const { results: bonusPayPeriodResults, load: bonusPayPeriodLoader, trigger: getBonusPayPeriod} = useGetMethod()
  const { load: loaderGeneratePayrollBonus, trigger: generatePayrollBonus } = usePostMethod();
  const { load: deletePayrollLoader, trigger: deletePayrollMethod } = usePostMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  const [bonusSalaryOptions, setBonusSalaryOptions] = useState([])
  const [triggerSelect,setTriggerSelect]=useState(0)
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
    getPremiumSchedule({
      url: "/payroll/bonus_pay",
      objFilters: filters,
      token: token,
      doAfterSuccess: (res) => {
      },
     });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, generatePremiumSchedule]);

  useEffect(() => {
    getBonusPayPeriod({
      url: "/payroll/settlement-cycles-reasons/",
      objFilters: {
        eaccount: idAccount,
        end_date: today()
      },
      token: token,
      doAfterSuccess: (res) => {
        let formattedBonusSalary = formatToRcSelect(
          res?.last_bonus_salary,
          "id",
          "sem",
          "year",
          "end",
          "year"
          );
      let options = []
      if(formattedBonusSalary.length > 0){
        formattedBonusSalary.forEach(option =>{
          if(option.value === 0){
            let label = option.label.split("-")[0]
            options.push({...option, label: label, value: "0"})
          }else{
            options.push(option)
          }
        })
        setBonusSalaryOptions(options)
        setTriggerSelect(triggerSelect + 1)
      }
      },
     });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePremiumSchedule]);
  const renderElement = (elem) => {
    
    const optionElem = {
      detail:{
        text: <span>Ver detalle</span>,
        class: CustomPopupStyles.popUpGeneric,
        event: !!myPermission?.read ? (e) => {setData({...data, idTemplateDetail: elem?.id, statusTemplate:elem?.status?.name, generatePremiumSchedule:true, dataPayroll:elem }) } : null,
        icon: detail
      },
      sendToTreasury:{
        text: <span>Enviar a tesorería</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: send,
        event:!!myPermission?.edit ? () => { } : null,
      },
      delete:{
        text: <span>Eliminar</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: deleteIcon,
        event: !!myPermission.edit ? () => {
          customSwaltAlert({
            title: `¿Está seguro?`,
            text: `Se eliminará la planilla del año ${elem?.paymentYear} con periodo ${elem?.range}`,
            icon: "warning",
            confirmButtonText: "Si, continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              deletePayrollFunction(elem?.id);
            }
          });
         } : null
      },
      sendToAccounting:{
        text: <span>Enviar a contabilidad</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: send,
        event: !!myPermission.edit ? () => { sendToAccountingFunction(elem.id, elem.paymentYear, elem.range)} : null
      },
      failed:{
          text: "Ver fallo",
          icon: iconProvisions,
          class: CustomPopupStyles.popUpGeneric,
          event: () => {
              message("warning", "Intenta de nuevo", elem?.cron_log_message, undefined, true )
          },
      }
    }
    const options = {
      loaded: [ optionElem.detail, optionElem.delete, optionElem.sendToAccounting],
      review: [ optionElem.detail],
      rejected:  [ optionElem.detail, optionElem.delete],
      approved: [ optionElem.detail, optionElem.sendToTreasury,],
      payed: [ optionElem.detail,],
      failed: [optionElem.failed, optionElem.delete]
    }
    return (
      <tr key={elem.id}>
        <td className={`col-3 px-3 text-start fw-bold ${tableStyles2.darkBlueText}`}>
          {elem?.paymentYear ?? "-"}
        </td>
        <td className="col-2 text-center">
          {elem?.range ?? "-"}
        </td>
        <td className="col-2 text-center">
          {elem?.totalEmployees ?? "-"}
        </td>
        <td className="col-2 text-end">
          {convertMoneyFormat(elem?.totalAmount ?? "-")}
        </td>
        <td className="col-2 text-center px-2">
          <div
            className="rounded-pill p-1"
            style={{
              backgroundColor: elem?.status?.bgColor,
            }}
          >
            <b style={{ color: elem?.status?.fontColor }}>{elem?.status?.value ?? "-"}
            </b>
          </div>
        </td>
        <td className="col-1 ">
        {elem.status.name!=="in_process" ?
          <CustomPopupExtend
            triggerSrc={threeDots}
            center
            noHover
            extraButtons={options?.[elem?.status?.name]}
          ></CustomPopupExtend>
          :
          <div style={{width: "24px"}}>&nbsp;</div>
          }
        </td>
      </tr>
    );
  };

  const renderHeaders = [
    <th key={1} className="text-start col-3 px-3">
      Año
    </th>,

    <th key={2} className="text-center col-2">
      Periodo
    </th>,

    <th key={3} className="text-center col-2">
      No. de empleados
    </th>,

    <th key={4} className="text-end col-2">
      Total
    </th>,

    <th key={5} className="text-center col-2">
      Estado
    </th>,

    <th key={6} className="text-start col-1">
      &nbsp;
    </th>,
  ];

  const sendToAccountingFunction =(id, paymentYear, range)=>{
    customSwaltAlert({
      icon:'warning',
      title: '¿Está seguro?',
      text: `Se enviará a contabilidad la planilla de primas`,
    }).then((result) => {
      if (result.isConfirmed) {
        updatePayroll({
          url: "/payroll/bonus_pay",
          token: token,
          method: "PUT",
          body: {
            "id" : id,
            "eaccount" : idAccount,
            "status":"review"
          },
          succesAction: (info) =>{
              setData(state=>({...state, trigger: state.trigger+1}))
            customSwaltAlert({
              title: `Enviada exitosamente`,
              text: `Se ha enviado a contabilidad planilla del año ${paymentYear} - Periodo ${range}. `,
              icon: "success",
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }
        });
      }
    });
  }

  const deletePayrollFunction = (id) => {
    deletePayrollMethod({
      url: "/payroll/bonus_pay",
      method: "DELETE",
      body: {
        id: id,
        eaccount: idAccount,
      },
      token: token,
      succesAction: () => {

        setData(state=>({...state, trigger: state.trigger + 1
        }))
        customSwaltAlert({
          icon:'success',
          title:'Actualizado exitosamente',
          text: `Se ha eliminado la planilla de primas`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      },
    });
  };

  const closeModal = ()=>{
    setData(state=>({...state, modal:{
      show:false,
      isEditing:false,
      bonusPayPeriod: "-undefined",
      datePayment: ""
    }}))
  }

  const validationRequest = ()=>{
    let validations = validateEmptyString([
      { field: data?.modal?.bonusPayPeriod, message: "El periodo de primas es obligatorio para generar la planilla de primas" },
      { field: data?.modal?.datePayment, message: "La fecha de pago es obligatorio para generar la planilla de primas" },
    ]);
    let status=true
    if (data.modal.bonusPayPeriod === "-undefined"){
      status=false
      message("info", "Campos vacíos", "El periodo de primas es obligatorio para generar la planilla de primas")
    }

    if (validations.valid && status){
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: "Se generará una planilla de prima.",
        confirmButtonText: "Si, continuar",
      }).then((result) => {
        if (result.isConfirmed) {
          generatePayrollBonus({
            url: "/payroll/bonus_pay",
            token: token,
            noAlert: true,
            method: "POST",
            body: {
              "eaccount" : idAccount,
              "period" : data.modal.bonusPayPeriod,
              "payment_date" : data.modal.datePayment,
              "created_by" : idUser
          },
            doAfterSuccess: (res) => {
              closeModal()

              setData(state=>({...state,
                trigger: state.trigger + 1,
                // generatePremiumSchedule:true,
                // idTemplateDetail: res?.results, 
                modal:{
                  show:false,
                  isEditing:false,
                  bonusPayPeriod: "",
                  datePayment: ""
                }
              }))
            },
            doAfterException: (res)=>{
              message("warning", `Intente de nuevo`, res.message, undefined, true)
            }
        });
        }
      });
    }
  }

  return (
    <>
    {((deletePayrollLoader ||updatePayrollLoader|| premiumScheduleLoader|| loaderGeneratePayrollBonus || bonusPayPeriodLoader) && loader)}
    { !generatePremiumSchedule
      ?(
        <>
          <OrdModal
      title={modal.isEditing ? "" : "Generar planilla de primas"}
      show={modal.show}
      btnYesName={modal.isEditing ? "Guardar" : "Guardar"}
      btnNoName={"Cancelar"}
      size={550}
      onHide={() => closeModal()}
      btnYesEvent={!!myPermission.create ? () => validationRequest() : null}
      btnNoEvent={() => closeModal()}
      sizeBody={`${tableStyles2.modalHeight}`}
      titleClassName={tableStyles2.darkBlueText}
      btnYesStyle={tableStyles2.btnYesStyle}
      btnCancelStyle={tableStyles2.btnCancelStyle}
    >

      <Grid container spacing={3}>

        <Grid item xs={12}>
        <label className={tableStyles.crudModalLabel}>
        Periodo de primas
        </label>
        <SelectComponent
          key={`select+${triggerSelect}`}
          className="text-secondary"
          placeholder={"Seleccionar..."}
          styles={customSelectNew}
          options={bonusSalaryOptions}
          value={bonusSalaryOptions.find((e)=> e.extra2 === "" ? "": e.extra2 === "0" ? "0" : e.extra + "-" + e.extra2 === modal?.bonusPayPeriod)}
          onChange={(e) => {
            let value =   e.extra2 === "0" ? "0" : e.extra + "-" + e.extra2
            setData(state=>({...state, modal:{...state.modal, bonusPayPeriod: value}}))
          }}
        />
        </Grid>
      </Grid>

      <Grid container className="my-3">
        <Grid item xs={12}>
          <label className={tableStyles.crudModalLabel}>
            Fecha de pago
          </label>
          <input
            className={`register-inputs `}
            type="date"
            onChange={(e) =>
              setData(state=>({...state, modal:{...state.modal, datePayment: e.target.value}}))
            }
          />
        </Grid>
      </Grid>

          </OrdModal>
          <div className="w-80 mx-auto">

          <h1 className={tableStyles.title}>Planilla de primas</h1>

          {!!myPermission?.read &&
          <>
          <Grid container spacing={3} display={'grid'} gridTemplateColumns={'200px 300px 2fr'}>
              <Grid item >
                <label className={tableStyles.crudModalLabel}>
                  Año
                </label>
                <input
                  onChange={(e) =>
                    setData(state=>({...state, filters:{...state.filters, year: e.target.value}}))
                  }
                  type="number"
                  placeholder="Escribir..."
                  className={`register-inputs `}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setData(state=>({...state, trigger: state.trigger+1}))
                    }
                  }}
                />
              </Grid>

              <Grid item className='d-flex align-items-end'>
                <input
                  className={` ${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch} 
                  name="search"
                  type="text"
                  placeholder="Buscar..."
                  value={filters.search}
                  onChange={(e)=>
                    setData(state=>({...state, filters:{...state.filters, search: e.target.value}}))
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setData(state=>({...state, trigger: state.trigger+1}))
                    }
                  }}
                />

                <img
                  src={Search}
                  style={{ cursor: "pointer" }}
                  alt="Search icon"
                  height="24px"
                  onClick={() => {
                    setData(state=>({...state, trigger: state.trigger+1}))
                  }}
                />
              </Grid>

              {!!myPermission?.create &&
              <>
              <Grid item className='d-flex align-items-end justify-content-end'>
                <label 
                  className="darkGray fw-bold" 
                  style={{ color: "#58595B", margin:'4px'}}
                >
                  Generar planilla
                </label>
                <div
                  className={Styles2.btnpreviw}
                  style={{ width: "33px", height: "31px" }}
                  onClick={() => 
                    setData(state=>({...state, modal:{...state.modal, isEditing:false, show:true}}))
                  }
                >
                </div>
              </Grid>
              </>
              }
          </Grid>
          <Grid container>

              <Grid item xs={12}>
                <GenericTableNew headers={renderHeaders} dark={true}>
                  { PremiumScheduleResults?.results?.length> 0 &&
                    PremiumScheduleResults?.results?.map((el)=>{
                      return renderElement(el);
                    })
                  }
                </GenericTableNew>
              </Grid>
              
              <Grid item xs={12}>
              <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {PremiumScheduleResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(PremiumScheduleResults?.rowTotal / filters.perpage)
                ? Math.ceil(PremiumScheduleResults?.rowTotal / filters.perpage)
                : ""}{" "}
              ({PremiumScheduleResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={PremiumScheduleResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => 
              setData(state=>({...state, filters:{...state.filters, page: e}, trigger:state.trigger + 1}))
              }
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
              </Grid>

            
            </Grid>
          </>}
          </div>
        </>
      ) 

      : (
        <GeneratePremiumSchedule backPage={{setData}} idTemplate={data.idTemplateDetail} status={data.statusTemplate} dataPayroll={data.dataPayroll}/>
      )
    }
    
    </>
  );
}
