import React from 'react';
import "../../OrderingModule/orderingStyles.css";
import { Accordion, Card, Col, Row } from 'react-bootstrap';

export const PseudoSideBarAccordion = (props) => {
    let { accordionItems } = props;

    return (
        <div className="w-100 mx-auto">
            <div className={`container-fluid ms-4`}>
                <h1 className={`title-blue-admin mt-5 fw-bold`}>
                    Módulo de Nómina
                </h1>
                {accordionItems?.length > 0 &&
                    accordionItems.map((e, i) => {
                        return (
                            <Accordion className="w-100">
                                <div className="w-100  p-2">
                                    <div className="w-100 ml-1">
                                        <Accordion.Toggle
                                            eventKey={i + 1}
                                            className={`w-100 text-start ord-darkGray-admin`}>
                                            <h5> <b>{e.title}</b></h5>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                            key={'Collapse' + i}
                                            eventKey={i + 1}>
                                            <Card.Body>
                                                {e.content?.length > 0 &&
                                                    e.content.map((item, i) => {
                                                        return (<Row>
                                                            <Col
                                                                key={i}
                                                                onClick={() => item.action()}
                                                                xs={11}
                                                                className={props.ActiveBlue ? 'ord-darkBlue2' : 'ord-darkGraySub'}>
                                                                <h5>{item?.subtitle}</h5> </Col>
                                                        </Row>
                                                        );
                                                    })
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </div>
                                </div>
                            </Accordion>
                        );
                    })}
            </div>
        </div>
    );
};
