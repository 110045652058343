import React, { useEffect, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import deleteIcon from "../../assets/img/icons/cleanCloseIcon.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import iconProvisions from "../../assets/img/icons/cloneIcon.svg";
import Styles from "./generatePayroll.module.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import Pagination from "react-js-pagination";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import CustomPopupStyles from "../../components/Popup/customPopup.module.scss";
// import CreateContract from "../CreateContract";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { URL_GATEWAY, API_VERSION } from "../../helpers/constants";
import { convertFilterToString } from "../../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CustomPopupExtend from "../../components/Popup/customPopUpExtends";
import ModalNew from "../../components/Layouts/ModalNew";
import DetailPayrollTemplate from "./DetailPayrollTemplate";
import SelectComponent from "../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
import { useGetMethod } from "../../Hooks/useFetch";
import { convertMoneyFormat, message } from "../../helpers/helpers";
import { ImbalanceWarningModal } from "./ImbalanceWarningModal";

const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

function getColorStatus({ color, fontColor, textStatus, status_label }) {
  switch (textStatus) {
    //'loaded', 'review', 'approved', 'rejected', 'processed', 'cancelled'
    //Borrador, En revisión, Aprobada, Rechazada, Procesada, Cancelada
    case textStatus:
      return {
        status_es: status_label,
        color,
        fontColor,
      };

    default:
      return {
        status_es: status_label,
        color,
        fontColor,
      };
  }
}

function GeneratePayrollList(props) {
  const MySwal = withReactContent(Swal);
  // funcion para formatear la fecha
  var date = new Date();
  const formatDate = (date) => {
    let formatted_date =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return formatted_date;
  };

  // funcion para formatear valores de respuesta
  const numberWithCommas = (x) => {
    if (x) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(".");
    } else {
      return x;
    }
  };

  const dispatch = useDispatch();
  const counter = useSelector((state) => state);
  const [trigger, setTrigger] = useState(1);

  const [filters, setFilter] = useState({
    //search: "",
    page: 1,
    perpage: 12,
    month: undefined,
    //status: "",
    entity_account: counter.loginReducer.currentAccount.id,
    module: "payrollGen",
    //area_id: "",
  });

  const [details, setDetails] = useState({
    show: false,
    sheet: "",
    status: "",
  });

  const [showDetail, setshowDetail] = useState({
    show: false,
  });

  let history = useHistory();

  const {
    trigger: getImbalance,
    results: resultImbalace,
    load: loadImbalance,
  } = useGetMethod();

  const myPermission =
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "plandenom"
    );
  const token = counter.loginReducer.Authorization;
  const [showImbalanceModal, setShowImbalanceModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, [filters.page, trigger]);

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [nominaList, setNominaList] = useState([]);
  const [nominaTotal, setNominaTotal] = useState([]);

  //Generate  -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [generate, setGenerate] = useState({
    entity_account: counter.loginReducer.currentAccount.id,
    entity_name: counter.loginReducer.currentAccount.name,
    start_date: "",
    end_date: "",
    payment_date: "",
    created_by: counter.loginReducer.user_data.id,
  });

  //fetch zone -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const fetchData = async () => {
    setIsLoading(true);
    let str_filters = convertFilterToString(filters);
    const getMyStuff = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/planilla_nomina/?${str_filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        setNominaList(res.results);
        setNominaTotal(res.total);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setIsLoading(false);
      });
  };

  const editDataStep2 = async (data, method) => {
    setIsLoading(true);
    const getMyStuff = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/payroll_sheet/`,
      {
        method: method,
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          let message = `Se ha enviado a contabilidad planilla del mes ${data.see_month} - ${data.see_year}`;
          let title = "Enviada exitosamente";
          if (data.status === "cancelled") {
            message = `Se ha eliminado la plantilla de nómina periodo ${data.see_month} ${data.see_year}`;
            title = "Actualizado exitosamente";
          }
          customSwaltAlert({
            icon: "success",
            title: title,
            text: message,
            showCancelButton: false,
            confirmButtonText: "Aceptar",
          });
          setTrigger(trigger + 1);
        } else {
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            showCancelButton: false,
            confirmButtonText: "Aceptar",
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const editData = async (data) => {
    let message = `Se enviará la plantilla de nómina periodo ${data.see_month} ${data.see_year}`;
    if (data.status === "cancelled") {
      message = `Se eliminará la plantilla de nómina periodo ${data.see_month} ${data.see_year}`;
    }
    customSwaltAlert({
      icon: "warning",
      title: "¿Está seguro?",
      showCancelButton: true,
      text: message,
      confirmButtonColor: "#005DBF",
      cancelButtonColor: "#fff",
      confirmButtonText: "Sí, continuar",
      cancelButtonText: "Cancelar",
    }).then((response) => {
      if (response.isConfirmed) {
        editDataStep2(data, data.status === "cancelled" ? "DELETE" : "PUT");
      }
    });
  };
  //END fetch zone -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_

  //Generate template -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const {
    results: statusPayrollResults,
    load: statusPayrollLoader,
    trigger: getStatusPayroll,
  } = useGetMethod();

  const btnGenerate = async (data) => {
    setIsLoading2(true);
    const getMyStuff = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/generate-payroll-template/`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          customSwaltAlert({
            icon: "success",
            title: "Actualizado exitosamente",
            text: `${res.message}`,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
          });
          setTrigger(trigger + 1);
          setShowModal({ show: false });
          setIsLoading2(false);
          setGenerate({
            entity_account: counter.loginReducer.currentAccount.id,
            entity_name: counter.loginReducer.currentAccount.name,
            start_date: "",
            end_date: "",
            payment_date: "",
            created_by: counter.loginReducer.user_data.id,
          });
          getStatusPayroll({
            url: "/payroll/planilla_nomina/",
            objFilters: {
              entity_account: counter.loginReducer.currentAccount.id,
              payrollSheetId: res.payroll_sheet,
            },
            token: token,
            doAfterSuccess: (results) => {
              // setDetails({show: true, sheet: res.payroll_sheet, status: results.results[0].status});
            },
          });
        } else {
          setIsLoading2(false);
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `${res.message}`,
            confirmButtonText: "Aceptar",
            showCancelButton: false,
          });
        }
      })
      .catch((err) => {
        console.error(err.message);
        setIsLoading2(false);
      });
  };
  //END Generate template  -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_

  //Pass to the next page  -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const handlerClick = () => {
    history?.push("/nomina/generarPlanillaDeNomina");
  };

  const searchFilters = () => {
    setFilter({
      ...filters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };

  const handlePageChange = (val) => {
    setFilter({
      ...filters,
      page: val,
    });
  };

  const handleCloseImbalanceModal = () => {
    setShowImbalanceModal(false);
  };

  const isThereImbalance = (payrollId) => {
    return new Promise((resolve) => {
      getImbalance({
        url: "/payroll/verify_data",
        objFilters: {
          entity_account: counter.loginReducer.currentAccount.id,
          payroll_id: payrollId,
        },
        token: token,
        succesAction: (res) => {
          if (res.success) {
            if (res.results.length !== 0) {
              setShowImbalanceModal(true);
              resolve(true);
            }
          }
          resolve(false);
        },
      });
    });
  };

  const handleEdit = async (item, textMonth) => {
    const imbalance = await isThereImbalance(item.PS_ID);
    if (imbalance) {
      return;
    }
    editData({
      id: item.PS_ID,
      see_year: item.payment_year,
      see_month: textMonth,
      status:
        item?.status === "loaded" || item?.status === "rejected"
          ? "review"
          : "settlement",
    });
  };

  const header = [
    <th className="pl-1 col-2 text-start" style={{ paddingLeft: "10px" }}>
      Descripción
    </th>,
    <th className="pl-1 col-2 text-start">Año</th>,
    <th className="pl-1 col-2 text-start">Mes</th>,
    // <th className="pl-1 col-1 text-start">Año</th>,
    <th className="col-1 text-center">Periodo</th>,
    <th className="col-2 text-center">No. de empleados</th>,
    <th className="col-2 text-center">
      <p style={{ margin: 0, paddingRight: "15px", textAlign: "end" }}>Total</p>
    </th>,
    <th className="col-1 text-center">Estado</th>,
    <th className="col-1 text-center" style={{ paddingLeft: "10px" }}>
      &nbsp;
    </th>,
  ];
  const body = [];
  if (Array.isArray(nominaList)) {
    nominaList.map((item) => {
      const objColorStatus = {
        fontcolor: item.fontcolor,
        color: item.background,
        textStatus: item.status,
        status_label: item.status_label,
      };

      const statuscolors = getColorStatus(objColorStatus);
      const textMonth = isNaN(parseInt(item.payment_month))
        ? "-"
        : monthNames[parseInt(item.payment_month) - 1];
      body.push(
        <tr key={"payroll" + item.PS_ID}>
          <td className="text-start col-2" style={{ paddingLeft: "10px" }}>
            {item.description}
          </td>
          <td className="text-start col-2">
            <b style={{ color: "#005DBF" }}>{item.payment_year}</b>
          </td>
          <td className="text-start col-2">
            <b style={{ color: "#005DBF" }}>{textMonth}</b>
          </td>
          {/* <td className="text-start col-1">{item.payment_year}</td> */}
          <td className="text-center col-1">{item.range}</td>
          <td className="text-center col-2">{item.employee_number}</td>
          <td className="text-end col-2" style={{ paddingRight: "15px" }}>
            {convertMoneyFormat(item.total_amount)}
          </td>
          <td className="text-center col-1">
            <div
              className="rounded-pill p-1"
              style={{
                backgroundColor: item.background,
              }}
            >
              <b style={{ color: item.fontcolor }}>{statuscolors.status_es}</b>
            </div>
          </td>

          <td className="col-1">
            {item.status !== "in_process" ? (
              <CustomPopupExtend
                showDetails={
                  !!myPermission?.edit && item.status !== "failed" && true
                }
                noHover
                triggerSrc={threeDots}
                showDetailsEvent={() => {
                  setDetails({
                    show: true,
                    sheet: item.PS_ID,
                    status: item.status,
                    elem: item,
                  });
                }}
                textDetails="Ver Detalle"
                showDelete={
                  !!myPermission?.edit &&
                  (item.status == "loaded" || item.status == "rejected")
                }
                deleteEvent={() =>
                  editData({
                    id: item.PS_ID,
                    see_year: item.payment_year,
                    see_month: textMonth,
                    status: "cancelled",
                  })
                }
                deleteText="Eliminar"
                showEdit={
                  !!myPermission?.edit &&
                  (item.status === "loaded" || item.status === "rejected")
                }
                editClickEvent={() =>
                    handleEdit(item, textMonth)
                }
                editText="Enviar a contabilidad"
                extraButtons={
                  ["approved", "processed"].includes(item?.status)
                    ? []
                    : item.status === "failed"
                    ? [
                        {
                          text: "Ver fallo",
                          icon: iconProvisions,
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            message(
                              "warning",
                              "Intenta de nuevo",
                              item?.cron_log_message,
                              undefined,
                              true
                            );
                          },
                        },
                        {
                          text: "Eliminar",
                          icon: deleteIcon,
                          class: CustomPopupStyles.popUpGeneric,
                          event: () =>
                            editData({
                              id: item.PS_ID,
                              see_year: item.payment_year,
                              see_month: textMonth,
                              status: "cancelled",
                            }),
                        },
                      ]
                    : []
                }
              />
            ) : (
              <div style={{ width: "24px" }}>&nbsp;</div>
            )}
          </td>
        </tr>
      );
    });
  }
  let optionConsumptionCenter = [
    { value: "", label: "Seleccione área", name: "area" },
  ];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.map((item) => {
      optionConsumptionCenter.push({
        value: item.id,
        label: item.description,
      });
    });
  }

  const monthOptions = [
    { label: "Seleccionar...", value: "" },
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 },
  ];
  const renderPrincipal = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {(isLoading || statusPayrollLoader || loadImbalance) && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      <div className="d-flex">
        <h1 className={tableStyles.title}>Planillas de nómina</h1>
      </div>

      {!!myPermission?.read && (
        <>
          <Row className="align-items-end">
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Mes</p>
              <SelectComponent
                value={monthOptions.filter((x) => x.value === filters.month)}
                styles={customSelectNewDark}
                placeholder={"Seleccionar..."}
                key={"state" + trigger}
                onChange={(e) => {
                  setFilter({
                    ...filters,
                    month: e.value,
                    page: 1,
                  });
                  setTrigger(trigger + 1);
                }}
                // noOptionsMessage={() => "No hay datos"}
                options={monthOptions}
              ></SelectComponent>{" "}
            </Col>
            <Col xs={3} className="d-flex gap-2">
              <div className="">
                <p className={tableStyles.crudModalLabel}>Año</p>
                <input
                  className="register-inputs"
                  type="number"
                  value={filters.year}
                  onChange={(e) =>
                    setFilter({
                      ...filters,
                      year: e.target.value,
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchFilters();
                    }
                  }}
                ></input>
              </div>
              <img
                className="hoverPointer align-self-end"
                alt="iconSearch"
                style={{}}
                src={Lupa}
                height="24px"
                onClick={(e) => searchFilters(e)}
              />
            </Col>

            <Col xs={7} className="align-self-end">
              {!!myPermission?.create && (
                <div className="d-flex justify-content-end gap-2">
                  <span
                    className="darkGray fw-bold align-self-center"
                    style={{ color: "#58595B", fontSize: "13px" }}
                  >
                    Generar planilla{" "}
                  </span>
                  <div
                    className={Styles.btnpreviw}
                    style={{ width: "31px", height: "31px" }}
                    onClick={() => setShowModal({ show: true })}
                  ></div>
                </div>
              )}
            </Col>
          </Row>
          <div></div>
          <GenericTable
            lowerCase
            headers={header}
            children={body}
            dark={true}
          ></GenericTable>
        </>
      )}

      <ImbalanceWarningModal
        data={resultImbalace.results}
        show={showImbalanceModal}
        onCloseModal={handleCloseImbalanceModal}
      />

      <ModalNew
        show={showModal.show}
        onHide={() => {
          setShowModal({ show: false });
          setGenerate({
            entity_account: counter.loginReducer.currentAccount.id,
            entity_name: counter.loginReducer.currentAccount.name,
            start_date: "",
            end_date: "",
            payment_date: "",
            created_by: counter.loginReducer.user_data.id,
          });
        }}
        title={"Generar planilla de nómina"}
        btnYesEvent={() => {
          btnGenerate(generate);
        }}
        btnYesName={"Generar"}
        btnYesDisabled={
          !!myPermission?.create &&
          (!!(generate.start_date == "") ||
            !!(generate.end_date == "") ||
            !!(generate.payment_date == ""))
        }
        btnNoEvent={() => {
          setShowModal({ show: false });
          setGenerate({
            entity_account: counter.loginReducer.currentAccount.id,
            entity_name: counter.loginReducer.currentAccount.name,
            start_date: "",
            end_date: "",
            payment_date: "",
            created_by: counter.loginReducer.user_data.id,
          });
        }}
        btnNoName={"Cancelar"}
        size={"500"}
      >
        {isLoading2 && (
          <div className="loading">
            <Loader type="Oval" color="#003f80" height={100} width={100} />
          </div>
        )}
        <Row className="d-flex">
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>
              Fecha de inicio
            </label>
            <input
              className="register-inputs"
              style={{ color: "#7FADDE", fontWeight: "700" }}
              type="date"
              value={generate.start_date}
              onChange={(e) =>
                setGenerate({
                  ...generate,
                  start_date: e.target.value,
                })
              }
              disabled={false}
            ></input>
          </Col>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Fecha de fin</label>
            <input
              className="register-inputs"
              style={{ color: "#7FADDE", fontWeight: "700" }}
              type="date"
              value={generate.end_date}
              onChange={(e) =>
                setGenerate({
                  ...generate,
                  end_date: e.target.value,
                })
              }
              disabled={false}
            ></input>
          </Col>
        </Row>

        <Row className="d-flex" style={{ marginBottom: "12px" }}>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Fecha de pago</label>
            <input
              className="register-inputs"
              style={{ color: "#7FADDE", fontWeight: "700" }}
              type="date"
              value={generate.payment_date}
              onChange={(e) =>
                setGenerate({
                  ...generate,
                  payment_date: e.target.value,
                })
              }
              disabled={false}
            ></input>
          </Col>
        </Row>
      </ModalNew>

      {nominaTotal > 12 ? (
        <div className={paginationStyles.wrapper}>
          <p className={` ${paginationStyles.paginationText} darkGray`}>
            pag. {nominaTotal ? filters.page : ""}
            {" de "}
            {Math.ceil(nominaTotal / filters.perpage)
              ? Math.ceil(nominaTotal / filters.perpage)
              : ""}{" "}
            ({" " + nominaTotal}
            {" encontrados"})
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={nominaTotal}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={`${paginationStyles.itemClassPrev} `}
            itemClassNext={`${paginationStyles.itemClassNext} `}
            itemClassFirst={`${paginationStyles.itemClassFirst} `}
            itemClassLast={`${paginationStyles.itemClassLast} `}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      ) : null}
    </div>
  );

  return !!details.show ? (
    <DetailPayrollTemplate
      payroll_sheet={details.sheet}
      details={details}
      setDetails={setDetails}
      showModal={showModal}
      setTrigger={setTrigger}
      trigger={trigger}
      setShowModal={setShowModal}
    />
  ) : (
    renderPrincipal
  );
}
export default GeneratePayrollList;
