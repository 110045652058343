import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import backArrow from "../../assets/img/icons/atras-icon.svg";
import Adjuntar from "../../assets/img/icons/Adjuntar.svg";
import Close from "../../assets/img/icons/cleanCloseIcon.svg";

import tableStyles from "../Layouts/tableStyle.module.scss";
import { useHistory } from "react-router";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import Dropzone from "react-dropzone";
import {
  getCandidateList,
  registerNewDisciplinaryProcess,
} from "../../actions/partnersActions";
import {
  convertBase64,
  convertDateToLatinFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
  today,
} from "../../helpers/helpers";
import SelectComponent from "../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

import Select from "react-select"
export const NewDisciplinaryProcess = () => {
  const store = useSelector((state) => state);
  const animatedComponents = makeAnimated();
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [inputs, setInputs] = useState({
    involved: ""
  });
  const token = store.loginReducer.Authorization;
  let id_candidate = store?.loginReducer?.user_data?.payrollInfo?.id_candidate;
  let id_contract = store?.loginReducer?.user_data?.payrollInfo?.id_contract;
  const fullName =
    store.loginReducer.user_data.first_name +
    " " +
    store.loginReducer.user_data.first_surname;
  const [currentColabs, setCurrentColabs] = useState();
  const arrEmployees = store.partnersReducer.employee_list;
  const dispatch = useDispatch();
  const history = useHistory();
  const handleBack = () => {
    history.push("/nomina/procesosDisciplinario");
  };
  const jobTitle = store.loginReducer?.user_data?.payrollInfo?.job_title;
  const jobId = store.loginReducer?.user_data?.payrollInfo?.id_jobPosition;
  const areaName = store.loginReducer?.user_data?.payrollInfo?.area_name;
  const [documents, setDocuments] = useState([]);
  const areaId = store.loginReducer?.user_data?.payrollInfo?.id_area;
  const formattedEmployees = formatToRcSelect(
    arrEmployees,
    "id_candidate",
    "full_name",
    "id_contract",
    "job_title",
    "",
    false,
  );
  const x = formatToRcSelect();
  const [allowSend, setallowSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs.concept) &&
      !isEmptyOrUndefined(inputs.description) &&
      documents.length > 0 &&
      !isEmptyOrUndefined(inputs.involved)
    ) {
      setallowSend(true);
    } else {
      setallowSend(false);
    }
  }, [inputs, documents]);

  useEffect(() => {
    dispatch(
      getCandidateList({
        status: "current",
        filtered: 1,
        eaccount: idEnterprise,
      })
    );
  }, []);

  const handleChangeColabs = (e) => {
    setCurrentColabs(e);
    let arrIdEnd = [];

    if (e.length > 0) {
      e.forEach((elem) => {
        arrIdEnd.push(elem.value);
      });
    }
    setInputs({ ...inputs, involved: arrIdEnd });
  };

  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const onChangeDoc = async (e) => {
    const file = e.target.files[0];

    const validExtensions = [
      "zip",
      "ZIP",
      "pdf",
      "PDF",
      "rar",
      "RAR",
      "gif",
      "GIF",
      "jpeg",
      "png",
      "swf",
      "psd",
      "bmp",
      "tiff",
      "jpc",
      "jp2",
      "jpf",
      "jb2",
      "swc",
      "aiff",
      "wbmp",
      "xbm",
      "jpg",
      "mp4",
      "mov",
      "mpg",
      "flv",
      "3gp",
      "webm",
      "wmv",
      "xlsx",
      "xls",
    ];

    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split(".");
      let extension = validExtensions.find(
        (obj) => obj === actFileExt[actFileExt.length - 1]
      );
      if (isEmptyOrUndefined(extension)) {
        e.target.value = "";
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }

      if (file.size > 250000000) {
        e.target.value = "";
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Su archivo no puede tener un tamaño superior a 25MB`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

      } else {
        if (file.size <= 0) {
          e.target.value = "";
          return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Por favor, adjunte un archivo valido.`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        }
      }
      if (!isEmptyOrUndefined(file)) {
        // setSelectedFile(file);
      }
    } else {
      return console.error("Archivo vacio: ", file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split(".")[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    let ext = type_doc.split("/");
    let finalName = `${name_doc}.${ext[1]}`;
    if (documents.find((x) => x.file64 === file64)) {
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `Este archivo ya fue cargado`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    }

    setDocuments([
      ...documents,
      {
        filename: finalName,
        base64: file64,
        route: "complaint",
      },
    ]);
  };

  const handleRemove = (elem) => {
    let newArr = documents.filter((x) => x.file64 !== elem.file64);

    setDocuments(newArr);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    if (documents.length <= 0) {
      setIsLoading(false);
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `Debe cargarse al menos un documento`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
       
    }
    try {
      let result = await registerNewDisciplinaryProcess(
        {
          reporter_id: id_candidate,
          job_position_id: jobId,
          date: convertDateToLatinFormat(today()),
          area_id: areaId,
          area_name: areaName,
          title: inputs.concept,
          description: inputs.description,
          entity_account: idEnterprise,
          created_by: idAccount,
          employees_improvement: inputs.involved,
          // employees_improvement: [1, 2, 4,24,34,6,766,86],
          files: documents,
        },
        token
      );

      if (result.success) {
        setIsLoading(false);
        handleBack();
        setInputs({});
        return customSwaltAlert({
          icon: "success",
          title: "Creado exitosamente",
          text: `Se ha creado un proceso disciplinario`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        });
      }
    } catch (error) {
      setIsLoading(false);
      return console.error(error);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      <div className="w-100 container">
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={backArrow}
                className={`mr-2 mb-2 cursorPointer`}
              ></img>
              Registrar proceso disciplinario
            </h1>
          </Col>
        </Row>

        {/* /* ------------------------------  ROW 1----------------------------- */}
        <div style={{ marginLeft: "3%" }}>
          <Row style={{ width: "80%" }}>
            <Col xs={5}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Reportante
                {/* <span className={"text-warning"}>*</span> */}
              </p>
              <input
                value={fullName}
                disabled={true}
                className="register-inputs text-secondary"
              />
            </Col>
            <Col xs={5}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Cargo
                {/* <span className={"text-warning"}>*</span> */}
              </p>
              <input
                value={jobTitle}
                disabled={true}
                className="register-inputs text-secondary"
              />
            </Col>
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha
                {/* <span className={"text-warning"}>*</span> */}
              </p>
              <input
                value={convertDateToLatinFormat(today())}
                disabled={true}
                className="register-inputs text-secondary"
              />
            </Col>
          </Row>
          <Row className="mt-2" style={{ width: "80%" }}>
            <Col xs={5}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Área
                {/* <span className={"text-warning"}>*</span> */}
              </p>
              <input
                value={areaName}
                disabled={true}
                className="register-inputs text-secondary"
              />
            </Col>
            <Col xs={7}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Concepto
                <span className={"text-warning"}>*</span>
              </p>
              <input
                placeholder="Escribir..."
                value={inputs.concept}
                onChange={(e) =>
                  setInputs({ ...inputs, concept: e.target.value })
                }
                value={inputs.concept}
                className="register-inputs text-secondary"
              />
            </Col>
          </Row>
          <h6 className={`${tableStyles.darkBlueText} mt-4`}>
            <b>• Descripción</b>
          </h6>
          <Row style={{ width: "80%" }}>
            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Descripción de la situación
                <span className={"text-warning"}>*</span>
              </p>
              <textarea
                onChange={(e) =>
                  setInputs({ ...inputs, description: e.target.value })
                }
                value={inputs.description}
                style={{ height: "6rem", minHeight: "3rem" }}
                placeholder="Escribir..."
                rows="5"
                cols="40"
                className="register-inputs"
                name="requisition_date"
              />
            </Col>
          </Row>
          <Row style={{ width: "80%" }}>
            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Colaboradores implicados
                <span className={"text-warning"}>*</span>
              </p>
              <Select
                isMulti
                noOptionsMessage={() => 'No hay datos'}
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={(e) => handleChangeColabs(e)}
                options={formattedEmployees}
                className="text-secondary"
                placeholder={"Seleccionar..."}
                styles={customSelectNewDark}
                value={formattedEmployees.find((x) => x.value === inputs?.involved)}

              ></Select>
            </Col>
          </Row>
          <h6 className={`${tableStyles.darkBlueText} mt-4`}>
            <b>• Evidencias</b>
          </h6>
          <Row style={{ width: "80%" }}>
            <Col xs={12}>
              <div className="display-grid">
                <label className={`${tableStyles.crudModalLabel} m-0`}>
                  Anexos
                  <span className="text-warning">*</span>
                </label>
              </div>
              <div style={{ height: "3rem", width: "40%" }}>
                <Dropzone
                //   onDrop={(acceptedFile) => validateFile(acceptedFile)}
                //   accept={type_file}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className="w-100 h-100 p-1 text-center mx-auto cursorPointer"
                      style={{
                        border: "solid 1px #7FADDE",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        {...getRootProps()}
                        className="d-flex justify-content-between"
                      >
                        <input
                          {...getInputProps()}
                          type="file"
                          name="documents"
                          onChange={(e) => onChangeDoc(e)}
                          // accept={validExtensions}
                          multiple={false}
                        />

                        <p
                          className={`text-muted m-0 m-auto text-center  mt-2 hoverPointer`}
                        >
                          {"Soltar archivo aquí"}
                        </p>

                        {
                          <img
                            src={Adjuntar}
                            alt="añadir"
                            className="pt-3"
                            // className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.Aling}`}
                          />
                        }
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </Col>
          </Row>

          <Row style={{ width: "80%" }} className={`mt-4`}>
            <Col xs={12} className=" d-flex">
              {documents.length > 0 &&
                documents.map((e, i) => {
                  return (
                    <div
                      key={"container" + i}
                      className="d-flex justify-content-between"
                    >
                      <label
                        key={"label" + i}
                        className={`${tableStyles.crudModalLabel} m-0`}
                      >
                        <img
                          key={"close" + i}
                          // onMouseEnter={() => setImage(Cerrar)}
                          // onMouseLeave={() => setImage(Close)}
                          onClick={() => handleRemove(e)}
                          // width="20%"
                          className={`cursorPointer `}
                          src={Close}
                        ></img>
                        &nbsp;
                        <u style={{ marginRight: "1rem" }}>{e.filename} </u>
                      </label>
                    </div>
                  );
                })}
            </Col>
          </Row>

          <Row style={{ width: "80%" }} className="pb-5">
            <Col xs={12}>
              <div className={`mt-2 p-0 d-flex justify-content-end`}>
                <Button
                  className={tableStyles.btnPrimary}
                  onClick={() => handleSubmit()}
                  disabled={allowSend ? false : true}
                >
                  Guardar
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
