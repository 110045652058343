//_--------------------. import hooks ⛳ -----------------------------//
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  getSelectStatus,
  getWorkAccidents,
} from "../../actions/payrollActions";
import { useSelector, useDispatch } from "react-redux";

//----------------------------- ☪ Images--------------------------------//
import Lupa from "../../assets/img/icons/lupa.svg";
import auctionDetailLupa2 from "../../assets/img/icons/auctionDetailLupa2.svg";
import Agregar from "../../assets/img/icons/add-check.svg";
// ---------------------------------------------------------------------//

// ----------- 🧮 components --------------------//
import { Tooltip } from "@material-ui/core";
import GenericTableNew from "../Layouts/GenericTableNew";
import Loader from "react-loader-spinner";
import { Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
// ----------- --------------- --------------------//

// ----------------- 🦄 Styless -----------------------------//
import paginationStyles from "../Layouts/pagination.module.scss";

import tableStyles from "../Layouts/tableStyle.module.scss";
import { customSelectNewDark } from "../Layouts/react-select-custom";

// ----------------- end styles-----------------------------//

// ---------------- functions 🛬 ---------------------------//
import { convertDateToLatinFormat } from "../../helpers";
import { formatToRcSelect } from "../../helpers/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SelectComponent from "../SelectComponent/SelectComponent";
//-----------------------------------------------------------//

export const WorkAccidents = (props) => {
  const dispatch = useDispatch();
  const reducers = useSelector((state) => state);
  const history = useHistory();
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    status: undefined,
    search: "",
    entity_account: reducers.loginReducer.currentAccount.id,
  });

  const myPermission = () => reducers.loginReducer.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === "WorkAcc");

  useEffect(() => {
    dispatch(getSelectStatus({ prefix: "work_accident" }));
    dispatch(getWorkAccidents(filters));
  }, []);

  const handleChangePage = (page) => {
    setFilters({ ...filters, page: page })
    dispatch(getWorkAccidents({ ...filters, page: page }));
  }

  const renderAccidents = () => {
    return reducers.payrollReducer?.accidents?.map((item) => {
      return (
        <tr className="text-start">
          <td className="px-2">{item.full_name}</td>
          <td className="px-2">{item.description_event}</td>
          <td className="text-center px-2">
            {convertDateToLatinFormat(item.accident_date)}
          </td>
          <td className="text-center d-flex justify-content-center">
            <div
              className="rounded-pill p-1 align-self-center"
              style={{
                backgroundColor: item?.statusValue === "Revisado" ? "#FAFDF6" : "#FEF7F5",
                width: "140px"
              }}
            >
              <b style={{ color: item?.statusValue === "Revisado" ? "#83C036" : "#F39682" }}>{item?.statusValue === "Revisado"
                ? item?.statusValue
                : item?.statusValue}</b>
            </div>
          </td>
          <td className="px-2">
            {
              myPermission()?.read && (
                <Link to={`/nomina/seguridadYSalud/accidentesLaborales/detalle/${item.id}`}>
                  <img
                    src={auctionDetailLupa2}
                    alt="Ver detalle"
                    height="20px"
                    className="hoverPointer"
                  />
                </Link>
              )
            }
          </td>
        </tr>
      );
    });
  };

  const formattedAreas = formatToRcSelect(reducers.payrollReducer.status, "name", "value");


  return (
    <div className="w-90">
      {!myPermission().read && <Redirect to="/nomina/inicio" />}
      {reducers.payrollReducer.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div className="d-flex px-5 flex-column container-fluid">
        <h1
          style={{ marginTop: "40px" }}
        >Accidentes laborales</h1>
        {/* filters section */}

        <Row>
          <Col xs={2}>
            <p className={tableStyles.crudModalLabel}>Estado</p>
            <SelectComponent
              styles={customSelectNewDark}
              placeholder={"Seleccionar..."}
              key={"area" + 1}
              onChange={(e) => {
                setFilters({ ...filters, status: e.value })
                dispatch(getWorkAccidents({ ...filters, status: e.value, page: 1 }))
              }}
              options={formattedAreas}
              value={formattedAreas.find((x) => x.value === filters.status)}

            ></SelectComponent>
          </Col>
          <Col
            xs={5}
            className="d-flex align-self-end gap-1"
          >
            {
              myPermission()?.read && (
                <>
                  <input
                    className={tableStyles.SearchNew}
                    value={filters.search}
                    placeholder="Buscar..."
                    type="text"
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(getWorkAccidents({ ...filters, page: 1 }))
                      }
                    }}
                  ></input>
                  <img
                    className="hoverPointer"
                    alt="iconSearch"
                    style={{}}
                    src={Lupa}
                    height="24px"
                    onClick={(e) =>
                      dispatch(getWorkAccidents({ ...filters, page: 1 }))}
                  />
                </>
              )
            }

          </Col>
          {myPermission().create && (
            <Col
              xs={4}
              className="align-self-end"
            >
              <div className="d-flex justify-content-end ">
                {/* <div className="d-flex justify-content-end groupAddButton"> */}
                <div
                  onClick={() => history.push("/nomina/seguridadYSalud/accidentesLaborales/registrar")}
                  className={tableStyles.createNomModule}
                >
                  <b className={`mr-2`}>Registrar accidente</b>
                  {/* <button
          className="addCheckButton mx-2"
          style={{ marginBottom: "1.5px" }}
          /> */}
                  <img
                    src={Agregar}
                    alt="User"
                  />
                  <div></div>
                </div>
              </div>
            </Col>
          )}
          {/* <Col xs={5}>
          </Col> */}
        </Row>
        {/* end filters section */}

        {/* table */}
        {
          myPermission()?.read && (
            <>
              <GenericTableNew
                lowerCase
                dark={true}
                headers={[
                  <th className="text-start px-2">Afectado</th>,
                  <th className="text-start px-2">Detalle</th>,
                  <th className="text-center px-2">Fecha</th>,
                  <th className="text-center px-2">Estado</th>,
                  <th style={{ width: "5%" }}></th>,
                ]}
              >
                {renderAccidents()}
              </GenericTableNew>

              {/* end table */}
              <div className={`${paginationStyles.wrapper} justify-content-between`}>
                <p style={{ display: "inline" }} className="darkGray">
                  Pag. {filters.page} de{" "}
                  {Math.ceil(
                    reducers.payrollReducer.rowTotalAccidents / filters.perpage
                  )
                    ? Math.ceil(
                      reducers.payrollReducer.rowTotalAccidents / filters.perpage
                    )
                    : ""}{" "}
                  ({reducers.payrollReducer.rowTotalAccidents} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={reducers.payrollReducer.rowTotalAccidents}
                  pageRangeDisplayed={filters.perpage}
                  activeClass={paginationStyles.activeClass}
                  onChange={(page) => handleChangePage(page)}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default WorkAccidents;
