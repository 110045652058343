import React, { useEffect, useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import NumberFormat from "react-number-format";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


import ModalNew from "../../Layouts/ModalNew";
import payrollStyles from "../payrollStyles.module.scss";
import popUpStyles from "../../Popup/customPopup.module.scss";

import {

} from "../../../actions/payrollActions";
import { URL_GATEWAY, API_VERSION } from "../../../helpers/constants";
import { convertFilterToString } from "../../../helpers/convertToFilter";

import Select from "react-select";
import { customSelectNewDark } from "../../Layouts/react-select-custom";

import Pagination from "react-js-pagination";
import GenericTableNew from "../../Layouts/GenericTableNew";
import GenericModalNew from "../../Layouts/ModalNew";

import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import CustomPopupExtends from "../../Popup/customPopUpExtends";

import iconDownload from "../../../assets/img/icons/iconDownload.svg";
import calendar from "../../../assets/img/icons/calendar.svg";
import calendaredit from "../../../assets/img/icons/calendaredit.svg";
import useredit from "../../../assets/img/icons/useredit.svg";
import recibir from "../../../assets/img/icons/recibir.svg";
import popupx from "../../../assets/img/icons/x.svg";
import { Checkbox } from "@material-ui/core";
import { convertDateToLatinFormat } from "../../../helpers";


import SalidaConStyles from "../../SalidaConsumo/SalidaConsumo.module.scss";
import Back from "../../../assets/img/icons/atras-icon.svg";
import Lupa from "../../../assets/img/icons/lupa.svg";

function MedicalExamDetail(props) {
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const token = store.loginReducer.Authorization;
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find( (x) => x.functionality?.prefix === "ExaMed");
    
    const today = new Date();
    const currYear = today.getFullYear();
    const [isLoading, setIsLoading] = useState(false);

    const [trigger, setTrigger] = useState(0);

    const get_my_areaname = props.area_name? props.area_name : store.PlanningMedicalExamsReducer.chosen_area? store.PlanningMedicalExamsReducer.chosen_area : "";
    const get_my_examID = props.exam_id? props.exam_id : store.PlanningMedicalExamsReducer.chosen_id? store.PlanningMedicalExamsReducer.chosen_id : "";

    const [filters, setFilters] = useState({
        area_id: "",
        page:1,
        perpage:10,
        status:"done",
        me_id: get_my_examID
    });

    const [medTableTotal, setMedTableTotal] = useState(0);
    const [candidateList, setCandidateList] = useState([]);

    //zone that should be actions but honestly we are over-using redux
    const fetchMedicalExamCandidates = async (my_filters) => {
          
        setIsLoading(true);

        let str_filters = convertFilterToString(my_filters);
        const getCandidates= await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam_candidates/?${str_filters}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            setCandidateList(res.results);
            setMedTableTotal(res.total);
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }
    //

    useEffect(() => {
        fetchMedicalExamCandidates(filters);
    }, [trigger]);


    const meHeaders = [
        <th key={1} ><p className="m-0 text-start"  style={{paddingLeft: 10}}>Colaborador</p></th>,
        <th key={2} ><p className="m-0 text-center">Identificación</p></th>,
        <th key={3} ><p className="m-0 text-start">Cargo</p></th>,
        <th key={4} >&nbsp;</th>,
    ];
    
    const meBody = ()=> {
        if(!Array.isArray(candidateList)){
            return [];
        }

        let filteredArray = candidateList;

        return filteredArray.map(item => { 
            //Seccion de opciones para el popup
            
            return (
                <tr key={item.id} className="hover-table-row">
                <td className={`col-md-5`}>
                    <p className={`m-0 text-start`} style={{paddingLeft: 10}}>{item.full_name}</p>
                </td>
                <td className={`col-md-3`}>
                    <p className={`m-0 text-center`}>{item.doc_number}</p>
                </td>
                <td className={`col-md-3`}>
                    <p className={`m-0 text-start`}>{item.occupation}</p>
                </td>
                <td className={`col-md-1`}>
                    <div className={`d-flex justify-content-center`}>
                    <a
                        href={item.result_location}
                        target="_blank"
                        className={payrollStyles.linkButton}
                    >
                        <img 
                            src={iconDownload}
                            alt="download"
                            style={{
                                width:18,
                                cursor:"pointer",
                                margin:0
                            }}
                            
                        />
                    </a>
                    
                    </div>
                </td>
            </tr>
        )
        })
    }

    const screenGoBack = () => {
        if(!!props.setScreenControl){
            props.setScreenControl(0);
        }else{
            history.goBack();
        }
    }

    return (
        <div  className={tableStyles.container} style={{padding:0}}>
        <div className={`d-flex ${SalidaConStyles.titleBlock}`} style={{marginBottom: 17}}>
            <img 
                src={Back} 
                className={SalidaConStyles.backIcon} 
                onClick={()=>screenGoBack()}
            />
            <div>
                <h1 className={SalidaConStyles.mainTitle} style={{margin:0}}>Resultados de Exámenes</h1>
                <h2 
                className={payrollStyles.subtitle} 
                style={{fontWeight: "normal", margin:0, padding:0}}
                >
                    {get_my_areaname}
                </h2>
            </div>
        </div>

        <div className={tableStyles.container} style={{paddingBottom:40, paddingLeft:50, paddingRight:50, flex:1}}>
            {isLoading && (
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            )}

                


                <div className={`d-flex`} style={{marginTop: 16}}>

                    <div className="d-flex flex-column mr-2" style={{flex:1}}>
                        <input 
                            type="text"
                            //className="register-inputs"
                            className={tableStyles.blueSearch}
                            placeholder="Buscar..."

                            value={filters.search}
                            onChange={e=> setFilters({...filters, search: e.target.value})}
                        />
                    </div>


                    <div className="d-flex flex-column" style={{flex:1}}>
                        <img 
                            className={tableStyles.cursorPointer}
                            style={{ width: "25px" }}
                            onClick={() => setTrigger(trigger+1)} 
                            src={Lupa} 
                        /> 
                    </div>

                    <div style={{flex:1}}></div>
                </div>


                <GenericTableNew
                    headers={meHeaders}
                    dark={true}
                >
                    {meBody()}
                </GenericTableNew>

                {(medTableTotal> filters.perpage) && <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                    Pag. {medTableTotal ? filters.page : ""}
                    {" de "}
                    {Math.ceil(medTableTotal / filters.perpage)
                        ? Math.ceil(medTableTotal / filters.perpage)
                        : ""}{" "}
                    ({medTableTotal} encontrados)
                    </p>
                    <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={medTableTotal}
                    pageRangeDisplayed={5}
                    onChange={val=>{setTrigger(trigger+1); setFilters({ ...filters,  page: val }) }}
                    itemClassPrev={`${paginationStyles.itemClassPrev} `}
                    itemClassNext={`${paginationStyles.itemClassNext} `}
                    itemClassFirst={`${paginationStyles.itemClassFirst} `}
                    itemClassLast={`${paginationStyles.itemClassLast} `}
                    itemClass={paginationStyles.itemClass}
                    />
                </div>}
        </div>
        </div>
    );

}

export default MedicalExamDetail;