import Swal from "sweetalert2";
import moment from "moment";
// function to validate a email structure
import Loader from "react-loader-spinner";
import withReactContent from "sweetalert2-react-content";
import Excel from "../assets/img/icons/excel.svg";
import Word from "../assets/img/icons/word.svg";
import PDF from "../assets/img/icons/pdf.svg";
import Image from "../assets/img/icons/Imagen.svg";
import File from "../assets/img/icons/subir.svg";
import { API_VERSION, DEFAULT_COUNTRY, URL_GATEWAY } from "./constants";
import { convertFilterToString } from "./convertToFilter";
import "../OrderingModule/orderingStyles.css";
import { customSwaltAlertAsistencial } from "./customSwaltAlertAsistencial";
import { customSwaltAlert } from "./customSwaltAlert";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export const calculatePresentationMedicine = (medicine) => {
  // //console.log("medicine", medicine);
  // (CantidadOrdenada * ((Durante * TiempoDurante) / (Cada* TiempoCada))) / (Cantidad por presentacion * Cantidad por unidad de medida )
  //                        ↑                                             /                            ↑
  //            Orden dgitados en el formulario                                              Medicamento seleccionado

  let mdcQtyDosis = parseFloat(medicine?.mdcQtyDosis);
  let mcdQtyFrequency = parseFloat(medicine?.mcdQtyFrequency);
  let frequencyValue = parseFloat(medicine?.frequencyValue);
  let mcdQtyPeriod = parseFloat(medicine?.mcdQtyPeriod);
  let periodValue = parseFloat(medicine?.periodValue);

  let qtyPerMeasUnit = parseFloat(medicine?.qtyPerMeasUnit);
  let qtyPerPresentation = parseFloat(medicine?.qtyPerPresentation);
  let result = Math.ceil(
    (mdcQtyDosis *
      ((mcdQtyPeriod * periodValue) / (mcdQtyFrequency * frequencyValue))) /
    (qtyPerPresentation / qtyPerMeasUnit)
  );
  return {
    medicine: medicine,
    result: result,
    presentation: medicine?.presentationDescrption,
  };
};

export const convertMoneyFormat = (textNumber) => {
  const country = DEFAULT_COUNTRY === "RD"
  const symbolThousand = country ? "." : ","
  const symbolDecimal = country ? "," : "."
  if (!isEmptyOrUndefined(textNumber)) {
    let num_parts = textNumber.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, symbolDecimal);
    return country ? `RD$${num_parts.join(symbolThousand)}`:`$${num_parts.join(symbolThousand)}`;
  }
};

export const convertMoneyFormatNew = ({textNumber, SymbolDecimal=',', SymbolShow=false, convertNumber}) => {
  if (!isEmptyOrUndefined(textNumber)) {
    if(convertNumber){
      let text = textNumber?.replaceAll(".","")?.replaceAll(",","")?.replaceAll('$','')
      return Number(text)
    }
    var num_parts = textNumber.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, SymbolDecimal);
    return `${SymbolShow ? '' : '$'}${num_parts.join(SymbolDecimal)}`;
  }
};

export const getPaymentCondition = (pay) => {
  switch (pay) {
    case "prepayment":
      return "Pago anticipado";
    case "delivery":
      return "Pago contraentrega";
    case "pay20":
      return "Pago a 20 días";
    case "pay30":
      return "Pago a 30 días";
    case "pay60":
      return "Pago a 60 días";
    case "pay90":
      return "Pago a 90 días";
    case null:
      return "-";
    default:
      return pay;
  }
};

export const removeSpaces = (text) => {
  return text.replace(/\s/g, "");
};

export const message = (icon, title, text, confirmButtonText, oldStyle) => {
  // styles
  if (oldStyle) {
    customSwaltAlert({
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: 'Aceptar',
      showCancelButton: false
    });

  } else {
    Swal.fire({
      icon,
      title,
      text,
      confirmButtonText: confirmButtonText || "Aceptar",
      showCancelButton: false,
      // confirmButtonColor: styles?.confirmButtonColor || "blue",
      // cancelButtonColor: "#d33",
    });
  }

};

//THIS FUNCTION WORKS TO FORMAT ANY PETITION TO REACT SELECT WAY, THE FIRST PARAM IS AN ARRAY , SECOND AND THIRD ARE THE NAMES OF ID AND NAME FOR RC SELECT, THOSE SHOULD BE STRING
export const formatToRcSelect = (
  arrToFormat, //Original array
  idName, // Value
  valueName, // Label
  extra, // Simple extra value
  extra2, // Simple extra value
  concat, // The key you want to concat to the original label
  withEmptyValue = true, // with or without "-Seleccione-" initial value
  extra3, // an extra JSon to take more params
  extra4, // an extra JSon to take more params
  extra5, // an extra JSon to take more params
  extra6, // an extra JSon to take more params
  extra7, // an extra JSon to take more params
  extra8, // an extra JSon to take more params
  extra9, // an extra JSon to take more params
  extra10, // an extra JSon to take more params
  concatValue, // an extra concat to the value
  concat2, // an extra concat string to the label
) => {
  let arrOut = withEmptyValue
    ? [
      {
        label: "Seleccionar...",
        value: "",
        extra: "",
      },
    ]
    : [];

  if (Array.isArray(arrToFormat)) {
    if (arrToFormat.length > 0) {
      // if (arrToFormat.length >= 100) {
      //   let fiveHundredArray = arrToFormat.slice(0, 10);
      //   // //console.log(
      //   //   "🚀 ~ file: helpers.js ~ line 84 ~ fiveHundredArray",
      //   //   fiveHundredArray
      //   // );
      //   fiveHundredArray.forEach((e) => {
      //     arrOut.push({
      //       label: concat ? e[valueName] + " - " + e[concat] : e[valueName],
      //       value: e[idName],
      //       extra: e[extra],
      //       extra2: e[extra2],
      //     });
      //   });
      // } else {
      arrToFormat.forEach((e) => {
        arrOut.push({
          label: concat2 ? e[valueName] + " - " + e[concat] + concat2 : concat ? e[valueName] + " - " + e[concat] : e[valueName],
          // value: e[idName],
          value: concatValue ? String(e[idName]) + String(e[concatValue]) : e[idName],
          extra: e[extra] ?? extra,
          extra2: e[extra2],
          extra3: e[extra3],
          extra4: e[extra4],
          extra5: e[extra5],
          extra6: e[extra6],
          extra7: e[extra7],
          extra8: e[extra8],
          extra9: e[extra9],
          extra10: e[extra10],
        });
      });
      // }
    }
  }

  return arrOut;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const MySwal = withReactContent(Swal);
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const accTypes = [
  { value: "", label: "Seleccionar..." },
  { value: "C", label: "Crédito" },
  { value: "D", label: "Debito" },
];
export const rhFactor = [
  { value: "", label: "Seleccionar..." },
  { value: "N", label: "Negativo" },
  { value: "P", label: "Positivo" },
];

export const placeMyIcon = (iconExtension) => {
  if (!isEmptyOrUndefined(iconExtension)) {
    switch (iconExtension) {
      case "pdf":
        return PDF;
      case "pdf":
        return Word;
      case "xls":
        return Excel;
      case "jpg" || "jpeg" || "png":
        return Image;
      case "zip" || "rar":
        return File;

      default:
        break;
    }
  }
};
//This function take two params
//1: value=any => is the value to test its respective regExp
//2: regExp=String => is the respective regular expression for value both are mandatory
export const validExpReg = (value, regExp) => {
  let reg = new RegExp(regExp);
  if (reg.test(value)) {
    //IF TRUE => VALUE IS A VALID DATA TYPE
    return true;
  } else {
    //ELSE IS ISN'T A VALID DATA TYPE
    return false;
  }
};

export const convertDateToLatinFormat = (date) => {
  return moment(date).format("DD/MM/YYYY");

  // let dd = new Date(date).getUTCDate();
  // let mm = String(date).slice(5, 7);
  // let yyyy = new Date(date).getFullYear();
  // return dd + "/" + mm + "/" + yyyy;
};

// function to validate a email structure

//This object is a list of keys that recevies a string of a regular expression
export const expRegList = {
  string:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  number: "[0-9]$",
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  onlyPositiveNumber: "^d$|^[1][0]$",
};
export const isEmptyOrUndefined = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === NaN ||
    value === -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const getFilenameByURL = (url) => {
  var filename = url.substring(url.lastIndexOf("/") + 1);
  return filename;
};

//THIS PIECE OF CODE EXPORT THE TODAY'S DAY, IT WORKS TO VALID IF A DATE FIELD HAVE MAJOR VALUE THAN TODAY
// <input max/min={today} type="date" />;
export const today = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd; //AAA-MM-DD
  return today;
};
export const month = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return mm;
};
export const year = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy;
};
export const day = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd;
};

export const numberWithCommas = (x) => {
  if (!isEmptyOrUndefined(x)) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }
};

export const calculateDays = (dateStart, dateEnd) => {
  let ellapsedDays;
  if (!isEmptyOrUndefined(dateStart) && !isEmptyOrUndefined(dateEnd)) {
    let miliSecondsDay = 24 * 60 * 60 * 1000;
    let elapsedMiliSecods = Math.abs(dateStart?.getTime() - dateEnd?.getTime());
    let ellapsedDays = Math.round(elapsedMiliSecods / miliSecondsDay);
  }
  return ellapsedDays;
};

export const downloaFile = (file) => {
  // //console.log(file, "file")
  let treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/pdf;base64,${treatPdf}`;
  let downloadLink = document.createElement("a");
  let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadFilePdf = (file, filename) => {
  let treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
  let linkSource = `data:application/pdf;base64,${treatPdf}`;
  let downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
};

export const formatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
});

export const daysOfTheMonth = [
  { value: "", label: "-Seleccione-" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
];
export const monthsOfTheYear = [
  { value: "", label: "-Seleccione-" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

//TODO: ENHANCE
export const getFivePreviousYear = () => {
  let array = [];
  let current_year = year();
  for (let step = 0; step <= 5; step++) {
    array.push(current_year - step);
  }
  return array;
};

export const fetchGet = async (params) => {
  /* --------------------------- How to use example --------------------------- */
  // fetchGet({
  //   url: "/medical/medicine/get/",
  //   objFilters: { slim: 1, idAccount: 31 },
  //   token: token,
  // });
  let results = <>Algún spinner</>;
  let { url, objFilters, token } = params;
  const filters = convertFilterToString(objFilters);
  if (isEmptyOrUndefined(url)) {
    return message(
      "warning",
      "Parámetro url no encontrado",
      "El parámetro url es obligatorio en el objeto de configuración"
    );
  }
  if (isEmptyOrUndefined(token)) {
    return message(
      "warning",
      "Parámetro token no encontrado",
      "El parámetro token es obligatorio en el objeto de configuración"
    );
  }

  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}${url}?${filters}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    results = await query.json();
    //console.log("From inside", await results);
    return results;
  } catch (error) {
    console.error(error.message);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const swalConfirm = (props) => {
  // const btn1 = `<button class="ordBtnPrimary">Hola mundo</button>`;
  let {
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    doAfterConfirm,
  } = props;
  Swal.fire({
    title: title || `¿Está seguro?`,
    text: text || "Verifique la información",
    icon: icon || "warning",
    customClass: {
      confirmButton: `ordBtnPrimary2`,
      cancelButton: `ordBtnSecondary`,
      title: `text-info`,
    },
    showCancelButton: showCancelButton,
    // confirmButtonColor: "#003f80",
    // cancelButtonColor: "#00b4cc",
    confirmButtonText: confirmButtonText || "Si, Guardar",
    // confirmButtonText: btn1,
    cancelButtonText: cancelButtonText || "Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      doAfterConfirm();
    }
  });
};
export const loader = (
  <div style={{ zIndex: "2000" }} className="loading">
    <Loader type="Oval" color="#003f80" height={100} width={100} />
  </div>
);
export const validateEmptyString = (arrayString) => {
  /* --------------------------- HOW TO USE EXAMPLE --------------------------- */
  // let validations = validateEmptyString([
  //   { field: data.cum, message: "El campo cum es obligatorio" },
  //   { field: data.cum, message: 'El campo cum es obligatorio', whithOutAlert: true },
  //   { field: selectedActives[0], message: 'Por favor seleccione un artículo' },
  // ]);
  let len = arrayString.length;
  let error = 0;
  let isOk = 0;
  let valid = false;
  arrayString?.length > 0 &&
    arrayString.forEach((e, i) => {
      if (isEmptyOrUndefined(e.field)) {
        error = error + 1;
        if (!e.whithOutAlert) {
          return customSwaltAlert({
            icon: e.icon || "warning",
            title: e.title || "Intenta de nuevo",
            text: e?.text || e?.message || `Por favor llene todos los campos obligatorios`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          })
          // return message(
          //   e.icon || "info",
          //   e.title || "Campos vacíos",
          //   e.message || `Por favor llene todos los campos obligatorios`
          // );
        }
      } else {
        isOk = isOk + 1;
      }
    });

  if (isOk === len) {
    valid = true;
  } else {
    valid = false;
  }

  return { valid };
};

export const downloadBase64 = (url) => {
  let win = window.open();
  // window.open(url, "popup", "fullscreen=yes");
  win.document.write(
    '<iframe src="' +
    url +
    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );
  setTimeout(() => {
    win.close();
  }, 3000);
};

export const getAge = (birth_date) => {
  let age = moment(birth_date);
  let today = moment();
  let years = today.diff(age, "years");
  return years;
}

export const capitalizeWord = (word) => {
  let capitalized = word?.charAt(0).toUpperCase() + word?.slice(1);
  return capitalized;
};


export const imgNoExist = "https://dummyimage.com/16:9x1080/";


export const generateId = (limit=2) => {
  const random = Math.random().toString(36).substr(limit)
  const date = Date.now().toString(36)
  return random + date
}
export const genericDownloaFile = (file, filename) => {
  let treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = treatPdf;
  let downloadLink = document.createElement("a");
  // let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
};

export const downloaFileAddName = (file, name) => {
  let treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/pdf;base64,${treatPdf}`;
  let downloadLink = document.createElement("a");
  let fileName = name.length > 0 ? `${name}.pdf` : "vct_illustration.pdf";
  // let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
export const downloadExcel = (file, name, fileExtend) => {
  // //console.log(file, "file")
  let treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${treatPdf}`;
  let downloadLink = document.createElement("a");
  let fileName = name.length > 0 ? `${name}.xlsx` : "vct_illustration.xlsx";
  downloadLink.href = fileExtend ? file : linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const genericDownloadExcel = (base64Data, filename) => {
  const treatedBase64 = base64Data.replace(/^data:.*;base64,/, '');

  const byteCharacters = atob(treatedBase64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const downloadLink = document.createElement('a');

  const url = URL.createObjectURL(blob);

  downloadLink.href = url;
  downloadLink.download = filename;

  downloadLink.click();
  URL.revokeObjectURL(url);
};

export function convertInputToDate(idInput) {
  let currentType = document.getElementById(idInput);
  currentType.type = "date";
}
export function convertInputToText(idInput) {
  let currentType = document.getElementById(idInput);
  currentType.type = "text";
}

export function checkArrayEquality(_array1, _array2) {
  return (
    _array1.length === _array2.length &&
    _array1.every(function (_array1_i, i) {
      return _array1_i === _array2[i];
    })
  );
}


export const downloadImg = (file, name) => {
  // let file = convertToBase64Replace(fileNew)
  // //console.log("file: ", file);
  let treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:image/png;base64,${treatPdf}`;
  //console.log("linkSource: ", linkSource);
  let downloadLink = document.createElement("a");
  //console.log("downloadLink: ", downloadLink);
  let fileName = name.length > 0 ? `${name}.jpg` : "vct_illustration.png";
  // let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};


export const optionsSelect = ({ list = [], label, value, extra = "" }) => {
  let newList = []
  const defaultSelect = { key: "default", value: "", label: "Seleccionar...", extra: extra }
  if (list?.length) {
    newList = [defaultSelect, ...list]
  }

  return newList?.map((e, i) =>
    i === 0
      ? ({ key: "default", label: e.label, value: e.value, extra: extra })
      : ({ key: generateId(), label: e[label], value: e[value], extra: extra, ...e })
  )
}

export const valueSelect = ({ list = [], findId, findLabel, value}) => {
  // eslint-disable-next-line eqeqeq
  const objProvince = list?.find(e => e[findId] == value) ?? {}
  const result = value ? { label: objProvince[findLabel], value: objProvince[findId], key: generateId() } : ''
  return result

}

export const getPermission = ({prefix, listPermission}) =>{
  if(Array.isArray(listPermission)){
    const results = listPermission?.find(x => x.functionality?.prefix === prefix)
    return results
  }

  return listPermission
}

export const onCapitalLetter = ({ text }) => {
  const formatText = text.toLowerCase()?.split('')
  formatText[0] = formatText[0].toUpperCase()
  const textJoin = formatText.join('')

  return textJoin
}



export const handleFilterSelectDynamic = async (
  {
    keyboardValue,
    listFilter,
    valueField,
    labelField,
    optionalLabelField,
    defaultMessage,
    triggerLength = 0
  }) => {
  let nombreEncontrados
  let expresión = new RegExp(`${keyboardValue?.toUpperCase()}.*`, "i");

  if (keyboardValue?.length > triggerLength) {
    nombreEncontrados = listFilter?.filter(dat => expresión?.test(dat[labelField]) || expresión?.test(dat[optionalLabelField]));
    const results = optionsSelect({ list: nombreEncontrados, label: labelField, value: valueField })
    return results
  }

  return [{ value: "", label: defaultMessage }]

}

export const onChangeFile = async (e) => {
  var f = e.target.files[0];
  if (f) {
    var name_doc = f.name;
    var ext = f.name.split(".")[1];
    var type_doc = f.type;
    if (ext !== "pdf" && ext !== "zip" && ext !== "rar") {
      // return message("error", "El formato del archivo debe ser PDF o ZIP", "El tamaño máximo permitido es de 5MB");
    }
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    return {
      fileName: name_doc,
      file: file64,
      typeFile: type_doc.split("/")[1],
    }
  }
};


export const removeDuplicates = ({ arrayObject, keyComparative }) => {
  let duplicates = []
  let listClear = []


  if (!arrayObject?.length) {
    return { success: false, listClear: [], duplicates: [] }
  }

  if (!Array(arrayObject)) {
    return { success: false }
  }

  for (let i = 0; i < arrayObject?.length; i++) {
    for (let j = 0; j < arrayObject?.length; j++) {
      if (i !== j && arrayObject[i][keyComparative] === arrayObject[j][keyComparative]) {
        let isExist = duplicates.filter(el => el[keyComparative] === arrayObject[i][keyComparative])?.length
        if (!isExist) {
          duplicates?.push(arrayObject[i])
        }
      } else {
        let isExist = listClear.filter(el => el[keyComparative] === arrayObject[i][keyComparative])?.length
        if (!isExist) {
          listClear?.push(arrayObject[i])
        }
      }
    }
  }

  return {
    success: true,
    duplicates,
    listClear
  }
}

export const inputMuiGray = {
  '& label.Mui-focused': {
      color: '#A2A3AB',
  },
  '& .MuiInput-underline:after': {
      borderBottomColor:'#A2A3AB',
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderColor: '#A2A3AB',
      },
      '&:hover fieldset': {
          borderColor: '#A2A3AB',
      },
      '&.Mui-focused fieldset': {
          borderColor: '#A2A3AB'
      }
  }
}

export const onlyCharacters = (value) => {
  let isValid = false;
  const pattern = new RegExp('^[A-ZÁÉÍÓÚÑ ]+$', 'i');
  if (pattern.test(value) || value === ''){
    isValid = true;
  }

  return isValid;
}

export const formatNumberDocRD = (number= 0) => {
  const numberStr = number.toString();
  let realNumber = numberStr
  if (numberStr.includes('-')){
    realNumber = numberStr.replace(/-/g, '');
  }
  if (realNumber.length === 9) {
    return {value: realNumber, formatNumber: realNumber.slice(0, 3) + '-' + realNumber.slice(3, -1) + '-' + realNumber.slice(-1)};
  } else if (realNumber.length === 11) {
    return {value: realNumber , formatNumber: realNumber.slice(0, 3) + '-' + realNumber.slice(3, -1) + '-' + realNumber.slice(-1)};
  } else {
    return {value: realNumber}
  }
};

export const dynamicFormatDocument = (number)=>{
  const country = DEFAULT_COUNTRY === "RD"
  if(country){
    if (number.length === 9 ||number.length === 11) {
      return (number.slice(0, 3) + '-' + number.slice(3, -1) + '-' + number.slice(-1))
    }
  }else{
    return number
  }

}