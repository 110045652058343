import React, { useEffect, useState } from "react";
import ADD from "../../assets/img/icons/addbutton.svg";
import undo from "../../assets/img/icons/deshacer.svg";
import check from "../../assets/img/icons/CheckMark.svg";
import deny from "../../assets/img/icons/extraBtnRedX.svg";
import PENDING from "../../assets/img/icons/Alert.svg";
import APPROVED from "../../assets/img/icons/Check Mark.svg";
import blueCheck from "../../assets/img/icons/blueCheck.svg";
import REJECTED from "../../assets/img/icons/extraBtnRedX.svg";
import PROCESSED from "../../assets/img/icons/grayCheckNew.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import ExcelIcon from '../../assets/img/icons/excel.svg';

import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import { connect, useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";

import Styles from "../../components/PayRollModule/Hiring/Contract.module.scss";
import { customSelectNew } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../Layouts/pagination.module.scss";

import { Col, Row, Button } from "react-bootstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import ModalNew from "../Layouts/ModalNew";
import {
  getInhabilities,
  updateInhabilitiesStatus,
} from "../../actions/approvedNewsActions";
import { costcenter_get } from "../../actions/costcenterActions";
import {
  formatToRcSelect,
  isEmptyOrUndefined,
  loader
} from "../../helpers/helpers";
import NumberFormat from "react-number-format";
import { PdfViewer } from "../Layouts/PdfViewer";
import { customSwaltAlert } from "../../helpers";
import { usePostMethod } from "../../Hooks";
export const Inhabilities = (props) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "inhab"
    );
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const arrConCenter = store.costcenterReducer?.cc;
  const [trigger, setTrigger] = useState(0);
  const [elemDetail, setElemDetail] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    entity_account: idEnterprise,
    search: "",
    status: undefined,
    dateFrom: "",
    dateUntil: "",
    area: "",
  });

  const arrInhabilities = store.approvedNewsReducer?.inhabilitiesList;

  const { trigger: getExcel, load: isLoadingExcel } = usePostMethod();

  useEffect(() => {
    // if (
    //   !!!myPermission?.read ||
    //   store.loginReducer.currentAccount?.profile?.nombre !== "SUPERVISOR"
    // ) {
    //   history.push("/nomina/inicio");
    // }
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      })
    );
    dispatch(getInhabilities(filters));
  }, [filters.page, trigger]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-2 text-start" style={{ paddingLeft: "0.5rem" }}>
          {elem.collaborator}
        </td>
        <td className="col-md-2 text-start">&nbsp; {elem.job_title}</td>
        <td className="col-md-1 text-start">
          {elem.type === "regular" ? "General" : elem.type === "maternity_leave" ? "Licencia de maternidad" : elem.type === "paternity_leave" ? "Licencia de paternidad" : elem.type}
        </td>
        <td className="col-md-2 text-center">
          {!isEmptyOrUndefined(elem.count) ? elem.count + " días" : "-"}
        </td>
        <td className="col-md-2 text-center">{elem.date}</td>
        <td className="col-md-2 text-center cursorPointer"
          title={
            elem.status === "pending"
              ? "Pendiente"
              : elem.status === "approved"
                ? "Aprobado"
                : elem.status === "rejected"
                  ? "Rechazado"
                  : elem.status === "processed" && "Procesado"
          }
        >
          <img
            src={
              elem.status === "pending"
                ? PENDING
                : elem.status === "approved"
                  ? APPROVED
                  : elem.status === "rejected"
                    ? REJECTED
                    : elem.status === "processed" && PROCESSED
            }
          ></img>
        </td>

        <td className="col-md-1 text-center cursorPointer">
          <div>
            <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
              showDetails={true}
              textDetails="Ver soporte"
              showDetailsEvent={() => handleClickDetail(elem)}
              showUndo={
                elem.status === "pending"
                  ? false
                  : elem.status === "approved"
                    ? true
                    : elem.status === "rejected"
                      ? true
                      : elem.status === "processed" && false
              }
              undoEvent={() => handleUndo(elem)}
              extraButtons={
                elem.status === "pending"
                  ? [
                    {
                      text: "Autorizar",
                      icon: APPROVED,
                      event: () => handleAuth(elem),
                    },
                    {
                      text: "Denegar",
                      icon: REJECTED,
                      event: () => handleDeny(elem),
                    },
                  ]
                  : []
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async (elem) => {
    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se va deshacer la solicitud de incapacidad de: ${elem?.collaborator}`,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateInhabilitiesStatus(
                {
                  status: "pending",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return customSwaltAlert({
                  icon: "success",
                  title: "Actualizado exitosamente",
                  text: `Se ha actualizado la solicitud de incapacidad de: ${elem?.collaborator}`,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false
                });

              } else {
                return customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: result.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false
                });
              }
            } catch (error) {
              return customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false
              });
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error.message,
        confirmButtonText: "Aceptar",
        showCancelButton: false
      });
    }
  };

  const handleAuth = async (elem) => {
    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se aprobará la solicitud de incapacidad de ${elem.collaborator}`,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateInhabilitiesStatus(
                {
                  status: "approved",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token
              );

              if (result.success) {
                setShowModal(false);
                setTrigger(trigger + 1);
                return customSwaltAlert({
                  icon: "success",
                  title: "Actualizado exitosamente",
                  text: result.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false
                });
              } else {
                return customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: result.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false
                });
              }
            } catch (error) {
              return customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false
              });
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error.message,
        confirmButtonText: "Aceptar",
        showCancelButton: false
      });
    }
  };
  const handleDeny = async (elem) => {
    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se denegará la solicitud de incapacidad de ${elem.collaborator}`,
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateInhabilitiesStatus(
                {
                  status: "rejected",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token
              );

              if (result.success) {
                setShowModal(false);
                setTrigger(trigger + 1);
                return customSwaltAlert({
                  icon: "success",
                  title: "Actualizado exitosamente",
                  text: result.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false
                });
              } else {
                return customSwaltAlert({
                  icon: "warning",
                  title: "Intenta de nuevo",
                  text: result.message,
                  confirmButtonText: "Aceptar",
                  showCancelButton: false
                });
              }
            } catch (error) {
              return customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false
              });
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error.message,
        confirmButtonText: "Aceptar",
        showCancelButton: false
      });
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrInhabilities !== undefined) {
      const elem2 = arrInhabilities;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className="text-start">
      &nbsp; Colaborador
    </th>,
    <th key={2} className="text-start">
      &nbsp; Cargo
    </th>,
    <th key={3} className="text-start">
      Tipo
    </th>,
    <th key={4} className="text-center">
      Incapacidad
    </th>,
    <th key={5} className="text-center">
      Periodo
    </th>,
    <th key={6} className="text-center">
      Estado
    </th>,
    <th key={7}>&nbsp;</th>,
  ];

  const onDownloadExcel = async () => {
    getExcel({
      token: store.loginReducer.Authorization,
      method: 'POST',
      url: '/payroll/download_approved_news_excel/',
      body: {
        entity_account: idEnterprise,
        news_type: 'inhabilities',
      },
      succesAction: result => {
        const base64 = result.results.base64;
        const fileName = result.results.filename;

        const anchor = document.createElement('a');
        anchor.href = base64;
        anchor.download = fileName;

        anchor.click();
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const handleClickDetail = async (elem) => {
    if (isEmptyOrUndefined(elem.file)) {
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: 'No se encontraron soportes para esta solicitud',
        confirmButtonText: "Aceptar",
        showCancelButton: false
      });
    }

    setElemDetail(elem);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const statusOptions = [
    { value: "", label: "Seleccionar..." },
    { value: "pending", label: "Pendiente" },
    { value: "approved", label: "Aprobado" },
    { value: "rejected", label: "Rechazado" },
    { value: "processed", label: "Procesado" },
  ];

  return (
    <>
      {(isLoadingExcel) && loader}
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={""}
        subtitle={
          elemDetail.status === "pending"
            ? "En espera"
            : elemDetail.status === "approved"
              ? "Aprobado"
              : elemDetail.status === "rejected"
                ? "Rechazado"
                : elemDetail.status === "processed" && "Procesado"
        }
        subtitleStyle={
          elemDetail.status === "pending"
            ? "text-warning"
            : elemDetail.status === "approved"
              ? "text-success"
              : elemDetail.status === "rejected"
                ? "text-danger"
                : elemDetail.status === "processed" && "text-secondary"
        }
        size={"lg"}
      >
        {(elemDetail.file) && elemDetail.file.includes('pdf') ?
          <PdfViewer file={elemDetail.file}></PdfViewer> :
          <div className="text-center">
            <img alt="icon" src={elemDetail.file} />
          </div>
        }
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Aprobar incapacidades</h1>

          {!!myPermission?.read &&
            <>
              <Row>
                <Col className="d-block" xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    onChange={(e) => setFilters({ ...filters, status: e.value })}
                    options={statusOptions}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    value={statusOptions.find((x) => x.value === filters?.status)}
                    styles={customSelectNew}
                  ></Select>
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Fecha desde</p>
                  <input
                    onChange={(e) =>
                      setFilters({ ...filters, dateFrom: e.target.value })
                    }
                    type="date"
                    placeholder="Escribe..."
                    className="register-inputs text-primary fw-bold"
                    name="position"
                  />
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Hasta</p>
                  <input
                    onChange={(e) =>
                      setFilters({ ...filters, dateUntil: e.target.value })
                    }
                    type="date"
                    placeholder="Escribe..."
                    className="register-inputs text-primary fw-bold"
                    name="position"
                  />
                </Col>

                <Col className="d-flex align-items-end">
                  <div>
                    <form onSubmit={e => handleSearch(e)} className='d-flex'>
                      <input
                        className={`${tableStyles.SearchNew} mr-2 `}
                        onChange={e => setFilters({ ...filters, search: e.target.value })}
                        name='search'
                        type='text'
                        placeholder='Buscar...'
                      />
                      <img
                        src={Search}
                        className='cursorPointer'
                        width={'30px'}
                        alt='Search icon'
                        onClick={e => handleSearch(e)}
                      />
                    </form>
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <img
                    src={ExcelIcon}
                    onClick={onDownloadExcel}
                    className='cursorPointer'
                    width={'25px'}
                    alt='Excel icon'
                  />
                </Col>
              </Row>

              <GenericTableNew lowerCase headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>

              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag.{" "}
                  {store.approvedNewsReducer.inhabilitiesList ? filters.page : ""}
                  {" de "}
                  {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                    ? Math.ceil(
                      store.approvedNewsReducer.rowTotal / filters.perpage
                    )
                    : ""}{" "}
                  ({store.approvedNewsReducer.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={store.approvedNewsReducer.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={(e) => setFilters({ ...filters, page: e })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>}
        </div>
      </div>
    </>
  );
};
