import React, { useEffect, useState } from "react";
import PENDING from "../../assets/img/icons/Alert.svg";
import APPROVED from "../../assets/img/icons/Check Mark.svg";
import REJECTED from "../../assets/img/icons/extraBtnRedX.svg";
import PROCESSED from "../../assets/img/icons/check2.svg";
import threeDots from "../../assets/img/icons/threeDots.svg";
import note from "../../assets/img/icons/ordSugess.svg";
import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { customSelectNew } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import ModalNew from "../Layouts/ModalNew";
import {
  getVacactionsList,
  updateVacationStatus,
} from "../../actions/approvedNewsActions";
import { costcenter_get } from "../../actions/costcenterActions";
import {
  convertMoneyFormat,
  isEmptyOrUndefined,
  loader,
  message,
} from "../../helpers/helpers";
import { PdfViewer } from "../Layouts/PdfViewer";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
export const Vacations = (props) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "vctns"
    );

  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [elemDetail, setElemDetail] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    entity_account: idEnterprise,
    search: "",
    status: undefined,
    dateFrom: "",
    dateUntil: "",
    area: "",
  });
  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: "",
    isEnabled: false,
    elem: {}
  })
  const arrVacations = store.approvedNewsReducer?.vacationList;
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      })
    );
    dispatch(getVacactionsList(filters));
  }, [filters.page, trigger]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-2 text-start" style={{ paddingLeft: "0.5rem" }}>
          {elem.collaborator}
        </td>
        <td className="col-md-2 text-start">&nbsp; {elem.job_title}</td>
        <td className="col-md-1 text-start">
          {elem.type === "paid" ? "Pagadas" : "Descansadas"}
        </td>
        <td className="col-md-2 text-center">
          {!isEmptyOrUndefined(elem.count) ? elem.count + " días" : "-"}
        </td>
        <td className="col-md-2 text-center">{elem.date}</td>
        <td className="col-md-2 text-end">{convertMoneyFormat(elem?.paymentValue ?? "-")}</td>
        <td className="col-md-2 text-center"
          title={
            elem.status === "pending"
              ? "Pendiente"
              : elem.status === "approved"
                ? "Aprobado"
                : elem.status === "rejected"
                  ? "Rechazado"
                  : elem.status === "processed" && "Procesado"
          }
        >
          <img
            alt="icon"
            src={
              elem.status === "pending"
                ? PENDING
                : elem.status === "approved"
                  ? APPROVED
                  : elem.status === "rejected"
                    ? REJECTED
                    : elem.status === "processed" && PROCESSED
            }
          ></img>
        </td>

        <td className="col-md-1 text-center ">
          {elem.comment !== "" &&
            <img alt="note" src={note}
              height="23px"
              className={`${tableStyles.svgFilterDarkBlue} cursorPointer`}
              onClick={() => {
                setCommentModal({ ...commentModal, show: true, comment: elem.comment })
              }} />
          }
        </td>
        <td className="col-md-1 text-center cursorPointer">
          <div>
            {elem.status !== "processed" &&
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                showUndo={
                  elem.status === "pending"
                    ? false
                    : elem.status === "approved"
                      ? true
                      : elem.status === "rejected"
                        ? true
                        : elem.status === "processed" && false
                }
                undoEvent={() => handleUndo(elem)}
                extraButtons={
                  elem.status === "pending"
                    ? [
                      {
                        text: "Autorizar",
                        icon: APPROVED,
                        event: !!myPermission.edit ? () => handleAuth(elem) : null,
                      },
                      {
                        text: "Denegar",
                        event: !!myPermission.edit ? () =>
                          setCommentModal({ ...commentModal, show: true, comment: "", elem: elem, isEnabled: true })
                          : null,
                        // event: () => setCommentModal({...commentModal, show: true, comment: "", elem: elem, isEnabled: true })

                        icon: REJECTED,
                      },
                    ]
                    : []
                }
              />
            }
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async (elem) => {

    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se va deshacer la solicitud de vacaciones de: ${elem?.collaborator}`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, Continuar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateVacationStatus(
                {
                  status: "pending",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
                (res) => {
                  if (res.success) {
                    setTrigger(trigger + 1);

                    return (
                      customSwaltAlert({
                        icon: "success",
                        title: "Actualizado exitosamente",
                        text: `Se ha actualizado la solicitud de vacaciones de: ${elem?.collaborator}`,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false
                      })
                    )
                  } else {
                    return (
                      customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: res.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false
                      })
                    )
                  }

                }
              );
            } catch (error) {
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false
              })
            }
          };
          send();
        }
      });
    } catch (error) {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: error.message,
        confirmButtonText: "Aceptar",
        showCancelButton: false
      })

    }
  };

  const handleAuth = async (elem) => {
    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se aprobará la solicitud de vacaciones de ${elem.collaborator}`,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, Continuar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateVacationStatus(
                {
                  status: "approved",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
                (res) => {
                  if (res.success) {
                    setShowModal(false);
                    setTrigger(trigger + 1);
                    return (
                      customSwaltAlert({
                        icon: "success",
                        title: "Vacaciones aprobadas",
                        text: `Las vacaciones de ${elem.collaborator} han sido aprobadas con éxito`,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false
                      })
                    )

                  } else {
                    return (
                      customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: res.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false
                      })
                    )
                  }
                }
              );

            } catch (error) {
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false
              })
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return (
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: error.message,
          confirmButtonText: "Aceptar",
          showCancelButton: false
        })
      )
    }
  };
  const handleDeny = async (elem) => {
    try {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se denegará la solicitud de vacaciones de: ${elem.collaborator}`,
        confirmButtonText: "Si, Continuar",
        showCancelButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updateVacationStatus(
                {
                  status: "rejected",
                  reviewed_by: idAccount,
                  id: elem.id,
                  comment: commentModal.comment
                },
                token,
                (res) => {
                  if (res.success) {
                    closeCommentModal()
                    setTrigger(trigger + 1);
                    return (
                      customSwaltAlert({
                        icon: "success",
                        title: "Actualizado exitosamente",
                        text: `Se ha actualizado la solicitud de vacaciones de: ${elem.collaborator}`,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false
                      })
                    )

                  } else {
                    return (
                      customSwaltAlert({
                        icon: "warning",
                        title: "Intenta de nuevo",
                        text: res.message,
                        confirmButtonText: "Aceptar",
                        showCancelButton: false
                      })
                    )
                  }
                }
              );
            } catch (error) {
              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton: false
              })
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return (
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: error.message,
          confirmButtonText: "Aceptar",
          showCancelButton: false
        })
      )
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrVacations !== undefined) {
      const elem2 = arrVacations;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className="text-start">
      &nbsp; Colaborador
    </th>,
    <th key={2} className="text-start">
      &nbsp; Cargo
    </th>,
    <th key={3} className="text-start">
      Tipo
    </th>,
    <th key={4} className="text-center">
      Solicitud
    </th>,
    <th key={5} className="text-center">
      Fechas solicitadas
    </th>,
    <th key={5} className="text-end">
      Valor a pagar
    </th>,
    <th key={6} className="text-center">
      Estado
    </th>,
    <th key={7}>
      &nbsp;
    </th>,
    <th key={8}>&nbsp;</th>,
  ];


  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const statusOptions = [
    { value: "", label: "Seleccionar..." },
    { value: "pending", label: "Pendiente" },
    { value: "approved", label: "Aprobado" },
    { value: "rejected", label: "Rechazado" },
    { value: "processed", label: "Procesado" },
  ]
  const closeCommentModal = () => {
    setCommentModal({ ...commentModal, show: false, comment: "", isEnabled: false, elem: {} })
  }
  return (
    <>
      {store.approvedNewsReducer?.loadingVct && loader}
      <ModalNew
        title="Comentarios"
        show={commentModal.show}
        // btnYesName={showModalRejected.justify !== "" && "Guardar"}
        btnNoName={"Cerrar"}
        size="400"
        btnYesEvent={commentModal.isEnabled ? () => handleDeny(commentModal.elem) : null}
        btnYesDisabled={commentModal.isEnabled ? false : true}
        onHide={() =>
          closeCommentModal()
        }
        btnNoEvent={() =>
          closeCommentModal()
        }
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>
          Comentarios
        </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          disabled={commentModal.isEnabled ? false : true}
          onChange={(e) => setCommentModal({ ...commentModal, comment: e.target.value })}
          defaultValue={commentModal.comment}
          style={{
            height: "4rem", borderRadius: "5px", border: "1.5px solid #7FADDE", padding: "0 0.05rem",
            width: "100%"
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={""}
        subtitle={
          elemDetail.status === "pending"
            ? "En espera"
            : elemDetail.status === "approved"
              ? "Aprobado"
              : elemDetail.status === "rejected"
                ? "Rechazado"
                : elemDetail.status === "processed" && "Procesado"
        }
        subtitleStyle={
          elemDetail.status === "pending"
            ? "text-warning"
            : elemDetail.status === "approved"
              ? "text-success"
              : elemDetail.status === "rejected"
                ? "text-danger"
                : elemDetail.status === "processed" && "text-secondary"
        }
        size={"lg"}
      >
        <PdfViewer file={elemDetail.file}></PdfViewer>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Autorizar vacaciones</h1>

          {!!myPermission.read &&
            <>
              <Row>
                <Col className="d-block" xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    onChange={(e) => setFilters({ ...filters, status: e.value })}
                    options={statusOptions}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={customSelectNew}
                    value={statusOptions.find((x) => x.value === filters?.status)}
                  ></Select>
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} `}>Fecha desde</p>
                  <input
                    placeholder="Escribe..."
                    className="register-inputs text-primary  fw-bold"
                    type="date"
                    onChange={(e) => setFilters({ ...filters, dateFrom: e.target.value })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSearch(e)
                      }
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} `}>Hasta</p>
                  <input
                    placeholder="Escribe..."
                    className="register-inputs text-primary  fw-bold"
                    type="date"
                    onChange={(e) => setFilters({ ...filters, dateUntil: e.target.value })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSearch(e)
                      }
                    }}
                  />
                </Col>

                <Col className="d-block" xs={4}>
                  <div className="pt-4">
                    <form onSubmit={(e) => handleSearch(e)} className="d-flex">
                      <input
                        className={`${tableStyles.SearchNew} mr-2 text-secondary`}
                        onChange={(e) =>
                          setFilters({ ...filters, search: e.target.value })
                        }
                        name="search"
                        type="text"
                        placeholder="Buscar..."
                      />
                      <img
                        src={Search}
                        className="cursorPointer"
                        width={"24px"}
                        height="24px"
                        style={{ marginBottom: "2px" }}
                        alt="Search icon"
                        onClick={(e) => handleSearch(e)}
                      />
                    </form>
                  </div>
                </Col>
              </Row>
              <GenericTableNew lowerCase headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>
              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {store.approvedNewsReducer.vacationList ? filters.page : ""}
                  {" de "}
                  {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                    ? Math.ceil(
                      store.approvedNewsReducer.rowTotal / filters.perpage
                    )
                    : ""}{" "}
                  ({store.approvedNewsReducer.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={store.approvedNewsReducer.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={(e) => setFilters({ ...filters, page: e })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>}


        </div>
      </div>
    </>
  );
};
