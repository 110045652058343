import NumberFormat from "react-number-format";
import { DEFAULT_COUNTRY } from "../helpers/constants";

const DynamicNumberFormat = ({inputType, ...props}) => {
    const country = DEFAULT_COUNTRY === "RD"
    const moneyFormat = inputType === "money"
    const thousandSeparator = moneyFormat ? country ? "," : "." : false
    const decimalSeparator = moneyFormat ? country ? ".": "," : undefined
    const prefix = moneyFormat? country ? 'RD$' : "$" : ""
    const placeholder = moneyFormat? country ? 'RD$0' : "$0" : "Escribir..."
    const format = !moneyFormat ? country ? "###-#######-#" : null : null
    return (
        <NumberFormat
            {...props}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            prefix={prefix}
            format={format}
            placeholder={placeholder}
            />
    );
};

export default DynamicNumberFormat;