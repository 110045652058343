import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../../assets/img/icons/lupa.svg";
import lupa from "../../../../assets/img/icons/auctionDetailLupa2.svg";
import GenericTableNew from "../../../Layouts/GenericTableNew";
import { useSelector, } from "react-redux";
import "reactjs-popup/dist/index.css";
import Styles2 from "../../../GeneratePayroll/generatePayroll.module.scss";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import {
    useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import tableStyles2 from "../../../../components/Layouts/tableStyle.module.scss";
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import { convertMoneyFormat, loader, message, validateEmptyString } from '../../../../helpers/helpers';
import { TssReportDetail } from "./TssReportDetail";
import { useGetMethod, usePostMethod } from "../../../../Hooks/useFetch";
import { customSwaltAlert } from "../../../../helpers/customSwaltAlert";
import { Col, Row } from "react-bootstrap";
import ModalNew from "../../../Layouts/ModalNew";

export const TssReport = () => {
    const store = useSelector((state) => state);
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "tssReport");
    const idAccount = store.loginReducer.currentAccount.id;
    const token = store.loginReducer.Authorization;
    const history = useHistory();
    const [data, setData] = useState({
        trigger: 0,
        generatePremiumSchedule: null,
        idTemplateDetail: "",
        statusTemplate: "",
        templateDetailActive: false,
        dataPayroll: "",
        filters: {
            year: "",
            search: "",
            page: 1,
            perpage: 10,
            eaccount: idAccount,
        },
        modal: {
            show: false,
            isEditing: false,
            datePayment: "",
            payPeriod: ""
        }
    });
    const { filters, trigger, modal, generatePremiumSchedule } = data;
    const { results: royaltyPaymentResults, load: royaltyPaymentLoader, trigger: getRoyaltyPayment } = useGetMethod();
    const { load: loaderGeneratePayrollBonus, trigger: generatePayrollBonus } = usePostMethod();
    const { load: deletePayrollLoader, trigger: deletePayrollMethod } = usePostMethod();
    const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();
    const { results: periodResults, load: periodLoader, trigger: getPeriodMethod } = useGetMethod();
    // const periodOptions = formatToRcSelect(periodResults?.results, "year", "year", "", "", "");


    useEffect(() => {
        if (!!!myPermission?.read) {
            history.push("/nomina/inicio");
        }
        getRoyaltyPayment({
            url: "/payroll/tss_report/",
            objFilters: filters,
            token: token,
            doAfterSuccess: (res) => {
            },
        });

        getPeriodMethod({
            url: "/payroll/periods_perks/",
            objFilters: {
                account: idAccount,
            },
            token: token,
            doAfterSuccess: (res) => {
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, generatePremiumSchedule]);

    const renderElement = (elem) => {
        return (
            <tr key={elem.id}>
                <td className={`col-3 px-3 text-start fw-bold ${tableStyles2.darkBlueText}`}>
                    {elem?.month ?? "-"}
                </td>
                <td className="col-2 text-center">
                    {elem?.year ?? "-"}
                </td>
                <td className="col-2 text-center">
                    {elem?.totalEmployees ?? "-"}
                </td>
                <td className="col-2 text-end">
                    {convertMoneyFormat(elem?.total_amount ?? "-")}
                </td>
                <td className="col-2 text-center px-2">
                    <div
                        className="rounded-pill p-1"
                        style={{
                            backgroundColor: elem?.status?.bgColor,
                        }}
                    >
                        <b style={{ color: elem?.status?.fontColor }}>{elem?.status?.value ?? "-"}
                        </b>
                    </div>
                </td>
                <td className="col-1 ">
                    <img
                        src={lupa}
                        type="button"
                        onClick={
                            !!myPermission?.read ? (e) => {
                                setData({
                                    ...data,
                                    idTemplateDetail: elem?.tssId,
                                    statusTemplate: elem?.status?.name,
                                    generatePremiumSchedule: true,
                                    dataPayroll: elem
                                });
                            }
                                : null
                        }
                        alt="complaint_search"
                        className="hoverPointer cursorPointer"
                        height="20px"
                    />
                </td>
            </tr>
        );
    };

    const renderHeaders = [
        <th key={1} className="text-start col-3 px-3">
            Mes
        </th>,

        <th key={2} className="text-center col-2">
            Año
        </th>,

        <th key={3} className="text-center col-2">
            No. de empleados
        </th>,

        <th key={4} className="text-end col-2">
            Total
        </th>,
        <th key={5} className="text-center col-2">
            Estado
        </th>,

        <th key={6} className="text-start col-1" style={{ minWidth: 20 }}>
            &nbsp;
        </th>,
    ];

    const sendToAccountingFunction = (id, paymentYear, range) => {
        customSwaltAlert({
            icon: 'warning',
            title: '¿Está seguro?',
            text: `Se enviará a contabilidad la reporte de TSS`,
        }).then((result) => {
            if (result.isConfirmed) {
                updatePayroll({
                    url: "/payroll/perks/",
                    token: token,
                    method: "PUT",
                    body: {
                        persk_id: id,
                        entity_account: idAccount,
                        status: "review",
                    },
                    succesAction: (info) => {
                        setData(state => ({ ...state, trigger: state.trigger + 1 }));
                        customSwaltAlert({
                            title: `Enviada exitosamente`,
                            text: `Se ha enviado a contabilidad planilla del año ${paymentYear} - Periodo ${range}. `,
                            icon: "success",
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false
                        });
                    }
                });
            }
        });
    };

    const deletePayrollFunction = (id, year) => {

        customSwaltAlert({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se eliminará la reporte de TSS del año ${year}`,
            confirmButtonText: "Sí, continuar",
        }).then((result) => {
            if (result.isConfirmed) {
                deletePayrollMethod({
                    url: "/payroll/perks/",
                    method: "DELETE",
                    body: {
                        id: id,
                        eaccount: idAccount,
                    },
                    token: token,
                    succesAction: () => {
                        setData(state => ({
                            ...state, trigger: state.trigger + 1
                        }));
                        customSwaltAlert({
                            icon: 'success',
                            title: 'Proceso completado',
                            text: `Se ha eliminado la reporte de TSS`,
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false
                        });
                    },
                });
            }
        });



    };

    const closeModal = () => {
        setData(state => ({
            ...state, modal: {
                show: false,
                isEditing: false,
                datePayment: "",
                payPeriod: "",
            }
        }));
    };

    const validationRequest = () => {
        let validations = validateEmptyString([
            { field: data?.modal?.payPeriod, message: "El periodo es obligatorio para generar el reporte TSS" },
            { field: data?.modal?.datePayment, message: "La fecha de pago es obligatorio para generar el reporte TSS" },
        ]);
        if (validations.valid) {
            customSwaltAlert({
                icon: "warning",
                title: "¿Está seguro?",
                text: "Se generará un reporte de TSS.",
                confirmButtonText: "Sí, continuar",
            }).then((result) => {
                if (result.isConfirmed) {
                    generatePayrollBonus({
                        url: "/payroll/tss_report/",
                        token: token,
                        noAlert: true,
                        method: "POST",
                        body: {
                            "entity_account": idAccount,
                            "payment_year": Number(data.modal.payPeriod),
                            payment_date: data.modal.datePayment,
                            created_by: store.loginReducer?.user_data.id,
                        },
                        doAfterSuccess: (res) => {
                            closeModal();
                            setData(state => ({ ...state, trigger: state.trigger + 1 }));
                        },
                        doAfterException: (res) => {
                            message("warning", `Intente de nuevo`, res?.message, undefined, true);
                        }
                    });
                }
            });
        }
    };
    const [commentModal, setCommentModal] = useState({
        show: false,
        comment: "",
    });
    const closeCommentModal = () => {
        setCommentModal({
            ...commentModal,
            show: false,
            cancelledReason: ""
        });
    };
    return (
        <>
            {((deletePayrollLoader || updatePayrollLoader || royaltyPaymentLoader || loaderGeneratePayrollBonus) && loader)}
            {!generatePremiumSchedule
                ? (
                    <>
                        <ModalNew
                            title="Comentarios"
                            show={commentModal.show}
                            // btnYesName={showModalRejected.justify !== "" && "Guardar"}
                            btnNoName={"Cerrar"}
                            size="400"
                            btnYesEvent={null}
                            btnYesDisabled={true}
                            onHide={() =>
                                closeCommentModal()
                            }
                            btnNoEvent={() =>
                                closeCommentModal()
                            }
                            btnNoDisabled={false}
                        >
                            <p className={tableStyles.crudModalLabel}>
                                Comentario
                            </p>
                            <textarea
                                placeholder={"Escribir..."}
                                id="txtArea"
                                rows="10"
                                cols="10"
                                disabled={true}
                                defaultValue={commentModal.comment}
                                style={{
                                    height: "4rem", borderRadius: "5px", border: "1.5px solid #01A0F6", padding: "0 0.05rem",
                                    width: "100%"
                                }}
                                className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
                            ></textarea>
                        </ModalNew>
                        <OrdModal
                            title={modal.isEditing ? "" : "Generar reporte de TSS"}
                            show={modal.show}
                            btnYesName={"Guardar"}
                            btnNoName={"Cancelar"}
                            size={550}
                            onHide={() => closeModal()}
                            btnYesEvent={!!myPermission?.create ? () => validationRequest() : null}
                            btnNoEvent={() => closeModal()}
                            sizeBody={`${tableStyles2.modalHeight}`}
                            titleClassName={tableStyles2.darkBlueText}
                            btnYesStyle={tableStyles2.btnYesStyle}
                            btnCancelStyle={tableStyles2.btnCancelStyle}
                        >
                            <Row className="py-2">
                                <Col xs={6}>
                                    <label className={tableStyles.crudModalLabel}>
                                        Periodo
                                    </label>
                                    <input
                                        className={`register-inputs `}
                                        type="number"
                                        onChange={(e) =>
                                            setData(state => ({ ...state, modal: { ...state.modal, payPeriod: e.target.value } }))
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <label className={tableStyles.crudModalLabel}>
                                        Fecha de pago
                                    </label>
                                    <input
                                        className={`register-inputs `}
                                        type="date"
                                        onChange={(e) =>
                                            setData(state => ({ ...state, modal: { ...state.modal, datePayment: e.target.value } }))
                                        }
                                    />
                                </Col>
                            </Row>
                        </OrdModal>
                        <div className="w-80 mx-auto">

                            <h1 className={tableStyles.title}>Reporte de TSS</h1>

                            {!!myPermission?.read &&
                                <>
                                    <Row className="d-flex">
                                        <Col xs={2} >
                                            <label className={tableStyles.crudModalLabel}>
                                                Periodo
                                            </label>
                                            <input
                                                onChange={(e) =>
                                                    setData(state => ({ ...state, filters: { ...state.filters, year: e.target.value } }))
                                                }
                                                type="month"
                                                placeholder="Escribir..."
                                                className={`register-inputs `}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        setData(state => ({ ...state, trigger: state.trigger + 1 }));
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col xs={5} className='d-flex align-items-end'>
                                            <input
                                                className={` ${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch} 
                                                name="search"
                                                type="text"
                                                placeholder="Buscar..."
                                                value={filters.search}
                                                onChange={(e) =>
                                                    setData(state => ({ ...state, filters: { ...state.filters, search: e.target.value } }))
                                                }
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter') {
                                                        setData(state => ({ ...state, trigger: state.trigger + 1 }));
                                                    }
                                                }}
                                            />

                                            <img
                                                src={Search}
                                                style={{ cursor: "pointer" }}
                                                alt="Search icon"
                                                height="24px"
                                                onClick={() => {
                                                    setData(state => ({ ...state, trigger: state.trigger + 1 }));
                                                }}
                                            />
                                        </Col>

                                        {!!myPermission?.create &&
                                            <>
                                                <Col xs={5} className='d-flex align-items-end justify-content-end'>
                                                    <label
                                                        className="darkGray fw-bold"
                                                        style={{ color: "#58595B", margin: '4px' }}
                                                    >
                                                        Generar planilla
                                                    </label>
                                                    <div
                                                        className={Styles2.btnpreviw}
                                                        style={{ width: "33px", height: "31px" }}
                                                        onClick={() =>
                                                            setData(state => ({ ...state, modal: { ...state.modal, isEditing: false, show: true } }))
                                                        }
                                                    >
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row >

                                        <div >
                                            <GenericTableNew headers={renderHeaders} dark={true}>
                                                {royaltyPaymentResults?.results?.length > 0 &&
                                                    royaltyPaymentResults?.results?.map((el) => {
                                                        return renderElement(el);
                                                    })
                                                }
                                            </GenericTableNew>
                                        </div>

                                        <div >
                                            <div className={paginationStyles.wrapper}>
                                                <p className={`${paginationStyles.paginationText} text-secondary`}>
                                                    Pag. {royaltyPaymentResults?.results ? filters.page : ""}
                                                    {" de "}
                                                    {Math.ceil(royaltyPaymentResults?.rowTotal / filters.perpage)
                                                        ? Math.ceil(royaltyPaymentResults?.rowTotal / filters.perpage)
                                                        : ""}{" "}
                                                    ({royaltyPaymentResults?.rowTotal} encontrados)
                                                </p>
                                                <Pagination
                                                    activePage={filters.page}
                                                    itemsCountPerPage={filters.perpage}
                                                    totalItemsCount={royaltyPaymentResults?.rowTotal}
                                                    pageRangeDisplayed={5}
                                                    onChange={(e) =>
                                                        setData(state => ({ ...state, filters: { ...state.filters, page: e }, trigger: state.trigger + 1 }))
                                                    }
                                                    itemClassPrev={paginationStyles.itemClassPrev}
                                                    itemClassNext={paginationStyles.itemClassNext}
                                                    itemClassFirst={paginationStyles.itemClassFirst}
                                                    itemClassLast={paginationStyles.itemClassLast}
                                                    itemClass={paginationStyles.itemClass}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </>}
                        </div>
                    </>
                )

                : (
                    <TssReportDetail backPage={{ setData }} idTemplate={data.idTemplateDetail} status={data.statusTemplate} dataPayroll={data.dataPayroll} />
                )
            }

        </>
    );
};
