import React, { useEffect, useState } from "react";
import { Tabs, Tab, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../assets/img/icons/backIcon.svg";
import Close from "../../assets/img/icons/cleanCloseIcon.svg";
import Adjuntar from "../../assets/img/icons/Adjuntar.svg";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  convertBase64,
  isEmptyOrUndefined,
  placeMyIcon,
  today,
} from "../../helpers/helpers";

import { customSelectNew } from "../Layouts/react-select-custom";
import { getDisciplinaryProcess } from "../../actions/partnersActions";
import moment from "moment";
import { updateDisciplinaryProcess } from "../../actions/DisciplinaryProcessesAction";
import Dropzone from "react-dropzone";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";
export const ProcessDetail = (props) => {
  const store = useSelector((state) => state);
  const location = useLocation();
  const idProcess = location.state?.id;
  const disDetail = location.state;
  const fullName =
    store.loginReducer.user_data.first_name +
    " " +
    store.loginReducer.user_data.first_surname;

  const [documents, setDocuments] = useState({
    filename: "",
    file64: "",
  });
  const [documentsMin, setDocumentsMin] = useState({
    filename: "",
    file64: "",
  });
  const idAccount = store.loginReducer?.user_data.id;
  const idEnterprise = store.loginReducer?.currentAccount.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [inputs, setInputs] = useState({});
  const token = store.loginReducer.Authorization;
  const [allowSend, setallowSend] = useState({
    citations: false,
    minutes: false,
    saveRes: false,
  });
  const selectedProcess = location.state;
  const [slider, setSlider] = useState({
    infoGen: true,
    citations: false,
    minutes: false,
    saveRes: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [mouseIn, setMouseIn] = useState(false);
  const myPermission = () =>
    store.loginReducer?.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "ListProces"
    );

  useEffect(() => {
    /* -------------------------------- CITATIONS ------------------------------- */
    if (
      !isEmptyOrUndefined(inputs.type_date) &&
      !isEmptyOrUndefined(inputs.title) &&
      !isEmptyOrUndefined(inputs.description)
      //   && documents.length > 0
    ) {
      setallowSend({ ...allowSend, citations: true });
    } else {
      setallowSend({ ...allowSend, citations: false });
    }
    /* --------------------------------- MINUTES -------------------------------- */
    if (
      !isEmptyOrUndefined(inputs.type_dateMin) &&
      !isEmptyOrUndefined(inputs.titleMin) &&
      !isEmptyOrUndefined(inputs.descriptionMin) &&
      !isEmptyOrUndefined(inputs.support_fileMin)
    ) {
      setallowSend({ ...allowSend, citations: true });
    } else {
      setallowSend({ ...allowSend, citations: false });
    }
    /* --------------------------------- SAVERES -------------------------------- */
    // if (
    //   !isEmptyOrUndefined(inputs.type_dateMin) &&
    //   !isEmptyOrUndefined(inputs.titleMin) &&
    //   !isEmptyOrUndefined(inputs.descriptionMin) &&
    //   !isEmptyOrUndefined(inputs.support_fileMin)
    // ) {
    //   setallowSend({ ...allowSend, citations: true });
    // } else {
    //   setallowSend({ ...allowSend, citations: false });
    // }
  }, [inputs, documents]);

  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push("/nomina/inicio");
    }
    dispatch(
      getDisciplinaryProcess({
        entity_account: idEnterprise,
        id: idProcess,
      })
    );
  }, []);
  const download = (url) => {
    window.location.href = url;
  };

  const handleSubmit = async (type) => {
    switch (type) {
      case "citation":
        try {
          if (
            isEmptyOrUndefined(inputs.type_date) ||
            isEmptyOrUndefined(inputs.title) ||
            isEmptyOrUndefined(inputs.description) ||
            isEmptyOrUndefined(documents.file64)
          ) {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `Por favor rellene todos los campos`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
          }
          setIsLoading(true);
          const result = await updateDisciplinaryProcess(
            {
              type_date: inputs.type_date,
              type: type,
              title: inputs.title,
              description: inputs.description,
              entity_account: idEnterprise,
              support_file: documents.file64,
              filename: documents.filename,
              created_by: idAccount,
              created_by_name: fullName,
              id: disDetail.id,
            },
            token
          );

          if (result.success) {
            setInputs({
              ...inputs,
              title: "",
              type_date: "",
              description: "",
            });
            setDocuments({
              filename: "",
              file64: "",
            });
            setTrigger(trigger + 1);
            setIsLoading(false);
            return customSwaltAlert({
              icon: "success",
              title: "Actualizado exitosamente",
              text: `Se ha actualizado un proceso disciplinario`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });

          } else {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `${result.message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
          }
        } catch (error) {
          setIsLoading(false);
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Ha ocurrido un error`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          });
          return console.error(error);
        }
        break;

      case "minute":
        try {
          if (
            isEmptyOrUndefined(inputs.type_dateMin) ||
            isEmptyOrUndefined(inputs.titleMin) ||
            isEmptyOrUndefined(inputs.descriptionMin) ||
            isEmptyOrUndefined(documentsMin.file64)
          ) {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `Por favor rellene todos los campos`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
          }
          setIsLoading(true);
          const result = await updateDisciplinaryProcess(
            {
              type_date: inputs.type_dateMin,
              type: type,
              title: inputs.titleMin,
              description: inputs.descriptionMin,
              entity_account: idEnterprise,
              support_file: documents.file64,
              filename: documents.filename,
              created_by: idAccount,
              created_by_name: fullName,
              id: disDetail.id,
            },
            token
          );

          if (result.success) {
            setInputs({
              ...inputs,
              titleMin: "",
              type_dateMin: "",
              descriptionMin: "",
            });
            setDocumentsMin({
              filename: "",
              file64: "",
            });
            setTrigger(trigger + 1);
            setIsLoading(false);
            return customSwaltAlert({
              icon: "success",
              title: "Actualizado exitosamente",
              text: `se ha actualizado un proceso disciplinario`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
          } else {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `${result.message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
            
          }
        } catch (error) {
          setIsLoading(false);
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Ha ocurrido un error`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          });
          
          return console.error(error);
        }
        break;

      case "result":
        try {
          if (
            isEmptyOrUndefined(inputs.last_action) ||
            isEmptyOrUndefined(inputs.descriptionSaveRes)
          ) {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `Por favor rellene todos los campos`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
          }
          setIsLoading(true);
          const result = await updateDisciplinaryProcess(
            {
              type: type,
              description: inputs.descriptionSaveRes,
              entity_account: idEnterprise,
              created_by: idAccount,
              created_by_name: fullName,
              last_action: inputs.last_action?.value,
              id: disDetail.id,
            },
            token
          );

          if (result.success) {
            setInputs({
              ...inputs,
              descriptionSaveRes: "",
              last_action: "",
            });

            setTrigger(trigger + 1);
            setIsLoading(false);
            return customSwaltAlert({
              icon: "success",
              title: "Actualizado exitosamente",
              text: `se ha actualizado un proceso disciplinario`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
            
          } else {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `${result.message}`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            });
            
          }
        } catch (error) {
          setIsLoading(false);
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Ha ocurrido un error`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          });
         
          return console.error(error);
        }
        break;

      default:
        break;
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */

  const onChangeDoc = async (e, type) => {
    const file = e.target.files[0];

    const validExtensions = ["zip", "ZIP", "pdf", "PDF", "rar", "RAR"];

    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split(".");
      let extension = validExtensions.find(
        (obj) => obj === actFileExt[actFileExt.length - 1]
      );
      if (isEmptyOrUndefined(extension)) {
        e.target.value = "";
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        });
      }

      if (file.size > 250000000) {
        e.target.value = "";
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Su archivo no puede tener un tamaño superior a 25MB`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        });
        
      } else {
        if (file.size <= 0) {
          e.target.value = "";
          return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Por favor, adjunte un archivo valido.`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          });
        }
      }
      if (!isEmptyOrUndefined(file)) {
        // setSelectedFile(file);
      }
    } else {
      return console.error("Archivo vacio: ", file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split(".")[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    let ext = type_doc.split("/");
    let finalName = `${name_doc}.${ext[1]}`;

    if (type === "citation") {
      setDocuments({
        filename: finalName,
        file64: file64,
      });
    } else {
      setDocumentsMin({
        filename: finalName,
        file64: file64,
      });
    }
  };

  return (
    <div className="container"
    style={{marginLeft: "65px"}}>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex">
        <Link
          to="/nomina/procesosDisciplinario"
          style={{ alignSelf: "center", margin: "4.2rem 1rem 0.5rem -3.3rem" }}
        >
          <img src={backIcon} alt="back" height="30px"/>
        </Link>
        <h1 className={tableStyles.f40}>Detalle del proceso</h1>
      </div>

      <p
        className="subtitleBlue"
        title={selectedProcess?.description}
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
      >
        {mouseIn
          ? selectedProcess?.description
          : selectedProcess?.description.length >= 45
          ? selectedProcess?.description.slice(0, 45) + "..."
          : selectedProcess?.description}
      </p>
      <Row>
        <Tabs
          defaultActiveKey="report"
          id="uncontrolled-tab-example"
          className="mb-3 nav nav-tabs"
          style={{
            borderBottom: "1px solid #dee2e6",
          }}
        >
          {/* /* ------------------------------ GENERAL INFO ------------------------------ */}
          <Tab
            eventKey="report"
            title="Información general"
            tabClassName={`text-center text-primary px-4 tabHeader col-3  ${tableStyles.bgClearBlue}`}
            onClick={() =>
              setSlider({
                infoGen: true,
                citations: false,
                minutes: false,
                saveRes: false,
              })
            }
          >
            <Row className="">
              <Col xs={6} className="mt-4 mb-4 ">
                <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                  <b>• Información general</b>
                </h6>

                <div className="mt-2">
                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                    >
                      <b>Fecha</b>
                    </Col>

                    <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                      {selectedProcess?.report_date}
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                    >
                      <b>Reportante</b>
                    </Col>
                    <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                      {selectedProcess?.reporter
                        ? selectedProcess?.reporter
                        : "-"}
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                    >
                      <b>Cargo</b>
                    </Col>
                    <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                      {selectedProcess?.job_title
                        ? selectedProcess?.job_title
                        : "-"}
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                    >
                      <b>Área</b>
                    </Col>
                    <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                      {selectedProcess?.area_name
                        ? selectedProcess?.area_name
                        : "-"}
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                    >
                      <b>Título</b>
                    </Col>
                    <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                      {selectedProcess?.title ? selectedProcess?.title : "-"}
                    </Col>
                  </Row>
                </div>
                <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                  <b>• Descripción</b>
                </h6>
                <div className="mt-2">
                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                    >
                      <b>Colaboradores implicados: </b>
                      <br></br>
                      {selectedProcess?.involved
                        ? selectedProcess?.involved
                        : "-"}
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={6}
                      className={`${tableStyles.darkGrayText} ${tableStyles.f14}`}
                    >
                      <b>Descripción de la situación: </b>
                      <br></br>

                      {selectedProcess?.description
                        ? selectedProcess?.description
                        : "-"}
                    </Col>
                  </Row>
                </div>
                <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                  <b>• Evidencias</b>
                </h6>
                <div className="mt-4">
                  <Row className="d-flex justify-content-start">
                    <Col
                      xs={12}
                      className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}
                    >
                      {selectedProcess?.support_files?.length > 0
                        ? selectedProcess?.support_files?.map((e) => {
                            return (
                              <>
                                <u
                                  onClick={() => download(e.support_file)}
                                  className={`${tableStyles.darkBlueText} cursorPointer`}
                                >
                                  {e.support_file.split("/").pop()}
                                </u>
                                &nbsp; &nbsp;
                              </>
                            );
                          })
                        : ""}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Tab>

          {/* /* -------------------------------- CITATIONS ------------------------------- */}
          <Tab
            eventKey="citations"
            title="Citaciones"
            tabClassName={`text-center text-primary px-4 tabHeader col-3  ${tableStyles.bgClearBlue}`}
            onClick={() =>
              setSlider({
                infoGen: false,
                citations: true,
                minutes: false,
                saveRes: false,
              })
            }
          >
            <Row>
              <h5 className={tableStyles.darkBlueText}>
                <b>Registro de nueva citación</b>
              </h5>
              <Col className={`mt-1 rounded ${tableStyles}`} xs={6}>
                <div
                  className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}
                >
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Fecha
                    <span className={"text-warning"}>*</span>
                  </p>
                  <input
                    key={"datex" + trigger}
                    type="date"
                    onChange={(e) =>
                      e.target.value < today()
                        ? setInputs({ ...inputs, type_date: today() })
                        : setInputs({ ...inputs, type_date: e.target.value })
                    }
                    min={today()}
                    value={inputs.type_date}
                    className="register-inputs   fw-bold "
                  />
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Concepto
                    <span className={"text-warning"}>*</span>
                  </p>
                  <input
                    onChange={(e) =>
                      setInputs({ ...inputs, title: e.target.value })
                    }
                    key={"conceptcit" + trigger}
                    maxLength="30"
                    value={inputs.title}
                    className="register-inputs text-secondary"
                  />

                  <p className={`${tableStyles.crudModalLabel} `}>
                    Descripción
                    <span className={"text-warning"}>*</span>
                  </p>
                  <textarea
                    value={inputs.description}
                    onChange={(e) =>
                      setInputs({ ...inputs, description: e.target.value })
                    }
                    key={"descrCit" + trigger}
                    onChange={(e) =>
                      setInputs({ ...inputs, description: e.target.value })
                    }
                    placeholder={"Escribir..."}
                    id="txtArea"
                    rows="10"
                    cols="10"
                    style={{ height: "4rem" }}
                    className={`register-inputs text-secondary`}
                  ></textarea>
                </div>

                <Row>
                  <Col xs={12}>
                    <div className="display-grid">
                      <label className={`${tableStyles.crudModalLabel} m-0`}>
                        Cargar archivo
                        <span className="text-warning">*</span>
                      </label>
                    </div>
                    <div style={{ height: "5rem" }}>
                      <Dropzone>
                        {({ getRootProps, getInputProps }) => (
                          <section className="w-100 h-100 p-1 text-center mx-auto cursorPointer  register-inputs">
                            <div
                              {...getRootProps()}
                              className="d-flex justify-content-between"
                            >
                              <input
                                {...getInputProps()}
                                type="file"
                                name="documents"
                                onChange={(e) => onChangeDoc(e, "citation")}
                                multiple={false}
                              />

                              <p
                                className={`text-muted m-0 m-auto text-center  mt-4 hoverPointer`}
                              >
                                {"Soltar archivo aquí"}
                              </p>

                              {
                                <img
                                  src={Adjuntar}
                                  alt="añadir"
                                  className="pt-5"
                                />
                              }
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </Col>
                </Row>

                <div className={`mt-4 `}>
                  {!isEmptyOrUndefined(documents.filename) && (
                    <Col xs={12} className=" d-flex">
                      <div
                        key={"container"}
                        className="d-flex justify-content-between"
                      >
                        <label
                          key={"label"}
                          className={`${tableStyles.crudModalLabel} m-0`}
                        >
                          <img
                            key={"closeDco"}
                            onClick={() =>
                              setDocuments({
                                filename: "",
                                file64: "",
                              })
                            }
                            className={`cursorPointer `}
                            src={Close}
                          ></img>
                          &nbsp;
                          <u style={{ marginRight: "1rem" }}>
                            {documents.filename}{" "}
                          </u>
                        </label>
                      </div>
                    </Col>
                  )}
                </div>
                <div className={`customTabs__Footer mt-2 p-0`}>
                  {!!myPermission?.create &&
                  <Button
                    className={tableStyles.btnPrimary}
                    onClick={() => handleSubmit("citation")}
                    // disabled={allowSend.citations ? false : true}
                  >
                    &nbsp; Guardar
                  </Button>
                  }
                </div>
              </Col>
              {/* /* ---------------------- VERTICAL LINE --------------------- */}
              <Col
                className=" d-flex justify-content-center"
                xs={1}
                style={{ marginTop: "-37px" }}
              >
                <div style={{ width: "20%" }}>&nbsp;</div>
                <hr
                  className="m-0 p-0"
                  style={{
                    border: "none",
                    borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
                    height: "60vh",
                    width: "1px",
                  }}
                ></hr>
              </Col>
            </Row>
          </Tab>
          {/* /* -------------------------------- MINUTES ------------------------------- */}
          <Tab
            eventKey="minutes"
            title="Actas"
            tabClassName={`text-center text-primary px-4 tabHeader col-3  ${tableStyles.bgClearBlue}`}
            onClick={() =>
              setSlider({
                infoGen: false,
                citations: false,
                minutes: true,
                saveRes: false,
              })
            }
          >
            <Row>
              <Col className={`mt-1 rounded `} xs={7}>
                <div
                  className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}
                >
                  <p className={`${tableStyles.crudModalLabel} `}>Fecha</p>
                  <input
                    key={"DateMin" + trigger}
                    type="date"
                    onChange={(e) =>
                      e.target.value > today()
                        ? setInputs({ ...inputs, type_dateMin: today() })
                        : setInputs({ ...inputs, type_dateMin: e.target.value })
                    }
                    value={inputs.type_dateMin}
                    max={today()}
                    className="register-inputs  fw-bold "
                  />
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Concepto
                    <span className={"text-warning"}>*</span>
                  </p>
                  <input
                    onChange={(e) =>
                      setInputs({ ...inputs, titleMin: e.target.value })
                    }
                    key={"conceptMIn" + trigger}
                    value={inputs.titleMin}
                    className="register-inputs text-secondary"
                  />

                  <p className={`${tableStyles.crudModalLabel} `}>
                    Descripción
                    <span className={"text-warning"}>*</span>
                  </p>
                  <textarea
                    key={"descrMIn" + trigger}
                    onChange={(e) =>
                      setInputs({ ...inputs, descriptionMin: e.target.value })
                    }
                    value={inputs.descriptionMin}
                    placeholder={"Escribir..."}
                    id="txtArea"
                    rows="10"
                    cols="10"
                    style={{ height: "4rem" }}
                    className={`register-inputs text-secondary`}
                  ></textarea>
                </div>

                <Row>
                  <Col xs={12}>
                    <div className="display-grid">
                      <label className={`${tableStyles.crudModalLabel} m-0`}>
                        Anexos
                        <span className="text-warning">*</span>
                      </label>
                    </div>
                    <div style={{ height: "5rem" }}>
                      <Dropzone>
                        {({ getRootProps, getInputProps }) => (
                          <section className="w-100 h-100 p-1 text-center mx-auto cursorPointer  register-inputs">
                            <div
                              {...getRootProps()}
                              className="d-flex justify-content-between"
                            >
                              <input
                                {...getInputProps()}
                                type="file"
                                name="documents"
                                onChange={(e) => onChangeDoc(e, "minutes")}
                                multiple={false}
                              />

                              <p
                                className={`text-muted m-0 m-auto text-center  mt-4 hoverPointer`}
                              >
                                {"Soltar archivo aquí"}
                              </p>

                              {
                                <img
                                  src={Adjuntar}
                                  alt="añadir"
                                  className="pt-5"
                                />
                              }
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </Col>
                </Row>

                <div className={`mt-4 `}>
                  {!isEmptyOrUndefined(documentsMin.filename) && (
                    <Col xs={12} className=" d-flex">
                      <div
                        key={"container"}
                        className="d-flex justify-content-between"
                      >
                        <label
                          key={"label"}
                          className={`${tableStyles.crudModalLabel} m-0`}
                        >
                          <img
                            key={"closeMin"}
                            onClick={() =>
                              setDocumentsMin({
                                filename: "",
                                file64: "",
                              })
                            }
                            className={`cursorPointer `}
                            src={Close}
                          ></img>
                          &nbsp;
                          <u style={{ marginRight: "1rem" }}>
                            {documentsMin.filename}
                          </u>
                        </label>
                      </div>
                    </Col>
                  )}
                </div>
                <div className={`customTabs__Footer mt-2 p-0`}>
                {!!myPermission?.create &&
                  <Button
                    className={tableStyles.btnPrimary}
                    onClick={() => handleSubmit("minute")}
                    // disabled={allowSend.minutes ? false : true}
                  >
                    &nbsp; Guardar
                  </Button>
                }
                </div>
              </Col>
            </Row>
          </Tab>
          {/* /* -------------------------------- SAVE RESULTS ------------------------------- */}
          <Tab
            eventKey="saveRes"
            title="Registrar resultados"
            tabClassName={`text-center text-primary px-4 tabHeader col-3  ${tableStyles.bgClearBlue}`}
            onClick={() =>
              setSlider({
                infoGen: false,
                citations: false,
                minutes: false,
                saveRes: true,
              })
            }
          >
            <Row>
              <Col className={`mt-1 rounded ${tableStyles}`} xs={7}>
                <div
                  className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}
                >
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Tipo
                    <span className={"text-warning"}>*</span>
                  </p>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    key={"deduction" + trigger}
                    onChange={(e) => {
                      setInputs({ ...inputs, last_action: e });
                    }}
                    options={[
                      {
                        value: "termination",
                        label: "Terminación del contrato",
                      },
                      { value: "suspension", label: "Suspención por días" },
                      { value: "misunderstand", label: "Mal entendido" },
                      { value: "memorandum", label: "LLamado de atención" },
                    ]}
                    className="text-secondary"
                    placeholder={"Seleccione"}
                    styles={customSelectNew}
                  ></Select>

                  <p className={`${tableStyles.crudModalLabel} `}>
                    Justificación
                  </p>
                  <textarea
                    key={"descr" + trigger}
                    onChange={(e) =>
                      setInputs({
                        ...inputs,
                        descriptionSaveRes: e.target.value,
                      })
                    }
                    placeholder={"Escribir..."}
                    id="txtArea"
                    rows="10"
                    cols="10"
                    style={{ height: "7rem" }}
                    className={`register-inputs text-secondary`}
                  ></textarea>
                </div>

                <div className={`customTabs__Footer mt-2 p-0`}>
                {!!myPermission?.create &&
                  <Button
                    className={tableStyles.btnPrimary}
                    onClick={() => handleSubmit("result")}
                    disabled={allowSend ? false : true}
                  >
                    {/* <img className="cursorPointer" src={send} /> */}
                    &nbsp; Guardar
                  </Button>
                }
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Row>
    </div>
  );
};
