import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
import {
  GET_APPROVED_OVERTIME,
  GET_INHABILITIES,
  GET_PERMISSIONS_LIST,
  GET_VACATIONS_LIST,
} from "./actionTypes";

export const getOverTimeList = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/overtime/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_APPROVED_OVERTIME,
        payload: res.results,
        rowTotal: res.row_total,
      });
    })
    .catch((error) => {
      console.error(error);
      return message("error", "Error", "Ha ocurrido un error");
    });
};

export const getDetail = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/overtime/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message("error", "Error", "Ha ocurrido un error");
  }
};
export const updateOverTimeStatus = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/overtime/${data.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const getPermissionsList = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  dispatch({
    type: GET_PERMISSIONS_LIST,
    payload: [],
    rowTotal: 0,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/permissions/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_PERMISSIONS_LIST,
        payload: res.results,
        rowTotal: res.row_total,
        loading: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PERMISSIONS_LIST,
        payload: [],
        rowTotal: 0,
        loading: false,
      });
      console.error(error);
      return message("error", "Error", "Ha ocurrido un error");
    });
};

export const updatePermissionsStatus = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/permissions/${data.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", "Ha ocurrido un error");
  }
};

export const getVacactionsList = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  dispatch({
    type: GET_VACATIONS_LIST,
    payload: [],
    rowTotal: 0,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/vacations/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_VACATIONS_LIST,
        payload: res.results,
        rowTotal: res.rowTotal,
        loading: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_VACATIONS_LIST,
        payload: [],
        rowTotal: 0,
        loading: false,
      });
      console.error(error);
      return message("error", "Error", "Ha ocurrido un error");
    });
};

export const updateVacationStatus = async (data, token, doAfter) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/vacations/${data.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      doAfter(result)
      return result;
    } else {
      return message("warning", "Intenta de nuevo", result.message, undefined, true);
    }
  } catch (error) {
    console.error(error);
    return message("warning", "Intenta de nuevo", "Ha ocurrido un error", undefined, true);
  }
};

export const getInhabilities = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/inhabilities/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_INHABILITIES,
        payload: res.results,
        rowTotal: res.row_total,
      });
    })
    .catch((error) => {
      console.error(error);
      return message("error", "Error", "Ha ocurrido un error");
    });
};
export const updateInhabilitiesStatus = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/inhabilities/${data.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.error(error);
    return message("error", "Error", "Ha ocurrido un error");
  }
};
