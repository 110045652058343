import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import { customSelectNewDark } from "../Layouts/react-select-custom";
import GenericTableNew from "../Layouts/GenericTableNew";
import Styles from "./tableStyles.module.scss";
import Search from "../../assets/img/icons/lupa.svg";
import lupa from "../../assets/img/icons/auctionDetailLupa2.svg";
import paginationStyles from "../Layouts/pagination.module.scss";
import { getComplaints } from "../../actions/complaintsAction";
import { getSelectStatus } from "../../actions/contractAction";
import Pagination from "react-js-pagination";
import ReportComplaintDetail from "./ReportComplaintDetail";
import SelectComponent from "../SelectComponent/SelectComponent";
function Complaint(props) {

  const counter = useSelector((state) => state);
  const myPermission =
  counter.loginReducer.currentAccount?.profile?.permission?.find(
    (x) => x.functionality?.prefix === "RepQue"
    );
    
  const [trigger, setTrigger] = useState(1);
  const dispatch = useDispatch();
  let history = useHistory();
  const [details, setDetails] = useState({ show: "", });
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    type: undefined,
    state: "",
    search: "",
    entity_account: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    status: undefined,
  });
  useEffect(() => {
    dispatch(getComplaints(filters));
    dispatch(getSelectStatus({ prefix: "complaints" }));
  }, []);
  useEffect(() => {
    if (details.show === false) {
      dispatch(getComplaints(filters));
    }
  }, [details.show]);
  useEffect(() => {
    dispatch(getComplaints(filters));
  }, [trigger, filters.page]);
  const handlePageChange = (val) => {
    setFilters({
      ...filters,
      page: val,
    });
  };
  const searchFilters = () => {
    setFilters({
      ...filters,
      page: 1,
    });
    dispatch(getComplaints(filters));
  };
  const header = [
    <th className="pl-1 col-1 text-start" style={{ paddingLeft: "10px" }}>
      Tipo
    </th>,
    <th className="col-3 text-start">Reportante</th>,
    <th className="col-3 text-start">Concepto</th>,
    <th className="col-2 text-start">Área</th>,
    <th className="col-1 text-center">Fecha</th>,
    <th className="col-1 text-center">Estado</th>,
    <th className="col-1 text-center">ㅤ </th>,
  ];
  const body = [];
  if (Array.isArray(counter?.complaintsReducer?.allReportsComplaints)) {
    counter.complaintsReducer.allReportsComplaints.map((item) => {
      body.push(
        <tr>
          <td className="text-start col-1" style={{ paddingLeft: "10px" }}>
            {item?.type}
          </td>
          <td className="text-start col-3">{item?.report}</td>
          <td className="text-start col-3">{item?.concept}</td>
          <td className="text-start col-2">{item?.area}</td>
          <td className="text-center col-1">{item?.report_date}</td>
          <td className="text-center col-1">
            <div
              className="rounded-pill p-1"
              style={{
                backgroundColor: item.background,
              }}
            >
              <b style={{ color: item.fontcolor }}>{item.status}</b>
            </div>
          </td>

          <td className="text-center col-1">
            {" "}
            <i>
              <img
                src={lupa}
                type="button"
                onClick={() => setDetails({ ...details, show: true, details: true, edit: false, info: item })}
                alt="complaint_search"
                className="hoverPointer"
                height="20px"
              />
            </i>
          </td>
        </tr>
      );
    });
  }
  const optionStatus = [
    { value: "", label: "Seleccionar...", name: "status" },
  ];

  if (Array.isArray(counter.contractReducer.resultsSlectStatus)) {
    counter.contractReducer.resultsSlectStatus.map((item) => {
      optionStatus.push({
        label: item.value,
        value: item.name,
        name: "status",
      });
    });
  }
  const opntionType = [
    { value: "", label: "Seleccionar...", name: "status" },
    { value: "complaint", label: "Queja", name: "status" },
    { value: "report", label: "Reporte", name: "status" },
  ];
  const renderPrincipal =
    <>
      {!!counter.agreementReducer.loading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        <div className="d-flex">
          <h1 className={tableStyles.title}>Reportes y quejas</h1>
        </div>
        {!!myPermission?.read && <>
        <Row>
      <Col xs={2}>
       <p className={tableStyles.crudModalLabel}>Tipo</p>
       <SelectComponent
        styles={customSelectNewDark}
        placeholder={"Seleccionar..."}
        key={"area" + trigger}
        value={opntionType.filter((e)=> e.value === filters.type)}
        onChange={(e) => {
          setFilters({
            ...filters,
            type: e.value,
          })
          dispatch(getComplaints({...filters, type: e.value, page:1}));
        }}
        options={opntionType}
        ></SelectComponent>
      </Col>
      <Col xs={2}>
       <p className={tableStyles.crudModalLabel}>Estado</p>
       <SelectComponent
        styles={customSelectNewDark}
        placeholder={"Seleccionar..."}
        value={optionStatus.filter((e)=> e.value === filters.status)}
        key={"state" + trigger}
        onChange={(e) => {
          setFilters({
            ...filters,
            status: e.value,
            page: 1,
          })
          dispatch(getComplaints({...filters, status: e.value, page:1}));
        }}
        // noOptionsMessage={() => "No hay datos"}
        options={optionStatus}
       ></SelectComponent>{" "}
      </Col>
      <Col
       xs={4}
       className="d-flex align-self-end gap-1"
      >
       <input
        className={tableStyles.SearchNew}
        value={filters.search}
        placeholder="Buscar..."
        type="text"
        onChange={(e) =>
          setFilters({ ...filters, search: e.target.value })
        }
        onKeyDown={(e) => {
         if (e.key === "Enter") {
          searchFilters()
        }
        }}
       ></input>
       <img
        className="hoverPointer"
        alt="iconSearch"
        style={{}}
        src={Search}
        height="24px"
        onClick={(e) => searchFilters()}
       />
      </Col>
      {/* <Col xs={5}>
          </Col> */}
        </Row>
        <GenericTableNew lowerCase dark={true} headers={header}>
          {body}
        </GenericTableNew>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag.{" "}
            {counter?.complaintsReducer?.totalReportsComplaints
              ? filters.page
              : ""}
            {" de "}
            {Math.ceil(
              counter?.complaintsReducer?.totalReportsComplaints /
              filters.perpage
            )
              ? Math.ceil(
                counter?.complaintsReducer?.totalReportsComplaints /
                filters.perpage
              )
              : ""}{" "}
            ({counter?.complaintsReducer?.totalReportsComplaints}
            {" encontrados"})
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={counter?.complaintsReducer?.totalReportsComplaints}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={`${paginationStyles.itemClassPrev} `}
            itemClassNext={`${paginationStyles.itemClassNext} `}
            itemClassFirst={`${paginationStyles.itemClassFirst} `}
            itemClassLast={`${paginationStyles.itemClassLast} `}
            itemClass={paginationStyles.itemClass}
          />
        </div>
        </>}

      </div>
    </>
  return (!!details.show) ?
    <ReportComplaintDetail
      setDetails={setDetails}
      info={details.info}
      edit={details.edit}
      details={details.details}
      show={details.show}
    />
    :
    renderPrincipal;

}
export default Complaint;
