import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import NumberFormat from "react-number-format";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


import ModalNew from "../../Layouts/ModalNew";
import payrollStyles from "../payrollStyles.module.scss";
import popUpStyles from "../../Popup/customPopup.module.scss";

import {

} from "../../../actions/payrollActions";
import { URL_GATEWAY, API_VERSION } from "../../../helpers/constants";
import { convertFilterToString } from "../../../helpers/convertToFilter";

import Select from "react-select";
import { customSelectNew } from "../../Layouts/react-select-custom";

import Pagination from "react-js-pagination";
import GenericTableNew from "../../Layouts/GenericTableNew";
import GenericModalNew from "../../Layouts/ModalNew";

import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import CustomPopupExtends from "../../Popup/customPopUpExtends";

import calendar from "../../../assets/img/icons/calendar.svg";
import calendaredit from "../../../assets/img/icons/calendaredit.svg";
import useredit from "../../../assets/img/icons/useredit.svg";
import recibir from "../../../assets/img/icons/recibir.svg";
import popupx from "../../../assets/img/icons/x.svg";
import threeDots from "../../../assets/img/icons/threeDots.svg";
import { Checkbox } from "@material-ui/core";
import { convertDateToLatinFormat } from "../../../helpers";
import MedicalExamHistory from "./MedicalExamHistory";
import PlanningMedicalExams from "./PlanningMedicalExams";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";
import moment from "moment";

function MedicalExam(props) {
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const token = store.loginReducer.Authorization;
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find( (x) => x.functionality?.prefix === "ExaMed");
  

    const [trigger, setTrigger] = useState(0);
    const [medExam, setMedExam] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [selectedCandidate, setSelectedCandidates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const today = new Date();
    const currYear = today.getFullYear();

    const [screenControl, setScreenControl] = useState(0);

    const [dateModal, setDateModal] = useState({
        show:false,
    });

    const [employeeModal, setEmployeeModal] = useState({
        show:false,
        filter_colab:"",
    });

    //zone that should be actions but honestly we are over-using redux
    const fetchData = async (data) => {
          
        setIsLoading(true);
        let filters = convertFilterToString({
            year: currYear,
            eaccount: store.loginReducer.currentAccount.id
        });
        const getMyStuff = await fetch(
            //${URL_GATEWAY}${API_VERSION}/payroll/medical_exam/?${filters}`,
            `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam_area/?${filters}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            setMedExam(res.results);
            setIsLoading(false);

        })
        .catch((err) => {

            setIsLoading(false);

        });
    }

    const editData = async (data, doAfter=null) => {
          
        setIsLoading(true);

        const editMyStuff = await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam/`,
            {
                method: "PUT",
                body: JSON.stringify(data),
                // body: JSON.stringify({
                //     ...employeeModal.selected,
                //     prog_date_new: employeeModal.prog_date_new,
                //     userids: selectedCandidate,
                // }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            //setData(res.results);
            setIsLoading(false);
            setTrigger(trigger+1);
            setEmployeeModal({ show:false, filter_colab:"" });
            if(doAfter){
                doAfter();
            }
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }

    const registerExam = async (data) => {
        setIsLoading(true);

        const startMyStuff = await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam/`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            setIsLoading(false);
            setTrigger(trigger+1);
            setDateModal({ show:false });
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }

    const fetchCandidates = async (userids) => {
          
        setIsLoading(true);

        let filters = convertFilterToString({
            userids: userids
        });
        const getCandidates= await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/candidates_in_list/?${filters}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            setCandidateList(res.results);
            if(Array.isArray(res.results)){
                setSelectedCandidates(
                    res.results.filter(x=> x.status !="canceled").map( (x) => x.id) 
                );
            }

            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }

    
    const fetchMedicalExamCandidates = async (filterobj) => {
          
        setIsLoading(true);

        let filters = convertFilterToString(filterobj);
        const getCandidates= await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam_candidates/?${filters}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            if(Array.isArray(res.results)){
                setCandidateList(res.results);
                // setSelectedCandidates(
                //     res.results.filter(x=> x.status !="canceled").map( (x) => x.id) 
                // );
            }
            if(Array.isArray(employeeModal?.selected?.details)){
                setSelectedCandidates(
                    employeeModal?.selected?.details.filter(x=>x.status==="done" || x.status==="pending" ).map(x=>x.employee)
                );
            }else{
                setSelectedCandidates([]);
            }

            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }
    //end actions

    useEffect(() => {
        if (!myPermission?.read) {
          history.push("/nomina/inicio");
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [setMedExam, trigger]);

    useEffect(()=>{
        if(employeeModal.show){
            // const userids = [];
            // if(Array.isArray(employeeModal?.selected?.details)){
            //     employeeModal.selected.details.map(y=>{
            //         if(!isNaN(y.employee) && userids.findIndex(x=>x==y.employee)==-1){
            //             userids.push(y.employee);
            //         }
            //     })
            //     fetchCandidates(userids);
            // }
            fetchMedicalExamCandidates({
                //me_id: employeeModal?.selected?.id,
                area_id: employeeModal?.selected?.CC_ID,
            });
        }

    }, [employeeModal.show])

    //these are the usual checkbox methods, you know what they do
    const isBoxSelected = (id_client) => {
        if (Array.isArray(selectedCandidate)) {
          let target = selectedCandidate.find((x) => x === id_client);
          return !!target;
        }
      };
    
      const changeBoxSelected = (id) => {
        let tempSelected = selectedCandidate;
        if (Array.isArray(tempSelected)) {
          let target = tempSelected.find((x) => x == id);
          if (!!target) {
            tempSelected = tempSelected.filter((x) => x != id);
          } else {
            tempSelected.push(id);
          }
          setSelectedCandidates(tempSelected);
        }
      };

      const isBoxAllSelected = () => {
        if (Array.isArray(candidateList) && Array.isArray(selectedCandidate)) {
            return candidateList.length===selectedCandidate.length;
        }
        return false;
      }
    
      const changeBoxAllSelected = (checkEmAll) => {
        //first what do we need to do?
        if (checkEmAll) {
          //if we need to check all positive, then insert all the ones we dont have
          let tempSelected = [];
          if (
            Array.isArray(candidateList) &&
            Array.isArray(tempSelected)
          ) {
            candidateList.map((x) => {
              tempSelected.push(x.id);
            });
            setSelectedCandidates(tempSelected);
          }
        } else {
          //if we need to check all positive, then just set the array as empty
          setSelectedCandidates([]);
        }
      };
    //end checkbox method









    const renderEditDate = () => {
        return <div className={`d-flex`}>
            <div className="d-flex flex-column  mr-2">
                <p className={tableStyles.crudModalLabel}>Fecha Actual</p>
                <input 
                    type="text"
                    className="register-inputs"
                    value={ 
                        convertDateToLatinFormat(
                            employeeModal.selected?.prog_date
                        )
                    }
                    disabled={true}
                />
            </div>
            
            <div className="d-flex flex-column">
                <p className={tableStyles.crudModalLabel}>Fecha Nueva</p>
                <input 
                    type="date"
                    className="register-inputs"

                    value={employeeModal.prog_date_new}
                    onChange={e=> setEmployeeModal({...employeeModal, prog_date_new: e.target.value})}
                />
            </div>
        </div>
    }

    const renderUserList = () => {
        const temp = [];
        if(Array.isArray(candidateList)){
            candidateList.map((user,index)=>{
                if(
                    String(user.full_name).toLowerCase().includes(employeeModal.filter_colab.toLowerCase()) ||
                    String(user.occupation).toLowerCase().includes(employeeModal.filter_colab.toLowerCase())
                ){
                    temp.push(<div 
                        key={"candidate#"+user.id}
                        className={`${payrollStyles.candidateWrap} ${isBoxSelected(user.id)? payrollStyles.active : ""}`}
                        onClick={()=>changeBoxSelected(user.id)}
                    >
                        <img 
                            src={user.s3_photo}
                            className={payrollStyles.candidateImage}
                        />
                        <div style={{flex:1}}>
                            <p className={`m-0 ${payrollStyles.candidateTitle}`}>{user.full_name}</p>
                            <p className={`m-0 ${payrollStyles.candidateSubtitle}`}>{user.occupation}</p>
                        </div>
                    </div>
                    );
                }
            })
        }
        return temp;
    }





    const meHeaders = [
        <th key={1} className="text-start px-2">Área</th>,
        <th key={2} className="text-center px-2">No. Empleados<br/><span style={{fontSize:10}}>(Asignados/Total)</span></th>,
        <th key={3} className="text-center px-2" >Fecha Programada</th>,
        <th key={4}  className="text-center px-2">Fecha Realizada</th>,
        <th key={5} >&nbsp;</th>,
    ];

    const meBody = () => {
        if(!Array.isArray(medExam)){
            return [];
        }

        return medExam.map(item => { 
            //Seccion de opciones para el popup
            const temparray = [];
            if(myPermission?.edit){
                temparray.push({
                    text: "Editar Examen",
                    icon: useredit,
                    event: ()=>setEmployeeModal({
                        ...employeeModal, 
                        show:true, 
                        selected: item, 
                        prog_date_new: item.prog_date
                    }),
                });
            }
            if(myPermission?.reject && item.id){
                temparray.push({
                    text: "Cancelar Resultados",
                    icon: popupx,
                    event: ()=>{return cancelResults(item.id)},
                })
            }
            if(!item.requested && item.id){
                temparray.push({
                    text: "Solicitar Resultados",
                    icon: recibir,
                    event: ()=>{return requestResults(item.id)},
                })
            }
            //fin de seccion de las opciones del gear
            
            return (
                <tr key={item.CC_ID} className="hover-table-row">
                <td className={`col-md-3`}>
                    <p className={`m-0 text-start`} style={{paddingLeft: 10}}>{item.CC_DESC}</p>
                </td>
                <td className={`col-md-2`}>
                    {Array.isArray(item.details)? item.details.filter(x=>x.status==="done" || x.status==="pending" ).length : 0}
                    /
                    {Array.isArray(item.area_candidates)? item.area_candidates.length : 0}
                </td>
                <td className={`col-md-3`}>
                    <div className={`d-flex justify-content-center`}>
                    {
                    item.cancelled_by?
                        <p className={`m-0 ${payrollStyles.statusCanceled}`} style={{textTransform: "capitalize"}}>Cancelado</p>
                    :
                        item.prog_date?
                            convertDateToLatinFormat(item.prog_date)
                        :
                            <p className={`m-0 ${payrollStyles.statusPending}`} style={{textTransform: "capitalize"}}>Pendiente</p>
                    }
                    </div>
                </td>
                <td className={`col-md-3`}>
                    <div className={`d-flex justify-content-center`}>
                    {
                    (item.cancelled_by || !item.prog_date)?
                        <span></span>
                    :
                        item.done_date?
                            convertDateToLatinFormat(item.done_date)
                        :
                            <p className={`m-0 ${payrollStyles.statusPending}`}  style={{textTransform: "capitalize"}}>Pendiente</p>
                    }
                    </div>
                </td>
                <td className={`col-md-1`}> 
                <CustomPopupExtends 
                noHover
                triggerSrc={threeDots}
                    position="left"
                    extraButtons={
                        item.cancelled_by?
                            []
                        :
                            !item.prog_date?
                                [
                                    {
                                        text: "Programar Fecha",
                                        icon: calendaredit,
                                        event: ()=>setDateModal({
                                            ...dateModal, 
                                            show:true, 
                                            medical_exam: item.id,
                                            area_name: item.CC_DESC, 
                                            area_id: item.CC_ID
                                        }),
                                        //class: ""
                                    }
                                ]
                            :
                                temparray
                    }
                />
                </td>
            </tr>
        )
        })
    }

    const btnRegister = (item)=> {
        let error = "";

        if(!dateModal.prog_date_new){ error="La fecha es obligatoria";}

        if(error!=""){
            customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: `${error}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              });
            return null;
        }

        registerExam(
            {
                initializeExam: true,
                id: dateModal.medical_exam,
                title: "Exámenes de "+dateModal.area_name+" "+currYear,
                area_id: dateModal.area_id,
                area_name: dateModal.area_name,
                prog_date_new: dateModal.prog_date_new,
                created_by: store.loginReducer.user_data.id,
                entity_account: store.loginReducer.currentAccount.id,
            }
        )

    }

    const btnEdit = () => {
        let error = "";

        if(!employeeModal.prog_date_new){ error="La fecha es obligatoria";}

        if(error!=""){
            customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: `${error}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              });
            return null;
        }

        editData(
            {
                ...employeeModal.selected,
                prog_date_new: employeeModal.prog_date_new,
                userids: selectedCandidate,
            }
        )
    }

    const requestResults = (meId) => {
        editData(
            {
                id: meId,
                requested: true,
            },
            ()=>{
                customSwaltAlert({
                    icon: "success",
                    title: "Solicitud exitosa",
                    text: "A la espera de resultados de examen",
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  });
            }
        )
    }

    const cancelResults = (meId) => {

        customSwaltAlert({
            icon: "warning",
            title: "¿Está seguro?",
            text: `Se cancelará el examen del dia: ${moment(today).format('YYYY-MM-DD')}` ,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          }).then((response)=>{
            if(response.isConfirmed){
                editData(
                    {
                        id: meId,
                        cancelled_by: store.loginReducer.user_data.id,
                        cancelled_at: today
                    }
                )
            }
        })

    }

    const renderMedicalExam = (
        <div className={tableStyles.container} style={{paddingBottom:"40px"}}>
            {isLoading && (
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            )}

                <h1 className={tableStyles.title} style={{marginBottom:0}}>Planeación de Exámenes Médicos</h1>
                <p className={`m-0 ${payrollStyles.subtitle}`}>Año {currYear}</p>

                <div className={`d-flex flex-row-reverse`}>

                    <div
                        className={`${tableStyles.iconWrap} hoverPointer`}
                        style={{marginLeft:10}}
                        onClick={()=>setScreenControl(2)}
                    >
                        <span
                            className={`${tableStyles.iconLabel}`}
                        >
                            Ver Calendario
                        </span>
                        <img
                            width="15px"
                            height="20px"
                            src={calendar}
                            alt="Form"
                            />
                    </div>

                    <div
                        className={`${tableStyles.iconWrap} hoverPointer`}
                        onClick={()=>setScreenControl(1)}
                    >
                        <span className={`${tableStyles.iconLabel} `} >
                            Histórico de Exámenes
                        </span>
                    </div>
                    <div style={{flex:1}}></div>
                </div>
                <GenericTableNew
                    headers={meHeaders}
                    dark={true}
                >
                    {meBody()}
                </GenericTableNew>


            <ModalNew
                show={dateModal.show}
                onHide={()=>setDateModal({show:false})}
                title="Programar Examen"
                btnYesEvent={()=>btnRegister()}
            >
                <div className={`d-flex`}>
                    <div className="d-flex flex-column  mr-2">
                        <p className={tableStyles.crudModalLabel}>Área</p>
                        <input 
                            type="text"
                            className="register-inputs"
                            value={ dateModal.area_name }
                            disabled={true}
                        />
                    </div>

                    <div className="d-flex flex-column">
                        <p className={tableStyles.crudModalLabel}>Fecha del Examen</p>
                        <input 
                            type="date"
                            className="register-inputs"

                            value={dateModal.prog_date_new}
                            onChange={e=> setDateModal({...dateModal, prog_date_new: e.target.value})}
                        />
                    </div>
                </div>
            </ModalNew>

            <ModalNew
                show={employeeModal.show}
                onHide={()=>setEmployeeModal({show:false, filter_colab:""})}
                title="Editar Examen"
                btnYesEvent={()=>btnEdit()}
                additionalContent={renderEditDate()}
            >
                <div className={`d-flex`}>
                    <div className="d-flex flex-column  mr-2" style={{flex:1}}>
                        <p className={tableStyles.crudModalLabel}>Colaboradores</p>
                        <input 
                            type="text"
                            className="register-inputs"

                            value={employeeModal.filter_colab}
                            onChange={e=> setEmployeeModal({...employeeModal, filter_colab: e.target.value})}
                        />
                    </div>
                    
                    {/* <div className="d-flex flex-column">
                        <p className={tableStyles.crudModalLabel}>Fecha de examen</p>
                        <input 
                            type="date"
                            className="register-inputs"

                            value={employeeModal.filter_date}
                            onChange={e=> setEmployeeModal({...employeeModal, filter_date: e.target.value})}
                        />
                    </div> */}
                </div>

                <div style={{height:300, overflowY:"auto",marginTop: 12}}>
                    {renderUserList()}
                </div>

                <div className={`d-flex justify-content-center align-items-center`}>
                    <Checkbox 
                        //type="checkbox"
                        checked={isBoxAllSelected()}
                        onChange={()=>changeBoxAllSelected(!isBoxAllSelected())}
                        color="primary"
                        style={ {padding:0} }
                    />
                    <p 
                    className={`m-0`}
                    style={{
                        color: "#58595B",
                        fontSize: 12,
                    }}
                    >Seleccionar Todos</p>
                </div>


            </ModalNew>
        </div>
    )
    

    switch(screenControl){
        case 1: return <MedicalExamHistory
            screenControl={screenControl}
            setScreenControl={setScreenControl}
            medExam={medExam}
         />
        case 2 : return <PlanningMedicalExams
            screenControl={screenControl}
            setScreenControl={setScreenControl}
        />

        default: return renderMedicalExam;
    } ;

}

export default MedicalExam;