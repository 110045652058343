import { GET_DEDUCTION_ACCRUALS, GET_THIRD_PARTIES, GET_DOCUMENT_TYPES, GET_RECIPIENT_TYPES } from "./actionTypes";

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

export const getDeductionAccruals = (objFilters) => (dispatch, getState) => {
  dispatch({
    type:GET_DEDUCTION_ACCRUALS ,
    loading: true,
  });
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustments/?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_total } = res;
        dispatch({
          type: GET_DEDUCTION_ACCRUALS,
          payload: results,
          total: row_total,
          loading: false
        });
      })
      .catch((err) => {
        dispatch({
          type:GET_DEDUCTION_ACCRUALS ,
          loading: false,
        });

        customSwaltAlert({
          icon: "error",
          title: "¡Error al traer los registros!",
          text: `Ocurrió un error al intentar conectarse, por favor intente de nuevo.`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })


      });
  };
export const get_third_party = () => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/third_parties/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const { results, row_total } = res;
        dispatch({
          type: GET_THIRD_PARTIES,
          payload: results,
          total: row_total,
        });
      })
      .catch((err) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `Ocurrió un error al intentar conectarse, por favor intente de nuevo.`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
      });
  };

export const get_doc_types = () => (dispatch, getState) => {
const token = getState().loginReducer.Authorization;
fetch(`${URL_GATEWAY}${API_VERSION}/payroll/document_types/`, {
    method: "GET",
    headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
    },
})
    .then((response) => response.json())
    .then((res) => {
    const { results } = res;
    dispatch({
        type: GET_DOCUMENT_TYPES,
        payload: results,
    });
    })
    .catch((err) => {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Ocurrió un error al intentar conectarse, por favor intente de nuevo.`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
    });
};

export const get_recipient_types = () => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/recipient_type/`, {
        method: "GET",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
        const { results } = res;
        dispatch({
            type: GET_RECIPIENT_TYPES,
            payload: results,
        });
        })
        .catch((err) => {

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: `Ocurrió un error al intentar conectarse, por favor intente de nuevo.`,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
        });
    };
export const create_Deductions_Accruals = (obj, objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let tipo = ""
    if(obj.type === 2){
        tipo = "Devengado"
    }else{
        tipo = "Deducción"
    }
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustments/`, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success || data.code === 401) {
            return customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: data.error,
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }
          ;

          customSwaltAlert({
            icon: "success",
            title: "Creado exitosamente",
            text: `Se ha creado la deducción y devengado: ${obj.description}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
          dispatch(getDeductionAccruals(objFilters));
        })
        .catch((err) => {

          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })

        });
}
export const update_DeductionAccruals = (obj, objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  let tipo = ""
  if(obj.type === 2){
      tipo = "Devengado"
  }else{
      tipo = "Deducción"
  }
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustments/`, {
      method: "PUT",
      body: JSON.stringify(obj),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success || data.code === 401) {
          return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: data.message,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        }

        customSwaltAlert({
          icon: "success",
          title: "Actualizado exitosamente",
          text: `Se ha actualizado la deducción y devengado: ${obj.description}`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        dispatch(getDeductionAccruals(objFilters));
      })
      .catch((err) => {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      });
}


export const activationDesactivation = (id, status, filters, description) => (
  dispatch,
  getState
) => {
  const token = getState().loginReducer.Authorization;
  
  let active = ""
  if (status === "enabled") {
    status = "disabled";
    active = "deshabilitar" 
  } else if (status === "disabled") {
    status = "enabled";
    active = "habilitar";
  }

 let data = {
     id: id,
     status: status,
     description: description,
 };
  if (id != null && status != null) { 

    
    customSwaltAlert({
      icon: "warning",
      title: `¿Está seguro?`,
      text: `Se ${status === "enabled" ? 'habilitará':'deshabilitará'} la deducción y devengado: ${description}`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type:GET_DEDUCTION_ACCRUALS ,
          loading: true,
        });
        fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustments/`,{
            body: JSON.stringify(data),
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.success || !res.message) {

              customSwaltAlert({
                icon: "success",
                title: "Actualizado exitosamente",
                text: `Se ha ${status === "enabled" ? 'habilitado':'deshabilitado'} la deducción y devengado: ${description}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

              dispatch(getDeductionAccruals(filters));
            } else {
            }
          })
          .catch((err) => {

            customSwaltAlert({
              icon: "error",
              title: "Error al guardar",
              text: "No se pudo cambiar el estado" + err,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })

          }).finally(()=>{
            dispatch({
              type:GET_DEDUCTION_ACCRUALS ,
              loading: false,
            });
          })
      }
    });
  } else {

    customSwaltAlert({
      icon: "error",
      title: "Error al ingresar",
      text: "Debe ingresar correo y contraseña para acceder al sistema.",
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  }
};
