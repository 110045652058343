import {
  GET_PROVIDERS,
  LOADING,
  GET_COUNTRIES,
  GET_CIIU,
  GET_PROVIDER_CLASSIFICATION,
  CREATE_DRAFT_PROVIDER,
  GET_EDIT_PROVIDER,
  GET_BANKING_ENTITIES,
  GET_TAX_PAYER_TYPE,
  GET_TYPE_SERVICES,
  GET_LABORATORIES
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  providers: [],
  countries: [],
  row_total: 0,
  total_index: 0,
  ciiu: [],
  providerClassification: [],
  providerDraft: null,
  providerEdit:null,
  bankingEntities:[],
  taxPayerTypes:[],
  provider_loading: false,
};
export const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { 
        ...state, 
        loading: action.payload,
        provider_loading: action.payload,
      };

    case GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
        row_total: action.row_total,
        total_index: action.total_index,
        provider_loading : action.loading,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_CIIU:
      return {
        ...state,
        ciiu: action.payload,
      };
    case GET_PROVIDER_CLASSIFICATION:
      return {
        ...state,
        providerClassification: action.payload,
      };
    case CREATE_DRAFT_PROVIDER:
      return {
        ...state,
        providerDraft: action.payload,
      };
    case GET_EDIT_PROVIDER:
      return {
        ...state,
        providerEdit: action.payload,
      };
    case GET_BANKING_ENTITIES:
      return {
        ...state,
        bankingEntities: action.payload,
      };
    case GET_TAX_PAYER_TYPE:
      return {
        ...state,
        taxPayerTypes: action.payload,
      };
    case GET_TYPE_SERVICES:
      return {
        ...state,
        listTypeServices: action.payload,
      };
    case GET_LABORATORIES:
      return {
        ...state,
        listLaboratories: action.payload,
      };

    default:
      return state;
  }
};
