import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import note from "../../assets/img/icons/ordSugess.svg";
import backIcon from "../../assets/img/icons/atras-icon.svg";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { convertMoneyFormat, loader, validateEmptyString } from "../../helpers/helpers";
import ModalNew from "../Layouts/ModalNew";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import { useSelector } from "react-redux";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

const DetailEmployee = (props) => {
  const store = useSelector((state) => state);
  const eaccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  let showEmployeeDetails = props.showEmployeeDetails;
  const [commentsModal, setCommentsModal] = useState({
    show: false,
    comments: "",
    detail: false,
  });
  const { results: commentResults, load: commentLoader, trigger: getCommentMethod } = useGetMethod();
  const { results: detailNominaResults, load: detailNomina, trigger: getDetailNomina } = useGetMethod();
  const { load: sendCommentLoader, trigger: sendCommentMethod } = usePostMethod();

  useEffect(() => {
    getCommentMethod({
      url: "/payroll/adjustment-subscriptions/employee/",
      objFilters: {
        employee: showEmployeeDetails?.data?.candidate_id,
        id: showEmployeeDetails?.data?.id,
        entity_account: eaccount
      },
      token: token,
      doAfterSuccess: (res) => {
        setCommentsModal({ ...setCommentsModal, comments: res?.result?.comments, details: showEmployeeDetails?.propsData === "En revisión" ? false : true })

      }
    });

    getDetailNomina({
      url: "/payroll/deductionsAccrued/",
      objFilters: {
        candidateId: showEmployeeDetails?.data?.candidate_id,
        payrollSheetId: props?.payrollSheet,
        eaccount: eaccount
      },
      token: token,
      
    });
    // dispatch(getWorkEnvPollList(filters)); NOTE PETITION
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eaccount]);

  const renderTooltipMessage = () => (
    <Tooltip>
      <span> Añadir comentario </span>
    </Tooltip>
  );


  const goBackFunction = () => {
    props.setDetailShowEmployee({ ...showEmployeeDetails, show: false })
    // history.push("/contabilidad/Movimientos/autorizacionNomina");
  };

  const closeCommentModal = () => {
    setCommentsModal({ ...commentsModal, show: false });
  };

  const sendComment = () => {
    let validations = validateEmptyString([
      { field: commentsModal.comments, message: "El comentario es obligatorio" },
    ]);

    if (validations.valid) {
      sendCommentMethod({
        url: "/payroll/payrollSheet/",
        method: "PUT",
        body: {
          "id": showEmployeeDetails?.data?.id,
          "candidate_id": showEmployeeDetails?.data?.candidate_id,
          "comment": commentsModal.comments
        },
        token: token,
        succesAction: (info) => {
          closeCommentModal()
          customSwaltAlert({
            icon: 'success',
            title: info.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          })
          // message("info", "Información", `<span style=color:#005dbf>${info.message}</span>`);
        },
        doAfterException: (error) => {
          customSwaltAlert({
            icon: 'success',
            title: error.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false
          })
          //   responseSwal({
          //     text: `<span style=color:#005dbf>${error.message}</span>`,
          //     icon: "warning",
          //   })
          // message("info", "Información", `<span style=color:#005dbf>${error.message}</span>` );
        },
      });
    }
  };

  const renderDetails = () => {
    const comment = commentResults?.result
    const mergeCurrentAndSettled = comment?.current?.concat(comment?.settled)
    if(mergeCurrentAndSettled?.length){
      return (
        <Row>
          <Col
          xs={6}
          className={"py-2"}
        >
          <b className={`${tableStyles.darkBlueText} ${tableStyles.f14} my-4 text-center mt-5`}>Detalles</b>
          <ul>
            {
              // eslint-disable-next-line array-callback-return
              detailNominaResults?.results?.accrued.map(e=>{
                  return(
                    <li
                      key={e.id_adjustment} 
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      <span>{e?.adjusmentDescription}: {convertMoneyFormat(e?.paymentValue)}</span>
                    </li>
                  )
              })
            }
          </ul>
        </Col>
          <Col
          xs={6}
          className={"py-2"}
        >
          <b className={`${tableStyles.darkBlueText} ${tableStyles.f14} my-4 text-center mt-5`}>Detalles</b>
          <ul>
            {
              // eslint-disable-next-line array-callback-return
              detailNominaResults?.results?.deductions?.map(e=>{
                  return(
                    <li 
                      key={e.id_adjustment} 
                      className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}
                    >
                      <span>{e.adjusmentDescription}: {convertMoneyFormat(e?.paymentValue)}</span>
                    </li>
                  )
              })
            }
          </ul>
        </Col>
        </Row>
      )
    }
  }

  return (
    <>
        {(commentLoader || sendCommentLoader) && loader}

      {/* ANCHOR MODAL COMMENTS/NOTES */}
      <ModalNew
        title="Comentarios"
        show={commentsModal.show}
        btnYesName={"Guardar"}
        btnNoName={"Cerrar"}
        size="400"
        btnYesEvent={sendComment}
        btnYesDisabled={commentsModal.details}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentarios de empleado</p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          disabled={commentsModal.details}
          onChange={(e) => setCommentsModal({ ...commentsModal, comments: e.target.value })}
          defaultValue={commentsModal.comments}
          style={{
            height: "4rem",
            borderRadius: "5px",
            border: "1.5px solid #7FADDE",
            padding: "0 0.05rem",
            width: "100%",
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <OrdGenericTemplate
        backIcon={backIcon}
        showBackArrow={true}
        backArrowAction={() => {
          goBackFunction();
        }}
        titleIcon={{
          icon: (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipMessage()}
              >
                <img
                  alt="goal"
                  src={note}
                  className={`align-self-center mx-2 ${tableStyles.svgFilterDarkBlue} cursorPointer`}
                  onClick={() => {
                    setCommentsModal({ ...commentsModal, show: true });
                  }}
                  height="30px"
                  width="30px"
                />
              </OverlayTrigger>
            </>
          ),
        }}
        title={showEmployeeDetails?.data?.employee}
        className="px-4 my-2 w-90"
        titleSize={12}
        style={{ height: "min-content" }}
        titleStyle={{ marginLeft: "1rem", fontSize: "32px" }}
      >
        <div style={{ marginLeft: "2rem" }}>
          <Row>
            <div className={`${tableStyles.OrdSlideButtonNom} py-4 pb-4`}>
              <Row>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>Cuenta</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={showEmployeeDetails?.data?.account_nbr}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>Días laborados</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={showEmployeeDetails?.data?.days_to_pay}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>Salario base</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(showEmployeeDetails?.data?.base_salary ? showEmployeeDetails?.data?.base_salary : "0")}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>Devengado</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(showEmployeeDetails?.data?.total_accrued ? showEmployeeDetails?.data?.total_accrued : "0")}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>Deducciones</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(showEmployeeDetails?.data?.total_deductions ? showEmployeeDetails?.data?.total_deductions : "0")}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className="display-grid mt-1">
                    <label className={`${tableStyles.crudModalLabel}`}>Total</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type="text"
                      value={convertMoneyFormat(showEmployeeDetails?.data?.total_payment ? showEmployeeDetails?.data?.total_payment : "0")}
                      disabled={true}
                      style={{ backgroundColor: "#FFF" }}
                    ></input>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col
              xs={6}
              className={"py-2"}
            >
              <b className={`${tableStyles.darkBlueText} ${tableStyles.f14} my-4 text-center mt-5`}>Devengados</b>
              <ul>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Horas extras: {convertMoneyFormat(showEmployeeDetails.data.overtime)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Recargo: {convertMoneyFormat(showEmployeeDetails.data.surcharge)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Auxilio de transporte: {convertMoneyFormat(showEmployeeDetails.data.transp_support)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Vacaciones: {convertMoneyFormat(showEmployeeDetails.data.vacations_paid)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Auxilio de conectividad: {convertMoneyFormat(showEmployeeDetails?.data?.connectivity_support)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Salario no constitutivo: {convertMoneyFormat(showEmployeeDetails?.data?.non_const_income)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Otros devengados: {convertMoneyFormat(showEmployeeDetails?.data?.others_accrued)}</span>
                </li>
              </ul>
            </Col>
            <Col
              xs={6}
              style={{ borderLeft: "1px solid #005dbf", backgroundColor: "#F2F7FC", borderRadius: "0px 6px 6px 0px" }}
              className={"py-2"}
            >
              <b className={`${tableStyles.darkBlueText} ${tableStyles.f14} my-4 text-center mt-5`}>Deducciones</b>
              <ul>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Salud: {convertMoneyFormat(showEmployeeDetails.data.healthcare_deducted)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Pensión: {convertMoneyFormat(showEmployeeDetails.data.pension_deducted)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Permisos: {convertMoneyFormat(showEmployeeDetails.data.permissions)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Retenciones: {convertMoneyFormat(showEmployeeDetails.data.retentions)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Solidaridad pensional: {convertMoneyFormat(showEmployeeDetails.data.solidary_pension_deducted)}</span>
                </li>
                <li className={`${tableStyles.f13} ${tableStyles.tlnTextGray}`}>
                  <span>Otras deducciones: {convertMoneyFormat(showEmployeeDetails.data.others_deducted)}</span>
                </li>
              </ul>
            </Col>
          </Row>

          {renderDetails()}
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default DetailEmployee;
