import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, matchPath } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.scss";
import style from "../../routes/fullLayout.module.css";
import Cruz from "../../assets/img/icons/Cruz.svg";
import Hamburgesa from "../../assets/img/icons/Hamburgesa.svg";
import HamburgesaOrd from "../../assets/img/icons/HamburgesaOrd.svg";
import { isEmptyOrUndefined, removeAccents } from "../../helpers";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedMenu: true,
      itemActive: "",
    };
  }

  onClickToggleMenu = () => {
    this.setState({ collapsedMenu: !this.state.collapsedMenu });
  };

  onClickToggleSubMenu = () => {
    if (this.state.collapsedMenu) {
      this.onClickToggleMenu();
    } else {
      let { itemActive } = this.state;
      if (itemActive !== "") {
        this.setState({ itemActive: "" });
      } else {
        this.setState({ itemActive: "pro-inner-item-active" });
      }
    }
  };
  renderFunctionalites(functionality) {
    const myUrl = this.props?.location?.pathname;
    const listPrefixMinomina = ["myCv", "WkCertifiR", "request", "minoreg"];

    let formatUrlMinomina = "";
    const currentModule = myUrl?.split("/")?.at(1);

    const newUrl = !isEmptyOrUndefined(functionality?.url?.split("/")?.at(-1))
      ? functionality?.url?.split("/")?.at(-1)
      : functionality?.url?.split("/")?.at(-2);

    if (listPrefixMinomina.includes(functionality.prefix)) {
      formatUrlMinomina = "/miNomina/miNomina/" + newUrl;
    }

    if (
      currentModule === "miNomina" &&
      !listPrefixMinomina.includes(functionality.prefix)
    ) {
      formatUrlMinomina = formatUrlMinomina = "/miNomina/" + newUrl;;
    }

    if (
      currentModule !== "miNomina" &&
      !listPrefixMinomina.includes(functionality.prefix)
    ) {
      formatUrlMinomina = functionality?.url;
    }

    if (
      this.props.loginReducer?.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === functionality?.prefix
      )?.read
    ) {
      return (
        <div>
          <MenuItem
            className={`${
              !!matchPath(myUrl, formatUrlMinomina) ? "menuItemActive" : null
            } activeHover`}
          >
            <Link to={formatUrlMinomina}>
              <p
                className="m-0"
                style={{ paddingLeft: "5px", whiteSpace: "initial" }}
              >
                {functionality?.name}
              </p>
            </Link>
          </MenuItem>
        </div>
      );
    } else {
      return null;
    }
  }
  renderModules(module) {
    const functionalities = module?.functionalities?.map((x) => {
      return this.renderFunctionalites(x);
    });
    if (Array.isArray(functionalities)) {
      if (functionalities.length === 0) {
        return <span />;
      } else {
        return (
          <SubMenu
            title={module.name}
            icon={
              <div id={module.id}>
                <img
                  id={module.name + "-" + module.id}
                  alt={module.name}
                  src={module.img}
                  style={{ width: "30px", height: "30px" }}
                  class="icons-sidebar"
                />
              </div>
            }
            className={`${this.state.itemActive} `}
            onClick={this.onClickToggleSubMenu}
          >
            {functionalities}
          </SubMenu>
        );
      }
    }
  }
  isAssistanceModule = () => {
    let value = false;
    window.location.pathname.includes("ordenamientos")
      ? (value = true)
      : window.location.pathname.includes("asistencial")
      ? (value = true)
      : window.location.pathname.includes("telemedicina")
      ? (value = true)
      : window.location.pathname.includes("cuentasMedicas")
      ? (value = true)
      : window.location.pathname.includes("admision")
      ? (value = true)
      : (value = false);
    return value;
  };

  render() {
    const category = this.props?.loginReducer?.all_categories?.find(
      (x) =>
        removeAccents(x.name || "")?.toLowerCase() ===
        removeAccents(this.props.loginReducer.category || "")?.toLowerCase()
    );

    const url = window.location.pathname.split("/")[1];

    let newModules = category?.modules;

    if (url === "configuracion") {
      newModules = category?.modules.filter((el) => el.name === "CUENTA");
    }

    if (url === "miNomina") {
      newModules = category?.modules.filter((el) => el.name !== "CUENTA");
    }

    if (url === "categoryselect") {
      newModules = this.props?.loginReducer?.all_categories?.find(
        (el) => el.name === "Nómina"
      )?.modules;
    }

    const categoryOrder = newModules?.sort((a, b) => a.id - b.id);

    const modules = categoryOrder?.map((x) => {
      return this.renderModules(x);
    }) || <></>;

    return (
      <div
        className={`${style.fullLayoutSideBar} shadow rounded d-inline-block full-height`}
      >
        <ProSidebar
          collapsed={this.state.collapsedMenu}
          className={style.zIndex800}
        >
          <Menu id="menu1" iconShape="square">
            <MenuItem
              icon={
                <img
                  alt="Hamburger"
                  src={this.isAssistanceModule() ? HamburgesaOrd : Hamburgesa}
                  onClick={() =>
                    this.setState({ collapsedMenu: !this.state.collapsedMenu })
                  }
                  className="Hamburger-sidebar"
                />
              }
            >
              <div style={{ textAlign: "end" }}>
                <img
                  src={Cruz}
                  alt="Ocultar"
                  onClick={this.onClickToggleMenu}
                  className="CruzBar"
                ></img>
              </div>
            </MenuItem>
            {modules}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

export default withRouter(Sidebar);
