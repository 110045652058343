import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import alertCircle from "../../../assets/img/icons/alert-circle-orange.svg";
import check from "../../../assets/img/icons/check2.svg";
import backArrow from "../../../assets/img/icons/atras-icon.svg";
import CheckMark from "../../../assets/img/icons/CheckMark.svg";
import FalseCross from "../../../assets/img/icons/FalseCross.svg";
import rejectedIcon from "../../../assets/img/icons/extraBtnRedX.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";
import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid, Button } from "@mui/material";
import tableStyles2 from "../../../components/Layouts/tableStyle.module.scss";
import { convertMoneyFormat, loader } from "../../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";
import EmployeeDetailAuth from "../partners/EmployeeDetailAuth";

export const DetailAuthorization = ({ backPage, setTrigger }) => {
  const store = useSelector((state) => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "pplTm");
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [employeeDetail, setEmployeeDetail] = useState({
    dataPayroll:backPage?.detailPage?.status?.name,
    show: false,
    dataEmployee: {},
    page: ""
  
  })
  const [data, setData] = useState({
    trigger: 0,
    filters: {
      date: "",
      search: "",
      page: 1,
      perpage: 10,
      eaccount: idAccount,
      id: backPage?.detailPage?.id,
      daysToPay: "",
    },
    modal: {
      show: false,
      isEditing: false,
      denial: "",
    },
  });

  const { filters, trigger, modal } = data;
  const { results: PremiumScheduleResults, load: premiumScheduleLoader, trigger: getPremiumSchedule } = useGetMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    getPremiumSchedule({
      url: "/payroll/detail_bonus_pay",
      objFilters: filters,
      token: token,
      doAfterSuccess: (res) => { },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className={`col-1 px-3 text-start`}>{elem?.docEmployee ?? "-"}</td>
        <td className="col-2 text-start">
        <u className="cursorPointer"
          onClick={()=>{
            setEmployeeDetail({...employeeDetail, show: true, dataEmployee: elem, dataPayroll: backPage, page:"bonus" })
          }}
          >
        {elem?.nameEmployee ?? "-"}
          </u>
          </td>
        <td className="col-1 text-center">{convertMoneyFormat(elem?.salaryEmployee ?? "-")}</td>
        <td className="col-2 text-center">{elem?.workDays ?? "-"}</td>
        <td className="col-2 text-center">{convertMoneyFormat(elem?.bonusPayment ?? "-")}</td>
        <td className="col-2 text-center">{convertMoneyFormat(elem?.inscBonusPayment ?? "-")}</td>
        <td className="col-2 text-center">
          {convertMoneyFormat((Number(elem?.bonusPayment) + Number(elem?.inscBonusPayment)).toFixed(2)) ?? "-"}
        </td>
      </tr>
    );
  };

  const renderHeaders = [
    <th
      key={1}
      className="text-start col-1 px-3"
    >
      ID
    </th>,

    <th
      key={2}
      className="text-start col-2"
    >
      Empleado
    </th>,

    <th
      key={3}
      className="text-center col-1"
    >
      Salario
    </th>,

    <th
      key={4}
      className="text-center col-2"
    >
      Días laborados
    </th>,

    <th
      key={5}
      className="text-center col-2"
    >
      Primas
    </th>,

    <th
      key={6}
      className="text-center col-2"
    >
      Primas INCS
    </th>,

    <th
      key={7}
      className="text-center col-2"
    >
      Total a pagar
    </th>,
  ];

  const approveOrDenyPayroll = (status) => {
    customSwaltAlert({
      icon: "warning",
      title: `<span style=color:#01A0F6>¿Está seguro?</span>`,
      text: `Se ${status === "approved" ? "autorizará" : "denegará"} la planilla de cesantías`,
      confirmButtonText: `Si, Continuar`,
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        updatePayroll({
          url: "/payroll/bonus_pay",
          token: token,
          method: "PUT",
          body: {
            id: data?.filters?.id,
            eaccount: idAccount,
            status: status,
          },
          succesAction: (info) => {
            setTrigger((state) => ({ ...state, trigger: state.trigger + 1 }));
            backPage?.setDetailPage((state) => null);
            customSwaltAlert({
              title: `Planilla de primas ${status === "approved" ? "autorizada" : "denegada"}`,
              icon: "success",
              confirmButtonText: "Aceptar",
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  return (
  employeeDetail.show ? 
     <EmployeeDetailAuth
     employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail}
     dataIn={data} setData={setData}
     />
      :
      <>
        {(premiumScheduleLoader || updatePayrollLoader) && loader}
        <div className="w-80 mx-auto">
          <h2
            style={{ color: "#005dbf" }}
            className={`${tableStyles.title} fw-bold d-flex gap-3`}
          >
            <div>
              <img
                onClick={() => {
                  backPage.setDetailPage((state) => null);
                }}
                src={backArrow}
                alt="backArrow"
                className={`${tableStyles2.svgDarkBlueFilter} pointer`}
              />
            </div>
  
            <div>
              <div>{` ${backPage.detailPage.range} / ${backPage.detailPage.paymentYear}`}</div>
              {backPage?.detailPage?.status?.name === "review" ? (
                <div>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{ margin: "10px 0px 0px 5px", color: "#ff8b00" }}
                  >
                    <span style={{ fontSize: "18px" }}>Revisión pendiente</span>
                    <img
                      height={20}
                      src={alertCircle}
                      alt="alertCircle"
                    />
                  </div>
                </div>
              ) : backPage?.detailPage?.status?.name === "rejected" ? (
                <div>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{ margin: "10px 0px 0px 5px", color: "rgba(236, 28, 36, 1)" }}
                  >
                    <span style={{ fontSize: "18px" }}>Planilla rechazada</span>
                    <img
                      height={15}
                      src={rejectedIcon}
                      alt="rejectedIcon"
                    />
                  </div>
                </div>
              ) : (
                backPage?.detailPage?.status?.name === "approved" && (
                  <div>
                    <div
                      className="d-flex gap-2 align-items-center"
                      style={{ margin: "10px 0px 0px 5px", color: "#00a551" }}
                    >
                      <span style={{ fontSize: "18px" }}>Planilla autorizada</span>
                      <img
                        height={20}
                        src={check}
                        alt="check"
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </h2>
  
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={2}
            >
              <p className={tableStyles.crudModalLabel}>Días menores a</p>
              <input
                onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, daysToPay: e.target.value } }))}
                type="number"
                value={filters.daysToPay}
                placeholder="Escribir..."
                className={`register-inputs`}
                name="position"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className="d-flex align-items-end justify-content-end"
            >
              <input
                className={`${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch}
                style={{ flex: 1, color: `#73a6dc` }}
                name="search"
                type="text"
                placeholder="Buscar por ID, Empleado, Días laborados..."
                value={filters.search}
                onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, search: e.target.value } }))}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                  }
                }}
              />
  
              <img
                src={Search}
                style={{ cursor: "pointer" }}
                alt="Search icon"
                onClick={() => {
                  setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <GenericTableNew
                headers={renderHeaders}
                dark={true}
              >
                {PremiumScheduleResults?.results?.length &&
                  PremiumScheduleResults?.results?.map((el) => {
                    return renderElement(el);
                  })}
              </GenericTableNew>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {PremiumScheduleResults?.results ? filters.page : ""}
                  {" de "}
                  {Math.ceil(PremiumScheduleResults?.rowTotal / filters.perpage)
                    ? Math.ceil(PremiumScheduleResults?.rowTotal / filters.perpage)
                    : ""}{" "}
                  ({PremiumScheduleResults?.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={PremiumScheduleResults?.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, page: e }, trigger: state.trigger + 1 }))}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </Grid>
          </Grid>
          <Grid className="d-flex justify-content-end gap-3 my-3">
            {backPage?.detailPage?.status?.name !== "review" ? (
              <>
                <Button
                  style={{ textTransform: "none" }}
                  className={tableStyles.btnCancelStyle}
                  onClick={() => {
                    backPage.setDetailPage((state) => null);
                  }}
                >
                  Cancelar
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{ textTransform: "none" }}
                  className={tableStyles.btnCancelStyle}
                  onClick={() => {
                    backPage.setDetailPage((state) => null);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  style={{ textTransform: "none" }}
                  className={tableStyles.btnCancelStyle}
                  onClick={() => approveOrDenyPayroll("rejected")}
                >
                  <div className="d-flex gap-2">
                    <img
                      src={FalseCross}
                      alt="FalseCross"
                    />
                    <span>Denegar</span>
                  </div>
                </Button>
                <Button
                  style={{ textTransform: "none" }}
                  className={tableStyles.btnCancelStyle}
                  onClick={() => approveOrDenyPayroll("approved")}
                >
                  <div className="d-flex gap-2">
                    <img
                      src={CheckMark}
                      alt="CheckMark"
                    />
                    <span>Autorizar</span>
                  </div>
                </Button>
              </>
            )}
          </Grid>
        </div>
      </>



  
  );
};
