import {   
    POST_OTROS_SI,
    GET_LIST_CONTRACT, 
    GET_SELECT_AREA, 
    GET_SELECT_STATUS, 
    GET_SELECTS_CONTRACT_CREATE, 
    GET_VACANCY_TYPE_LIST, 
    LOADING, 
    GET_ANNOUNCEMENT, 
    GET_TEMP_WORK_CERT,
    GET_TEMP_WORK_CERT_NOM,
    GET_PERIODS,
    NW_CONTRACT_LOADING
} from "../actions/actionTypes";


const initialState = {

    otrosi_loading: false,

    results:[],
    total:[],
    country: "",
    resultsSlectStatus:[],
    resultsSlectArea:[],
    job_positions:[],
    contractTypes:[],
    candidates:[],
    vacancyType:[],
    announcement:[],
    loading:false,
    tempWorkCertificatePDF: "",
    tempWorkCertificatePDFNOM: "",
    periods:[],
    loadingCContract:false  
};
export const contractReducer = (state = initialState, action) => {
    switch (action.type) {
            case LOADING:
            return { ...state, loading: action.payload };
            case GET_LIST_CONTRACT:
            return {
                ...state,
                results: action.payload,
                total: action.total,
                country: action.country
            };
            case GET_SELECT_STATUS:
                return {
                    ...state,
                    resultsSlectStatus: action.payload,
                };
                case GET_SELECT_AREA:
                return {
                    ...state,
                    resultsSlectArea: action.payload,
                };
                case GET_SELECTS_CONTRACT_CREATE:
                    return{
                        ...state,
                        job_positions:action.job_positions,
                        contractTypes:action.contractTypes,
                        candidates:action.candidates,
                    }
                case GET_ANNOUNCEMENT: 
                return{
                    ...state,
                    announcement: action.announcement,
                }
                case  GET_VACANCY_TYPE_LIST:
                    return{
                        ...state,
                        vacancyType: action.vacancyType
                    }
        case POST_OTROS_SI:
            return{
                ...state,
                otrosi_loading: action.loading,
            }
        case GET_TEMP_WORK_CERT:
            return{
                ...state,
                tempWorkCertificatePDF: action.payload,
            }
        case GET_TEMP_WORK_CERT_NOM:
            return{
                ...state,
                tempWorkCertificatePDFNOM: action.payload,
            }
        case GET_PERIODS:
            return{
                ...state,
                periods: action.payload,
            }
        case NW_CONTRACT_LOADING:
            return{
                ...state,
                loadingCContract: action.loading,
            }
        default:
            return state;
    }
};

