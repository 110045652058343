// ----------------- COMPONENTS- ---------------------------//
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link, useParams, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
// --------------------------------------------------------//
// ----------------- CONSTANTS 🚡 - ---------------------------//
import { MAX_SIZE_FILE, REGEX_VALID_PDF_TYPE } from "../../helpers/constants";
// -------------------------------------------------------//

// ⛳ functions ---------------------------------------//
import {
  getWorkAccidents,
  updateWorkAccidents,
} from "../../actions/payrollActions";
import { convertDateToLatinFormat } from "../../helpers";
import { getFilenameByURL } from "../../helpers/helpers";
// ---------------------------------------------------//
import tableStyles from "../Layouts/tableStyle.module.scss";

// ---------------------- 🌌 ICONS --------------------------------//
import Attach from "../../assets/img/icons/insertar.svg";
import PDF from "../../assets/img/icons/pdf.svg";
import littleClose from "../../assets/img/icons/littleClose.svg";
import backIcon from "../../assets/img/icons/backIcon.svg";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

// ---------------------- ##### -----------------------------------//

function AccidentDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const reducers = useSelector((state) => state);

  let accidentDetails = null;
  const { id } = useParams();

  const initialState = {
    registerResults: {
      file_base64: "",
      filename: "",
      type_doc: "",
      result: "",
      route: "work-accidents",
    },
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    dispatch(getWorkAccidents({ id: id }));
  }, []);

  if (reducers.payrollReducer.accidentDetails)
    accidentDetails = reducers.payrollReducer.accidentDetails;

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const onChangeDoc = async (e) => {
    var f = e.target.files[0];
    var name_doc = f.name.split(".")[0];
    var type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    setState({
      ...state,
      registerResults: {
        ...state.registerResults,
        file_base64: file64,
        filename: name_doc,
        type_doc: type_doc,
      },
    });
  };

  const validateFile = async (acceptedFile) => {
    let size = acceptedFile[0].size;
    if (size > MAX_SIZE_FILE) {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `El archivo es demasiado pesado`,
        footer: "El tamaño máximo permitido es de 5MB",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      });
      
    }
    if (new RegExp(REGEX_VALID_PDF_TYPE).test(acceptedFile.type)) {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "El formato del archivo debe ser PDF",
        footer: "El tamaño máximo permitido es de 5MB",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      });
      
    }
  };

  const cleanFile = () => {
    setState({
      ...state,
      registerResults: {
        ...state.registerResults,
        type_doc: "",
        filename: "",
        file_base64: "",
        route: "work-accidents",
        description: "",
      },
    });
  };

  const myPermission = () =>
    reducers.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "WorkAcc"
    );

  //   handle submit to update results
  const handleSubmit = (data) => {
    if (!data.file_base64 || data.description) {
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Debe llenar todos los campos",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      });
    }
    dispatch(updateWorkAccidents({ ...data, id: id, status: "reviewed" }, ()=>history.push("/nomina/seguridadYSalud/accidentesLaborales")));
  };


  return (
    <div className="container mx-5"
    style={{marginLeft: "65px"}}>

      { !myPermission().read && <Redirect to="/nomina/inicio"/> }
      <div className="d-flex">
      <div
        onClick={()=> history.push("/nomina/seguridadYsalud/accidentesLaborales")
        }
          style={{ alignSelf: "center", margin: "33px 10px 0px -27px" }}
          className="d-flex align-items-end"
        >
          <img src={backIcon} alt="back" height="30px" className="cursorPointer"
          style={{marginBottom: "16px", marginRight: "10px"}}
          />
          <h1 className={tableStyles.f40}>Detalle del accidente</h1>

        </div>
        {/* <Link
          to="/nomina/seguridadYsalud/accidentesLaborales"
          style={{ alignSelf: "center", margin: "3rem 1rem 0.5rem -2.5rem" }}
        >
          <img src={backIcon} alt="back" height="30px" />
        </Link> */}
      </div>
      <p className="subtitleBlue">
        {accidentDetails?.full_name} / {accidentDetails?.title}
      </p>
      <Tabs
        defaultActiveKey="report"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="report" title="Informe" tabClassName="px-4 tabHeader">
          <section className="d-flex">
            {/* Rows */}
            <div className="d-flex flex-column w-25">
              <ul>
                <li className="text-primary fw-bold">Información general</li>
              </ul>
              <ul>
                <li className="darkGray fw-bold list-unstyled">
                  Fecha del accidente:
                </li>
                <li className="darkGray fw-bold list-unstyled mt-2">
                  Reportante:
                </li>
                <li className="darkGray fw-bold list-unstyled mt-2">Cargo:</li>
                <li className="darkGray fw-bold list-unstyled mt-2">Área:</li>
                <li className="darkGray fw-bold list-unstyled mt-2">Título:</li>
              </ul>
            </div>
            {/* details show */}
            <div className="d-flex flex-column">
              <ul>
                <li className="text-primary fw-bold list-unstyled">ㅤ </li>
              </ul>
              <ul>
                <li className="darkGray list-unstyled">
                  {convertDateToLatinFormat(accidentDetails?.accident_date)}
                </li>
                <li className="darkGray list-unstyled mt-2">
                  {accidentDetails?.user_reviewed
                    ? accidentDetails?.user_reviewed
                    : "ㅤ "}
                </li>
                <li className="darkGray list-unstyled mt-2">
                  {accidentDetails?.job_title}
                </li>
                <li className="darkGray list-unstyled mt-2">
                  {accidentDetails?.area_name}
                </li>
                <li className="darkGray list-unstyled mt-2">
                  {accidentDetails?.title}
                </li>
              </ul>
            </div>
          </section>
          {/* second section */}
          <section className="d-flex">
            {/* Rows */}
            <div className="d-flex flex-column w-25">
              <ul>
                <li className="text-primary fw-bold">
                  Información del accidente
                </li>
              </ul>
              <ul>
                <li className="darkGray fw-bold list-unstyled">
                  Colaborador afectado:
                </li>
              </ul>
            </div>
            {/* details show */}
            <div className="d-flex flex-column">
              <ul>
                <li className="text-primary fw-bold list-unstyled">ㅤ</li>
              </ul>
              <ul>
                <li className="darkGray list-unstyled">
                  {accidentDetails?.full_name}
                </li>
              </ul>
            </div>
          </section>
          <section className="d-flex" style={{ marginTop: "-12px" }}>
            <ul>
              <li className="darkGray fw-bold list-unstyled mt-2">
                Descripción de la lesión:
              </li>
              <li className="darkGray list-unstyled">
                {accidentDetails?.description_injury}
              </li>
              <li className="darkGray fw-bold list-unstyled mt-2">
                Descripción de la situación:
              </li>
              <li className="darkGray list-unstyled">
                {accidentDetails?.description_event}
              </li>
            </ul>
          </section>
          <section className="d-flex">
            <div className="d-flex flex-column">
              <ul>
                <li className="text-primary fw-bold">Evidencias</li>
              </ul>
              <ul>
                <div className="d-flex">
                  <a
                    href={accidentDetails?.result_file}
                    target="_blank"
                    className="text-primary px-2" rel="noreferrer"
                  >
                    {!!accidentDetails?.result_file
                      ? getFilenameByURL(accidentDetails?.result_file)
                      : ""}
                  </a>
                  <a
                    href={accidentDetails?.inhability_doc}
                    target="_blank"
                    className="text-primary px-2" rel="noreferrer"
                  >
                    {!!accidentDetails?.inhability_doc
                      ? getFilenameByURL(accidentDetails?.inhability_doc)
                      : ""}
                  </a>
                  <a
                    href={accidentDetails?.furat}
                    target="_blank"
                    className="text-primary px-2" rel="noreferrer"
                  >
                    {!!accidentDetails?.furat
                      ? getFilenameByURL(accidentDetails?.furat)
                      : ""}
                  </a>
                  <a
                    href={accidentDetails?.other_docs}
                    target="_blank"
                    className="text-primary px-2" rel="noreferrer"
                  >
                    {!!accidentDetails?.other_docs
                      ? getFilenameByURL(accidentDetails?.other_docs)
                      : ""}
                  </a>
                </div>
              </ul>
            </div>
          </section>
        </Tab>
        <Tab
          eventKey="register"
          title="Registrar resultados"
          tabClassName="px-4 tabHeader"
        >
          <CKEditor
            disabled={
              reducers.payrollReducer.accidentDetails?.result ? true : false
            }
            editor={ClassicEditor}
            data={
              reducers.payrollReducer.accidentDetails?.result
                ? reducers.payrollReducer.accidentDetails?.result
                : state.registerResults?.result
            }

            onChange={(event, editor) => {
              const data = editor.getData();
              setState({
                ...state,
                registerResults: {
                  ...state.registerResults,
                  result: data,
                },
              });
            }}

          />
          <section className="mt-5 w-50">
            <label className="labelFont" htmlFor="uploadFile">
              Subir PDF de resultados
            </label>
            <Dropzone
              onDrop={(acceptedFile) => validateFile(acceptedFile)}
              accept={"application/pdf"}
              name="uploadFile"
              className="rounded"
            >
              {({ getRootProps, getInputProps }) => (
                <section
                  className="w-100 h-100 p-1 text-center mx-auto border-dark-blue rounded-3 "
                  style={{ position: "relative", border: "1px solid #7FADDE" }}
                >
                  <div {...getRootProps()}>
                    {!!state.registerResults.filename && (
                      <img
                        disabled={
                          reducers.payrollReducer.accidentDetails?.result_file
                            ? true
                            : false
                        }
                        className="hoverPointer"
                        src={littleClose}
                        alt="discard file"
                        width="16"
                        height="16"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          zIndex: 2,
                        }}
                        onClick={() => cleanFile()}
                      />
                    )}
                    <input
                      {...getInputProps()}
                      type="file"
                      name="documents"
                      onChange={onChangeDoc}
                      accept={"application/pdf"}
                      multiple={false}
                      disabled={
                        reducers.payrollReducer.accidentDetails?.result_file
                          ? true
                          : false
                      }
                    />
                    {!!state.registerResults.filename ||
                    reducers.payrollReducer.accidentDetails?.result_file ? (
                      <div
                        id="2"
                        className={"text-muted text-center px-3 pt-3"}
                      >
                        <img src={PDF} alt="pdf icon" />
                        <p className={`darkGray`}>
                          {reducers.payrollReducer.accidentDetails?.result_file
                            ? getFilenameByURL(
                                reducers.payrollReducer.accidentDetails
                                  ?.result_file
                              )
                            : state.registerResults.filename}
                        </p>
                      </div>
                    ) : (
                      <>
                        <p
                          className={`text-muted text-center "px-3 pt-3 pb-5": "p-5"} hoverPointer`}
                        >
                          {"Soltar archivo aquí"}
                        </p>
                        <div className="d-flex justify-content-end">
                          <img src={Attach} alt="añadir" />
                        </div>
                      </>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </section>
          <div className="d-flex justify-content-end">
            <button
              className="btn-primary rounded-2"
              onClick={() => handleSubmit(state.registerResults)}
            >
              Guardar
            </button>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AccidentDetails;
