import React, { useEffect, useState } from "react";
import Agregar from "../../../../assets/img/icons/add-check.svg";
import threeDots from "../../../../assets/img/icons/threeDots.svg";
import Pagination from "react-js-pagination";
import Search from "../../../../assets/img/icons/lupa.svg";
import SearchClear from "../../../../assets/img/icons/lupaTransparent.svg";
import Edit from "../../../../assets/img/icons/editar.svg";
import alertIcon from "../../../../assets/img/icons/alert-circle-auxiliary-red.svg";
import GenericTableNew from "../../../Layouts/GenericTableNew";
import {  useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import valeStyles from "../../../TableAccAccounts/AccAccounts_CSS.module.scss";
import Styles from "../../../../components/PayRollModule/Hiring/Contract.module.scss";
import CustomPopupStyles from "../../../../components/Popup/customPopup.module.scss";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomPopupExtend from "../../../Popup/customPopUpExtends";
import { loader, today } from "../../../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../../../Hooks/useFetch";
import {customSelectNewDark} from "../../../Layouts/react-select-custom";
import Select from "react-select";
import moment from "moment";

const PerformanceAppraisal = () => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "evalPerf"
    );
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    startDate: "",
    endDate: "",
    status: undefined,
    page: 1,
    perpage: 10,
    eaccount: idAccount,
  });
  const { results: surveyListResults, load: surveyListLoader, trigger: getSurveyListMethod } = useGetMethod();

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }

    getSurveyListMethod({
      url: "/payroll/performance_evaluation",
      objFilters: filters,
      token: token,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

// --------------------- SECTION TABLE  -------------------------

const toolTipAlert = (message) => <Tooltip>{message}</Tooltip>;



  const renderElement = (elem) => {
    const options = !!myPermission.edit ? [
      {
        text: "Editar",
        class: CustomPopupStyles.popUpGeneric,
        icon: Edit,
        event: () => {
          handleClickSurvey("edit", elem)
        },
      },
      {
        text: "Ver detalle",
        icon: SearchClear,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickSurvey("detail", elem)
        },
      },
    ] : [
      {
        text: "Ver detalle",
        icon: SearchClear,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickSurvey("detail", elem)
        },
      },
    ]
    const optionsDetail = [options[1]]
    const optionsEdit = [options[0]]
    // const dateAlert = moment(elem.startDate).subtract(2, "days").format("YYYY-MM-DD");
    const dateAlert = moment(today()).add(2, "days").format("YYYY-MM-DD");
    let showAlert = false
    let message;
    if ( elem.startDate >= today() && elem.startDate <= dateAlert &&  elem.status === "draft" ){
    showAlert = true
    message = "Próximo a vencer"
    }else if ( elem.startDate < today() &&  elem.status === "draft" ){
      showAlert = true
      message = "Vencida"
    }



    return (
      <tr key={elem.id}>
        <td className="col-md-3 text-start " style={{ paddingLeft: "2rem" }}>
          {elem.title ? elem.title : "-"}
        </td>

        <td className="col-md-2 text-center"
        style={{ paddingLeft: "" }}
        >
          <div className="d-flex justify-content-center"
          style={{marginLeft: `${showAlert ? "1.7rem" : ""}`}}
          >
          <span className=" text-center">{elem.startDate ?? "-"}</span>
          {showAlert &&
          <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 200 }}
          overlay={toolTipAlert(message)}
          >
          <img alt="alertIcon" src={alertIcon} height="15px" className="px-2"/>
          </OverlayTrigger>
          }
          </div>
        </td>
        <td className="col-md-2 text-center">{elem.endDate ?? "-"}</td>

        <td className="col-md-2 text-center">{elem.numberRespondents ?? "-"}</td>
        <td className="col-md-2 text-center">
          <div
            className="rounded-pill p-1"
            style={{
              backgroundColor: elem?.background,
            }}
          >
            <b style={{ color: elem?.fontColor }}>{elem.statusName ? elem.statusName : "-"}</b>
          </div>
        </td>
        <td className="col-md-1 text-center cursorPointer">
          <div>
            <CustomPopupExtend
              noHover
              className={`align-self-end`}
              // triggerClass={`${tableStyles.hoverPopup} `}
              triggerSrc={threeDots}
              extraButtons={elem.status === "in_progress" || elem.status === "completed" || elem.status === "to_start" ? optionsDetail
              : elem.status === "draft" ? options
              : optionsDetail}
            />
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    // surveyListResults
    if (surveyListResults?.results?.length > 0) {
      const elem2 = surveyListResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className=" pl-5 text-start"
    style={{ paddingLeft: "2rem" }}
    >
    Título de la encuesta
    </th>,
    <th key={2} className="text-center">
      Fecha inicio
    </th>,
    <th key={3} className="text-center">
      Fecha fin
    </th>,
    <th key={4} className="text-center">No. de evaluados</th>,
    <th key={4} className="text-center">Estado</th>,
    <th key={5} className="text-center">&nbsp;</th>,
  ];
// ----------------!SECTION--------------------------------

  const handleClickSurvey = (action, elem) => {
    if (action === "detail" || action === "edit"){
      history.push({
        pathname: "/nomina/EvaluacionDeDesempeño/Evaluacion",
        state: { action: action, data: elem },
      });
    } else {
      history.push({
        pathname: "/nomina/EvaluacionDeDesempeño/CrearEvaluacion",
        state: action === "new" ? {action: action} : {},
      });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const statusOptions = [
    {value: "", label:"Seleccionar..."},
    {value: "in_progress", label:"En curso"},
    {value: "to_start", label:"Por iniciar"},
    {value: "completed", label:"Finalizada"},
    {value: "draft", label:"Borrador"},
  ]

  return (
    <>
{(surveyListLoader) && loader}
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Evaluación de Desempeño</h1>

        {!!myPermission.read &&
        <>
          <Row>
      <Col xs={2}>
       <p className={tableStyles.crudModalLabel}>Fecha inicio</p>
       <input
        onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
        type="date"
        placeholder="Escribe..."
        className={`register-inputs `}
        name="position"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(e);
          }
        }}
      />
      </Col>
      <Col xs={2}>
       <p className={tableStyles.crudModalLabel}>Fecha fin</p>
       <input
        onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
        type="date"
        placeholder="Escribe..."
        className={`register-inputs `}
        name="position"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(e);
          }
        }}
      />
      </Col>
      <Col xs={2} className="align-self-end">
       <p className={tableStyles.crudModalLabel}>Estado</p>
       <Select noOptionsMessage={() => 'No hay datos'}
        styles={customSelectNewDark}
        placeholder={"Seleccionar..."}
        key={"state" + trigger}
        value={statusOptions.find(
          (x) => x.value === filters.status
        )}
        onChange={(e) => {setFilters({
          ...filters,
          status: e.value, page: 1
          })
          setTrigger(trigger + 1);
        }}
        // noOptionsMessage={() => "No hay datos"}
        options={statusOptions}
       ></Select>{" "}
      </Col>
      <Col
       xs={3}
       className="d-flex align-self-end gap-1"
      >
       <input
        className={tableStyles.SearchNew}
        value={filters.search}
        placeholder="Buscar..."
        type="text"
        onChange={(e) =>
          setFilters({ ...filters, search: e.target.value })
        }
        onKeyDown={(e) => {
         if (e.key === "Enter") {
          handleSearch(e)
         }
        }}
       ></input>
       <img
        className="hoverPointer"
        alt="iconSearch"
        style={{}}
        src={Search}
        height="24px"
        onClick={(e) => handleSearch(e)}
       />
      </Col>
      {!!myPermission?.create ? (
       <Col
        xs={3}
        className="align-self-end"
       >
        {!!myPermission.create &&
        <div className="d-flex justify-content-end">
         <div
          onClick={() => handleClickSurvey("new")}
          className={tableStyles.createNomModule}
         >
          <b className={`mr-2`}>Crear evaluación</b>
          <img
           src={Agregar}
           alt="User"
          />
          <div></div>
         </div>
        </div>
        }
       </Col>
      ) : null}
      {/* <Col xs={5}>
          </Col> */}
          </Row>
          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>
          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {surveyListResults?.results ? filters.page : ""}
              {" de "}
              {Math.ceil(surveyListResults?.rowTotal / filters.perpage)
                ? Math.ceil(surveyListResults?.rowTotal / filters.perpage)
                : ""}{" "}
              ({surveyListResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={surveyListResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </>
        }
        </div>
      </div>
    </>
  );
};

export default PerformanceAppraisal