import React from "react";
import { OrdGenericTemplate } from "../../../../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetMethod } from "../../../../Hooks/useFetch";
import { useState } from "react";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import { Col, Row } from "react-bootstrap";
import GenericTableNew from "../../../Layouts/GenericTableNew";
import { loader } from "../../../../helpers/helpers";
import backIcon from "../../../../assets/img/icons/nomIconRow.svg";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import Pagination from "react-js-pagination";

const SurveyDetail = () => {
 const location = useLocation();
 const history = useHistory();
 const store = useSelector((state) => state);
 const idUser = store.loginReducer.user_data.id;
 const idAccount = store.loginReducer.currentAccount.id;
 const token = store.loginReducer.Authorization;
 const detail = location?.state?.data;
 const { results: employeeListResults, load: employeeListLoader, trigger: getEmployeeList } = useGetMethod();

 const [filters, setFilters] = useState({
  page: 1,
  perpage: 10,
  eaccount: idAccount,
  idUser: idUser,
  id: detail?.id
 });
 useEffect(() => {
  getEmployeeList({
   url: "/payroll/performance_evaluation_answers_by_supervisor",
   objFilters: filters,
   token: token,
  });
 }, []);

 // SECTION TABLE


 const goToAnswer = (elem)=>{

    history.push({
        pathname: "/configuracion/minomina/registros/detalleDeEvaluacion/responderEvaluacion",
        state: {data: elem, idSurvey: detail?.id, dataBefore: detail}
    })

 }





 const renderElement = (elem) => {
  return (
   <tr key={elem.id}>
    <td
     className="col-md-3 text-start "
     style={{ paddingLeft: "2rem" }}
    > 
    {elem.status === "Pendiente"?
    <button
    onClick={()=> goToAnswer(elem)}
    className="text-start"
    >
     <u >{elem.fullNameUserAssessed ?? "-"}</u>
    </button>
    :
<span>
{elem.fullNameUserAssessed ?? "-"}
</span>

    }
     
    </td>
    <td className="col-md-2 text-start">{elem.dependency ?? "-"}</td>
    <td className="col-md-2 text-start">{elem.jobTitle ?? "-"}</td>
    <td className="col-md-2 text-center">
     <div
      className="rounded-pill p-1"
      style={{
       backgroundColor: elem?.background,
      }}
     >
      <b style={{ color: elem?.fontColor }}>{elem.status ? elem.status : "-"}</b>
     </div>
    </td>
   </tr>
  );
 };

 const listElem = () => {
  let elemMap;
  // employeeListResults
  if (employeeListResults?.results?.length > 0) {
   const elem2 = employeeListResults?.results;
   if (elem2?.length > 0) {
    elemMap = elem2.map((elem) => {
     return renderElement(elem);
    });
   }
  }
  return elemMap;
 };

 const renderHeaders = [
  <th
   key={1}
   className=" pl-5 text-start pr-2"
   style={{ paddingLeft: "2rem" }}
  >
   Nombre
  </th>,
  <th
   key={2}
   className="text-start"
  >
   Dependencia
  </th>,
  <th
   key={3}
   className="text-start"
  >
   Cargo
  </th>,
  <th
   key={4}
   className="text-center"
  >
   Estado
  </th>,
 ];

 //   !SECTION
 return (
  <>
  {employeeListLoader && loader}
   <OrdGenericTemplate
    title="Evaluación de Desempeño"
    titleSize={12}
    backIcon={backIcon}
    showBackArrow={true}
    backArrowAction={() => history.push("/configuracion/minomina/registros/")}
    className="mx-4 mt-4"
   >
    <div className="d-flex justify-content-between">
     <div
      className="mt-3 "
      style={{ width: "95%", paddingLeft: "2rem" }}
     >
      <Row className="mt-2">
       <Col xs={2}>
        <p className={`${tableStyles.crudModalLabel} `}>
         Fecha inicio
         <span className={"text-danger"}>*</span>
        </p>
        <input
         type={"date"}
         value={detail?.startDate}
         readOnly
         className={`${tableStyles.nomInput}  text-secondary fw-bold`}
         name="position"
        />
       </Col>
       <Col xs={2}>
        <p className={`${tableStyles.crudModalLabel} `}>
         Fecha fin
         <span className={"text-danger"}>*</span>
        </p>
        <input
         type={"date"}
         value={detail?.endDate}
         readOnly
         className={`${tableStyles.nomInput}  text-secondary fw-bold`}
         name="position"
        />
       </Col>
       <Col xs={8}>
        <p className={`${tableStyles.crudModalLabel} `}>
         Título principal
         <span className={"text-danger"}>*</span>
        </p>
        <input
         readOnly
         value={detail?.title}
         className={`${tableStyles.nomInput}  text-secondary`}
         name="position"
        />
       </Col>
      </Row>
      <Row className="">
       <Col xs={12}>
        <p className={`${tableStyles.crudModalLabel} `}>
         Descripción de la evaluación
         <span className={"text-danger"}>*</span>
        </p>
        <textarea
         value={detail?.description}
         placeholder={"Escribir..."}
         readOnly
         rows="10"
         cols="10"
         className={` text-secondary ${tableStyles.outlineNone}`}
         style={{ height: "3rem", border: "1.5px solid #005dbf", borderRadius: "5px", width: "100%", padding: "5px" }}
        ></textarea>
       </Col>
      </Row>
 
       <GenericTableNew
        headers={renderHeaders}
        dark={true}
       >
        {listElem()}
       </GenericTableNew>
 
       <div className={paginationStyles.wrapper}>
      <p className={`${paginationStyles.paginationText} text-secondary`}>
       Pag. {employeeListResults?.results ? filters.page : ""}
       {" de "}
       {Math.ceil(employeeListResults?.rowTotal / filters.perpage) ? Math.ceil(employeeListResults?.rowTotal / filters.perpage) : ""} (
        
       {employeeListResults?.rowTotal} encontrados)
      </p>
      <Pagination
       activePage={filters.page}
       itemsCountPerPage={filters.perpage}
       totalItemsCount={employeeListResults?.rowTotal}
       pageRangeDisplayed={5}
       onChange={(e) => setFilters({ ...filters, page: e })}
       itemClassPrev={paginationStyles.itemClassPrev}
       itemClassNext={paginationStyles.itemClassNext}
       itemClassFirst={paginationStyles.itemClassFirst}
       itemClassLast={paginationStyles.itemClassLast}
       itemClass={paginationStyles.itemClass}
      />
     </div>

     
     </div>
    </div>
   </OrdGenericTemplate>
  </>
 );
};

export default SurveyDetail;
