import React, { useEffect, useState } from "react";
import PENDING from "../../assets/img/icons/Alert.svg";
import APPROVED from "../../assets/img/icons/Check Mark.svg";
import REJECTED from "../../assets/img/icons/extraBtnRedX.svg";
import PROCESSED from "../../assets/img/icons/grayCheckNew.svg";
import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import triggerSrc from "../../assets/img/icons/threeDots.svg";
import GenericTableNew from "../Layouts/GenericTableNew";
import {useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import { customSelectNew } from "../../components/Layouts/react-select-custom";
import paginationStyles from "../Layouts/pagination.module.scss";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import CustomPopupExtend from "../Popup/customPopUpExtends";
import ModalNew from "../Layouts/ModalNew";
import {
  getPermissionsList,
  updatePermissionsStatus,
} from "../../actions/approvedNewsActions";
import { costcenter_get } from "../../actions/costcenterActions";
import {
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from "../../helpers/helpers";
import { PdfViewer } from "../Layouts/PdfViewer";
import Loader from "react-loader-spinner";
export const AuthPermissions = (props) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "permMy"
    );

  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const arrConCenter = store.costcenterReducer?.cc;
  const [trigger, setTrigger] = useState(0);
  const [elemDetail, setElemDetail] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    entity_account: idEnterprise,
    search: "",
    status: "",
    dateFrom: "",
    dateUntil: "",
    area: "",
    user: idAccount,
  });
  const formattedConCenter = formatToRcSelect(
    arrConCenter,
    "id",
    "description"
  );
  const arrPermisions = store.approvedNewsReducer?.permissionsList;
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/configuracion/miperfil");
    }

    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      })
    );
    dispatch(getPermissionsList(filters));
  }, [filters.page, trigger]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-2 text-start" style={{ paddingLeft: "0.5rem" }}>
          {elem.collaborator}
        </td>
        <td className="col-md-2 text-start">&nbsp; {elem.job_title}</td>
        <td className="col-md-1 text-start">
          {elem.type === "paid" ? "Remunerado" : "No remunerado"}
        </td>
        <td className="col-md-2 text-center">{elem.count}</td>
        <td className="col-md-2 text-center">{elem.date}</td>
        <td className="col-md-2 text-center cursorPointer" title={
          elem.status === "pending"
            ? "Pendiente"
            : elem.status === "approved"
              ? "Aprobado"
              : elem.status === "rejected"
                ? "Rechazado"
                : elem.status === "processed" && "Procesado"
        }>
          <img
          alt="icon"
            src={
              elem.status === "pending"
                ? PENDING
                : elem.status === "approved"
                  ? APPROVED
                  : elem.status === "rejected"
                    ? REJECTED
                    : elem.status === "processed" && PROCESSED
            }
          ></img>
        </td>

        <td className="col-md-1 text-center cursorPointer">
          <div>
            <CustomPopupExtend
              noHover
              triggerSrc={triggerSrc}
              showDetails={true}
              textDetails="Ver soporte"
              showDetailsEvent={() => handleClickDetail(elem)}
              showUndo={
                elem.status === "pending"
                  ? false
                  : elem.status === "approved"
                    ? true
                    : elem.status === "rejected"
                      ? true
                      : elem.status === "processed" && false
              }
              undoEvent={() => handleUndo(elem)}
              extraButtons={
                elem.status === "pending"
                  ? [
                    {
                      text: "Autorizar",
                      icon: APPROVED,
                      event: () => handleAuth(elem),
                    },
                    {
                      text: "Denegar",
                      icon: REJECTED,
                      event: () => handleDeny(elem),
                    },
                  ]
                  : []
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async (elem) => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Está seguro?",
        text: `¿Desea deshacer esta solicitud de permiso?`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, Continuar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              const result = await updatePermissionsStatus(
                {
                  status: "pending",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token
              );

              if (result.success) {
                setTrigger(trigger + 1);
                return message("success", "Éxito", result.message);
              } else {
                return message("error", "Ha ocurrido un error", result.message);
              }
            } catch (error) {
              message("error", "Error", error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message("error", "Error", error.message);
    }
  };

  const handleAuth = (elem) => {
    //console.log(elem);
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se aprobará el permiso solicitado por ${elem?.collaborator}`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, Continuar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const send = async () => {
            try {
              const result = await updatePermissionsStatus(
                {
                  status: "approved",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token
              );

              if (result.success) {
                setIsLoading(false);
                setShowModal(false);
                setTrigger(trigger + 1);
                return message("success", "Éxito", result.message);
              } else {
                setIsLoading(false);
                return message("error", "Ha ocurrido un error", result.message);
              }
            } catch (error) {
              setIsLoading(false);
              message("error", "Error", error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message("error", "Error", error.message);
    }
  };
  const handleDeny = async (elem) => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Está seguro?",
        text: `Se denegará el permiso solicitado por ${elem.collaborator}`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, Continuar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(false);
          const send = async () => {
            try {
              const result = await updatePermissionsStatus(
                {
                  status: "rejected",
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token
              );

              if (result.success) {
                setIsLoading(false);
                setShowModal(false);
                setTrigger(trigger + 1);
                return message("success", "Éxito", result.message);
              } else {
                return message("error", "Ha ocurrido un error", result.message);
              }
            } catch (error) {
              setIsLoading(false);
              message("error", "Error", error.message);
            }
          };
          send();
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message("error", "Error", error.message);
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrPermisions !== undefined) {
      const elem2 = arrPermisions;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className="text-start">
      &nbsp; Colaborador
    </th>,
    <th key={2} className="text-start">
      &nbsp; Cargo
    </th>,
    <th key={3} className="text-start">
      Tipo
    </th>,
    <th key={4} className="text-center">
      Cantidad
    </th>,
    <th key={5} className="text-center">
      Fecha
    </th>,
    <th key={6} className="text-center">
      Estado
    </th>,
    <th key={7}>&nbsp;</th>,
  ];

  const handleClickDetail = async (elem) => {
    if (isEmptyOrUndefined(elem.file)) {
      return message(
        "info",
        "Sin soporte",
        "No se encontraron soportes para esta solicitud"
      );
    }

    setElemDetail(elem);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      {store?.approvedNewsReducer?.loadingPerm ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={"Soporte"}
        subtitle={
          elemDetail.status === "pending"
            ? "En espera"
            : elemDetail.status === "approved"
              ? "Aprobado"
              : elemDetail.status === "rejected"
                ? "Rechazado"
                : elemDetail.status === "processed" && "Procesado"
        }
        subtitleStyle={
          elemDetail.status === "pending"
            ? "text-warning"
            : elemDetail.status === "approved"
              ? "text-success"
              : elemDetail.status === "rejected"
                ? "text-danger"
                : elemDetail.status === "processed" && "text-secondary"
        }
        size={"lg"}
      >
        <PdfViewer file={elemDetail.file}></PdfViewer>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Autorizar permisos</h1>

          <Row>
            <Col className="d-block" xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
              <Select noOptionsMessage={() => 'No hay datos'}
                onChange={(e) => setFilters({ ...filters, status: e.value })}
                options={[
                  { value: "", label: "-Seleccione-" },
                  { value: "pending", label: "Pendiente" },
                  { value: "approved", label: "Aprobado" },
                  { value: "rejected", label: "Rechazado" },
                  { value: "processed", label: "Procesado" },
                ]}
                className="text-secondary"
                placeholder={"Seleccione"}
                styles={customSelectNew}
              ></Select>
            </Col>
            <Col className="d-block" xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Tipo</p>
              <Select noOptionsMessage={() => 'No hay datos'}
                onChange={(e) => setFilters({ ...filters, type: e.value })}
                options={[
                  { value: "", label: "-Seleccione-" },
                  { value: "paid", label: "Remunerado" },
                  { value: "unpaid", label: "No remunerado" },
                ]}
                className="text-secondary"
                placeholder={"Seleccione"}
                styles={customSelectNew}
              ></Select>
            </Col>

            <Col className="d-block align-self-end" xs={4}>
              <div className="pt-4">
                <form onSubmit={(e) => handleSearch(e)} className="d-flex">
                  <input
                    className={`${tableStyles.SearchNew} mr-2 text-secondary`}
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                    name="search"
                    type="text"
                    placeholder="Buscar..."
                  />
                  <img
                    src={Search}
                    className="cursorPointer"
                    width={"30px"}
                    height={"24px"}
                    alt="Search icon"
                    onClick={(e) => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag.{" "}
              {store.approvedNewsReducer.permissionsList ? filters.page : ""}
              {" de "}
              {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                ? Math.ceil(
                  store.approvedNewsReducer.rowTotal / filters.perpage
                )
                : ""}{" "}
              ({store.approvedNewsReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.approvedNewsReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={(e) => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
