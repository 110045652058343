//All actions for Login component
import { COSTCENTER_GET, COSTCENTER_GETONE, COSTCENTER_EDIT, COSTCENTER_POST , GET_ALL_COST_CENTER, COSTCENTER_LOAD} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal)

export const costcenter_get = (objFilters) => (dispatch, getState)  => {
    dispatch({ type:COSTCENTER_LOAD, loading: true });
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/costcenter/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }}).then(response => response.json())
    .then(res => {
        //console.error(res)
        const {results, row_total} = res
        dispatch({
            type: COSTCENTER_GET,
            results: results,
            row_total: row_total
        })
        dispatch({ type:COSTCENTER_LOAD, loading: false });
    })
    .catch((err)=>{
        console.error(err)
        dispatch({ type:COSTCENTER_LOAD, loading: false });
    })
}

export const getAllCostCenter = () =>(dispatch, getState) =>{
    dispatch({ type:COSTCENTER_LOAD, loading: true });
    const token = getState().loginReducer.Authorization;
   
    fetch(
      `${URL_GATEWAY}${API_VERSION}/costcenter/all/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          
          token: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        const { data } = res;
        let costc = [];
       for(let m in data){
        if(data[m].active === true){
            costc.push(data[m]);
        }
       }
        dispatch({
          type: GET_ALL_COST_CENTER,
          payload: costc,
        });
        dispatch({ type:COSTCENTER_LOAD, loading: false });
      })
      .catch((err) => {
        dispatch({ type:COSTCENTER_LOAD, loading: false });
      });
  }

export const costcenter_edit_active = (codigo, data, description, executeThisAfter) => (dispatch, getState)  => {
    dispatch({ type:COSTCENTER_LOAD, loading: true });
    const token = getState().loginReducer.Authorization;
    let status = data.active ? "habilitar" : "deshabilitar"
    let status2 = data.active ? "habilitado" : "deshabilitado"
        
        customSwaltAlert({
            icon: 'warning',
            title: `¿Está seguro?`,
            text: `Se va ${status} el centro de costos: ${description}`,
            showCancelButton: true,
          })
        .then((result) => {
        if (result.isConfirmed) {
            fetch(`${URL_GATEWAY}${API_VERSION}/inventory/costcenter/${codigo}`,{
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                }
            })
            .then(response => response.json())
            .then(res => {
                if(res.status ===200){
                    
                    customSwaltAlert({
                        icon:'success',
                        title:'Actualizado exitosamente',
                        text:`Se ha ${status2} el centro de costos: ${description}`,
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })

                }else{
                    
                    customSwaltAlert({
                        icon:'warning',
                        title:'Intenta de nuevo',
                        text:`${res.message}`,
                        confirmButtonText:'Aceptar',
                        showCancelButton:false
                      })
                }
                dispatch({ type:COSTCENTER_LOAD, loading: false });
            }).then (
                ()=>{executeThisAfter();}
            )
            .catch((err)=>{
                console.error(err);
                

                customSwaltAlert({
                    icon:'warning',
                    title:'Intenta de nuevo',
                    text:'No se pudo cambiar el estado',
                    footer:'Si el problema persiste comuníquese con un asesor.',
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

                dispatch({ type:COSTCENTER_LOAD, loading: false });
            })
        }
        })
      
  }

export const costcenter_edit = (codigo, data, executeThisAfter) => (dispatch, getState)  => {
    if(data.description.trim()){    
        dispatch({ type:COSTCENTER_LOAD, loading: true });
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/costcenter/${codigo}`,{
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            }
        })
        .then(response => response.json())
        .then(res => {
            if(res.status ===200){

                customSwaltAlert({
                    icon:'success',
                    title:'Actualizado exitosamente',
                    text:`Se ha actualizado el centro de costos: ${data.description}`,
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })

            }else{
                
                customSwaltAlert({
                    icon:'warning',
                    title:'Intenta de nuevo',
                    text:`${res.message}`,
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                  })
            }
            dispatch({ type:COSTCENTER_LOAD, loading: false });
        }).then (
            ()=>{executeThisAfter();}
        )
        .catch((err)=>{
            console.error(err);
            
            customSwaltAlert({
                icon:'warning',
                title:'Intenta de nuevo',
                text:'No se pudo cambiar el estado',
                footer:'Si el problema persiste comuníquese con un asesor.',
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

            dispatch({ type:COSTCENTER_LOAD, loading: false });
        })
    }else{

        customSwaltAlert({
            icon:'warning',
            title:'Intenta de nuevo',
            text:'Razón Social no puede estar vacia',
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
    }
    
}
    
  

  export const costcenter_insert = (data, executeThisAfter) => (dispatch, getState)  => {
    const token = getState().loginReducer.Authorization;
    dispatch({ type:COSTCENTER_LOAD, loading: true });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/costcenter/`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        }
    })
    .then(response => response.json())
    .then(res => {
        if(res.status ===200){
            
            customSwaltAlert({
                icon:'success',
                title:'Creado exitosamente',
                text: `Se ha creado el centro de costos: ${data.description}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })

        }else{

            customSwaltAlert({
                icon:'warning',
                title:'Intenta de nuevo',
                text:`${res.message}`,
                confirmButtonText:'Aceptar',
                showCancelButton:false
              })
        }
        dispatch({ type:COSTCENTER_LOAD, loading: false });
    }).then (
        ()=>{executeThisAfter();}
    )
    .catch((err)=>{
        console.error(err);

        customSwaltAlert({
            icon:'warning',
            title:'Intenta de nuevo',
            text:'No se pudo crear el registro',
            footer:'Si el problema persiste comuníquese con un asesor.',
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        dispatch({ type:COSTCENTER_LOAD, loading: false });
    })
    
      
  }
