import React, { useEffect, useState } from "react";
import Lupa from "../../assets/img/icons/lupa.svg";
import generateTemplate from "../../assets/img/icons/darkBlueDownload.svg";
import GenericTable from "../../components/Layouts/GenericTableNew";
import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import { customSelectNewDark } from "../../components/Layouts/react-select-custom";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import paginationStyles from "../../components/Layouts/pagination.module.scss";
import Pagination from "react-js-pagination";
import Select from "react-select";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { URL_GATEWAY, API_VERSION } from "../../helpers/constants";
import { convertFilterToString } from "../../helpers/convertToFilter";
import ModalNew from "../../components/Layouts/ModalNew";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import {
  downloadExcel,
  formatToRcSelect,
  loader,
  message,
  validateEmptyString,
  genericDownloaFile,
  today,
} from "../../helpers/helpers";
import { getNiifAccounts } from "../../actions/pucActions";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

function getColorStatus(textStatus) {
  switch (textStatus) {
    case "loaded":
      return {
        status_es: "Borrador",
        color: "#58595B1A",
        fontcolor: "#58595B",
      };
    case "review":
      return {
        status_es: "En revisión",
        color: "#58595B1A",
        fontcolor: "#58595B",
      };
    case "approved":
      return {
        status_es: "Aprobada",
        color: "#00A5511A",
        fontcolor: "#00A551",
      };
    case "rejected":
      return {
        status_es: "Rechazada",
        color: "#EC1C241A",
        fontcolor: "#EC1C24",
      };
    case "processed":
      return {
        status_es: "Procesada",
        color: "#00A5511A",
        fontcolor: "#00A551",
      };
    case "cancelled":
      return {
        status_es: "Cancelada",
        color: "#EC1C241A",
        fontcolor: "#EC1C24",
      };
    default:
      return {
        status_es: textStatus,
        color: "#00A551201A",
        fontcolor: "#00A551",
      };
  }
}

function PaymentsTemplate(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector((state) => state);
  const counter = useSelector((state) => state);
  const myPermission =
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "plandepay"
    );
  const token = counter.loginReducer.Authorization;

  const { trigger: getListInfo, load: getListInfoLoader } = useGetMethod();
  const { load: locationsLoader, trigger: getPaymentTemplate } = useGetMethod();

  const {
    results: getBankingEntitiesResults,
    load: getBankingEntitiesLoader,
    trigger: getBankingEntitiesMethod,
  } = useGetMethod();

  const bankingOptions = formatToRcSelect(
    getBankingEntitiesResults?.results,
    "id",
    "description"
  );
  const [showPaymentDetail, setShowPaymentDetail] = useState({
    show: false,
    id: "",
    bankId: "",
    format: "",
    bankLabel: "",
    bank_number: "",
    sequence: "",
    transmission_date: "",
    application_date: "",
  });
  const store = useSelector((state) => state);
  const idAccount = store.loginReducer.currentAccount.id;
  // -------------------------------------------------------------------
  // funcion para formatear valores de respuesta
  const numberWithCommas = (x) => {
    if (x) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(".");
    } else {
      return x;
    }
  };
  const [step, setStep] = useState(1);
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilter] = useState({
    //search: "",
    page: 1,
    perpage: 12,
    //status: "",
    entity_account: counter.loginReducer.currentAccount.id,
    status: "approved",
    //area_id: "",
  });

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [foundedNiif, setFoundedNiif] = useState([
    { value: "", label: "Digite el nombre o número de la cuenta" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [nominaList, setNominaList] = useState([]);
  const [nominaTotal, setNominaTotal] = useState([]);
  const sequencyOptions = [
    { label: "Seleccionar...", value: "" },
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
    { label: "F", value: "F" },
    { label: "G", value: "G" },
    { label: "H", value: "H" },
    { label: "I", value: "I" },
    { label: "J", value: "J" },
    { label: "K", value: "K" },
    { label: "L", value: "L" },
    { label: "M", value: "M" },
    { label: "N", value: "N" },
    { label: "O", value: "O" },
    { label: "P", value: "P" },
    { label: "Q", value: "Q" },
    { label: "R", value: "R" },
    { label: "S", value: "S" },
    { label: "T", value: "T" },
    { label: "U", value: "U" },
    { label: "V", value: "V" },
    { label: "W", value: "W" },
    { label: "X", value: "X" },
    { label: "Y", value: "Y" },
    { label: "Z", value: "Z" },
  ];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const filtered = employees.filter((employee) =>
      employee.employee.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };

  const headersModal = [
    <th key={1} className="text-center px-2">
      <input
        type="checkbox"
        onChange={({ target }) => handleAllCheckboxChange(target.checked)}
        checked={allSelected}
      />
    </th>,
    <th
      key={2}
      style={{ flex: 3, paddingLeft: 14 }}
      className="pl-2 text-start pr-4"
    >
      empleado{" "}
    </th>,
  ];

  const handleFilter = (e) => {
    let nombreEncontrados;
    let expresion = new RegExp(`${e.toUpperCase()}.*`, "i");

    if (e.length > 0) {
      nombreEncontrados = storage?.pucReducer?.niifAccounts?.filter(
        (dat) =>
          expresion.test(dat.description) ||
          expresion.test(dat.complete_account)
      );
      setFoundedNiif(
        formatToRcSelect(
          nombreEncontrados,
          "id",
          "complete_account",
          "",
          "",
          "description"
        )
      );
    } else {
      setFoundedNiif([
        { value: "", label: "Digite el nombre o número de la cuenta" },
      ]);
    }
  };

  const renderTooltipPaymentDetail = () => (
    <Tooltip>
      <span style={{ color: "#c99489" }}>Generar planilla</span>
    </Tooltip>
  );
  useEffect(() => {
    dispatch(
      getNiifAccounts({
        eaccount: storage.loginReducer.currentAccount.id,
        active: 1,
        bank: 1,
      })
    );
    getBankingEntitiesMethod({
      url: "/admin/bankingEntities",
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((employeeId) => employeeId !== id)
        : [...prevSelected, id]
    );
  };

  const renderListModal = filteredEmployees.map((employee) => (
    <tr key={employee.id}>
      <td className="text-center px-2">
        <input
          type="checkbox"
          checked={selectedEmployees.includes(employee.id)}
          onChange={() => handleCheckboxChange(employee.id)}
        />
      </td>
      <td className={`text-start`} style={{ flex: 3, paddingLeft: 14 }}>
        {employee.employee}
      </td>
    </tr>
  ));

  useEffect(() => {
    fetchData();
  }, [filters.page, trigger]);

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
  }, []);

  useEffect(() => {
    if (step === 2) {
      const fetchData = async () => {
        try {
          const result = await getListInfo({
            url: "/payroll/payroll-template/",
            objFilters: {
              id: showPaymentDetail.id,
              banking_entity_id: showPaymentDetail.bankId,
              entity_account: counter.loginReducer.currentAccount.id,
            },
            token: store.loginReducer.Authorization,
          });
          if (!result.success) {
            customSwaltAlert({
              showCancelButton: false,
              icon: "warning",
              title: "Intenta de nuevo",
              text: result.message,
              confirmButtonText: "Aceptar",
            });
          } else {
            const mappedData = result.data.map((item) => ({
              id: item.candidate_id,
              employee: item.employee,
            }));
            setEmployees(mappedData);
            setFilteredEmployees(mappedData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const nextStep = () => {
    let validations = validateEmptyString([
      {
        field: showPaymentDetail.bankId,
        message: "La entidad bancaria es obligatoria para continuar",
      },
      {
        field: showPaymentDetail.format,
        message: "El formaro es obligatorio para continuar",
      },
    ]);

    if (validations.valid) {
      setStep(2);
    }
  };

  useEffect(() => {
    setAllSelected(
      employees.length > 0 && selectedEmployees.length === employees.length
    );
  }, [selectedEmployees, employees]);

  const handleAllCheckboxChange = () => {
    if (allSelected) {
      setSelectedEmployees([]);
    } else {
      const allEmployeeIds = employees.map((employee) => employee.id);
      setSelectedEmployees(allEmployeeIds);
    }
    setAllSelected(!allSelected);
  };

  //fetch zone -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const fetchData = async () => {
    setIsLoading(true);
    let str_filters = convertFilterToString(filters);
    const getMyStuff = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/planilla_nomina/?${str_filters}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        setNominaList(res.results);
        setNominaTotal(res.total);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setIsLoading(false);
      });
  };
  const searchFilters = () => {
    setFilter({
      ...filters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };
  const handlePageChange = (val) => {
    setFilter({
      ...filters,
      page: val,
    });
  };
  const header = [
    <th className="pl-1 col-2 text-start" style={{ paddingLeft: "10px" }}>
      Mes
    </th>,
    // <th className="pl-1 col-1 text-start">Año</th>,
    <th className="col-1 text-center">Periodo</th>,
    <th className="col-2 text-center">No. de empleados</th>,
    <th className="col-2 text-center">
      <p style={{ margin: 0, paddingRight: "15px", textAlign: "end" }}>Total</p>
    </th>,
    <th className="col-1 text-center">Estado</th>,
    <th className="col-1 text-center"></th>,
  ];
  const body = [];
  if (Array.isArray(nominaList)) {
    nominaList.map((item) => {
      const statuscolors = getColorStatus(item.status);
      const textMonth = isNaN(parseInt(item.payment_month))
        ? "-"
        : monthNames[parseInt(item.payment_month) - 1];
      body.push(
        <tr key={"payroll" + item.PS_ID}>
          <td className="text-start col-2" style={{ paddingLeft: "10px" }}>
            <b style={{ color: "#005DBF" }}>{textMonth}</b>
          </td>
          {/* <td className="text-start col-1">{item.payment_year}</td> */}
          <td className="text-center col-1">{item.range}</td>
          <td className="text-center col-2">{item.employee_number}</td>
          <td className="text-end col-2" style={{ paddingRight: "15px" }}>
            $ {numberWithCommas(item.total_amount)}
          </td>
          <td className="text-center col-1">
            <div
              className="rounded-pill p-1"
              style={{
                backgroundColor: statuscolors.color,
              }}
            >
              <b style={{ color: statuscolors.fontcolor }}>
                {statuscolors.status_es}
              </b>
            </div>
          </td>

          <td className="col-1">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipPaymentDetail()}
            >
              <img
                alt="icon"
                src={generateTemplate}
                className="cursorPointer"
                onClick={() =>
                  setShowPaymentDetail({
                    ...showPaymentDetail,
                    show: true,
                    id: item.PS_ID,
                  })
                }
              />
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  }
  let optionConsumptionCenter = [
    { value: "", label: "Seleccione área", name: "area" },
  ];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.map((item) => {
      optionConsumptionCenter.push({
        value: item.id,
        label: item.description,
      });
    });
  }
  const closePaymentDetailModal = () => {
    setShowPaymentDetail({
      ...showPaymentDetail,
      show: false,
      bankId: "",
      bankLabel: "",
      bank_number: "",
      id: "",
      transmission_date: "",
      application_date: "",
      sequence: "",
      format: "",
    });
    setStep(1);
    setEmployees([]);
    setSelectedEmployees([])
  };

  const getPaymentTemplateFunction = () => {
    const url =
      showPaymentDetail.format === "excel"
        ? "/payroll/payment_sheet_excel"
        : "/payroll/payment_sheet_txt";

    const body = {
      type_payroll: nominaList.find((obj) => obj.PS_ID === showPaymentDetail.id)
        .type_payroll,
      bank_id: showPaymentDetail.bankId,
      eaccount: idAccount,
      payroll_id: showPaymentDetail.id,
      bank_number: showPaymentDetail.bank_number,
      sequence: showPaymentDetail.sequence,
      transmission_date: showPaymentDetail.transmission_date,
      application_date: showPaymentDetail.application_date,
      account_type: showPaymentDetail.account_type,
      employees: selectedEmployees,
    };

    if (!selectedEmployees.length) {
      return customSwaltAlert({
        showCancelButton: false,
        icon: "warning",
        title: "Intenta de nuevo",
        text: "El empleado es obligatorio para generar",
        confirmButtonText: "Aceptar",
      });
    }

    if (selectedEmployees.length) {
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        showCancelButton: true,
        text: `Se generará una planilla de pago con la entidad bancaria ${showPaymentDetail.bankLabel} en formato ${showPaymentDetail.format}`,
        confirmButtonColor: "#005DBF",
        cancelButtonColor: "#fff",
        confirmButtonText: "Sí, continuar",
        cancelButtonText: "Cancelar",
      }).then((response) => {
        if (response.isConfirmed) {
          getPaymentTemplate({
            url: url,
            objFilters: body,
            token: token,
            doAfterSuccess: (data) => {
              if (showPaymentDetail.format === "excel") {
                downloadExcel(
                  data.results.base64,
                  data.results.filename.split(".").at(0),
                  true
                );
              } else {
                genericDownloaFile(
                  `data:text/plain;base64,${data.results.base64.split("'")[1]}`,
                  "planillaDePago.txt"
                );
              }
              setTrigger(trigger + 1);
              closePaymentDetailModal();
            },
            doAfterException: (results) => {
              customSwaltAlert({
                showCancelButton: false,
                icon: "warning",
                title: "Intenta de nuevo",
                text: results.message,
                confirmButtonText: "Aceptar",
              });
            },
          });
        }
      });
    }
  };

  const renderPrincipal = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: "3rem" }}
    >
      {(getBankingEntitiesLoader ||
        isLoading ||
        getListInfoLoader ||
        locationsLoader) &&
        loader}

      <ModalNew
        title={"Generar planilla de pago"}
        subtitle={`paso ${step} de 2`}
        show={showPaymentDetail.show}
        btnYesName={step === 1 ? "continuar" : "generar"}
        btnNoName={"Cancelar"}
        size={500}
        onHide={() => closePaymentDetailModal()}
        btnNoEvent={() => closePaymentDetailModal()}
        btnYesEvent={
          step === 1 ? () => nextStep() : () => getPaymentTemplateFunction()
        }
      >
        <div className="mb-4">
          {step === 1 && (
            <>
              <div className="mb-2">
                <Row className="d-flex px-1">
                  <label className={tableStyles.crudModalLabel}>
                    Entidad bancaria
                    <span className={"text-danger"}>*</span>
                  </label>
                  <Select
                    noOptionsMessage={() => "No hay datos"}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={customSelectNewDark}
                    options={bankingOptions}
                    value={bankingOptions.find(
                      (x) => x.value === showPaymentDetail.bankId
                    )}
                    onChange={(e) =>
                      setShowPaymentDetail({
                        ...showPaymentDetail,
                        bankId: e.value,
                        bankLabel: e.label,
                        bank_number: "",
                        application_date: "",
                        sequence: "",
                        transmission_date: "",
                        format: "",
                      })
                    }
                  />
                </Row>
              </div>

              <div className="mb-4">
                <Row className="d-flex px-1">
                  <label className={tableStyles.crudModalLabel}>
                    Formato
                    <span className={"text-danger"}>*</span>
                  </label>
                  <Select
                    noOptionsMessage={() => "No hay datos"}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={customSelectNewDark}
                    options={[
                      { label: "EXCEl", value: "excel" },
                      { label: "TXT", value: "txt" },
                    ]}
                    onChange={({ value }) => {
                      setShowPaymentDetail({
                        ...showPaymentDetail,
                        format: value,
                      });
                    }}
                  />
                </Row>
              </div>
            </>
          )}

          {step === 2 && (
            <Row>
              <Row className="d-flex px-1">
                <form
                  className="d-flex flex-grow-1 me-3 pt-2"
                  onSubmit={handleSearchSubmit}
                >
                  <input
                    placeholder="Buscar..."
                    className={`${tableStyles.SearchNew} w-50 me-2 `}
                    name="name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <button
                    type="submit"
                    style={{ border: "none", background: "none" }}
                  >
                    <img
                      style={{ width: "1.5rem" }}
                      src={Lupa}
                      alt="iconSearch"
                      className={`${tableStyles.iconSvgMargin2} pointer`}
                    />
                  </button>
                </form>
              </Row>
              <div
                className="fex w-100"
                style={{
                  height: 300,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                <GenericTable
                  headers={headersModal}
                  dark={true}
                  body={renderListModal}
                  typeHead={"2"}
                />
              </div>
            </Row>
          )}
        </div>
      </ModalNew>
      <div className="d-flex">
        <h1 className={tableStyles.title}>Planillas de pagos</h1>
      </div>
      {!!myPermission?.read && (
        <>
          <Row>
            <Col xs={4} className="d-flex gap-2">
              <div className="">
                <p className={tableStyles.crudModalLabel}>Año</p>
                <input
                  className="register-inputs"
                  type="number"
                  value={filters.year}
                  onChange={(e) =>
                    setFilter({
                      ...filters,
                      year: e.target.value,
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchFilters();
                    }
                  }}
                ></input>
              </div>
              <img
                className="hoverPointer align-self-end"
                alt="iconSearch"
                style={{}}
                src={Lupa}
                height="24px"
                onClick={(e) => searchFilters(e)}
              />
            </Col>
          </Row>
          <div></div>
          <GenericTable
            headers={header}
            children={body}
            dark={true}
          ></GenericTable>
          {nominaTotal > 12 ? (
            <div className={paginationStyles.wrapper}>
              <p className={` ${paginationStyles.paginationText} darkGray`}>
                pag. {nominaTotal ? filters.page : ""}
                {" de "}
                {Math.ceil(nominaTotal / filters.perpage)
                  ? Math.ceil(nominaTotal / filters.perpage)
                  : ""}{" "}
                ({" " + nominaTotal}
                {" encontrados"})
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={nominaTotal}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={`${paginationStyles.itemClassPrev} `}
                itemClassNext={`${paginationStyles.itemClassNext} `}
                itemClassFirst={`${paginationStyles.itemClassFirst} `}
                itemClassLast={`${paginationStyles.itemClassLast} `}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );

  return renderPrincipal;
}
export default PaymentsTemplate;
