import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import { useSelector } from "react-redux";
import FullLayout from './FullLayout';
import { ValidatePage } from "../validate";
import { listRoutes } from "../helpers";

function PrivateRoute({ children, ...rest }) {
    const store = useSelector((state) => state);
    const hasPermission = store?.loginReducer?.user_data?.active;

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (location.pathname === '/validate' && hasPermission) {
                    return (<Redirect
                        to={{
                            pathname: "/nomina/inicio",
                            state: { from: location },
                        }}
                    />);
                } else if (location.pathname !== '/validate' && hasPermission) {
                    return children;
                } else {
                    window.location.replace(`${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}/categoryselect`);
                }
            }}
        />
    );
}

const AppRoutes = () => {
    return (
        <Router>
            <Switch>
                <Route path={'/validate'}>
                    <ValidatePage />
                </Route>

                {listRoutes?.map(route => (
                    <PrivateRoute key={route.id} path={route.path} exact={route.exact}>
                        <FullLayout>
                            {route.component}
                        </FullLayout>
                    </PrivateRoute>
                ))}
            </Switch>
        </Router >
    );

};

export default AppRoutes;