import React, { useEffect, useState } from "react";
import Search from "../../../../assets/img/icons/lupa.svg";
import backArrow from "../../../../assets/img/icons/atras-icon.svg";
import note from "../../../../assets/img/icons/ordSugess.svg";
import excelIcon from "../../../../assets/img/icons/excelIcon.svg";
import { useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import "../../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid, Button, Tooltip } from "@mui/material";
import { loader, convertMoneyFormat, downloadExcel } from "../../../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../../../Hooks/useFetch";
import ModalNew from "../../../Layouts/ModalNew";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import TableScroll from "../../../Layouts/TableScroll/TableScroll";

export const TssReportDetail = ({ backPage, idTemplate, status, dataPayroll }) => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "tssReport");
  const idAccount = store.loginReducer.currentAccount.id;
  const history = useHistory();
  const [data, setData] = useState({
    trigger: 0,
    filters: {
      search: "",
      entity_account: idAccount,
      tssId: idTemplate,
    },
  });
  const { filters, trigger } = data;
  const { results: PremiumScheduleResults, load: premiumScheduleLoader, trigger: getPremiumSchedule } = useGetMethod();
  const { load: excelMethodLoader, trigger: getExcelMethod } = useGetMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
    getPremiumSchedule({
      url: "/payroll/tss_report_detail/",
      objFilters: filters,
      token: token,
      doAfterSuccess: (res) => {
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const getExcelFunction = () => {
    getExcelMethod({
      url: "/payroll/tss_report_detail_excel/",
      objFilters: {
        tssId: idTemplate,
        entity_account: idAccount
      },
      token: token,
      doAfterSuccess: (res) => {
        downloadExcel(res.results.base64, res.results.filename, true);

      },
    });
  };
  const bodyTable = () => {
    let tempArr = [];
    PremiumScheduleResults?.results?.length > 0 &&
      PremiumScheduleResults?.results?.forEach((elem, ind) => {
        tempArr.push(
          <tr key={elem.id}>
            <td className={`col-6 px-3 text-start fw-bold`}>
              <Row className="d-flex" >
                <Col xs={2} className="px-2 text-center" > {elem.type}</Col>
                <Col xs={5} className="px-2 text-center "> {elem.start_date ?? "-"}</Col>
                <Col xs={5} className="px-2 text-center">{elem.end_date ? elem.end_date === "0000-00-00"? "-" : elem.end_date : "-"} </Col>
              </Row>
            </td>
            <td className="col-2 text-center px-2">
              {elem?.description ?? "-"}
            </td>
            <td className="col-2 text-center px-2">
              {elem?.doc_number ?? "-"}
            </td>
            <td className="col-2 text-start px-2"> 
              {elem?.full_name ?? "-"}
            </td>
            <td className="col-2 text-start px-2"> 
              {elem?.position ?? "-"}
            </td>
            <td className="col-2 text-center px-2">
              {elem?.gender === "female" ? "Femenino" : "Masculino" ?? "-"}
            </td>
            <td className="col-2 text-center px-2">
              {elem?.birthdate ?? "-"}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.sdss_quotable_salary ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.sdss_voluntay_contribution ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.other_remuneration ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.rnc_ced_agent_ret ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.rem_others_agents ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.balance_favor_period ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.perks ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.food_withholding ?? "-")}

            </td>
            <td className="col-2 text-end px-2">
              {convertMoneyFormat(elem?.salary_infontep ?? "-")}

            </td>
          </tr>
        );
      });
    return tempArr;
  };
  const renderHeaders = [
    <th key={1} className="text-start col-6 px-3">
      <Row className="d-flex" style={{ width: 600 }}>
        <Col xs={2} className="px-2 text-center"> Tipo.</Col>
        <Col xs={5} className="px-2 text-center">F. inicio</Col>
        <Col xs={5} className="px-2 text-center">F. fin</Col>
      </Row>
    </th>,
    <th key={2} className="text-center col-2">
      Tipo doc
    </th>,
    <th key={2} className="text-center col-2">
      Número de documento
    </th>,
    <th key={2} className="text-start col-2">
      Nombres
    </th>,
    <th key={2} className="text-start col-2">
      Cargo
    </th>,
    <th key={2} className="text-center col-2">
      Sexo
    </th>,
    <th key={2} className="text-center col-2">
      F. nacimien.
    </th>,
    <th key={2} className="text-end col-2">
      Salario cotizable SDSS
    </th>,
    <th key={2} className="text-end col-2">
      Aporte voluntario SDSS
    </th>,
    <th key={2} className="text-end col-2">
      Otras remuneraciones
    </th>,
    <th key={2} className="text-end col-2">
      RNC/Céd. Agente Ret.
    </th>,
    <th key={2} className="text-end col-2">
      Rem. otros Agentes
    </th>,
    <th key={2} className="text-end col-2">
      Saldo a favor del período
    </th>,
    <th key={2} className="text-end col-2">
      Regalía pascual (saldo13)
    </th>,
    <th key={2} className="text-end col-2">
      Retención p. alimenticia
    </th>,
    <th key={2} className="text-end col-2">
      Salario INFOTEP
    </th>,
  ];

  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: "",
  });

  const closeCommentModal = () => {
    setCommentModal({
      ...commentModal,
      show: false,
      cancelledReason: ""
    });
  };

  return (
    <>
      {(excelMethodLoader || premiumScheduleLoader || updatePayrollLoader) && loader}
      <ModalNew
        title="Comentarios"
        show={commentModal.show}
        // btnYesName={showModalRejected.justify !== "" && "Guardar"}
        btnNoName={"Cerrar"}
        size="400"
        btnYesEvent={null}
        btnYesDisabled={true}
        onHide={() =>
          closeCommentModal()
        }
        btnNoEvent={() =>
          closeCommentModal()
        }
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>
          Comentario
        </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          disabled={true}
          defaultValue={commentModal.comment}
          style={{
            height: "4rem", borderRadius: "5px", border: "1.5px solid #01A0F6", padding: "0 0.05rem",
            width: "100%"
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>

      <div className="w-90 mx-auto">
        <h1
          className={`${tableStyles.title} d-flex gap-2 align-items-center`}
          style={{ marginLeft: "-30px" }}
        >
          <img
            onClick={() => {
              backPage.setData(state => ({ ...state, generatePremiumSchedule: false, trigger: state.trigger + 1 }));
            }}
            src={backArrow}
            alt="backArrow"
            className={`pointer`}
            height="30px"
          />
          <span className={`${tableStyles.titleAdministrative}`}>Reporte TSS {dataPayroll.year}</span>
          {status === "rejected" && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={() => <Tooltip>
                <span> Añadir comentario </span>
              </Tooltip>}
            >
              <img
                alt="goal"
                src={note}
                className={`align-self-center mx-2 ${tableStyles.svgFilterDarkBlue} cursorPointer`}
                onClick={() => {
                  setCommentModal({ ...commentModal, show: true, comment: dataPayroll?.comment ?? "" });
                }}
                height="30px"
                width="30px"
              />
            </OverlayTrigger>)}
        </h1>

        <Row
        >

          <Col
            item
            xs={6}
            className="d-flex align-items-end justify-content-end"
          >
            <input
              className={`${tableStyles.blueSearch} mr-2`}
              style={{ flex: 1, color: `#73a6dc` }}
              name="search"
              type="text"
              placeholder="Buscar..."
              value={filters.search}
              onChange={(e) => setData((state) => ({ ...state, filters: { ...state.filters, search: e.target.value } }))}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setData((state) => ({ ...state, trigger: state.trigger + 1 }));
                }
              }}
            />

            <img
              src={Search}
              style={{ cursor: "pointer" }}
              alt="Search icon"
              onClick={() => {
                setData((state) => ({ ...state, trigger: state.trigger + 1 }));
              }}
            />
          </Col>
          <Col
            item
            xs={6}
            className="d-flex align-items-end justify-content-end"
          >
            <img
              src={excelIcon}
              style={{ cursor: "pointer" }}
              alt="Search icon"
              onClick={() => {
                getExcelFunction();
              }}
            />
          </Col>
        </Row>

        <Row className="py-4">
          <TableScroll
            body={bodyTable()}
            headers={renderHeaders}
          ></TableScroll>
        </Row>

        <Grid className="d-flex justify-content-end gap-3 mt-3">
          <Button
            style={{ textTransform: "none" }}
            className={tableStyles.btnCancelStyle}
            onClick={() => {
              backPage.setData(state => ({ ...state, generatePremiumSchedule: false, trigger: state.trigger + 1 }));
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </div>
    </>
  );
};
