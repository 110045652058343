import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const URL_GATEWAY =
process.env.REACT_APP_URL_PROTOCOL +
process.env.REACT_APP_URL_BASE +
process.env.REACT_APP_URL_GATEWAY;
export const DEFAULT_COUNTRY = process.env.REACT_APP_COUNTRY
//Filtros por defecto
export const PERPAGE = 10;
export const PAGE = 1;
export const defaultFilters = {
  perpage: 10,
  page: 1,
  search: "",
};

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

export const CURDATE = yyyy + "-" + mm + "-" + dd;

export const MAX_SIZE_FILE = 5000000;
// Regex for inputs files, valid to format file
export const REGEX_VALID_PDF_TYPE = /^.+\.(([pP][dD][fF]))$/;
export const REGEX_VALID_EXCEL_TYPE = /^.+\.(xlsx|xls|csv)$/g;
export const REGEX_VALID_WORD_TYPE = /^.+\.(doc|docx)$/g;

export const MULTIPLE_REGEX_VALID_FILE =
  /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;

export const FILE_ACCEPT_PROVIDER =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, \
.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
application/pdf";
// provider type like Enumns
export const ProviderType = {
  legal_person: "Persona Jurídica",
  natural: "Persona natural",
};

export const ProviderTypeShort = {
  legal_person: "p. jurídica",
  natural: "p. natural",
};
Object.freeze(ProviderType);

export const ProviderStatus = {
  incomplete: "Incompleto",
  enabled: "Habilitado",
  disabled: "Deshabilitado",
};
Object.freeze(ProviderStatus);

export const CompantyTypes = {
  private: "Privada",
  public: "Pública",
  mix: "Mixta",
  nonprofit: "Sin ánimo de lucro",
};
Object.freeze(CompantyTypes);

export const SocietyTypes = {
  other: "Otra",
  anonymous: "Anónimas",
  limited: "Limitada",
  sas: "SAS",
};
Object.freeze(SocietyTypes);

export const regexNumbers = /^[0-9]*$/;

export const BankAccountTypes = {
  current: "Corriente",
  investmentFunds: "Fondos de inversión",
  savings: "Ahorros",
  fiduciaries: "Fiduciarias",
};

export const REGEX_IMAGES = /\.(gif|jpe?g|tiff?|png|webp|bmp|jpg)$/i;

export const ACCEPT_IMAGES = "image/png, image/jpeg";

////////////////////// Template body ///////////////////////////////////////////

export const bodyTemplate = (colums) => {
  let body = [];
  for (let i = 0; i < 10; i++) {
    let bodyTemp = (
      <section2 className={`d-flex`}>
        <div className={`col-5`}>-</div>
        <div className={`col-5`}>-</div>
        <div className={`col-1`}>-</div>
        <div className={`col-1`}>-</div>
      </section2>
    );
    body.push(bodyTemp);
  }
  return body;
};

export const bloodType = [
  { value: "", label: "Seleccionar..." },
  { value: "O", label: "O" },
  { value: "AB", label: "AB" },
  { value: "B", label: "B" },
  { value: "A", label: "A" },
];
export const genderList = [
  { value: "", label: "Seleccionar..." },
  { value: "male", label: "Masculino" },
  { value: "female", label: "Femenino" },
];
export const shirtSize = [
  { value: "", label: "Seleccionar..." },
  { value: "XS", label: "XS" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
];
export const months = [
  { value: "", label: "Seleccionar..." },
  { value: 1, label: "Enero" },
  { value: 2, label: "Febrero" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  { value: 12, label: "Diciembre" },
];

export const maritalStatusList = [
  { value: "", label: "Seleccionar..." },
  { value: "single", label: "Soltero(a)" },
  { value: "married", label: "Casado(a)" },
  { value: "divorced", label: "Divorciado(a)" },
  { value: "widowed", label: "Viudo(a)" },
  { value: "freeunion", label: "Unión libre" },
  // { value: "nocommit", label: "No séeee" },
];
export const parentList = [
  { value: "", label: "Seleccionar..." },
  { value: "partner", label: "Pareja" },
  { value: "parent", label: "Padre/Madre" },
  { value: "son", label: "Hijo(a)" },
  { value: "sibling", label: "Hermano(a)" },
  { value: "others", label: "Otros" },
  // { value: "nocommit", label: "No séeee" },
];

export const typeIdList = [
  { label: 'C.C', value: 'cc' },
  { label: 'T.I', value: 'ti' },
  { label: 'C.E', value: 'ce' },
  { label: 'R.C', value: 'rc' },
  { label: 'M.S', value: 'ms' },
  { label: 'A.S', value: 'as' },
  { label: 'N.U', value: 'nu' },
  { label: 'P.A', value: 'pa' },
]

export const genderList2 = [
  { value: "male", label: "Masculino" },
  { value: "female", label: "Femenino" },
];


export const multiHorizontalBarOptions = (props) => {
  const barOptions = {
    legend: {
      position: props?.legendPosition ? props.legendPosition : "top",
    },
    colors: props?.colors ? props?.colors : ["#005DBF", "#9C27B0"],

    theme: {
      monochrome: props?.monochrome
        ? {
          enabled: true,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        }
        : {},
    },

    chart: {
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },

    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      show: false,
      categories: props?.categories
        ? props?.categories
        : [2001, 2002, 2003, 2004, 2005, 2006, 2007],
    },
  };
  return barOptions;
};

export const donutOptions = (props) => {
  const donutOptions = {
    donut: {
      size: "100%",
    },
    theme: {
      palette: "palette2", // upto palette10
    },
    colors: props?.colors
      ? props?.colors
      : [
        "#43AA8B",
        "#90BE6D",
        "#FFD500",
        "#FF8B00",
        "#00296B",
        "#8D5B4C",
        "#F86624",
        "#449DD1",
        "#A300D6",
        "#546E7A",
        "#A5978B",
        "#449DD1",
        "#2E294E",
        "#FF4560",
      ],
    fill: {
      // colors: [
      //   "#00296B",
      //   "#3180DC",
      //   "#9C27B0",
      //   "#43AA8B",
      //   "#90BE6D",
      //   "#FFD500",
      //   "#FFA200",
      //   // "red",
      // ],
    },
    legend: {
      position: props?.legendPosition ? props.legendPosition : "bottom",
    },
    options: {
      chart: {
        id: "apexchart-example",
      },
    },

    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: "50%",
          background: "transparent",
          labels: {
            show: false,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: false,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: false,
              showAlways: true,
              label: "Total",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#373d3f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    labels: props?.labels
      ? props.labels
      : ["Uno", "Dos", "Tres", "Cuatro", "Cinco"],
  };
  return donutOptions;
};

export const singleLineOptions = (props) => {
  const options = {
    stroke: {
      curve: "straight",
    },
    //RECEIVES AN ARRAY OF COLOR PATTERN
    colors: props?.colors ? props?.colors : ["#005DBF", "#9C27B0"],
    title: {
      align: "left",
    },
    markers: {
      size: 0,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: props?.categories
        ? props.categories
        : [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Sun",
        ],
      title: {
        text: props?.bottomTitle ? props.bottomTitle : "Inserte un bottomTitle",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "gray",
          fontSize: "16px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ",",
          headerCategory: "category",
          headerValue: "value",
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString();
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
      autoSelected: "zoom",
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,

      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["gray"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },

      title: {
        text: props?.leftTitle ? props.leftTitle : "Inserte un leftTitle",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "gray",
          fontSize: "16px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      tooltip: {
        enabled: true,
        offsetX: 0,
      },
    },
  };

  return options;
};
export const doubleLineAreaOptions = (props) => {
  const options = {
    legend: {
      show: props?.hideLegend ? false : true,
      position: props?.legendPosition ? props.legendPosition : "top",
    },
    series: props?.series ? props.series : [],
    stroke: {
      curve: props?.stroke ? props?.stroke : "smooth",
    },
    //RECEIVES AN ARRAY OF COLOR PATTERN
    colors: props?.colors ? props?.colors : ["#005DBF", "#9C27B0"],

    markers: {
      size: 0,
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: props?.categories ? props.categories : [],
      title: {
        text: props?.bottomTitle ? props.bottomTitle : "Inserte un bottomTitle",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "gray",
          fontSize: "16px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },

    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,

      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["gray"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },

      title: {
        text: props?.leftTitle ? props.leftTitle : "Inserte un left title",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "gray",
          fontSize: "16px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      tooltip: {
        enabled: true,
        offsetX: 0,
      },
    },
  };

  return options;
};
export const multiVerticalBarOptions = (props) => {
  const barOptions = {
    legend: {
      show: props?.hideLegend ? false : true,
      position: props?.legendPosition ? props.legendPosition : "top",
    },
    colors: props?.colors ? props?.colors : ["#005DBF", "#9C27B0"],

    // theme: {
    //   monochrome: {
    //     enabled: true,
    //     color: "#255aee",
    //     shadeTo: "light",
    //     shadeIntensity: 0.65,
    //   },
    // },
    labels: {
      show: false,
    },

    chart: {
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        endingShape: "rounded",
      },
    },

    grid: {
      yaxis: {
        show: false,
        lines: {
          show: false,
        },
      },
      xaxis: {
        show: false,
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: props?.categories ? props?.categories : [""],
    },
    yaxis: {
      show: false,
    },
  };
  return barOptions;
};
export const verticalBarOptions = (props) => {
  const barOptions = {
    series: [
      {
        name: "Inflation",
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      title: {
        text: "Monthly Inflation in Argentina, 2002",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };
  return barOptions;
};
export const multVerticalOptions = (props) => {
  const percentBar = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    xaxis: {
      type: "datetime",
      categories: props?.categories
        ? props.categories
        : [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
    },
    legend: {
      position: props?.legendPosition ? props?.legendPosition : "top",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };
  return percentBar;
};

export const highChartHorizontalLine = (props) => {
  const horizontalBar = {
    chart: {
      type: "bar",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      show: false,
    },
    xAxis: {
      categories: props?.categories ? props?.categories : [],
      title: {
        text: null,
      },
    },
    colors: props?.colors ? props.colors : ["#01A0F6", "#FFD500"],
    yAxis: {
      visible: false,

      min: 0,
      title: {
        text: "Millones",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      valueSuffix: "Millones",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: "vertical",
      align: props?.legendPosition ? props.legendPosition : "right",
      verticalAlign: "top",
      // x: -40,
      // y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: props?.series ? props?.series : [],
  };
  return horizontalBar;
};

export const highChartVerticalBars = (props) => {
  const verticalBars = {
    colors: props?.colors ? props.colors : ["#01A0F6"],
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      // text: "Plain",
    },
    xAxis: {
      categories: props?.categories ? props.categories : [],
    },
    yAxis: {
      title: {
        text: "Cantidades",
      },
    },
    series: props?.series ? props.series : [],
    chart: {
      type: "column",
    },
  };
  return verticalBars;
};

export const higChartMultiVerticalOneLine = (props) => {
  const multVericalOneLine = {
    chart: {
      type: "column",
      height: props?.height,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: props?.categories
        ? props?.categories
        : ["Apples", "Oranges", "Pears", "Grapes", "Bananas"],
    },
    yAxis: {
      min: 0,
      title: {
        text: props?.leftTitle ? props.leftTitle : "leftTitle",
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "percent",
      },
    },
    series: props?.series
      ? props?.series
      : [
        {
          name: "John",
          data: [5, 3, 4, 7, 2],
        },
        {
          name: "Jane",
          data: [2, 2, 3, 2, 1],
        },
        {
          name: "Joe",
          data: [3, 4, 4, 2, 5],
        },
      ],
  };
  return multVericalOneLine;
};

export const highChartVerticalLines = (props) => {
  const verticalLines = {
    chart: {
      type: "column",
      inverted: props?.inverted ? true : false,
      height: props?.height,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    colors: ["#003F80"],
    xAxis: {
      visible: props?.xAxisVisible ? true : false,
      type: "category",
      labels: {
        // rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      visible: props?.yAxisVisible ? true : false,
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    // tooltip: {
    //   pointFormat: "Population in 2017: <b>{point.y:.1f} millions</b>",
    // },
    series: [
      {
        name: props?.seriesName ? props.seriesName : "Nombre de la serie=>",
        data: props?.series ? props.series : [],

        dataLabels: {
          enabled: false,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // one decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
    ],
  };

  return verticalLines;
};
export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
export const AntSwitchBlue = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#00B4CC",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));


export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#01a0f6',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export const listDepotType = [
  { label: 'Seleccionar...', value: '' },
  { label: 'Billete', value: 'cash' },
  { label: 'Moneda', value: 'coin' },
  { label: 'Cripto', value: 'cripto' },
]

export const StatesType = [
  {label:'Seleccionar...', value:''},
  {label:'Habilitado', value:'1'},
  {label:'Inhabilitado', value:'0'},
]

export const depositType = [
  { label: 'Efectivo', value: 'cash' },
  { label: 'Transferencia', value: 'transfer' },
  { label: 'Tarjeta de crédito', value: 'tc' },
]

export const listCollections = ['transfer','cash','card']