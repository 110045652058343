/* ----------------------------------------------------------------------------- */
/*                               Routes Nomina                      */
/* ----------------------------------------------------------------------------- */
import { Inhabilities } from '../components/ApprovedNews/Inhabilities';
import { OverTimeList } from '../components/ApprovedNews/OverTimeList';
import { Permissions } from '../components/ApprovedNews/Permissions';
// import NewsSheet  from '../components/ApprovedNews/newsSheet';
import { Vacations } from '../components/ApprovedNews/Vacations';
import ListContracts from '../components/PayRollModule/Hiring/Contract';
import CreateContract from '../components/PayRollModule/Hiring/CreateContract';
import Dependencies from '../components/PayRollModule/Hiring/Dependencies';
import { NewPosition } from '../components/PayRollModule/Hiring/NewPosition';
import { OtrosiHistory } from '../components/PayRollModule/Hiring/OtrosiHistory';
import { PositionTable } from '../components/PayRollModule/Hiring/PositionTable';
import { Settlement } from '../components/PayRollModule/Hiring/Settlement';
import { AccordionForm } from '../components/PayRollModule/partners/AccordionForm';
import { CurriculumDetail } from '../components/PayRollModule/partners/CurriculumDetail';
import { NewPartner } from '../components/PayRollModule/partners/NewPartner';
import { PeopleMasterTable } from '../components/PayRollModule/partners/PeopleMasterTable';
import DetailAnswerSurvey from '../components/PayRollModule/partners/PerformanceAppraisal/DetailAnswerSurvey';
import DetailSurveyPerf from '../components/PayRollModule/partners/PerformanceAppraisal/DetailSurveyPerf';
import PerformanceAppraisal from '../components/PayRollModule/partners/PerformanceAppraisal/PerformanceAppraisal';
import SurveyPerf from '../components/PayRollModule/partners/PerformanceAppraisal/SurveyPerf';
import PaymentsTemplate from '../components/PaymentsTemplate/PaymentsTemplate';
import { Endowments } from '../components/Payroll/Endowments';
import MedicalExam from '../components/Payroll/Medical/MedicalExam';
import MedicalExamDetail from '../components/Payroll/Medical/MedicalExamDetail';
import PlanningMedicalExams from '../components/Payroll/Medical/PlanningMedicalExams';
import { CreateSurvey } from '../components/SecAndHealth/CreateSurvey';
import { NewSurvey } from '../components/SecAndHealth/NewSurvey';
import { WorkEnv } from '../components/SecAndHealth/WorkEnv';
import PayrollSeveranceGenerate from '../components/SeverancePayroll/PayrollSeveranceGenerate';
import { SeverancePayroll } from '../components/SeverancePayroll/SeverancePayroll';
import ReportComplaintDetail from '../components/TableComplaints/ReportComplaintDetail';
import Complaint from '../components/TableComplaints/TableComplaints';
import DisciplinaryProcesses from '../components/TableDisciplinaryProcesses/DisciplinaryProcesses';
import { NewDisciplinaryProcess } from '../components/TableDisciplinaryProcesses/NewDisciplinaryProcess';
import { ProcessDetail } from '../components/TableDisciplinaryProcesses/ProcessDetail';
import AccidentDetails from '../components/WorkAccidents/AccidentDetails';
import RegisterAccident from '../components/WorkAccidents/RegisterAccident';
import { WorkAccidents } from '../components/WorkAccidents/WorkAccidents';
import WorkCertificate from '../components/WorkCertificate/WorkCertificate';
import { BonusSheet } from '../components/payrollRD/payroll/bonusSheet/BonusSheet';
import { RoyaltyPayment } from '../components/payrollRD/payroll/royaltyPayment/RoyaltyPayment';
import GeneratePayrollList from '../components/GeneratePayroll/generatePayrollList';

/* ----------------------------------------------------------------------------- */
/*                               Routes miNomina                      */
/* ----------------------------------------------------------------------------- */
import { MyCv } from '../components/MyPayroll/MyCv';
import { Requests } from '../components/MyPayroll/Requests';
import MiNomina from '../components/Payroll/MiNomina/MiNomina';
import { AuthPermissions } from '../components/AreaManagement/AuthPermissions';
import { VacationsMyPayRoll } from '../components/AreaManagement/VacationsMyPayRoll';
import AnswerSurvey from '../components/Payroll/MiNomina/performanceSurvey/AnswerSurvey';
import SurveyDetail from '../components/Payroll/MiNomina/performanceSurvey/SurveyDetail';
import WorkCertificateRequest from '../components/WorkCertificate/WorkCertificateRequest';
import { MyPayrollOverTimeList } from '../components/AreaManagement/MyPayrollOverTimeList';

import {
    PremiumAuthorization,
    PremiumSchedule,
    Provisions,
    SeveranceAuthorization,
    SocialSecuritySchedule,
  } from '../components/PayRollModule/';

import UserProfile from '../components/userProfile/UserProfile';
import { PayrollHomeContent } from "../components/Home/AlternativeHome/PayrollHomeContent";
import { TssReport } from "../components/payrollRD/payroll/tssreport/TssReport";

export const listRoutes = [
    {
        id: 0,
        exact: true,
        path: '/categoryselect',
        component: <PayrollHomeContent />,
    },
    {
        id: 1,
        exact: true,
        path: '/nomina/inicio',
        component: <PayrollHomeContent />,
    },
    {
        id: 2,
        exact: true,
        path: '/nomina/CartaLaboral',
        component: <WorkCertificate />,
    },
    {
        id: 3,
        exact: true,
        path: '/nomina/dotaciones',
        component: <Endowments />,
    },
    {
        id: 4,
        exact: true,
        path: '/nomina/contratos',
        component: <ListContracts />,
    },
    {
        id: 5,
        exact: true,
        path: '/nomina/contratos/historialOtrosi',
        component: <OtrosiHistory />,
    },
    {
        id: 6,
        exact: true,
        path: '/nomina/dependencias',
        component: <Dependencies />,
    },
    {
        id: 7,
        exact: true,
        path: '/nomina/CrearContrato',
        component: <CreateContract />,
    },
    {
        id: 8,
        exact: true,
        path: '/nomina/tmPersonal',
        component: <PeopleMasterTable />,
    },
    {
        id: 9,
        exact: true,
        path: '/nomina/tmPersonal',
        component: <PeopleMasterTable />,
    },
    {
        id: 10,
        exact: true,
        path: '/nomina/planillaDePrimas',
        component: <PremiumSchedule />,
    },
    {
        id: 11,
        exact: true,
        path: '/nomina/planillaRegaliasPascual',
        component: <RoyaltyPayment />,
    },
    {
        id: 12,
        exact: true,
        path: '/nomina/planillaDeBonificacion',
        component: <BonusSheet />,
    },
    {
        id: 13,
        exact: true,
        path: '/nomina/autorizacionPrimas',
        component: <PremiumAuthorization />,
    },
    {
        id: 14,
        exact: true,
        path: '/nomina/autorizacionCesantias',
        component: <SeveranceAuthorization />,
    },
    {
        id: 15,
        exact: true,
        path: '/nomina/planillaDeSeguridadSocial',
        component: <SocialSecuritySchedule />,
    },
    {
        id: 16,
        exact: true,
        path: '/nomina/planillaDeNomina',
        component: <GeneratePayrollList />,
    },
    {
        id: 17,
        exact: true,
        path: '/nomina/provisionesDeNomina',
        component: <Provisions />,
    },
    {
        id: 18,
        exact: true,
        path: '/nomina/planillaDeCesantias',
        component: <SeverancePayroll />,
    },
    {
        id: 19,
        exact: true,
        path: '/nomina/planillaDeCesantias/planilla',
        component: <PayrollSeveranceGenerate />,
    },
    {
        id: 20,
        exact: true,
        path: '/nomina/colaborador/nuevo',
        component: <NewPartner />,
    },
    {
        id: 21,
        exact: true,
        path: '/nomina/colaborador/curriculo',
        component: <AccordionForm />,
    },
    {
        id: 22,
        exact: true,
        path: '/nomina/colaborador/detalleCurriculo',
        component: <CurriculumDetail />,
    },
    {
        id: 23,
        exact: true,
        path: '/nomina/cargos',
        component: <PositionTable />,
    },
    {
        id: 24,
        exact: true,
        path: '/nomina/nuevoCargo',
        component: <NewPosition />,
    },
    {
        id: 25,
        exact: true,
        path: '/nomina/planillaDePago',
        component: <PaymentsTemplate />,
    },
    {
        id: 26,
        exact: true,
        path: '/nomina/EvaluacionDeDesempeño',
        component: <PerformanceAppraisal />,
    },
    {
        id: 27,
        exact: true,
        path: '/nomina/EvaluacionDeDesempeño/CrearEvaluacion',
        component: <SurveyPerf />,
    },
    {
        id: 28,
        exact: true,
        path: '/nomina/EvaluacionDeDesempeño/Evaluacion',
        component: <DetailSurveyPerf />,
    },
    {
        id: 29,
        exact: true,
        path: '/nomina/EvaluacionDeDesempeño/Evaluacion/DetalleRespuesta',
        component: <DetailAnswerSurvey />,
    },
    {
        id: 30,
        exact: true,
        path: '/nomina/seguridadYSalud/accidentesLaborales',
        component: <WorkAccidents />,
    },
    {
        id: 31,
        exact: true,
        path: '/nomina/seguridadYSalud/accidentesLaborales/detalle/:id',
        component: <AccidentDetails />,
    },
    {
        id: 32,
        exact: true,
        path: '/nomina/seguridadYSalud/accidentesLaborales/registrar',
        component: <RegisterAccident />,
    },
    {
        id: 33,
        exact: true,
        path: '/nomina/seguridadYsalud/climaLaboral',
        component: <WorkEnv />,
    },
    {
        id: 34,
        exact: true,
        path: '/nomina/seguridadYsalud/registrarEncuesta',
        component: <NewSurvey />,
    },
    {
        id: 35,
        exact: true,
        path: '/nomina/seguridadYsalud/crearEncuesta',
        component: <CreateSurvey />,
    },
    {
        id: 36,
        exact: true,
        path: '/nomina/ReporteQuejas',
        component: <Complaint />,
    },
    {
        id: 37,
        exact: true,
        path: '/nomina/examenesMedicos',
        component: <MedicalExam />,
    },
    {
        id: 38,
        exact: true,
        path: '/nomina/examenesMedicosDetalles',
        component: <MedicalExamDetail />,
    },
    {
        id: 39,
        exact: true,
        path: '/nomina/examenesMedicos/planificacionExamenes',
        component: <PlanningMedicalExams />,
    },
    {
        id: 40,
        exact: true,
        path: '/nomina/ReporteQuejas/Detalle',
        component: <ReportComplaintDetail />,
    },
    {
        id: 41,
        exact: true,
        path: '/nomina/nuevoProcesoDisciplinario',
        component: <NewDisciplinaryProcess />,
    },
    {
        id: 42,
        exact: true,
        path: '/nomina/NovedadesAprobadas/horasExtra',
        component: <OverTimeList />,
    },
    {
        id: 43,
        exact: true,
        path: '/nomina/NovedadesAprobadas/permisos',
        component: <Permissions />,
    },
    // {
    //     id: 44,
    //     path: '/nomina/NovedadesAprobadas/PlanillaDeDevengados',
    //     component: <NewsSheet />,
    // },
    // {
    //     id: 45,
    //     path: '/nomina/NovedadesAprobadas/PlanillaDeDevengados/:year/:month',
    //     component: <NewSheetDetail />,
    // },
    {
        id: 46,
        exact: true,
        path: '/nomina/NovedadesAprobadas/vacaciones',
        component: <Vacations />,
    },
    {
        id: 47,
        exact: true,
        path: '/nomina/NovedadesAprobadas/Incapacidades',
        component: <Inhabilities />,
    },
    {
        id: 48,
        exact: true,
        path: '/nomina/ProcesosDisciplinarios/Detalle',
        component: <ProcessDetail />,
    },
    {
        id: 49,
        exact: true,
        path: '/nomina/liquidacion',
        component: <Settlement />,
    },
    {
        id: 50,
        exact: true,
        path: '/nomina/procesosDisciplinario',
        component: <DisciplinaryProcesses />,
    },
    {
        id: 51,
        exact: true,
        path: '/miNomina/miNomina/SolCartaLaboral',
        component: <WorkCertificateRequest />,
    },
    {
        id: 52,
        exact: true,
        path: '/miNomina/miNomina/hojaDeVida',
        component: <MyCv />,
    },
    {
        id: 53,
        exact: true,
        path: '/miNomina/miNomina/registros',
        component: <MiNomina />,
    },
    {
        id: 54,
        exact: true,
        path: '/miNomina/miNomina/registros/detalleDeEvaluacion',
        component: <SurveyDetail />,
    },
    {
        id: 55,
        exact: true,
        path: '/miNomina/miNomina/registros/detalleDeEvaluacion/responderEvaluacion',
        component: <AnswerSurvey />,
    },
    {
        id: 56,
        exact: true,
        path: '/miNomina/aprobarHorasExtra',
        component: <MyPayrollOverTimeList />,
    },
    {
        id: 57,
        exact: true,
        path: '/miNomina/autorizarPermisos',
        component: <AuthPermissions />,
    },
    {
        id: 58,
        exact: true,
        path: '/miNomina/aprobarIncapacidades',
        component: <AuthPermissions />,
    },
    {
        id: 59,
        exact: true,
        path: '/miNomina/autorizarVacaciones',
        component: <VacationsMyPayRoll />,
    },
    {
        id: 60,
        exact: true,
        path: '/miNomina/miNomina/solicitudes',
        component: <Requests />,
    },
    {
        id: 61,
        exact: true,
        path: '/configuracion/miperfil',
        component: <UserProfile />,
    },
    {
        id: 62,
        exact: true,
        path: '/nomina/reporteTSS',
        component: <TssReport />,
    }
];