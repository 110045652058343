import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "reactjs-popup/dist/index.css";
import {
  BodyContent,
  Container,
  Description,
  Section,
  Timeline,
  YearContent,
} from "vertical-timeline-reactjs";
import {
  getAnnotations,
  getCandidateInfo,
  getDisciplinaryProcess,
  getMedicalInfo,
} from "../../actions/partnersActions";
import Ellipse from "../../assets/img/icons/Ellipse.svg";
import anonymus from "../../assets/img/icons/anonymus.svg";
import circleGreenCheck from "../../assets/img/icons/circleGreenCheck.svg";
import link from "../../assets/img/icons/modalsearch.svg";
import ModalNew from "../../components/Layouts/ModalNew";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { customSwaltAlert } from "../../helpers";
import {
  convertDateToLatinFormat,
  convertMoneyFormat,
  isEmptyOrUndefined,
  placeMyIcon
} from "../../helpers/helpers";
import { DialogModalFullScreen } from "../Layouts/DialogModalFullScreen";
import { PdfViewer } from "../Layouts/PdfViewer";
import tableStyles from "../Layouts/tableStyle.module.scss";
import "../TableUsers/tableUserStyle.scss";
import { DiscountsAndPayout } from "./DiscountsAndPayouts";

export const MyCv = () => {
  const store = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [slider, setSlider] = useState({
    infoGen: true,
    attached: false,
    disciplinaryProcess: false,
    annotations: false,
    medicalExams: false,
    examns: false,
    accruedAndDeductions: false,
  });


  useEffect(() => {
    if (!myPermission?.read) {
      history?.push("/configuracion/miperfil");
    }
    if (Object.keys(store?.loginReducer?.user_data?.payrollInfo).length === 0){
      customSwaltAlert({
        icon: "warning",
        title: "Advertencia",
        text: `El usuario no posee un contrato vigente en la cuenta ${store.loginReducer.currentAccount.name.toLowerCase()}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(({isConfirmed})=>{
        if(isConfirmed){
          history.push("/nomina/inicio")
        }
      })
    }

    if (slider.infoGen) {
      dispatch(getCandidateInfo({ id_candidate, id_contract, generalInfo: 1 }));
    } else if (slider.attached) {
      dispatch(getCandidateInfo({ id_candidate, id_contract }));
    } else if (slider.annotations) {
      dispatch(getAnnotations({ id_candidate, id_contract, annotations: 1 }));
    }
  }, [slider]);

  useEffect(() => {
    dispatch(getAnnotations({ annotations: 1, id_contract, id_candidate }));
    dispatch(
      getMedicalInfo({
        candidate: id_candidate,
        entity_account: idEnterprise,
      })
    );
    dispatch(
      getDisciplinaryProcess({
        entity_account: idEnterprise,
        involved: id_candidate,
      })
    );
  }, []);

  let id_candidate = store?.loginReducer?.user_data?.payrollInfo?.id_candidate;
  let id_contract = store?.loginReducer?.user_data?.payrollInfo?.id_contract;

  const medicalInfo = store?.partnersReducer?.employee_info;
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "myCv"
    );
  const historial = store?.partnersReducer?.medicalInfo?.historial;
  const idEnterprise = store?.loginReducer?.currentAccount.id;
  const employeeInfo = store.partnersReducer?.employee_info;
  const annotations = store.partnersReducer?.employee_info?.annotations;
  const disciplinaryProcess = store.partnersReducer?.disciplinaryProcesses;
  let arrSections = employeeInfo?.sections;

  const downloadExam = (url) => {
    window.location.href = url;
  };


  const download = (url) => {
    window.location.href = url;
  };
  const renderSections = () => {
    let sections = [];
    if (Array.isArray(arrSections)) {
      arrSections.map((elem, index) => {
        let docs = [];
        if (Array.isArray(employeeInfo?.documents)) {
          const filteredDocs = employeeInfo?.documents.filter(
            (doc) => doc.section === elem.section
          );
          filteredDocs.map((elem2, index2) => {
            let filename = elem2?.s3_location?.split("/").pop();
            let ext = filename?.split(".")[1];

            const generaterow = (
              <div key={elem2.id}>
                <div>
                  <div className="mt-1">
                    <label
                      for={`checkBox-${elem2.id}`}
                      className={`${tableStyles.darkBlueText} ${tableStyles.f13}`}
                      style={{ marginLeft: "13%" }}
                    >
                      {elem2.doc_description}
                    </label>
                  </div>
                  {/* DESCARGAR DOCUMENTO  */}
                  {isEmptyOrUndefined(elem2.s3_location) ? (
                    <Row>
                      <Col xs={12} className="d-flex">
                        <img className="mr-2" src={Ellipse}></img>

                        <div className={`w-100 ${tableStyles.clearBlueBorder}`}>
                          <input
                            readOnly={true}
                            // disabled={true}
                            className={` register-inputs ${tableStyles.clearBlueText}
                            ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput} ${tableStyles.noBorderFocus} `}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} className="d-flex">
                        {/*Conditional empty circle div */}
                        <img className="mr-2" src={circleGreenCheck}></img>

                        <div className={`w-100 ${tableStyles.clearBlueBorder}`}>
                          <img src={placeMyIcon(ext)}></img>
                          <input
                            readOnly={true}
                            style={{
                              backgroundColor: "#eff4fb",
                              textDecoration: "underline",
                              color: "#4d84fe",
                            }}
                            className={`register-inputs ${tableStyles.clearBlueText} hoverPointer
                           ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput} `}
                            onClick={
                              isEmptyOrUndefined(elem2.s3_location)
                                ? null
                                : () =>
                                  (window.location.href = elem2.s3_location)
                            }
                            value={
                              isEmptyOrUndefined(elem2.s3_location)
                                ? null
                                : elem2.s3_location.split("/").pop()
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            );
            if (elem2.doc_description) {
              docs.push(generaterow);
            }
          });
        }
        const generateSec = (
          <div key={index}>
            <div className="" style={{ width: "21.5rem" }}>
              <h6>
                <b className={`${tableStyles.darkBlueText}`}>{elem.section}</b>
              </h6>
              {docs.length > 0 ? docs :
                <div
                  className={`text-secondary ${tableStyles.f12} justify-content-center `}
                >No hay documentos disponibles</div>
              }
            </div>
          </div>
        );
        sections.push(generateSec);
      });
    }
    return (
      <div
        key={"sectionwrap"}
        className={"mb-4 d-flex justify-content-between"}
      >
        {sections}
      </div>
    );
  };

  const customTheme = {
    yearColor: "#005dbf",
    // lineColor: "#005dbf",
    lineColor: "#005dbf",
    dotColor: "#005dbf",
    borderDotColor: "#d0cdc4",
    titleColor: "#58595b",
    subtitleColor: "#005dbf",
    textColor: "gray",
  };
  /* ------------------------- HANDLE CHANGES SECTION ------------------------- */

  const renderDisciplinaryProcessInfo = () => {
    return (
      <>
        {disciplinaryProcess?.length > 0 ? (
          disciplinaryProcess.map((e, i) => {
            return (
              <Row className={`d-flex justify-content-start `}>
                <Col
                  xs={3}
                  className={`${tableStyles.darkGrayText} `}
                >
                  <b className={tableStyles.darkBlueText}>•</b>
                  <b
                    className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                  >
                    {e.report_date}
                  </b>
                </Col>

                <Col xs={9} className={`text-secondary`}>
                  <span className={tableStyles.fw700End}>
                    <u
                      className={`${tableStyles.darkGrayText} `}
                      title={e.title}
                    >
                      <b
                        onClick={() => {
                          setOpen(true);
                          setSelectedProcess(e);
                        }}
                        className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis} cursorPointer`}
                      >
                        {e.title.length >= 45
                          ? e.title.slice(0, 45) + "..."
                          : e.title}
                      </b>
                    </u>
                    <br />
                    {!isEmptyOrUndefined(e.reporter)
                      ? e.reporter
                      : "-"}
                  </span>
                </Col>
              </Row>
            );
          })
        ) : (
          <h6 className={`${tableStyles.darkBlueText} pt-4`}>
            <b>No se encontraron procesos disciplinarios</b>
          </h6>
        )}
      </>
    );
  };
  return (
    <>
      {/* /* -------------------------------- PDF Viewer ------------------------------- */}
      <ModalNew
        title="Contratos"
        show={showPdf}
        btnNoName={"Cancelar"}
        size="700"
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer file={employeeInfo?.signed_doc}></PdfViewer>
      </ModalNew>
      {/* /* ------------------------------------ - ----------------------------------- */}

      {/* /* ------------------------ DIALOG MODAL FULL SCREEN  COMPONENT ------------------------ */}
      {open ? (
        <DialogModalFullScreen
          closeDialog={() => {
            setSlider({
              ...slider,
              infoGen: false,
              attached: false,
              disciplinaryProcess: true,
              annotations: false,
              medicalExams: false,
              examns: false,
            });
            setOpen(false);
          }}
          title={selectedProcess.title}
        >
          <Row className="ml-5">
            <Col xs={6} className="mt-4 mb-4 ml-5">
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Información general</b>
              </h6>

              <div className="mt-2">
                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                  >
                    <b>Fecha</b>
                  </Col>

                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess.type_date
                      ? moment(selectedProcess?.type_date).format("YYYY/MM/DD")
                      : "-"}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                  >
                    <b>Reportante</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.reporter ? selectedProcess.reporter : "-"}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                  >
                    <b>Cargo</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.job_title
                      ? selectedProcess.job_title
                      : "-"}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                  >
                    <b>Área</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.area_name
                      ? selectedProcess.area_name
                      : "-"}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                  >
                    <b>Título</b>
                  </Col>
                  <Col xs={6} className={`text-secondary ${tableStyles.f14}`}>
                    {selectedProcess?.title ? selectedProcess.title : "-"}
                  </Col>
                </Row>
              </div>
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Descripción</b>
              </h6>
              <div className="mt-2">
                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f15}`}
                  >
                    <b>Colaboradores implicados: </b>
                    <br></br>
                    {selectedProcess?.involved ? selectedProcess.involved : "-"}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-start">
                  <Col
                    xs={6}
                    className={`${tableStyles.darkGrayText} ${tableStyles.f14}`}
                  >
                    <b>Descripción de la situación: </b>
                    <br></br>

                    {selectedProcess?.description
                      ? selectedProcess.description
                      : "-"}
                  </Col>
                </Row>
              </div>
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Evidencias</b>
              </h6>
              <div className="mt-4">
                <Row className="d-flex justify-content-start">
                  <Col
                    xs={12}
                    className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}
                  >
                    {selectedProcess?.support_files?.length > 0
                      ? selectedProcess.support_files?.map((e) => {
                        return (
                          <>
                            <u
                              onClick={() => download(e.support_file)}
                              className={`${tableStyles.darkBlueText} cursorPointer`}
                            >
                              {e.support_file.split("/").pop()}
                            </u>
                            &nbsp; &nbsp;
                          </>
                        );
                      })
                      : ""}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </DialogModalFullScreen>
      ) : (
        <div className={`${tableStyles.container}`}>
          <div className="mt-3 d-flex  " style={{ marginLeft: "4rem" }}>
              <img
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "10px",
                  border: "1px solid #005dbf",

                }}
                src={employeeInfo?.photo ? employeeInfo?.photo : anonymus}
                alt="imagen usuario"
              />
            {/* <Card
              border="primary"
              className="mt-3 mb-2"
              style={{ width: "120px", height: "120px" }}
            >
              <Card.Body className="m-0 p-0"
              >
                <img
                  style={{
                    maxWidth: "120px",
                    height: "120px",
                  }}
                  src={employeeInfo?.photo ? employeeInfo?.photo : anonymus}
                  alt="imagen usuario"
                />
              </Card.Body>
            </Card> */}
            &nbsp; &nbsp;
            <div>
              <h1 className={`${tableStyles.title} mb-0 pt-0`}>
                {employeeInfo?.full_name}
              </h1>
              <h6 className={`${tableStyles.darkBlueText}`}>
                <b>{employeeInfo?.job_title}</b>
              </h6>
            </div>
          </div>

          <div className={tableStyles.tableArea}>
            <Row className="mb-4">
              {/* /* -------------------------- DIV TABBAR CONTAINER -------------------------- */}
              {/* <div
                className={`${tableStyles.container1} ${tableStyles.bgColorClearBlue}  mt-2 p-0`}
              > */}
              {/* <div
                  className={`${tableStyles.bgColorClearB} d -flex `}
                  style={{ width: "100%" }}
                > */}

              <Col xs={12} className={`${tableStyles.weirdBackGround3} m-0 p-0`}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link
                      onClick={() =>
                        setSlider({
                          infoGen: true,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false
                        })
                      }
                      eventKey="link-1"
                      className={tableStyles.darkBlueText}
                      style={{
                        color: slider.infoGen ? "#495057" : "",
                        backgroundColor: slider.infoGen ? "#fff" : "",
                        borderColor: slider.infoGen
                          ? "#dee2e6 #dee2e6 #fff"
                          : "",
                      }}
                    >
                      Información general
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() =>
                        setSlider({
                          infoGen: false,
                          attached: true,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false
                        })
                      }
                      eventKey="link-2"
                      // className={`${tableStyles.darkBlueText}`}
                      style={{
                        color: "#005dbf",
                      }}
                    >
                      Contrato y anexos
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: true,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false
                        });
                      }}
                      // style={{
                      //   color: slider.disciplinaryProcess ? "#495057" : "",
                      //   backgroundColor: slider.disciplinaryProcess
                      //     ? "#fff"
                      //     : "",
                      //   borderColor: slider.disciplinaryProcess
                      //     ? "#dee2e6 #dee2e6 #fff"
                      //     : "",
                      // }}
                      style={{
                        color: "#005dbf",
                      }}
                      eventKey="link-3"
                    >
                      Procesos disciplinarios
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: true,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: false
                        });
                      }}
                      eventKey="link-4"
                      style={{
                        color: "#005dbf",
                      }}
                    >
                      Anotaciones
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: true,
                          examns: false,
                          accruedAndDeductions: false
                        });
                      }}
                      eventKey="link-5"
                      style={{
                        color: "#005dbf",
                      }}
                    >
                      Exámenes medicos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setSlider({
                          ...slider,
                          infoGen: false,
                          attached: false,
                          disciplinaryProcess: false,
                          annotations: false,
                          medicalExams: false,
                          examns: false,
                          accruedAndDeductions: true
                        });
                      }}
                      eventKey="link-6"
                      style={{
                        color: "#005dbf",
                      }}
                    >
                      Descuentos y libranzas
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              {/* </div> */}
              {/* </div> */}
              {/* /* --------------------------- GENERAL INFORMATION -------------------------- */}
              {slider.infoGen && (
                <>
                  <Col xs={4} className="mt-4 mb-4">
                    <h4 className={tableStyles.darkBlueText}>
                      <b>Datos básicos</b>
                    </h4>
                    <div className="mt-4">
                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText}  ${tableStyles.f13}`}
                        >
                          <b>Nacionalidad</b>
                        </Col>

                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.country_name}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Tipo de documento</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.document_type}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Vigencia hasta</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.end_date
                            ? employeeInfo?.end_date
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Expedición</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.doc_issued}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Fecha de nacimiento</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {moment(employeeInfo?.birthdate).format("YYYY/MM/DD")}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Edad</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.age ? employeeInfo?.age : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Género</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.gender_label}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Est. civil</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.marital_status === "single" && "Soltero(a)"}
                          {employeeInfo?.marital_status === "married" && "Casado(a)"}
                          {employeeInfo?.marital_status === "divorced" && "Divorciado(a)"}
                          {employeeInfo?.marital_status === "widowed" && "Viudo(a)"}
                          {employeeInfo?.marital_status === "freeunion" && "Unión(a)"}
                          {employeeInfo?.marital_status === "others" && "Soltero(a)"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>No. de hijos</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.children_qty}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Fecha de ingreso</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {moment(employeeInfo?.start_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Método de pago</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.payment_method ?? "-"}
                        </Col>
                      </Row>
                      {employeeInfo?.payment_method === "Transferencia" &&
                        <>
                          <Row className="d-flex justify-content-start">
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>No. cuenta</b>
                            </Col>
                            <Col
                              xs={6}
                              className={`text-secondary ${tableStyles.f12}`}
                            >
                              {employeeInfo?.bank_account_nbr && employeeInfo?.bank_account_nbr}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-start">
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>Tipo de cuenta</b>
                            </Col>
                            <Col
                              xs={6}
                              className={`text-secondary ${tableStyles.f12}`}
                            >
                              {employeeInfo?.bank_account_type && employeeInfo?.bank_account_type}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-start">
                            <Col
                              xs={6}
                              className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                            >
                              <b>Banco</b>
                            </Col>
                            <Col
                              xs={6}
                              className={`text-secondary ${tableStyles.f12}`}
                            >
                              {employeeInfo?.bankingName && employeeInfo?.bankingName}
                            </Col>
                          </Row>
                        </>
                      }
                    </div>
                    <h4 className={`${tableStyles.darkBlueText} mt-3`}>
                      <b>Contacto</b>
                    </h4>
                    <div className="mt-4">
                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Télefono</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.mobile}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Email</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.email}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Domicilio</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.home_address}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Cont. de emergencia</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.emergency_contact}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Parentesco</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.emercont_relationship === "partner" && "Pareja"}
                          {employeeInfo?.emercont_relationship === "parent" && "Padre/madre"}
                          {employeeInfo?.emercont_relationship === "son" && "Hijo(a)"}
                          {employeeInfo?.emercont_relationship === "sibling" && "Hermano(a)"}
                          {employeeInfo?.emercont_relationship === "others" && "Otro"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Teléfono</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {employeeInfo?.emercont_mobile
                            ? employeeInfo?.emercont_mobile
                            : "-"}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    className={`pt-4  ${tableStyles.bgColorClearBlue} ${tableStyles.scrollThis}`}
                    style={{ height: "83vh" }}
                  >
                    <div className="mt-2 ml-5">
                      <p className={tableStyles.darkBlueText}>
                        <b>Educación</b>
                      </p>

                      {employeeInfo?.education?.length > 0 &&
                        employeeInfo?.education?.map((e, i) => {
                          return (
                            <Timeline lang="es" theme={customTheme}>
                              {e.graduation_date ? (
                                <Container>
                                  <YearContent
                                    startDate={moment(e.graduation_date).format(
                                      "YYYY/MM/DD"
                                    )}
                                    endDate={moment(e.graduation_date).format(
                                      "YYYY/MM/DD"
                                    )}
                                  />
                                  <BodyContent>
                                    <Section
                                      title={
                                        <b
                                          className={`${tableStyles.darkGrayText}`}
                                        >
                                          {e.education_type}
                                        </b>
                                      }
                                    >
                                      <Description text={e.institution_name} />

                                      <Description
                                        variant="subtitle"
                                        text={e.description}
                                      />
                                    </Section>
                                    <Section></Section>
                                  </BodyContent>
                                </Container>
                              ) : (
                                <></>
                              )}
                            </Timeline>
                          );
                        })}
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    className={`pt-4  ${tableStyles.bgColorClearBlue} ${tableStyles.scrollThis} `}
                    style={{ height: "83vh" }}
                  >
                    <div className="mt-2 ">
                      <p className={`mt-1 ${tableStyles.darkBlueText}`}>
                        <b>Experiencia</b>
                      </p>
                      {employeeInfo?.workExperience?.length > 0 &&
                        employeeInfo?.workExperience?.map((e, i) => {
                          return (
                            <Timeline lang="es" theme={customTheme}>
                              {e.start_date ? (
                                <Container>
                                  <YearContent
                                    startDate={moment(e.start_date).format(
                                      "YYYY/MM/DD"
                                    )}
                                    endDate={moment(e.end_date).format(
                                      "YYYY/MM/DD"
                                    )}
                                  />
                                  <BodyContent>
                                    <Section
                                      title={
                                        <b
                                          className={`${tableStyles.darkGrayText}`}
                                        >
                                          {e.company_name}
                                        </b>
                                      }
                                    >
                                      <Description text={e.description} />
                                    </Section>
                                    <Section></Section>
                                  </BodyContent>
                                </Container>
                              ) : (
                                <></>
                              )}
                            </Timeline>
                          );
                        })}
                    </div>
                  </Col>
                </>
              )}
              {/* /* ------------------------------------ - ----------------------------------- */}
            </Row>
            {/* /* -------------------------------- CONTRACT AND ATTACHED -------------------------------- */}
            {slider.attached && (
              <>
                <div>
                  <Row className="mb-3">
                    {/* /* -------------------------------- CONTRATCT ------------------------------- */}

                    <Col xs={4} className="mt-4">
                      <div className="">
                        <h4 className={tableStyles.darkBlueText}>
                          <b>Contrato</b>
                        </h4>
                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Tipo de contrato</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.description
                              ? employeeInfo?.description
                              : "-"}
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Fecha de inicio</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.start_date
                              ? moment(employeeInfo?.start_date).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Vigencia hasta</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.end_date
                              ? employeeInfo?.end_date
                              : "-"}
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Salario base</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {/* {employeeInfo?.base_salary} */}
                            {convertMoneyFormat(
                              employeeInfo?.base_salary
                                ? employeeInfo?.base_salary
                                : "0"
                            )}
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Salario no constitutivo</b>
                          </Col>
                          <Col
                            xs={6}

                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {convertMoneyFormat(
                              employeeInfo?.non_const_income ?? '-'
                            )}
                          </Col>
                        </Row>
                      </div>{" "}
                    </Col>
                    <Col xs={5} className="mt-4">
                      {/* /* --------------------------
                      - SECURITY AND HEALTH -------------------------- */}
                      <div className="mt-3">
                        <h4 className={tableStyles.darkBlueText}>
                          <b>Seguridad y salud</b>
                        </h4>
                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Entidad Prestadora de Salud</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.hce_description
                              ? employeeInfo?.hce_description
                              : "-"}
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Fondo de pensión</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.pension_description
                              ? employeeInfo?.pension_description
                              : "-"}
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>ARL</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.occupational_risk_description
                              ? employeeInfo?.occupational_risk_description
                              : "-"}
                          </Col>
                        </Row>

                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={6}
                            className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                          >
                            <b>Caja de compensación</b>
                          </Col>
                          <Col
                            xs={6}
                            className={`text-secondary ${tableStyles.f12}`}
                          >
                            {employeeInfo?.compensation_center
                              ? employeeInfo?.compensation_center
                              : "-"}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={3}>
                      {/* /* --------------------------- SECURITY AND HEALTH -------------------------- */}
                      <div
                        className="mt-2"
                      //  style={{ height: "14.5rem" }}
                      >
                        <Row className="d-flex justify-content-start">
                          <Col
                            xs={12}
                            className={`d-flex justify-content-center`}
                            style={{ width: "11rem" }}
                          >
                            <button
                              className={`${tableStyles.buttonExtra1} w-100 mt-1`}
                              onClick={() => setShowPdf(true)}
                            >
                              <img className="cursorPointer" src={link} />
                              &nbsp;
                              <b>Ver contrato</b>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  {renderSections()}
                </div>{" "}
              </>
            )}
            {/* /* ------------------------------------ - ----------------------------------- */}
            {/* /* -------------------------------- DISCIPLINARY PROCESS -------------------------------- */}
            {slider.disciplinaryProcess && (
              <>
                {renderDisciplinaryProcessInfo()}
              </>
            )}
            {/* /* ------------------------------------ - ----------------------------------- */}
            {/* /* -------------------------------- ANNOTATIONS ----------------------------------------- */}
            {slider.annotations && (
              <>
                <div
                  className={`${tableStyles.scrollThis} ${tableStyles.grayBorderBottom}`}
                >
                  {annotations?.length > 0 ? (
                    annotations.map((e, i) => {
                      return (
                        <div
                          key={i}
                          className={`${tableStyles.cardBoxContainer} p-2 mr-2 m-2 mb-4`}
                          style={{ width: "40%" }}
                        >
                          <p className={tableStyles.cardBoxTitle}>{e.title}</p>
                          <hr className="m-0"></hr>

                          <div className={`text-secondary p-1  text-wrap`}>
                            {isEmptyOrUndefined(e.description) ? (
                              <h1 class="text-secondary text-center">
                                No hay descripción para esta anotación
                              </h1>
                            ) : (
                              <p>{e.description}</p>
                            )}
                          </div>

                          <div
                            className={`d-flex text-start justify-content-between pt-4`}
                          >
                            <p className={tableStyles.cardBoxBottomText}>
                              {e.created_by_name}
                            </p>
                            <p
                              className={`${tableStyles.cardBoxBottomText} ${tableStyles.fw700End}`}
                            >
                              {convertDateToLatinFormat(e.created_at)}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <h4 className={tableStyles.darkBlueText}>
                        <b>Este colaborador no tiene anotaciones</b>
                      </h4>
                    </>
                  )}
                </div>
              </>
            )}
            {/* /* ------------------------------------ - ----------------------------------- */}
            {/* /* -------------------------------- medicalExamsS ----------------------------------------- */}
            {slider.medicalExams && (
              <>
                <Row
                  className={`${tableStyles.grayBorderBottom} pb-5  ${tableStyles.scrollThis}`}
                >
                  {/* /* ------------------------- MEDIC INFORMATION ------------------------- */}
                  <Col xs={4} className="mt-3">
                    <h3 className={tableStyles.darkBlueText}>
                      <b>Información médica</b>
                    </h3>
                    <div className="mt-2">
                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Tipo de sangre</b>
                        </Col>

                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.blood_type
                            ? medicalInfo?.blood_type
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>RH</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.rh_factor
                            ? medicalInfo?.rh_factor
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Peso</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.weight_kg
                            ? medicalInfo?.weight_kg + " kg"
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Estatura</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.heigth_cm
                            ? medicalInfo?.heigth_cm + " cm"
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Alergias</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.allergies
                            ? medicalInfo?.allergies
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Discapacidades</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.disabilities
                            ? medicalInfo?.disabilities
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Medicamentos</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.medicines
                            ? medicalInfo?.medicines
                            : "-"}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-start">
                        <Col
                          xs={6}
                          className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}
                        >
                          <b>Enfermedades</b>
                        </Col>
                        <Col
                          xs={6}
                          className={`text-secondary ${tableStyles.f12}`}
                        >
                          {medicalInfo?.diseases
                            ? medicalInfo?.diseases
                            : "-"}
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  {/* /* ---------------------- EXAMS INFORMATION --------------------- */}
                  <Col className={`mt-3 rounded m-3`} xs={4}>
                    <h4 className={tableStyles.darkBlueText}>
                      <b>Información de exámenes </b>
                    </h4>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Fecha de próximos exámenes
                    </p>
                    <input
                      disabled={true}
                      value={store.partnersReducer?.medicalInfo?.next_exam}
                      className="register-inputs text-secondary"
                    />
                    <h4 className={`${tableStyles.darkBlueText} pt-4`}>
                      <b>Historial</b>
                    </h4>
                    {historial?.length > 0 ? (
                      historial.map((e, i) => {
                        return (
                          <Row className={`d-flex justify-content-start `}>
                            <Col
                              xs={3}
                              className={`${tableStyles.darkGrayText} `}
                            >
                              <b className={tableStyles.darkBlueText}>•</b>
                              <b
                                className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                              >
                                {e.done_date}
                              </b>
                            </Col>

                            <Col xs={9} className={`text-secondary`}>
                              <span className={tableStyles.fw700End}>
                                <u
                                  className={`${tableStyles.darkGrayText} `}
                                  title={e.title}
                                >
                                  <b
                                    onClick={() =>
                                      downloadExam(e.result_location)
                                    }
                                    className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis} cursorPointer`}
                                  >
                                    {e.title.length >= 45
                                      ? e.title.slice(0, 45) + "..."
                                      : e.title}
                                  </b>
                                </u>
                                <br />
                                {!isEmptyOrUndefined(e.result_loaded_by_name)
                                  ? e.result_loaded_by_name
                                  : "-"}
                              </span>
                            </Col>
                          </Row>
                        );
                      })
                    ) : (
                      <h6 className={`${tableStyles.darkBlueText} pt-4`}>
                        <b>No se encontraron fechas</b>
                      </h6>
                    )}
                  </Col>
                </Row>
              </>
            )}
            {slider.accruedAndDeductions &&
              <DiscountsAndPayout idCandidate={id_candidate} />
            }
            {/* /* ------------------------------------ - ----------------------------------- */}
          </div>
        </div>
      )}
    </>
  );
};
