import React, { useEffect, useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import NumberFormat from "react-number-format";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


import ModalNew from "../../Layouts/ModalNew";
import payrollStyles from "../payrollStyles.module.scss";
import popUpStyles from "../../Popup/customPopup.module.scss";

import {

} from "../../../actions/payrollActions";
import { URL_GATEWAY, API_VERSION } from "../../../helpers/constants";
import { convertFilterToString } from "../../../helpers/convertToFilter";

import { customSelectNewDark } from "../../Layouts/react-select-custom";

import Pagination from "react-js-pagination";
import GenericTableNew from "../../Layouts/GenericTableNew";
import GenericModalNew from "../../Layouts/ModalNew";

import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import CustomPopupExtends from "../../Popup/customPopUpExtends";

import calendar from "../../../assets/img/icons/calendar.svg";
import calendaredit from "../../../assets/img/icons/calendaredit.svg";
import useredit from "../../../assets/img/icons/useredit.svg";
import recibir from "../../../assets/img/icons/recibir.svg";
import popupx from "../../../assets/img/icons/x.svg";
import { Checkbox } from "@material-ui/core";
import { convertDateToLatinFormat } from "../../../helpers";

import SalidaConStyles from "../../SalidaConsumo/SalidaConsumo.module.scss";
import Back from "../../../assets/img/icons/atras-icon.svg";
import Lupa from "../../../assets/img/icons/lupa.svg";
import MedicalExamDetail from "./MedicalExamDetail";

import { 
    SELECT_CALENDAR_MEDICAL_EXAMS,
} from "../../../actions/actionTypes";
import SelectComponent from "../../SelectComponent/SelectComponent";

function MedicalExamHistory(props) {
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const token = store.loginReducer.Authorization;
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find( (x) => x.functionality?.prefix === "ExaMed");
    
    const today = new Date();
    const currYear = today.getFullYear();
    const [isLoading, setIsLoading] = useState(false);
    const [screenControl, setScreenControl] = useState(0);
    const [chosen_exam_id, set_chosen_exam_id] = useState(0);
    const [chosen_area_name, set_chosen_area_name] = useState("");

    const [trigger, setTrigger] = useState(0);

    const [filters, setFilters] = useState({
        area_id: undefined,
        page:1,
        perpage:10,
        status:"done",
    });

    const [medTable, setMedTable] = useState([]);
    const [medTableTotal, setMedTableTotal] = useState(0);

    //zone that should be actions but honestly we are over-using redux
    const fetchData = async (data) => {
          
        setIsLoading(true);
        const getMyStuff = await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam/?${convertFilterToString(filters)}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        .then((response) => response.json())
        .then((res) => {
            setMedTable(res.results);
            setMedTableTotal(res.total);
            setIsLoading(false);

        })
        .catch((err) => {

            setIsLoading(false);

        });
    }
    //

    useEffect(() => {
        fetchData();
    }, [trigger]);

    const optionArea = () => {
        let temp = [{value: "",label: "Seleccionar..."}];
        if (Array.isArray(props.medExam)) {
            props.medExam.map((elem) => {
                temp.push({
                    value: elem.CC_ID,
                    label: elem.CC_ID+": "+elem.CC_DESC,
                });
            });

          }
        return temp;
    }

    const meHeaders = [
        <th key={1} className="text-start px-2" >Área</th>,
        <th key={2} className="text-center" >Fecha Programada</th>,
        <th key={3} className="text-center" >No. Examinados<br/><span style={{fontSize:10}}>(Realizados/Total)</span></th>,
        <th key={4} >&nbsp;</th>,
    ];
    
    const meBody = ()=> {
        if(!Array.isArray(medTable)){
            return [];
        }

        let filteredArray = medTable;

        // if(!!filters.area_id){
        //     filteredArray = filteredArray.filter(x=>x.area_id === filters.area_id)
        // }

        // if(!!filters.from_date){
        //     filteredArray = filteredArray.filter(x=>x.done_date >= filters.from_date)
        // }
        
        // if(!!filters.to_date){
        //     filteredArray = filteredArray.filter(x=>x.done_date <= filters.to_date)
        // }

        return filteredArray.map(item => { 
            //Seccion de opciones para el popup
            
            return (
                <tr key={item.id} className="hover-table-row">
                <td className={`col-md-3`}>
                    <p className={`m-0 text-start`} style={{paddingLeft: 10}}>{item.area_name}</p>
                </td>
                <td className={`col-md-3`}>
                    <div className={`d-flex justify-content-center`}>
                        {
                            item.prog_date?
                                convertDateToLatinFormat(item.prog_date)
                            :
                                "00/00/0000"
                        }
                    </div>
                </td>
                <td className={`col-md-2`}>
                    {
                        Array.isArray(item.details)?
                            `
                            ${item.details?.reduce((acum, loop)=>{
                                return acum + ((loop.status==="done")? 1: 0)
                            }, 0)}
                            /
                            ${item.details?.length}
                            `
                        :
                            "-"
                    }
                </td>
                <td className={`col-md-3`}>
                    <div className={`d-flex justify-content-center`}>
                    
                        <p 
                            className={`m-0 ${payrollStyles.statusPending}`} 
                            style={{textTransform: "capitalize", cursor:"pointer"}}
                            onClick={()=>{
                                //setScreenControl(1);
                                //set_chosen_exam_id(item.id);
                                //set_chosen_area_name(item.area_name);
                                
                                dispatch({
                                    type:SELECT_CALENDAR_MEDICAL_EXAMS,
                                    //...x,
                                    id: item.id,
                                    area: item.area_name,
                                });
                                history.push("/nomina/examenesMedicosDetalles/");

                                }
                            }
                        >
                            Ver Resultados
                        </p> 
                    
                    </div>
                </td>
            </tr>
        )
        })
    }

    const renderHistory = (
        <div  className={tableStyles.container} style={{padding:0}}>
        <div className={`d-flex ${SalidaConStyles.titleBlock} align-items-center gap-2`}
        style={{marginLeft: "22px"}}
        >
            <img 
                src={Back} 
                alt="backIcon"
                className="cursorPointer"
                height="30px"
                onClick={()=>props.setScreenControl(0)}
            />
            <span className={`fw-bold text-primary ${tableStyles.f40} ${tableStyles.darkBlueText} `}>Historial de Exámenes Médicos</span>
            {/* <h1 className={tableStyles.title} style={{marginBottom:0}}>Historial de Exámenes Médicos</h1> */}
        </div>

        <div className={tableStyles.container} style={{paddingBottom:40, paddingLeft:50, paddingRight:50, flex:1}}>
            {isLoading && (
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            )}

                


                <div className={`d-flex`} style={{marginTop: 16}}>
                    <div className="d-flex flex-column  mr-2" style={{flex:1}}>
                        <p className={tableStyles.crudModalLabel}>Área</p>
                        <SelectComponent
                            value={optionArea().find(x => x.value == filters.area_id)}
                            onChange={e => {setFilters({...filters, area_id: e.value}) 
                            setTrigger(trigger+1)}
                        } 
                            options={optionArea()}
                            placeholder={"Seleccionar..."}
                            styles={customSelectNewDark}
                            maxHeight={22}
                        />
                    </div>

                    <div className="d-flex flex-column mr-2" style={{flex:1}}>
                        <p className={tableStyles.crudModalLabel}>Fecha desde</p>
                        <input 
                            type="date"
                            className="register-inputs"
                            value={filters.from_date}
                            onChange={e=> {setFilters({...filters, from_date: e.target.value})
                        }}
                        onKeyDown={e=>{
                            if (e.key === 'Enter') {
                                setTrigger(trigger+1)}
                            }  
                          }
                        />
                    </div>

                    <div className="d-flex flex-column mr-2" style={{flex:1}}>
                        <p className={tableStyles.crudModalLabel}>Hasta</p>
                        <input 
                            type="date"
                            className="register-inputs"

                            value={filters.to_date}
                            onChange={e=> {setFilters({...filters, to_date: e.target.value})
                        }}
                        onKeyDown={e=>{
                            if (e.key === 'Enter') {
                                setTrigger(trigger+1)}
                            }  
                          }
                        />
                    </div>

                    <div className="d-flex flex-column" style={{flex:1}}>
                        <p className={tableStyles.crudModalLabel}>&nbsp;</p>
                        <img 
                        className={tableStyles.cursorPointer}
                        style={{ height: "24px", width: "24px" }}
                        alt="icon"
                        onClick={() => setTrigger(trigger+1)} 
                        src={Lupa} 
                        /> 
                    </div>

                    <div style={{flex:1}}></div>
                </div>


                <GenericTableNew
                    headers={meHeaders}
                    dark={true}
                >
                    {meBody()}
                </GenericTableNew>

                {(medTableTotal> filters.perpage) && <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                    Pag. {medTableTotal ? filters.page : ""}
                    {" de "}
                    {Math.ceil(medTableTotal / filters.perpage)
                        ? Math.ceil(medTableTotal / filters.perpage)
                        : ""}{" "}
                    ({medTableTotal} encontrados)
                    </p>
                    <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={medTableTotal}
                    pageRangeDisplayed={5}
                    onChange={val=>{setTrigger(trigger+1); setFilters({ ...filters,  page: val }) }}
                    itemClassPrev={`${paginationStyles.itemClassPrev} `}
                    itemClassNext={`${paginationStyles.itemClassNext} `}
                    itemClassFirst={`${paginationStyles.itemClassFirst} `}
                    itemClassLast={`${paginationStyles.itemClassLast} `}
                    itemClass={paginationStyles.itemClass}
                    />
                </div>}
        </div>
        </div>
    );

    switch(screenControl){
        case 1: return <MedicalExamDetail
            screenControl={screenControl}
            setScreenControl={setScreenControl}
            exam_id={chosen_exam_id}
            area_name={chosen_area_name}
         />

        default: return renderHistory;
    } ;
}

export default MedicalExamHistory;