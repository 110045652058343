import React, { useEffect, useState } from "react";
import Agregar from "../../../assets/img/icons/add-check.svg";
import {customSelectNewDark } from "../../Layouts/react-select-custom";

import Pagination from "react-js-pagination";
import Search from "../../../assets/img/icons/lupa.svg";
import threeDots from "../../../assets/img/icons/threeDots.svg";
import GenericTableNew from "../../Layouts/GenericTableNew";

import {useSelector, useDispatch } from "react-redux";

import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import ModalNew from "../../Layouts/ModalNew";
import withReactContent from "sweetalert2-react-content";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";

import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  convertDateToLatinFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from "../../../helpers/helpers";
import {
  changePositionStatus,
  createNewJobPosition,
  getAllJobPositions,
  getWorkTimes,
  job_position_areas,
  list_status,
} from "../../../actions/positionsActions";
import { getConCenter } from "../../../actions/consumptionCenterActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomPopupExtend from "../../Popup/customPopUpExtends";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";
import { useGetMethod } from "../../../Hooks/useFetch";

export const PositionTable = (props) => {
  const store = useSelector((state) => state);
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "position"
    );

  const [arrEmployees, setArrEmployees] = useState([]);
  const [actualItem, setActualItem] = useState({});
  // const arrAreas = store.consumptionCenterReducer?.consumption_center;
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [modalAlertControl, setModalAlertControl] = useState({
    showFirst: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
  });
  let i = 0;
  const arrJobsPosition = store.positionReducer?.jobsPositionsList;

  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const { results: areaOptionsResults, load: areaOptionsLoader, trigger: getAreaOptions} = useGetMethod()
  useEffect(() => {
    getAreaOptions({
     url: "/consumption_center/",
     objFilters: {
         eaccount: idEnterprise,
         active : 1,
         lite: 1,
         handleResponse: 1,
       },
     token: token,
     doAfterSuccess: (res) => {
     },
    });
}, [])
  const formattedAreas = formatToRcSelect(areaOptionsResults?.results, "id", "description");

  const history = useHistory();
  const [trigger, setTrigger] = useState(0);

  const handleDetails = (elem) => {
    history.push({
      pathname: "/nomina/nuevoCargo",
      state: { detail: true, data: elem },
    });
  };

  //filtros para el functional_get
  const [filters, setFilters] = useState({
    area_id: undefined,
    search: "",
    status: undefined,
    slim: 0,
    page: 1,
    perpage: 10,
    entity_account: store.loginReducer.currentAccount.id,
    onlyCurrent: "current",
    active: undefined,
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
    dispatch(getAllJobPositions(filters, () => setLoading(false)));
    dispatch(
      getConCenter({
        page: 1,
        perpage: 999,
        account: store.loginReducer.currentAccount.id,
      })
    );
    dispatch(list_status({ prefix: "contracts" }));
  }, [filters.page, trigger]);

  const collaboratorsListelem = () => {
    let elemMap;
    if (arrEmployees !== undefined) {
      const elem2 = arrEmployees;
      elemMap = elem2.map((elem) => {
        return collaboratorsRenderElement(elem);
      });
    }
    return elemMap;
  };
  const collaboratorsRenderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-2 text-center">{elem.doc}</td>
        <td className="col-md-4 text-start">{elem.name}</td>
        <td className="col-md-4 text-center">
          {convertDateToLatinFormat(elem.date)}
        </td>
        {/* <td className="col-md-2 text-center hoverPointer">
          <div
            className={`d-flex`}
            onClick={() => alert("clicked!")}
            style={{ justifyContent: "flex-end", marginRight: "5px" }}
          >
            <img src={miniSearchIcon} alt="Search icon" />
          </div>
        </td> */}
      </tr>
    );
  };

  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i} className="px-2">
      No.
    </th>,
    <th className="text-start" key={i + 1}>
      Nombre completo
    </th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1} className="text-center">
      Fecha de inicio
    </th>,
  ];

  // const renderTooltip = (props) => (
  //   <Tooltip placement="top-end" id="button-tooltip" {...props}>
  //     Manejará copagos
  //   </Tooltip>
  // );
  const renderElement = (elem) => {
    return (
      <tr key={elem.id}>
        <td className="col-md-3 text-start px-2">
          {isEmptyOrUndefined(elem.area_name) ? "-" : elem.area_name}
        </td>
        <td className="col-md-3 text-start">
          {isEmptyOrUndefined(elem.job_title) ? "-" : elem.job_title}
        </td>
        <td
          className="col-md-2 hoverPointer text-center"
          onClick={
            elem.employees_count > 0 ? () => handleEmployees(elem) : null
          }
        >
          <u
            className={
              elem.employees_count > 0 ? "text-primary" : "text-warning"
            }
          >
            <b>{elem.employees_count}</b>
          </u>
        </td>
        <td className="col-md-1 text-center cursorPointer">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip placement="top-end" id="button-tooltip" {...props}>
                {elem.active === 1 ? "Habilitado" : "Inhabilitado"}
              </Tooltip>
              // renderTooltip(elem.active)
            }
          >
            <div
            className="rounded-pill p-1"
            style={{
              backgroundColor: elem?.active === 1 ? "#FAFDF6" : "#FEF7F5" ,
            }}
          >
            <b style={{ color: elem?.active === 1 ? "#83C036" : "#F39682" }}>{elem?.active === 1
                  ? "Habilitado"
                  : "Inhabilitado"}</b>
          </div>
            {/* <img
              style={{ "vertical-align": "top" }}
              src={question}
              width={12}
            ></img> */}
          </OverlayTrigger>
        </td>

        <td className="col-md-1 ">
          {!!myPermission?.edit || !!myPermission?.delete ? (
            <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
              // newButton={popUpBtn}
              // showEdit={true && !!myPermission?.edit}
              // editClickEvent={() => handleEdit(elem)}
              showDetails={!!myPermission.read && true}
              textDetails="Ver Detalle"
              showDetailsEvent={() => handleDetails(elem)}
              showEnable={!!myPermission.edit && true}
              isEnabled={!!myPermission.edit && elem.active}
              enableClickEvent={() =>
                customSwaltAlert({
                  icon: "warning",
                  title: elem.active ? "¿Esta seguro?" : "¿Esta seguro?",
                  // "Deshabilitar cargo",
                  text: elem.active
                    ? `Se deshabilitará el cargo: ${elem.job_title}`
                    : `Se habilitará el cargo: ${elem.job_title}`,
                  // confirmButtonColor: "#3085d6",
                  confirmButtonText: elem.active
                    ? "Si, continuar"
                    : "Si, continuar",
                  // cancelButtonColor: "#d33",
                  cancelButtonText: "Cancelar",
                  showCancelButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    const send = async () => {
                      try {
                        let result = await changePositionStatus(
                          elem.id,
                          elem.active === 0 ? 1 : 0,
                          token
                        );
                        setTrigger(trigger + 1);
                        if (result.success) {
                          message(
                            "success",
                            "Actualizado exitosamente",
                            `Se ha actualizado el cargo: ${elem.job_title}`,
                            undefined,
                            true
                          );
                        } else {
                          return message(
                            "error",
                            "Ha ocurrido un error",
                            result.message,
                            undefined,
                            true
                          );
                        }
                      } catch (error) {
                        message("error", "Error", error.message,  undefined,
                        true
              );
                      }
                    };
                    send();
                  }
                })
              }
            />
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrJobsPosition !== undefined) {
      const elem2 = arrJobsPosition;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={i + 1} className="text-start px-2">
      Área
    </th>,
    <th key={i + 1} className="text-start">
      Nombre del cargo
    </th>,
    <th key={i + 1} className="text-center">Colaboradores</th>,
    <th key={i + 1} className="text-center">Estado</th>,
    <th key={i + 1}>&nbsp;</th>,
  ];

  const handleEmployees = (elem) => {
    setArrEmployees(elem.employees);
    setModalAlertControl({
      ...modalAlertControl,
      showFirst: true,
    });
  };
  const handleClickNew = () => {
    history.push({
      pathname: "/nomina/nuevoCargo",
      state: { detail: false },
    });
  };

  const handleSearch = (e) => {
    setLoading(true)
    e?.preventDefault();
    setFilters({ ...filters, page: 1 });
    dispatch(getAllJobPositions(filters, () => setLoading(false)));
  };

  const handleChangeArea = (area_id)=>{
    setLoading(true)
    dispatch(getAllJobPositions({...filters,...area_id}, () => setLoading(false)));
  }

  const handleChangeStatus = (status)=>{
    setLoading(true)
    dispatch(getAllJobPositions({...filters,...status}, () => setLoading(false)));
  }

  const statusOptions =
  [
    { value: "", label: "Seleccionar..." },
    { value: 0, label: "Inhabilitado" },
    { value: 1, label: "Habilitado" },
  ]
  return (
    <>
      {(loading || areaOptionsLoader) && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}
      {/* /* ------------------------------ First modal ------------------------------ */}
      <ModalNew
        size="600"
        title="Colaboradores"
        show={modalAlertControl.showFirst}
        hideCancelButton={true}
        onHide={() =>
          setModalAlertControl({
            ...modalAlertControl,
            showFirst: false,
          })
        }
      >
        <div className={tableStyles.tableScroll}>
          <GenericTableNew headers={renderHeadersFirst}>
            {collaboratorsListelem()}
          </GenericTableNew>
        </div>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Cargos</h1>

     { !!myPermission.read &&
     <>
      <Row>
      <Col xs={2}>
       <p className={tableStyles.crudModalLabel}>Área</p>
       <SelectComponent
        styles={customSelectNewDark}
        placeholder={"Seleccionar..."}
        key={"area" + trigger}
        onChange={(e) => {
          setFilters({ ...filters, area_id: e.value })
          handleChangeArea({area_id: e.value})
        }}
        value={formattedAreas.find((x)=> x.value === filters.area_id)}
        options={formattedAreas}
       ></SelectComponent>
      </Col>
      <Col xs={2}>
       <p className={tableStyles.crudModalLabel}>Estado</p>
       <SelectComponent
        value={(statusOptions.find((x)=> x.value === filters.active))}
        styles={customSelectNewDark}
        placeholder={"Seleccionar..."}
        key={"state" + trigger}
        onChange={(e) => {
          setFilters({ ...filters, active: e.value })
          handleChangeStatus({active: e.value})
        }}
        // noOptionsMessage={() => "No hay datos"}
        options={statusOptions}
       ></SelectComponent>{" "}
      </Col>
      <Col
       xs={4}
       className="d-flex align-self-end gap-1"
      >
       <input
        className={tableStyles.SearchNew}
        value={filters.search}
        placeholder="Buscar..."
        type="text"
        onChange={(e) =>
          setFilters({ ...filters, search: e.target.value })
        }
        onKeyDown={(e) => {
         if (e.key === "Enter") {
          handleSearch(e)
         }
        }}
       ></input>
       <img
        className="hoverPointer"
        alt="iconSearch"
        style={{}}
        src={Search}
        height="24px"
        onClick={(e) => handleSearch(e)}
       />
      </Col>
      {!!myPermission?.create ? (
       <Col
        xs={4}
        className="align-self-end"
       >
        <div className="d-flex justify-content-end">
         <div
          onClick={() => handleClickNew()}
          className={tableStyles.createNomModule}
         >
          <b className={`mr-2`}>Crear cargo</b>
          <img
           src={Agregar}
           alt="User"
          />
          <div></div>
         </div>
        </div>
       </Col>
      ) : null}
      {/* <Col xs={5}>
          </Col> */}
          </Row>
          <GenericTableNew
            className="mt-0 "
            lowerCase
            headers={renderHeaders}
            dark={true}
          >
            {listElem()}
          </GenericTableNew>
          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.positionReducer.jobsPositionsList ? filters.page : ""}
              {" de "}
              {Math.ceil(
                store.positionReducer.row_positions_total / filters.perpage
              )
                ? Math.ceil(
                    store.positionReducer.row_positions_total / filters.perpage
                  )
                : ""}{" "}
              ({store.positionReducer.row_positions_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              // itemsCountPerPage={2}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.positionReducer.row_positions_total}
              // totalItemsCount={2}
              pageRangeDisplayed={5}
              onChange={(e) => {setFilters({ ...filters, page: e }); if(e !== filters.page){setLoading(true)}}}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
     </>
      }
        </div>
      </div>
    </>
  );
};
