import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import makeAnimated from "react-select/animated";
import backArrow from "../../../assets/img/icons/atras-icon.svg";
import Adjuntar from "../../../assets/img/icons/Adjuntar.svg";
import { useDispatch, useSelector } from "react-redux";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import iconCamera from "../../../assets/img/icons/blueCamera.svg";
import Loader from "react-loader-spinner";
import x from "../../../assets/img/icons/Cerrar.svg";
import {
  customSelectMulti,
  customSelectNew,
} from "../../Layouts/react-select-custom";
import userStyles from "../../userProfile/UserProfile.module.scss";
import IndividualStyles from "../../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import {
  getArl,
  getCandidateInfo,
  getCandidateList,
  getEndowmentGroups,
  getEPS,
  getPensionFund,
  updateCollaborator,
} from "../../../actions/partnersActions";
import {
  convertBase64,
  convertMoneyFormat,
  expRegList,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
  optionsSelect,
  validExpReg,
  valueSelect,
} from "../../../helpers/helpers";
import NumberFormat from "react-number-format";
import Dropzone from "react-dropzone";

import Swal from "sweetalert2";
import { getBankingEntities } from "../../../actions/providersActions";
import { GET_EMPLOYEE_INFO } from "../../../actions/actionTypes";
import Styles from "./partners.module.scss";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";
import { useGetMethod } from "../../../Hooks/useFetch";
import { Radio } from "@material-ui/core";

export const NewPartner = () => {
  const store = useSelector((state) => state);
  const isEditing = useLocation().state?.isEditing;
  const employeeToEdit = useLocation().state?.employeeToEdit;
  const currentPercentage = useLocation().state?.currentPercentage;
  const bankList = store.providerReducer.bankingEntities;
  const arrEmployees = store.partnersReducer.employee_list;
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const arrEps = store.partnersReducer.eps_list;
  const arrPensionFund = store.partnersReducer.pension_fund_list;
  const arrEndowmentGroups = store.partnersReducer.endowment_groups;
  const arrArl = store.partnersReducer.arl_list;
  const [assign, setAssign] = useState(false);
  const token = store.loginReducer.Authorization;
  const [currentEndowment, setCurrentEndowment] = useState(null);
  let employeeInfo = store.partnersReducer?.employee_info;
  const animatedComponents = makeAnimated();
  const [selectedFile, setSelectedFile] = useState(null);
  const [info, setInfo] = useState([]);
  const accTypes = [
    { value: "A", label: "Ahorros" },
    { value: "CO", label: "Corriente" },
  ];
  const myPermission =
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "pplTm"
    );
  const [clearData, setclearData] = useState();
  useEffect(() => {
    setclearData(clearData + 1);
    dispatch({
      type: GET_EMPLOYEE_INFO,
      payload: {},
    });
    setimage({ file: "", file64: "", fileUrl: employeeInfo?.photo });
  }, [clearData]);
  const [counter, setCounter] = useState(0);
  //es-lint-disable-next-line

  const {
    results: listRiskLevel,
    trigger: getListRiskLevel,
    load: loadListRiskLevel
  } = useGetMethod()

  const [files, setFiles] = useState([
    {
      filename: null,
      support_type: "s3_photo",
      base64: null,
    },
    {
      filename: null,
      support_type: "cv",
      base64: null,
    },
    {
      filename: null,
      support_type: "s3_bank_certificate_doc",
      base64: null,
    },
    {
      filename: null,
      support_type: "s3_data_verification",
      base64: null,
    },
    {
      filename: null,
      support_type: "s3_clinic_history",
      base64: null,
    },
  ]);
  // //console.log(files);
  // //console.log("info=>", info);
  const [image, setimage] = useState({
    file: "",
    file64: "",
    fileUrl: employeeInfo?.photo,
  });
  const [inputs, setInputs] = useState({
    cv: null,
    bank: undefined,
    colaborator: { value: undefined },
    // eps: {value: "", label: "Seleccionar..."},
    eps: undefined,
    // arl: {value: "", label: "Seleccionar..."},
    arl: undefined,
    pensionFund: { value: undefined },
    type: null,
    documents: files,
    endowmentGroups: null,
    bankNumber: null,
    pensioner: false,
    risk_level_id: undefined,
    payment_method: "Transferencia",
  });
  const [isLoading, setIsLoading] = useState(false);

  const formattedEmployees = formatToRcSelect(
    arrEmployees,
    "id_contract",
    "full_name",
    "id_candidate",
    "",
    "job_title"
  );

  const fotmattedEndowments = formatToRcSelect(
    arrEndowmentGroups,
    "id",
    "description",
    "",
    "",
    false
  );
  //   const validExtensions = ["pdf", "PDF", "zip", "ZIP", "rar"];||
  const dispatch = useDispatch();
  const history = useHistory();
  const bankListFormatted = formatToRcSelect(bankList, "id", "description", "");
  const epsListFormatted = formatToRcSelect(arrEps, "id", "description", "");
  const pensionFundFormatted = formatToRcSelect(
    arrPensionFund,
    "id",
    "description",
    ""
  );
  const arlListFormatted = formatToRcSelect(arrArl, "id", "description", "");
  const riskLevelOptions = formatToRcSelect(listRiskLevel.results, "riskLevelId", "riskLevelDescription", "", "", "riskLevelValue", true, "", "", "", "", "", "", "", "", "", "%");
  useEffect(() => {
    if (!!!myPermission?.read) {
      history.push("/nomina/inicio");
    }
    if (isEditing) {


      if (!isEditing) {
        setInputs({ ...inputs, bankNumber: employeeInfo.bank_account_nbr });
      }

      dispatch(
        getCandidateInfo({
          id_candidate: employeeToEdit?.id_candidate,
          id_contract: employeeToEdit?.id_contract,
        },
          (res) => {
            setInputs({
              cv: null,
              // bank: bankListFormatted.find(
              //   (obj) => obj.value === employeeToEdit?.bankingEntity
              // ),
              bank: res.results?.bankingEntity ?? "",

              colaborator: { value: employeeToEdit?.id_candidate },
              // eps: epsListFormatted.find(
              //   (obj) => obj.value === employeeToEdit?.hce_id
              // ),
              eps: res.results?.hce_id ?? "",

              // arl: arlListFormatted.find(
              //   (obj) => obj.value === employeeToEdit?.occupational_risk_entity
              // ),
              arl: res.results?.occupational_risk_entity ?? "",
              risk_level_id: res.results?.risk_level_id ?? "",
              pensionFund: pensionFundFormatted.find(
                (obj) => obj.value === employeeInfo?.pension_fund_entity
              ),
              type: accTypes.find(
                (obj) => obj.value === employeeToEdit?.bank_account_type
              ),
              documents: files,
              endowmentGroups: null,
              bankNumber: employeeToEdit.bank_account_nbr,
              pensioner: employeeInfo.pensioner ?? false,
              payment_method: res.results?.payment_method ?? "",
            })
          }

        )
      );


    }
    dispatch(getCandidateList({ status: "signed", eaccount: idEnterprise }));
    dispatch(getBankingEntities());
    dispatch(getEPS());
    dispatch(getPensionFund());
    dispatch(getArl());
    dispatch(getEndowmentGroups({ active: 1 }));

    getListRiskLevel({
      url: `/payroll/occupationalRiskLevels/`,
      objFilters: { riskLevelId: inputs?.risk_level_id },
      token: token
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // //console.log("Inputs=>", inputs);

  const handleImagePick = async (e) => {
    if (!!!e.target.files[0]) {
      return;
    }
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1]; // Delete base64 prefix (data:application...

    let f = e.target.files[0];
    let name_doc = f.name.split(".")[0];
    let type_doc = f.type;
    let ext = type_doc.split("/");

    let finalName = `${name_doc}.${ext[1]}`;

    setimage({
      ...image,
      file: e.target.files[0],
      file64: file64,
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });

    files[0].base64 = file64;
    files[0].filename = finalName;
    // URL.createObjectURL(e.target.files[0]);
  };
  const cvFile = () => {
    if (employeeInfo.documents?.length > 0) {
      //   let cv = employeeInfo.documents?.find((cv) => cv.prefix === "cv");
      let name = employeeInfo.documents
        ?.find((cv) => cv.prefix === "cv")
        ?.s3_location?.split("/")
        .pop();
      return name;
    }
  };
  useEffect(() => {
    employeeInfo?.endowmentGroups?.length > 0
      ? setAssign(true)
      : setAssign(false);
  }, [employeeInfo?.endowmentGroups]);

  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const onChangeDoc = async (e, supportType) => {
    setCounter(counter + 1);
    const file = e.target.files[0];
    const validExtensions = ["zip", "pdf", "rar"];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split(".");
      let extension = validExtensions.find(
        (obj) => obj === actFileExt[actFileExt.length - 1]
      );
      if (isEmptyOrUndefined(extension)) {
        setSelectedFile(null);
        e.target.value = "";
        return (
          customSwaltAlert({
            icon: "warning",
            title: 'Intenta de nuevo',
            text: `No se permiten documentos con extensión .${actFileExt[
              actFileExt.length - 1
            ].toUpperCase()}`,
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
      }

      if (file.size > 50000000) {
        setSelectedFile(null);
        e.target.value = "";
        return (
          customSwaltAlert({
            icon: "warning",
            title: 'Intenta de nuevo',
            text: 'Su archivo no puede tener un tamaño superior a 5MB',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
      } else {
        if (file.size <= 0) {
          setSelectedFile(null);
          e.target.value = "";
          return (
            customSwaltAlert({
              icon: "warning",
              title: 'Intenta de nuevo',
              text: 'Por favor, adjunte un archivo valido.',
              confirmButtonText: "Aceptar",
              showCancelButton:false
            })
          )
        }
      }
      if (!isEmptyOrUndefined(file)) {
        setSelectedFile(file);
      }
    } else {
      return console.error("Archivo vacio: ", file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split(".")[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(",")[1];
    let ext = type_doc.split("/");
    let finalName = `${name_doc}.${ext[1]}`;
    setInfo({
      ...info,
      file64: file64,
      fileName: name_doc,
      type_doc: type_doc,
    });
    switch (supportType) {
      // case "s3_photo":
      //   files[0].filename = finalName;
      //   files[0].base64 = file64;
      //   break;
      case "cv":
        files[1].filename = finalName;
        files[1].base64 = file64;
        break;
      case "s3_bank_certificate_doc":
        files[2].filename = finalName;
        files[2].base64 = file64;
        break;

      case "s3_data_verification":
        files[3].filename = finalName;
        files[3].base64 = file64;
        break;
      case "s3_clinic_history":
        files[4].filename = finalName;
        files[4].base64 = file64;
        break;

      default:
        break;
    }
  };
  const handleSubmit = async () => {
    // //console.log(inputs);
    // //console.log(files);
    let remoteCvLocation = employeeInfo?.documents
      ?.find((cv) => cv.prefix === "cv")
      ?.s3_location?.toString();

    let remoteDocVerificated = employeeInfo?.s3_data_verification?.toString();

    if (
      isEmptyOrUndefined(remoteCvLocation) &&
      isEmptyOrUndefined(files[1].base64)
    ) {
      return (
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: 'El documento de hoja de vida es obligatorio',
          confirmButtonText: "Aceptar",
          showCancelButton:false
        })
      )
    }

    // if (
    //   isEmptyOrUndefined(inputs?.risk_level_id)
    // ) {
    //   return message(
    //     "warning",
    //     "Campos obligatorios",
    //     "El campo niveles de riesgo ocupacional es obligatorio"
    //   );
    // }

    if (
      isEmptyOrUndefined(remoteDocVerificated) &&
      isEmptyOrUndefined(employeeToEdit?.s3_data_verification?.toString()) &&
      isEmptyOrUndefined(files[3].filename)
    ) {
      return (
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: 'El documento de verificación de datos es obligatorio',
          confirmButtonText: "Aceptar",
          showCancelButton:false
        })
      )
    }

    try {
      if (isEmptyOrUndefined(inputs.colaborator)) {
        setIsLoading(false);
        return (
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Seleccione un colaborador',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
      }
      if (isEmptyOrUndefined(inputs.eps)) {
        setIsLoading(false);
        return (
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Seleccione una Entidad Prestadora de Salud',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
      }
        if (inputs.payment_method === "Transferencia"){
          if (isEmptyOrUndefined(inputs.bankNumber)) {
            setIsLoading(false);
            return message("warning", "Advertencia", "Escriba un número de cuenta",
            undefined,
              true
            );
          }
          if (isEmptyOrUndefined(inputs.type)) {
            setIsLoading(false);
            return message("warning", "Advertencia", "Seleccione un tipo de cuenta",
            undefined,
              true
            );
          }
          if (isEmptyOrUndefined(inputs.bank)) {
            setIsLoading(false);
            return message("warning", "Advertencia", "Seleccione un banco",
            undefined,
              true
            );
          }
      }

      if (isEmptyOrUndefined(inputs.arl)) {
        setIsLoading(false);
        return (
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Seleccione una ARL',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
      }
      if (isEmptyOrUndefined(inputs.risk_level_id)) {
        setIsLoading(false);
        return (
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: 'Seleccione un nivel de riesgo ocupacional',
            confirmButtonText: "Aceptar",
            showCancelButton:false
          })
        )
      }
      if (inputs.pensioner === false) {
        if (isEmptyOrUndefined(inputs.pensionFund.value)) {
          setIsLoading(false);
          return (
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: 'Seleccione un fondo de pensiones',
              confirmButtonText: "Aceptar",
              showCancelButton:false
            })
          )
        }
      }
      customSwaltAlert({
        icon: "warning",
        title: "¿Está seguro?",
        text: isEditing
          ? `¿ Deseas actualizar los datos de ${employeeToEdit?.full_name} ?`
          : `${employeeInfo.full_name
          }  comenzará a hacer parte de la nómina de ${store.loginReducer?.currentAccount?.name.toUpperCase()}`,
        // confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, Continuar",
        // cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const send = async () => {
            setIsLoading(true);
            try {

              const result = await updateCollaborator(
                {
                  id_candidate:
                    employeeInfo.id_candidate || employeeToEdit?.id_candidate,
                  id_contract:
                    employeeInfo.id_contract || employeeToEdit?.id_contract,
                  full_name:
                    employeeInfo.full_name || employeeToEdit?.full_name,
                  entity_account: idEnterprise,
                  // healthcare_entity: inputs.eps?.value,
                  healthcare_entity: inputs.eps,
                  pension_fund_entity: inputs.pensionFund?.value,
                  occupational_risk_entity: inputs.arl,
                  bank_account_nbr: inputs.bankNumber,
                  bank_account_type: inputs.type?.value,
                  // bankingEntity: inputs.bank?.value,
                  bankingEntity: inputs?.bank,
                  status: "verified",
                  contract_status: "current",
                  endowmentGroups: inputs.endowmentGroups,
                  document: files,
                  percentage: isEditing ? currentPercentage : 20,
                  risk_level_id: inputs?.risk_level_id,
                  pensioner: inputs?.pensioner,
                  payment_method: inputs?.payment_method
                },
                token
              );

              if (result.success) {
                setIsLoading(false);
                history.push({
                  pathname: "/nomina/colaborador/curriculo",
                  state: {
                    employeeInfo: isEditing
                      ? { ...employeeToEdit, isEditing: true }
                      : employeeInfo,
                  },
                  // state: employeeInfo,
                  // state: { isEditing: true, employeeToEdit: employeeInfo },
                });
                dispatch({
                  type: GET_EMPLOYEE_INFO,
                  payload: {
                    id_contract: "",
                    full_name: "",
                    birthdate: "",
                    description: "",
                    job_position: "",
                    job_title: "",
                    consumption_center: "",
                    functional_unit_description: "",
                    start_date: "",
                    site_id: "",
                    site_name: "",
                    site_address: "",
                    base_salary: "",
                    non_const_income: "",
                    hce_id: "",
                    hce_description: "",
                    pension_fund_entity: "",
                    pension_description: "",
                    occupational_risk_entity: "",
                    occupational_risk_description: "",
                    bank_account_nbr: "",
                    bank_account_type: "",
                    bankingEntity: "",
                  },
                });

                setInputs({
                  cv: null,
                  bank: null,
                  colaborator: {},
                  eps: null,
                  arl: "",
                  pensionFund: null,
                  type: null,
                  documents: files,
                  endowmentGroups: null,
                  risk_level_id: null
                });

                return (
                  customSwaltAlert({
                    icon: "success",
                    title: isEditing
                      ? "Actualizado exitosamente"
                      : "creado exitosamente",
                    text: isEditing
                      ? `Se ha actualizado el candidato: ${employeeInfo.full_name}`
                      : `Se ha creado el candidato: ${employeeInfo.full_name}`,
                    confirmButtonText: "Aceptar",
                    showCancelButton:false
                  })
                )
              } else {
                setIsLoading(false);
                return (
                  customSwaltAlert({
                    icon: "warning",
                    title: 'Intenta de nuevo',
                    text: result.message,
                    confirmButtonText: "Aceptar",
                    showCancelButton:false
                  })
                )
              }
            } catch (error) {

              setIsLoading(false);
              customSwaltAlert({
                icon: "warning",
                title: 'Intenta de nuevo',
                text: error.message,
                confirmButtonText: "Aceptar",
                showCancelButton:false
              })
            }
          };
          send();
        }
      });
    } catch (error) {
      setIsLoading(false);
      return (
        customSwaltAlert({
          icon: "warning",
          title: 'Intenta de nuevo',
          text: error.message,
          confirmButtonText: "Aceptar",
          showCancelButton:false
        })
      )
    }
  };

  const handleBack = () => {
    setclearData(clearData + 1);
    history.goBack();
  };
  const getEmployeeInfo = (e) => {
    dispatch({
      type: GET_EMPLOYEE_INFO,
      payload: {
        id_contract: "",
        full_name: "",
        birthdate: "",
        description: "",
        job_position: "",
        job_title: "",
        consumption_center: "",
        functional_unit_description: "",
        start_date: "",
        site_id: "",
        site_name: "",
        site_address: "",
        base_salary: "",
        non_const_income: "",
        hce_id: "",
        hce_description: "",
        pension_fund_entity: "",
        pension_description: "",
        occupational_risk_entity: "",
        occupational_risk_description: "",
        bank_account_nbr: "",
        bank_account_type: "",
        bankingEntity: "",
      },
    });
    setFiles([
      {
        filename: null,
        support_type: "s3_photo",
        base64: null,
      },
      {
        filename: null,
        support_type: "cv",
        base64: null,
      },
      {
        filename: null,
        support_type: "s3_bank_certificate_doc",
        base64: null,
      },
      {
        filename: null,
        support_type: "s3_data_verification",
        base64: null,
      },
      {
        filename: null,
        support_type: "s3_clinic_history",
        base64: null,
      },
    ]);
    setInputs({
      cv: null,
      bank: "",
      colaborator: {},
      eps: null,
      arl: "",
      pensionFund: null,
      type: null,
      documents: files,
      endowmentGroups: null,
      risk_level_id: "",
      pensioner: false
    });
    if (!isEmptyOrUndefined(e?.value)) {
      setInputs({ ...inputs, colaborator: e });
      dispatch(
        getCandidateInfo({ id_candidate: e.extra, id_contract: e?.value })
      );
    }
  };

  const handleEndowments = (e) => {
    setCurrentEndowment(e);
    let arrIdEnd = [];

    if (e.length > 0) {
      e.forEach((elem) => {
        arrIdEnd.push(elem.value);
      });
    }
    setInputs({ ...inputs, endowmentGroups: arrIdEnd });
  };

  const onChangeCheckBox = (value) => {
    if (inputs.pensioner) {
      setInputs({ ...inputs, pensioner: false })
    } else {
      setInputs({ ...inputs, pensioner: true })
    }
  }

  const handleChangePaymentMethod = (value, key)=>{
    setInputs({ ...inputs, [key]: value })
  }
  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      <div className="w-100 container">
        <Row>
          <Col xs={10}
            style={{ marginLeft: "-32px" }}
          >
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={backArrow}
                className={`mr-2 mb-2 cursorPointer`}
                alt="icon"
              ></img>
              {isEditing ? "Editar colaborador" : "Nuevo colaborador"}
            </h1>
          </Col>
        </Row>
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <div className="d-flex justify-content-between">
          <div className="mt-4" style={{ width: "75%" }}>
            {isEditing ? (
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Colaborador seleccionado
                </p>
                <input
                  disabled={true}
                  value={employeeToEdit?.full_name}
                  className="register-inputs text-secondary"
                  name="position"
                />
              </>
            ) : (
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Seleccionar colaborador
                  <span className={"text-danger"}>*</span>
                </p>
                <SelectComponent
                  key={"colab"}
                  onChange={(e) => getEmployeeInfo(e)}
                  options={formattedEmployees}
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={customSelectNew}
                  value={formattedEmployees.find((x) => x.value === inputs?.colaborator?.value)}

                ></SelectComponent>
              </>
            )}

            <Row className="mt-2">
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Código de contrato
                </p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.id_contract
                      : employeeInfo?.id_contract
                        ? employeeInfo?.id_contract
                        : ""
                  }
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Nombre del cargo
                </p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.job_title
                      : employeeInfo?.job_title
                        ? employeeInfo?.job_title
                        : ""
                  }
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha inicio
                  <span className={"text-danger"}>*</span>
                </p>
                <input
                  value={
                    isEditing
                      ? employeeToEdit?.start_date
                      : employeeInfo?.start_date
                        ? employeeInfo?.start_date
                        : ""
                  }
                  disabled={true}
                  type="date"
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
            </Row>
            <h6 className={`${tableStyles.darkBlueText} mt-4`}>
              <b>• Contabilidad</b>
            </h6>

            <Row className="">
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Centro de consumo
                </p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.consumption_center
                      : employeeInfo?.consumption_center
                        ? employeeInfo?.consumption_center
                        : ""
                  }
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Unidad funcional
                </p>
                <input
                  disabled={true}
                  value={
                    isEditing
                      ? employeeToEdit?.functional_unit_description
                      : employeeInfo?.functional_unit_description
                        ? employeeInfo?.functional_unit_description
                        : ""
                  }
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Salario</p>
                <NumberFormat
                  disabled={true}
                  className={`register-inputs text-secondary`}
                  name="rnd_value_note"
                  allowNegative={false}
                  prefix="$"
                  value={
                    isEditing
                      ? employeeToEdit?.base_salary
                      : employeeInfo?.base_salary
                        ? employeeInfo?.base_salary
                        : ""
                  }
                  thousandsGroupStyle="thousand"
                  thousandSeparator
                />
              </Col>
            </Row>

            <Row className="">
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Ingreso no constitutivo
                </p>
                <NumberFormat
                  disabled={true}
                  className={`register-inputs text-secondary`}
                  name="ingress_not_const"
                  allowNegative={false}
                  prefix="$"
                  value={
                    isEditing
                      ? employeeToEdit?.non_const_income
                      : employeeInfo?.non_const_income
                        ? employeeInfo?.non_const_income
                        : ""
                  }
                  thousandsGroupStyle="thousand"
                  thousandSeparator
                />
                {/* <input
                  value={
                    isEditing
                      ? employeeToEdit?.non_const_income
                      : employeeInfo?.non_const_income
                        ? employeeInfo?.non_const_income
                        : ""
                  }
                  disabled={true}
                  className="register-inputs text-secondary"
                  name="position"
                /> */}
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Sede</p>
                <input
                  value={
                    isEditing
                      ? employeeToEdit?.site_name
                      : employeeInfo?.site_name
                        ? employeeInfo?.site_name
                        : ""
                  }
                  disabled={true}
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Dirección</p>
                <input
                  value={
                    isEditing
                      ? employeeToEdit?.site_address
                      : employeeInfo?.site_address
                        ? employeeInfo?.site_address
                        : ""
                  }
                  disabled={true}
                  className="register-inputs text-secondary"
                  name="position"
                />
              </Col>
            </Row>

            <Row className="">
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Entidad Prestadora de Salud</p>
                <SelectComponent
                  // onChange={(e) => setInputs({ ...inputs, eps: e })}
                  // value={
                  //   inputs.eps === null
                  //     ? epsListFormatted.find(
                  //       (obj) => obj.value === employeeInfo?.hce_id
                  //     ) || ""
                  //     : inputs.eps || ""
                  // }
                  value={epsListFormatted.find(
                    (x) => x.value === inputs.eps
                  )}
                  onChange={({ value }) => {
                    setInputs({ ...inputs, eps: value })
                  }}
                  options={epsListFormatted}
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={customSelectNew}
                ></SelectComponent>
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>ARL</p>
                <SelectComponent
                  // onChange={(e) => {
                  //   if(e.value === '') {
                  //     setInputs({ ...inputs, arl: e , risk_level_id : '' })
                  //   }else{
                  //     setInputs({ ...inputs, arl: e })
                  //   }

                  // }}
                  // value={
                  //   inputs.arl == ""
                  //     ? arlListFormatted.find(
                  //       (obj) =>
                  //         obj.value === employeeInfo?.occupational_risk_entity
                  //     )
                  //     : inputs.arl
                  // }
                  value={arlListFormatted.find(
                    (x) => x.value === inputs.arl
                  )}
                  onChange={({ value }) => {
                    if (value === '') {
                      setInputs({ ...inputs, arl: value, risk_level_id: '' })
                    } else {
                      setInputs({ ...inputs, arl: value })
                    }

                  }}
                  options={arlListFormatted}
                  className="text-secondary"
                  placeholder={"Seleccionar..."}
                  styles={customSelectNew}
                ></SelectComponent>
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>Niveles de riesgo ocupacional</p>

                <SelectComponent
                  // options={optionsSelect({
                  //   list: listRiskLevel?.results,
                  //   label: 'riskLevelDescription',
                  //   value: 'riskLevelId'
                  // })}
                  // value={valueSelect({
                  //   list: listRiskLevel?.results,
                  //   findId: 'riskLevelId',
                  //   findLabel: 'riskLevelDescription',
                  //   value: inputs?.risk_level_id
                  // })}
                  options={riskLevelOptions}
                  value={riskLevelOptions.find(
                    (x) => x.value === inputs.risk_level_id
                  )}
                  onChange={({ value }) => {
                    setInputs({ ...inputs, risk_level_id: value })
                  }}
                  className="text-secondary"
                />
              </Col>
              {inputs.pensioner === false &&
                <Col xs={4}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Fondo de pensiones
                  </p>
                  <SelectComponent
                    onChange={(e) => setInputs({ ...inputs, pensionFund: e })}
                    value={
                      inputs.pensionFund === undefined
                        ? pensionFundFormatted.find(
                          (obj) =>
                            obj.value === employeeToEdit?.pension_fund_entity
                        ) || ""
                        : inputs.pensionFund || ""
                    }
                    // value={
                    //   inputs.pensionFund == ""
                    //     ? pensionFundFormatted.find(
                    //         (obj) =>
                    //           obj.value === employeeInfo?.pension_fund_entity
                    //       )
                    //     : inputs.pensionFund
                    // }
                    options={pensionFundFormatted}
                    className="text-secondary"
                    placeholder="Seleccionar..."
                    styles={customSelectNew}
                  ></SelectComponent>
                </Col>
              }
              <Col
                className=" align-self-center "
                xs={4}
              >
                <p className={`${tableStyles.crudModalLabel} `}>&nbsp;</p>
                <div className="d-flex">
                  <input
                    // checked={data?.modules?.some((e) => e?.id === mod?.id)}
                    checked={inputs.pensioner}
                    id={`checkBox-`}
                    key={`keyCheckbox-`}
                    onChange={(e) => onChangeCheckBox(e)}
                    className={` form-check-input p1 `}
                    type="checkbox"
                    style={{ width: "16px", height: "16px", border: " 1.5px solid #7FADDE" }}
                  />
                  <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} px-2 pt-1`}>Pensionado</label>
                </div>

              </Col>
            </Row>



            {isEmptyOrUndefined(inputs?.colaborator?.value) ? (
              <></>
            ) : (
              <>
                <Row
                  className={` mt-4 pb-2 ${tableStyles.bgClearBlue}`}
                  style={{ borderRadius: "10px" }}
                >
                  <h6 className={`${tableStyles.darkBlueText} pt-1`}>
                    <b>• Método de pago</b>
                  </h6>
                  <div className="d-flex">
                  <div className="d-flex">
                      <Radio
                        checked={inputs.payment_method === "Transferencia" ? true : false}

                        onClick={(e) => {
                          handleChangePaymentMethod("Transferencia", "payment_method")
                        }}
                        name="alphabetic"
                        // color="primary"
                        // className={"text-info"}
                        // id={Number(radio.id)}
                        style={{ color: "#005dbf" }}
                        onChange={(e) => {
                          // handleRadioChange(e)
                        }}
                      />
                <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} px-2 pt-1 align-self-center`}>Transferencia</label>
                  </div>
                  <div className="d-flex">
                      <Radio
                        checked={inputs.payment_method === "Cheque" ? true : false}
                        onClick={(e) => {
                          handleChangePaymentMethod("Cheque", "payment_method")
                        }}
                        // value="alphabetic"
                        name="alphabetic" 
                        // color="primary"
                        // className={"text-info"}
                        // id={Number(radio.id)}
                        style={{ color: "#005dbf" }}
                        onChange={(e) => {
                          // handleRadioChange(e)
                        }}
                      />
                <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} px-2 pt-1 align-self-center`}>Cheque</label>
                  </div>
                  </div>
                  { inputs.payment_method === "Transferencia" &&
                    <>
                  <h6 className={`${tableStyles.darkBlueText} pt-1`}>
                    <b>• Cuenta bancaria del empleado</b>
                  </h6>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      No. Cuenta
                    </p>
                    <input
                      onChange={(e) => {
                        // if (validExpReg(e.target.value, expRegList.number)) {
                        setInputs({ ...inputs, bankNumber: e.target.value });
                        // }
                      }}
                      value={
                        inputs.bankNumber
                        // isEditing? employeeToEdit?.bank_account_nbr
                        //   : inputs.bankNumber? employeeInfo?.bank_account_nbr
                        //   : ""
                      }
                      placeholder="Escribe..."
                      className="register-inputs text-secondary"
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>Tipo</p>
                    <SelectComponent
                      value={
                        inputs.type === null
                          ? accTypes.find(
                            (obj) =>
                              obj.value === employeeInfo?.bank_account_type
                          )
                          : inputs.type
                      }
                      options={accTypes}
                      className="text-secondary"
                      onChange={(e) => setInputs({ ...inputs, type: e })}
                      placeholder={"Seleccionar..."}
                      styles={customSelectNew}
                    ></SelectComponent>
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>Banco</p>
                    <SelectComponent
                      // onChange={(e) => setInputs({ ...inputs, bank: e })}
                      // value={
                      //   inputs.bank === null
                      //     ? bankListFormatted.find(
                      //       (obj) => obj.value === employeeInfo?.bankingEntity
                      //     )
                      //     : inputs.bank
                      // }
                      value={bankListFormatted.find(
                        (x) => x.value === inputs.bank
                      )}
                      onChange={({ value }) => {
                        setInputs({ ...inputs, bank: value })
                      }}
                      options={bankListFormatted}
                      className="text-secondary"
                      placeholder={"Seleccionar..."}
                      styles={customSelectNew}
                    ></SelectComponent>
                  </Col>
                    </>
                  }
                </Row>
                <h6 className={`${tableStyles.darkBlueText} mt-2`}>
                  <b>• Anexos</b>
                </h6>
                <Row className="">
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Hoja de vida
                      <span className={"text-warning"}>*</span>
                    </p>
                    <Dropzone>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="d-flex justify-content-between"
                          style={{
                            border: "1px solid #7FADDE",
                            borderRadius: "5px",
                            height: "52%",
                          }}
                        >
                          <input
                            key={`cv-${counter}`}
                            disabled={
                              inputs.colaborator === null ? true : false
                            }
                            {...getInputProps()}
                            type="file"
                            className="register-inputs text-secondary"
                            name="documents"
                            onChange={(e) => onChangeDoc(e, "cv")}
                            multiple={false}
                          />
                          {/* {!isEmptyOrUndefined(info.fileName) ? ( */}
                          {!isEmptyOrUndefined(files[1].filename) ? (
                            <p
                              style={{ margin: "0" }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[1].filename} &nbsp;
                              {!isEmptyOrUndefined(files[1].filename) &&
                                isEmptyOrUndefined([1].base64) && (
                                  <img
                                    width="20px"
                                    className="cursorPointer"
                                    onClick={() => {
                                      files[1].base64 = "";
                                      files[1].filename = "";
                                    }}
                                    alt="icon"
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer 
                          ${IndividualStyles.ellipsis}`}
                            >
                              {employeeInfo?.documents
                                ?.find((cv) => cv.prefix === "cv")
                                ?.s3_location?.split("/")
                                .pop()}
                            </p>
                          )}
                          {/* SI we_billDocument está vacio entonces cargar */}
                          {isEmptyOrUndefined(
                            employeeInfo?.documents
                              ?.find((cv) => cv.prefix === "cv")
                              ?.s3_location?.split("/")
                              .pop()
                          ) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title="añadir"
                              width="6%"
                              alt="icon"
                            />
                          ) : (
                            // SI NO ENTONCES DESCARGAR!
                            <img
                              className={Styles.filterImage}
                              onClick={
                                isEmptyOrUndefined(cvFile())
                                  ? null
                                  : () => {
                                    window.location.href =
                                      employeeInfo?.documents?.find(
                                        (cv) => cv.prefix === "cv"
                                      )?.s3_location;
                                  }
                              }
                              alt="icon"
                              width="6%"
                              src={Adjuntar}
                              title="descargar"
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Resultados verificación de datos
                      <span className={"text-danger"}>*</span>
                    </p>
                    <Dropzone>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="d-flex justify-content-between"
                          style={{
                            border: "1px solid #7FADDE",
                            borderRadius: "5px",
                            height: "52%",
                          }}
                        >
                          <input
                            key={`verification-${counter}`}
                            {...getInputProps()}
                            disabled={
                              inputs.colaborator === null ? true : false
                            }
                            type="file"
                            className="register-inputs text-secondary"
                            name="documents"
                            onChange={(e) =>
                              onChangeDoc(e, "s3_data_verification")
                            }
                            multiple={false}
                          />
                          {!isEmptyOrUndefined(files[3].filename) ? (
                            <p
                              style={{ margin: "0" }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[3].filename} &nbsp;
                              {!isEmptyOrUndefined(files[3].filename) &&
                                isEmptyOrUndefined([3].base64) && (
                                  <img
                                    alt=""
                                    width="20px"
                                    className="cursorPointer"
                                    onClick={() => {
                                      //console.log(files);
                                      files[3].base64 = "";
                                      files[3].filename = "";
                                    }}
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer 
                          ${IndividualStyles.ellipsis}`}
                            >
                              {isEditing
                                ? employeeToEdit?.s3_data_verification
                                  ?.split("/")
                                  .pop()
                                : employeeInfo?.s3_data_verification
                                  ?.split("/")
                                  .pop()}
                            </p>
                          )}
                          {/* SI s3_data_verification está vacio entonces cargar */}
                          {isEmptyOrUndefined(
                            employeeInfo?.s3_data_verification
                          ) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title="añadir"
                              width="16px"
                              alt="cion"
                            />
                          ) : (
                            // SI NO ENTONCES DESCARGAR!
                            <img
                              className={Styles.filterImage}
                              alt="icon"
                              onClick={
                                isEmptyOrUndefined(
                                  employeeInfo?.s3_data_verification
                                )
                                  ? null
                                  : () => {
                                    window.location.href =
                                      employeeInfo?.s3_data_verification;
                                  }
                              }
                              width="16px"
                              src={Adjuntar}
                              title="descargar"
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Historia clínica
                    </p>
                    <Dropzone
                    // onDrop={(acceptedFile) => validateFile(acceptedFile)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="d-flex justify-content-between"
                          style={{
                            border: "1px solid #7FADDE",
                            borderRadius: "5px",
                            height: "52%",
                          }}
                        >
                          <input
                            key={`history-${counter}`}
                            {...getInputProps()}
                            disabled={
                              inputs.colaborator === null ? true : false
                            }
                            type="file"
                            className="register-inputs text-secondary"
                            name="documents"
                            onChange={(e) =>
                              onChangeDoc(e, "s3_clinic_history")
                            }
                            multiple={false}
                          />

                          {!isEmptyOrUndefined(files[4].filename) ? (
                            <p
                              style={{ margin: "0" }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[4].filename} &nbsp;
                              {!isEmptyOrUndefined(files[4].filename) &&
                                isEmptyOrUndefined([4].base64) && (
                                  <img
                                    alt=""
                                    width="20px"
                                    className="cursorPointer"
                                    onClick={() => {
                                      //console.log(files);
                                      files[4].base64 = "";
                                      files[4].filename = "";
                                    }}
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer 
                          ${IndividualStyles.ellipsis}`}
                            >
                              {isEditing
                                ? employeeToEdit?.s3_clinic_history
                                  ?.split("/")
                                  .pop()
                                : employeeInfo?.s3_clinic_history
                                  ?.split("/")
                                  .pop()}
                            </p>
                          )}
                          {/* SI we_billDocument está vacio entonces cargar */}
                          {isEmptyOrUndefined(
                            employeeInfo?.s3_clinic_history
                          ) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title="añadir"
                              width="16px"
                              alt="icon"
                            />
                          ) : (
                            // SI NO ENTONCES DESCARGAR!
                            <img
                              className={Styles.filterImage}
                              onClick={
                                isEmptyOrUndefined(
                                  employeeInfo?.s3_clinic_history
                                )
                                  ? null
                                  : () => {
                                    window.location.href =
                                      employeeInfo?.s3_clinic_history;
                                  }
                              }
                              alt="icon"
                              width="16px"
                              src={Adjuntar}
                              title="descargar"
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>

                  <Col xs={4}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Certificado de cuenta bancaria
                    </p>
                    <Dropzone
                    // onDrop={(acceptedFile) => validateFile(acceptedFile)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="d-flex justify-content-between"
                          style={{
                            border: "1px solid #7FADDE",
                            borderRadius: "5px",
                            height: "52%",
                          }}
                        >
                          <input
                            key={`bank-certify-${counter}`}
                            {...getInputProps()}
                            disabled={
                              inputs.colaborator === null ? true : false
                            }
                            type="file"
                            className="register-inputs text-secondary"
                            name="documents"
                            onChange={(e) =>
                              onChangeDoc(e, "s3_bank_certificate_doc")
                            }
                            multiple={false}
                          />
                          {!isEmptyOrUndefined(files[2].filename) ? (
                            <p
                              style={{ margin: "0" }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsis} hoverPointer`}
                            >
                              {files[2].filename} &nbsp;
                              {!isEmptyOrUndefined(files[2].filename) &&
                                isEmptyOrUndefined([2].base64) && (
                                  <img
                                    alt=""
                                    width="20px"
                                    className="cursorPointer"
                                    onClick={() => {
                                      //console.log(files);
                                      files[2].base64 = "";
                                      files[2].filename = "";
                                    }}
                                    src={x}
                                  ></img>
                                )}
                            </p>
                          ) : (
                            <p
                              className={`${IndividualStyles.crudModalLabelGris} hoverPointer 
                          ${IndividualStyles.ellipsis}`}
                            >
                              {isEditing
                                ? employeeToEdit?.s3_bank_certificate_doc
                                  ?.split("/")
                                  .pop()
                                : employeeInfo?.s3_bank_certificate_doc
                                  ?.split("/")
                                  .pop()}
                            </p>
                          )}
                          {/* SI we_billDocument está vacio entonces cargar */}
                          {isEmptyOrUndefined(
                            employeeInfo?.s3_bank_certificate_doc
                          ) ? (
                            <img
                              className={Styles.filterImage}
                              src={Adjuntar}
                              title="añadir"
                              width="16px"
                              alt=""
                            />
                          ) : (
                            // SI NO ENTONCES DESCARGAR!
                            <img
                              alt="icon"
                              className={Styles.filterImage}
                              onClick={
                                isEmptyOrUndefined(
                                  employeeInfo?.s3_bank_certificate_doc
                                )
                                  ? null
                                  : () => {
                                    window.location.href =
                                      employeeInfo?.s3_bank_certificate_doc;
                                  }
                              }
                              width="16px"
                              src={Adjuntar}
                              title="descargar"
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Row>{" "}
                <Col xs={6} style={{ marginTop: "1.6%" }}>
                  <div
                    style={{ alignSelf: "flex-end", borderRadius: "6px" }}
                    className={`d-flex p-1`}
                  >
                    <div>
                      <input
                        // checked={assign}
                        onChange={(e) => setAssign(e.target.checked)}
                        className="mt-2 border border-dark-blue form-check-input p1 check-dark-blue"
                        type="checkbox"
                        checked={assign}
                        id="assi"
                      ></input>
                    </div>
                    <div>
                      <label
                        for="assi"
                        className={`${tableStyles.crudModalLabel} text-secondary`}
                      >
                        <b>Asignar dotación</b>
                      </label>
                    </div>
                  </div>
                </Col>
              </>
            )}

            {assign && (
              <>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Grupos dotacionales
                </p>
                <Col xs={8}>
                  <SelectComponent
                    isMulti
                    value={
                      currentEndowment === null
                        ? formatToRcSelect(
                          employeeInfo?.endowmentGroups,
                          "id",
                          "description",
                          "",
                          "",
                          "",
                          false
                        )
                        : currentEndowment
                    }
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={(e) => handleEndowments(e)}
                    options={fotmattedEndowments}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={customSelectMulti}
                  ></SelectComponent>
                </Col>
              </>
            )}
          </div>

          <hr
            className="mt-5"
            style={{
              border: "none",
              borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
              height: "100vh",
              width: "1px",
            }}
          ></hr>

          <Col xs={2}>
            <Card
              border=""
              className="w-100 mt-5"
              style={{ width: "20%", height: "10rem", border: "1px solid #7FADDE" }}
            >
              {/* SI EL USUAIO PUSO UNA FOTO */}
              {image?.fileUrl ? (
                <>
                  <img
                    style={{ position: "absolute" }}
                    className={`${tableStyles.x} mt-2 mr-2`}
                    src={x}
                    onClick={() =>
                      setimage({
                        file: "",
                        file64: "",
                        fileUrl: "",
                      })
                    }
                    alt="Eliminar"
                  />
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    src={image?.fileUrl}
                    alt="imagen usuario "
                  />
                </>
              ) : // SI VIENE FOTO DEL BACKEND

                !isEmptyOrUndefined(employeeInfo?.photo) ? (
                  <>
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                      src={employeeInfo?.photo || ""}
                      alt="imagen usuario"
                    />
                  </>
                ) : isEditing ? (
                  <>
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                      src={employeeToEdit?.photo || ""}
                      alt="imagen usuario"
                    />
                  </>
                ) : (
                  // SI 1 Y 2 SON FALSE
                  <>
                    <span className="mt-4"></span>
                    <p className={` text-center text-secondary pt-5`}>
                      Insertar imagen
                    </p>
                  </>
                )}

              <label
                htmlFor="imageUpload"
                className={`${userStyles.imageInputLabel} `}
              >
                <img
                  className={`${userStyles.changepfp} ${userStyles.hoverPointer}  `}
                  src={iconCamera}
                  alt="cambiar imagen"
                />
              </label>
              <input
                accept="image/png, image/jpeg"
                id="imageUpload"
                name="imageUpload"
                type="file"
                onChange={handleImagePick}
                className={`${userStyles.imageInputBtn} mb-4`}
              />
            </Card>

            {!isEmptyOrUndefined(inputs.colaborator) && (
              <div style={{ marginTop: "1.2rem" }}>
                <h6 className={`${tableStyles.darkBlueText}`}>
                  <b>
                    {employeeInfo?.full_name ? employeeInfo?.full_name : ""}
                  </b>
                </h6>
                <h3 className={`${tableStyles.crudModalLabel} `}>{`${employeeInfo?.document_type ? employeeInfo?.document_type : ""
                  } ${employeeInfo?.doc_number ? employeeInfo?.doc_number : ""
                  }`}</h3>
                <h3 className={`${tableStyles.crudModalLabel} `}>{`${employeeInfo?.job_title ? employeeInfo?.job_title : ""
                  }`}</h3>
                <h3 className={`${tableStyles.crudModalLabel} `}>{`${employeeInfo?.age ? `${employeeInfo?.age} años` : ""
                  }`}</h3>
              </div>
            )}
          </Col>
          {/* </div> */}
        </div>

        <div className={`customTabs__Footer mt-2`}>
          {!!myPermission.create &&
            <Button
              onClick={() => handleSubmit()}
              className={tableStyles.btnPrimary}
              disabled={!isEmptyOrUndefined(inputs.colaborator) ? false : true}
            >
              Guardar
            </Button>
          }

          <Button
            variant="outline-primary"
            onClick={() => handleBack()}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
