export const getParamsObject = (url) => {

    let params = new URLSearchParams(url);

    let paramsObj = {};
    params.forEach((value, key) => {
        paramsObj[key] = value;
    });

    return paramsObj;
};