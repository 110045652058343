import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import svgX from "../../assets/img/icons/x.svg";
import Agregar from "../../assets/img/icons/add-check.svg"
import threeDots from "../../assets/img/icons/threeDots.svg"
import { customSelectNew, customSelectNewDark } from "../Layouts/react-select-custom";
import Pagination from "react-js-pagination";
import Search from "../../assets/img/icons/lupa.svg";
import { useSelector, useDispatch } from "react-redux";
import GenericModalNew from "../Layouts/ModalNew";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import GenericTableScroll from "../Layouts/GenericTableScroll";
import tableStylesScroll from "../Layouts/genericTableScroll.module.scss";
import "reactjs-popup/dist/index.css";
// import payrollStyles from "./payrollStyles.module.scss";

import {
  get_endownment_groups,
  create_endownment_groups,
  edit_endownment_groups,
} from "../../actions/payrollActions";

import { getArticles } from "../../actions/articlesActions";

import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import CustomPopupExtends from "../Popup/customPopUpExtends";

import { Col, Row } from "react-bootstrap";


//import CollapsibleTable from "./tabletest";
import tabletest from "../Requisitions/tabletest.module.scss";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SelectComponent from "../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";



export const Endowments = (props) => {

  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  let history = useHistory();



  const [filters, setFilter] = useState({
    search: "",
    active: undefined,
    page: 1,
    perpage: 10,
    eaccount: counter.loginReducer.currentAccount.id,
  });

  const [modalControl, setModalControl] = useState({
    //id: 0,
    description: "",
    //active: 0,
    min_quantity: 0,
    id_article: undefined,
    //id_end_group: 0
  });

  const [putState, setPutState] = useState({
    usage: "",
    modalShow: false,
    eaccount: counter.loginReducer.currentAccount.id,

    id: 0,
    description: "",
    articles: [
      // {
      //   "id": 0,
      //   "description": "",
      //   "active": 0,
      //   "min_quantity": 0,
      //   "id_article": 0,
      //   "id_end_group": 0
      // }
    ],
  });

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "endow");
  const [trigger, setTrigger] = useState(0); //esto es para recargar la pagina

  useEffect(() => {
    if (!myPermission?.read) {
      history.push("/nomina/inicio");
    }
    dispatch(getArticles({ page: 1, perpage: 10000 }));
  }, []);

  useEffect(() => {
    dispatch(get_endownment_groups(filters));
  }, [trigger, filters.page]);

  const handleResetPutState = () => {
    setPutState({
      usage: "",
      modalShow: false,
      eaccount: counter.loginReducer.currentAccount.id,

      id: 0,
      description: "",
      articles: [
        // {
        //   "id": 0,
        //   "description": "",
        //   "active": 0,
        //   "min_quantity": 0,
        //   "id_article": 0,
        //   "id_end_group": 0
        // }
      ],
    });
  };



  function handleEnable(id, active, description) {
    customSwaltAlert({
      icon: "warning",
      title: `¿Está seguro?`,
      text: `Se ${active ? "habilitará" : "deshabilitará"} la dotación: ${description}`,
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(
          edit_endownment_groups(
            {
              id: id,
              active: active,
              description
            },
            () => {
              //handleResetPutState();
              setTrigger(trigger + 1);
            }
          )
        );

      }
    });



  }

  function handleSubmit() {

    if (!Array.isArray(putState.articles) || putState.articles.length < 1) {

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `Se requiere uno o más artículos`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      });

      return null;
    }


    if (putState.usage === "PUT") {
      return dispatch(
        edit_endownment_groups(
          putState,
          () => {
            handleResetPutState();
            setTrigger(trigger + 1);
          }
        )
      );
    }
    if (putState.usage === "POST") {
      return dispatch(
        create_endownment_groups(
          putState,
          () => {
            handleResetPutState();
            setTrigger(trigger + 1);
          }
        )
      );
    }

    return null;
  }

  const optionArticles = [ {value: "", label: "Seleccionar..."}, ...counter.articleReducer.articles
    .filter(item => putState.articles.findIndex(taken => item.id === taken.id_article) < 0)
    .map((elem) => {
      return {
        ...elem,
        value: elem.id,
        label: elem.description,
      };
    }),]


































  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  const [collapsedRows, setcollapsedRows] = useState([]);

  const togglecollapserow = (id_article) => {
    let temparray = [...collapsedRows];
    const itscollapsed = collapsedRows.find(x => x == id_article);
    if (itscollapsed) {
      temparray = temparray.filter(x => x != id_article);
    } else {
      temparray.push(id_article);
    }
    setcollapsedRows(temparray);
  }

  function CollapsibleRow(props) {
    const { row } = props;
    const open = !!(collapsedRows.find(x => x == row?.id));
    const classes = useRowStyles();

    return (
      <React.Fragment>

        {/* table row summary shown */}
        <TableRow
          //${(props.index%2 == 0)? tabletest.tablerowEven : ""}
          className={`
      ${tabletest.tablerow} 
      ${classes.root}
      `}

          style={{ backgroundColor: `${(props.index % 2 == 0) ? "#f5f7fa" : "#fff"}` }}
        >

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => togglecollapserow(row?.id)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell align="left"> {row?.description} </TableCell>
          <TableCell align="center">{Array.isArray(row?.articles) ? row?.articles.length : "-"}</TableCell>
          <TableCell align="center">
          <div
            className="rounded-pill p-1"
            style={{
              backgroundColor: row?.active ? "#FAFDF6" : "#FEF7F5" ,
            }}
          >
            <b style={{ color: row?.active ? "#83C036" : "#F39682" }}>{row?.active
                  ? "Habilitado"
                  : "Inhabilitado"}</b>
          </div>
          </TableCell>
          <TableCell align="center">
            <CustomPopupExtends
            noHover
            triggerSrc={threeDots}
              showEdit={!!myPermission.edit && true}
              editText="Editar"
              editClickEvent={() => setPutState({
                ...putState,
                modalShow: true,
                usage: "PUT",

                id: row.id,
                description: row.description,
                articles: row.articles,
              })}

              // extraButtons = {
              //   [
              //     {
              //       icon: addbutton,
              //       text:"Agregar elementos",
              //       event: () => console.log("agregar click")
              //     }
              //   ]
              // }

              showEnable={!!myPermission.edit && true}
              isEnabled={row?.active}
              enableClickEvent={() => {
                handleEnable(row.id, !row?.active, row?.description)
              }}

            />
          </TableCell>

        </TableRow>

        {/* subtable in row details */}
        <TableRow
          //className={`${(props.index%2 == 0)? tabletest.tablerowEven : ""}`}
          style={{ backgroundColor: `${(props.index % 2 == 0) ? "#f5f7fa" : "#fff"}` }}
        >
          {
            Array.isArray(row?.articles) && row?.articles.length > 0 &&
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>

                  <p className={tabletest.subtableTitle}>Elementos</p>

                  <Table size="small" aria-label="purchases">
                    {
                      //subtable headers
                    }
                    <TableHead>
                      <TableRow className={tabletest.subtableTh}>
                        <TableCell align="left">Código</TableCell>
                        <TableCell align="left">Artículo</TableCell>
                        <TableCell align="center">Cantidad mínima</TableCell>
                        {/* <TableCell align="center"></TableCell> */}
                      </TableRow>
                    </TableHead>


                    {
                      //subtable headers 
                    }
                    <TableBody>
                      {row?.articles.map((subitem, subindex) => (
                        <TableRow key={subindex} className={tabletest.subtableTr}>
                          <TableCell align="left" width="10%"> {subitem.id} </TableCell>
                          <TableCell align="left" width="80%">{subitem.description} </TableCell>
                          <TableCell align="center" width="10%">{subitem.min_quantity}</TableCell>
                          {/* <TableCell align="center" width="10%"> 
                      <img 
                        src={Trash} 
                        className={tableStyles.cursorPointer}
                        onClick={()=>//console.log("trash button")}
                      />
                    </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          }
        </TableRow>
      </React.Fragment>
    );
  }




  //main table 
  const CollapsibleTable = () =>
    <Paper style={{ marginBottom: 20, }}>
      <TableContainer className={tabletest.table}>
        <Table stickyHeader aria-label="sticky collapsible table">
          {/* table headers here */}
          <TableHead className={`${tabletest.head} ${tabletest.dark}`}>
            <TableRow>
              <TableCell className={tabletest.dark} />
              <TableCell align="left" className={`text-start ${tabletest.dark}`}>Grupo dotacional</TableCell>
              <TableCell align="center" className={tabletest.dark}>Artículos</TableCell>
              <TableCell align="center" className={tabletest.dark}>Estado</TableCell>
              <TableCell align="center" className={tabletest.dark}></TableCell>
            </TableRow>
          </TableHead>

          {/* table body is generated from array */}
          <TableBody className={tabletest.body}>
            {Array.isArray(counter.payrollReducer.endownment_groups) ? counter.payrollReducer.endownment_groups
              .map((row, index) => (
                <CollapsibleRow
                  key={index}
                  index={index}
                  row={row}
                />
              ))
              :
              <span></span>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>;






  const renderEndowmentArticles = () => {
    let reslitst = [];
    let total = 0;
    if (Array.isArray(putState.articles)) {
      putState.articles.map((item, index) => {
        total += item.lot_qty_received;
        let temprow = <section className={`d-flex`}>
          <td className={tableStylesScroll.f1}>
            {item.id_article}
          </td>
          <td className={`text-start ${tableStylesScroll.f6}`}>
            {item.description}
          </td>
          <td className={tableStylesScroll.f2}>
            {item.min_quantity}
          </td>
          <td className={tableStylesScroll.f1}>
            <img
              src={svgX}
              onClick={() => deleteEndowmentArticles(item.id_article)}
            />
          </td>
        </section>
        reslitst.push(temprow);
      })
    }
    return reslitst;
  }

  const addEndowmentArticles = () => {
    if (modalControl.min_quantity > 0 && !!modalControl.id_article) {
      const temparray = putState.articles;
      temparray.push({
        "description": modalControl.description,
        "active": 1,
        "min_quantity": modalControl.min_quantity,
        "id_article": modalControl.id_article,
        "id_end_group": putState.id,
      });

      setPutState({ ...putState, articles: temparray });
      setModalControl({
        description: "",
        min_quantity: 0,
        id_article: 0,
      });
    } else {
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Se requiere escoger un artículo y agregar una cantidad",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      });
    }
  }

  const deleteEndowmentArticles = (id_article) => {
    const temparray = putState.articles.filter(x => x.id_article != id_article);
    setPutState({ ...putState, articles: temparray });
  }





  const optionsStatus = [
    { value: "", label: "Seleccionar..." },
    { value: 0, label: "Inhabilitado" },
    { value: 1, label: "Habilitado" },
  ]



  return (
    <>
      <GenericModalNew
        title="Nuevo Grupo"
        show={putState.modalShow}
        onHide={() => handleResetPutState()}
        btnYesEvent={  putState.usage === "PUT" ? !!myPermission.edit : !!myPermission.create ? () => handleSubmit(): null}
        btnYesName="Agregar"
      >
        <div className="d-flex flex-column">
          <p className={tableStyles.crudModalLabel}>Nombre del Grupo dotacional*</p>
          <input
            className="register-inputs"
            name="description"
            type="text"
            placeholder="Escribir..."
            defaultValue={putState.description}
            onChange={(event) =>
              setPutState({
                ...putState,
                description: event.target.value,
              })
            }
          />
        </div>

        <p >Elementos</p>

        <div className={`d-flex`}>
          <div className="d-flex flex-column mr-2" style={{ flex: 6 }} >
            <p className={tableStyles.crudModalLabel}>Relacionar artículo</p>
            <SelectComponent
              key={"modalarticlesel" + modalControl.id_article}
              value={optionArticles.find(
                (option) => option.value === modalControl.id_article
              )}
              onChange={e => setModalControl({
                ...modalControl,
                id_article: e.value,
                description: e.label
              })}

              options={optionArticles}
              placeholder={"Seleccionar..."}
              styles={customSelectNew}
            />
          </div>

          <div className="d-flex flex-column  mr-2" style={{ flex: 2 }} >
            <p className={tableStyles.crudModalLabel}>Cant.&nbsp;mínima</p>
            <input
              type="number"
              className="register-inputs"

              value={modalControl.min_quantity}
              onChange={e => setModalControl({ ...modalControl, min_quantity: e.target.value })}
            />
          </div>

          <div className="d-flex flex-column" >
            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
            <img
              src={blueAddNew}
              style={{ flex: 1 }}
              onClick={() => addEndowmentArticles()}
            />
          </div>
        </div>

        <GenericTableScroll
        lowerCase
          headers={[
            <div key={`damnit1`} className={tableStylesScroll.f1}>#</div>,
            <div key={`damnit2`} className={tableStylesScroll.f6}>Artículo</div>,
            <div key={`damnit3`} className={tableStylesScroll.f2}>Cant.&nbsp;min</div>,
            <div key={`damnit4`} className={tableStylesScroll.f1}>&nbsp;</div>,
          ]}
          body={renderEndowmentArticles()}
        />
      </GenericModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea} style={{ paddingBottom: "40px" }}>
          <h1 className={tableStyles.title}>Dotaciones</h1>

          {!!myPermission.read &&
          <>
          <Row className="pb-2">
          <Col xs={3}>
          <p className={tableStyles.crudModalLabel}>Estado</p>
          <SelectComponent
          styles={customSelectNewDark}
          placeholder={"Seleccionar..."}
          key={"state" + trigger}
          value={optionsStatus.find((e)=> e.value === filters.active)}
          onChange={(e) => {
          setFilter({ ...filters, active: e.value })
          setTrigger(trigger + 1);
          }}
          // noOptionsMessage={() => "No hay datos"}
          options={optionsStatus}
          ></SelectComponent>{" "}
          </Col>
          <Col xs={5}
          className="d-flex align-self-end gap-1"
          >
          <input
          className={tableStyles.SearchNew}
          defaultValue={filters.search}
          placeholder="Buscar..."
          type="text"
          onChange={(e) =>
          setFilter({ ...filters, name: e.target.value })
          }
          onKeyDown={(e) => {
          if (e.key === "Enter") {
          setTrigger(trigger + 1);
          }
          }}
          ></input>
          <img
          className="hoverPointer"
          alt="iconSearch"
          style={{}}
          src={Search}
          height="24px"
          onClick={(e) => 
          {
            setFilter({ ...filters, page: 1 });
            setTrigger(trigger + 1);
          }
          }
          />
          </Col>
          {!!myPermission?.create ? (
          <Col xs={4}
          className="align-self-end"
          >
          <div className="d-flex justify-content-end">
            {!!myPermission.create &&
          <div
          onClick={() =>
          {setPutState({
            ...putState,
            usage: "POST",
            modalShow: true,
          })}
          }
          className={tableStyles.createNomModule}
          >
          <b className={`mr-2`}>Crear Grupo</b>
          <img
          src={Agregar}
          alt="User"
          />
          <div></div>
          </div>
}
          </div>
          </Col>
          ) : null}
          {/* <Col xs={5}>
          </Col> */}
          </Row>
          {CollapsibleTable()}
          {counter.payrollReducer.endownment_loading &&
            <div className="loading">
              <Loader
                type="Oval"
                color="#003f80"
                height={100}
                width={100}
              />
            </div>
          }
          {(counter.payrollReducer.endownment_total > 10) &&
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {counter.payrollReducer.endownment_total ? filters.page : ""}
                {" de "}
                {Math.ceil(counter.payrollReducer.endownment_total / filters.perpage)
                  ? Math.ceil(counter.payrollReducer.endownment_total / filters.perpage)
                  : ""}{" "}
                ({counter.payrollReducer.endownment_total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={counter.payrollReducer.endownment_total}
                pageRangeDisplayed={5}
                onChange={(val) => setFilter({ ...filters, page: val, })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>}
          </>
          }
        </div>
      </div>
    </>
  );
};
