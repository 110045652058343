import React, { useEffect, useState } from "react";
import { Tabs, Tab, Row, Col, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import IndividualStyles from "../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss";
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../assets/img/icons/backIcon.svg";
import Attach from "../../assets/img/icons/insertar.svg";
import note from "../../assets/img/icons/ordSugess.svg";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  convertBase64,
  convertDateToLatinFormat,
  convertMoneyFormat,
  formatToRcSelect,
  getFivePreviousYear,
  isEmptyOrUndefined,
  message,
  monthsOfTheYear,
  today,
  validateEmptyString,
} from "../../helpers/helpers";
import paginationStyles from "../Layouts/pagination.module.scss";
import { customSelectNew } from "../Layouts/react-select-custom";
import { getCandidateList } from "../../actions/partnersActions";
import moment from "moment";
import Dropzone from "react-dropzone";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {
  availableDays,
  getMyTickets,
  getMyVacationsList,
  getPermissionsList,
  sendPaymentTicket,
  sendPermissionRequest,
  sendVacationRequest,
} from "../../actions/myPayrollActions";
import { getAllJobPositions } from "../../actions/positionsActions";
import { getPdfTest } from "../../actions/IndividualAuctionActions";
import ModalNew from "../Layouts/ModalNew";
import { PdfViewer } from "../Layouts/PdfViewer";
import { GET_TICKETS_LIST } from "../../actions/actionTypes";
import GenericTableNew from "../Layouts/GenericTableNew";
import { useGetMethod, usePostMethod } from "../../Hooks/useFetch";
import Pagination from "react-js-pagination";
import { customSwaltAlert } from "../../helpers/customSwaltAlert";

export const Requests = (props) => {
  const store = useSelector((state) => state);
  const location = useLocation();
  const idProcess = location.state?.id;
  const disDetail = location.state;
  let id_contract = store?.loginReducer?.user_data?.payrollInfo?.id_contract;
  let area_id = store?.loginReducer?.user_data?.payrollInfo?.id_area;
  const fullName =
    store.loginReducer.user_data.first_name +
    " " +
    store.loginReducer.user_data.first_surname;

  const [documents, setDocuments] = useState({
    file_name: "",
    base64: "",
  });
  const [base64, setBase64] = useState("");
  const idAccount = store.loginReducer?.user_data.id;
  const idEnterprise = store.loginReducer?.currentAccount?.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: "",
  })
  const closeCommentModal = () => {
    setCommentModal({ ...commentModal, show: false, comment: "" })
  }
  let id_candidate = store?.loginReducer?.user_data?.payrollInfo?.id_candidate;
  const { load: periodDetailLoader, trigger: getPeriodDetails } = usePostMethod();
  const { load: getEndDateLoader, trigger: getEndDate } = useGetMethod();
  const { load: updateRequestVacations, trigger: sendRequestVacations } = usePostMethod();

  const [inputs, setInputs] = useState({
    job_position: "",
    leader: "",
    startDate: "",
    endDate: "",
    type: "",
    description: "",
    job_positionVct: "",
    leaderVct: "",
    startDateVct: "",
    endDateVct: "",
    typeVct: "",
  });
  const token = store.loginReducer.Authorization;

  const selectedProcess = location.state;
  const [slider, setSlider] = useState({
    permissions: true,
    vacations: false,
    paymentTicket: false,
  });
  const arrEmployees = store.partnersReducer.employee_list;
  const [isLoading, setIsLoading] = useState(false);

  const arrJobsPosition = store.positionReducer?.jobsPositionsList;
  const formattedPositions = formatToRcSelect(
    arrJobsPosition,
    "contract_id",
    "job_title",
    "area_id",
    "id"
  );
  const historial = store?.myPayrollReducer?.permissionsList;
  const loadingTickets = store?.myPayrollReducer?.loadingTickets;
  const vacationHistory = store?.myPayrollReducer?.vacationsList;
  const [pdfLoading, setPdfLoading] = useState(false);
  const ticketsList = store?.myPayrollReducer?.ticketsList;

  const formattedEmployees = formatToRcSelect(
    arrEmployees,
    "id_candidate",
    "full_name",
    "id_candidate",
    "job_title"
  );
  const [showPdf, setShowPdf] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const myPermission = () =>
    store.loginReducer.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "request"
    );
  const getPdf = async (id) => {
    setPdfLoading(true);
    const result = await getPdfTest(
      {
        id: id,
        doc: 20,
      },
      token
    );
    if (result?.success) {
      setPdfLoading(false);
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      setPdfLoading(false);
      message(
        "warning",
        "Intenta de nuevo",
        "No ha sido posible cargar el documento", undefined, true
      );
      return setShowPdf(false);
    }
  };
  /* ----------------------- GET DAYS NUMBER PERMISSIONS ---------------------- */
  let startPerm = moment(inputs.startDate).format("DD-MM-YYYY");
  let endPerm = moment(inputs.endDate).format("DD-MM-YYYY");
  var admissionPerm = moment(startPerm, "DD-MM-YYYY");
  var dischargePerm = moment(endPerm, "DD-MM-YYYY");
  let diferencePerm = dischargePerm.diff(admissionPerm, "days");
  /* ----------------------- GET DAYS NUMBER VACATIONS ---------------------- */
  let startVct = moment(inputs.startDateVct).format("DD-MM-YYYY");
  let endVct = moment(inputs.endDateVct).format("DD-MM-YYYY");
  var admission = moment(startVct, "DD-MM-YYYY");
  var discharge = moment(endVct, "DD-MM-YYYY");
  let diferenceVct = discharge.diff(admission, "days");
  const UNLOKED_NUMBER_PERIODS = 3;

  const [periodDetailResults, setPeriodDetailResults] = useState({})
  const [countedDays, setCountedDays] = useState();
  useEffect(() => {
    dispatch({
      type: GET_TICKETS_LIST,
      payload: [],
      loading: false,
    });
  }, []);

  useEffect(() => {
    if (!myPermission().read) {
      history?.push("/configuracion/miperfil");
    }
    dispatch(
      getPermissionsList({
        user: idAccount,
        entity_account: idEnterprise,
      })
    );
    dispatch(
      getMyVacationsList({
        user: idAccount,
        entity_account: idEnterprise,
      })
    );
    dispatch(
      getAllJobPositions({
        id_candidate: id_candidate,
        active: 1,
        entity_account: store.loginReducer.currentAccount.id
      })
    );
  }, [trigger]);

  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs.typeVct) &&
      !isEmptyOrUndefined(inputs.job_positionVct?.extra2) &&
      !isEmptyOrUndefined(inputs.job_positionVct?.value)
    ) {
      getAvailabledays();
    }
  }, [inputs.typeVct]);

  // ANCHOR USE EFFECT
  useEffect(() => {
    if (!isEmptyOrUndefined(inputs.job_positionVct)
      && !isEmptyOrUndefined(inputs.leaderVct)) {
      getPeriodDetailsFunction()
    }
  }, [inputs.job_position, inputs.leaderVct])


  const getTicketList = () => {
    setBase64("");
    if (isEmptyOrUndefined(inputs.month)) {
      return message("info", "Campos vacios", "Por favor seleccione un mes", undefined, true);
    }
    if (isEmptyOrUndefined(inputs.year)) {
      return message("info", "Campos vacios", "Por favor seleccione un año", undefined, true);
    }
    dispatch(
      getMyTickets({
        employee: id_candidate,
        entity_account: idEnterprise,
        month: inputs.month,
        year: inputs.year,
      })
    );
  };

  const getAvailabledays = async () => {
    const res = await availableDays(
      {
        created_by: idAccount,
        type: inputs.typeVct?.value,
        entity_account: idEnterprise,
        job_position: inputs.job_positionVct?.extra2,
        leader: inputs.leaderVct?.value,
        contract: inputs.job_positionVct?.value,
      },
      token
    );

    if (res?.success) {
      setCountedDays(res?.days);
      return;
    } else {
      return res?.message;
    }
  };

  const handleSubmit = async (type) => {
    switch (type) {
      case "permission":
        if (
          isEmptyOrUndefined(inputs.job_position) ||
          isEmptyOrUndefined(inputs.leader) ||
          isEmptyOrUndefined(inputs.startDate) ||
          isEmptyOrUndefined(inputs.endDate) ||
          isEmptyOrUndefined(inputs.type) ||
          isEmptyOrUndefined(inputs.description)||
          isEmptyOrUndefined(documents.base64)
        ) {
          return message(
            "warning",
            "Intenta de nuevo",
            "Por favor rellene todos los campos obligatorios", undefined, true
          );
        }
        // if (inputs?.startDate > inputs?.endDate) {
        //   return message(
        //     "info",
        //     "Fechas erradas",
        //     "La fecha inicial no puede ser mayor a la fecha final"
        //   );
        // }
        // if (inputs?.startDate < today()) {
        //   return message(
        //     "info",
        //     "Fechas erradas",
        //     "La fecha inicial no puede ser menor a la fecha actual"
        //   );
        // }
        // if (inputs?.endDate < today()) {
        //   return message(
        //     "info",
        //     "Fechas erradas",
        //     "La fecha final no puede ser menor a la fecha actual"
        //   );
        // }

        try {
          setIsLoading(true);
          const result = await sendPermissionRequest(
            {
              employee: id_candidate,
              created_by: idAccount,
              dateFrom: inputs.startDate,
              dateUntil: inputs.endDate,
              type: inputs.type.value,
              days_calendar: diferencePerm + 1,
              entity_account: idEnterprise,
              job_position: inputs.job_position?.extra2,
              leader: inputs.leader.value,
              // contract: inputs.job_position.value,
              description: inputs.description,
              contract: id_contract,
              document: isEmptyOrUndefined(documents.base64) ? {} : documents,
            },
            token
          );

          if (result.success) {
            setInputs({
              job_position: "",
              leader: "",
              startDate: "",
              endDate: "",
              type: "",
              description: "",
            });
            setDocuments({
              file_name: "",
              base64: "",
            });
            setTrigger(trigger + 1);
            setIsLoading(false);
            return message("success", "Éxito", result.message, undefined, true);
          } else {
            setIsLoading(false);
            return message("warning", "Intenta de nuevo", result.message, undefined, true);
          }
        } catch (error) {
          setIsLoading(false);
          message("warning", "Intenta de nuevo", "Ha ocurrido un error", undefined, true);
          return console.error(error);
        }
        break;
      case "vacations":
        if (
          isEmptyOrUndefined(inputs.job_positionVct) ||
          isEmptyOrUndefined(inputs.leaderVct) ||
          isEmptyOrUndefined(inputs.startDateVct) ||
          isEmptyOrUndefined(inputs.endDateVct) ||
          isEmptyOrUndefined(inputs.typeVct)
        ) {
          return message(
            "warning",
            "Intenta de nuevo",
            "Por favor rellene todos los campos obligatorios", undefined, true
          );
        }
        if (inputs?.startDateVct > inputs?.endDateVct) {
          return message(
            "warning",
            "Intenta de nuevo",
            "La fecha inicial no puede ser mayor a la fecha final", undefined, true
          );
        }
        try {
          setIsLoading(true);
          const result = await sendVacationRequest(
            {
              created_by: idAccount,
              dateFrom: inputs.startDateVct,
              dateUntil: inputs.endDateVct,
              type: inputs.typeVct.value,
              days_calendar: diferenceVct + 1,
              //  calculateDays(inputs.startDateVct, inputs.endDateVct),
              entity_account: idEnterprise,
              job_position: inputs.job_positionVct?.extra2,
              leader: inputs.leaderVct.value,
              contract: inputs.job_positionVct.value,
              description: inputs.description,
              document: isEmptyOrUndefined(documents.base64) ? {} : documents,
            },
            token
          );

          if (diferenceVct > countedDays) {
            setIsLoading(false);
            return message(
              "warning",
              "Intenta de nuevo",
              "Los días solicitados superan los días permitidos para aplicar a vacaciones", undefined, true
            );
          }

          if (result.success) {
            setInputs({
              job_positionVct: "",
              leaderVct: "",
              startDateVct: "",
              endDateVct: "",
              typeVct: "",
            });
            setCountedDays(0);
            setTrigger(trigger + 1);
            setIsLoading(false);
            return message("success", "Éxito", result.message, undefined, true);
          } else {
            setIsLoading(false);
            return message("warning", "Intenta de nuevo", result.message, undefined, true);
          }
        } catch (error) {
          setIsLoading(false);
          message(
            "warning",
            "Intenta de nuevo",
            "Ha ocurrido un error, verifique los datos", undefined, true
          );
          return console.error(error);
        }
        break;
      case "paymentTicket":
        if (
          isEmptyOrUndefined(inputs.ticketMonth) ||
          isEmptyOrUndefined(inputs.ticketYear)
        ) {
          return message(
            "warning",
            "Intenta de nuevo",
            "Por favor rellene todos los campos obligatorios", undefined, true
          );
        }

        try {
          setIsLoading(true);
          const result = await sendPaymentTicket(
            {
              entity_account: idEnterprise,
              created_by: idAccount,
              month: inputs.ticketMonth,
              year: inputs.ticketYear,
            },
            token
          );

          if (result.success) {
            setInputs({
              ...inputs,
              month: "",
              year: "",
            });
            setTrigger(trigger + 1);
            setIsLoading(false);
            return message("success", "Éxito", result.message, undefined, true);
          } else {
            setIsLoading(false);
            return message("warning", "Intenta de nuevo", result.message, undefined, true);
          }
        } catch (error) {
          setIsLoading(false);
          message(
            "warning",
            "Intenta de nuevo",
            "Ha ocurrido un error, verifique los datos", undefined, true
          );
          return console.error(error);
        }
        break;

      default:
        break;
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */

  const onChangeDoc = async (e, type) => {
    const file = e.target.files[0];

    const validExtensions = ["zip", "ZIP", "pdf", "PDF", "rar", "RAR"];

    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split(".");
      let extension = validExtensions.find(
        (obj) => obj === actFileExt[actFileExt.length - 1]
      );
      if (isEmptyOrUndefined(extension)) {
        e.target.value = "";
        return message(
          "warning",
          "Advertencia!",
          `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`, undefined, true
        );
      }

      if (file.size > 250000000) {
        e.target.value = "";
        return message(
          "warning",
          "Advertencia",
          "Su archivo no puede tener un tamaño superior a 25MB", undefined, true
        );
      } else {
        if (file.size <= 0) {
          e.target.value = "";
          return message(
            "warning",
            "Advertencia",
            "Por favor, adjunte un archivo valido.", undefined, true
          );
        }
      }
      if (!isEmptyOrUndefined(file)) {
        // setSelectedFile(file);
      }
    } else {
      return console.error("Archivo vacio: ", file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split(".")[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let base64 = encode.split(",")[1];
    let ext = type_doc.split("/");
    let finalName = `${name_doc}.${ext[1]}`;

    setDocuments({
      file_name: finalName,
      base64: base64,
    });
  };

  const renderHeaders = [
    <th key={1} className="text-start col-3 px-3">
      Periodo
    </th>,
    <th key={2} className="text-center col-3">
      Días <br /> disfrute
    </th>,
    <th key={2} className="text-center col-3 px-3">
      Valor <br /> a liquidar
    </th>,
    <th key={3} className="text-center col-3 ">
      Días <br /> compensados
    </th>,
  ];

  const onChangeInputs = (value, param, period, elem) => {
    let temPrd = periodDetailResults
    let findIndex = temPrd?.periods?.findIndex((x) => x.period === period)
    if (param === "compensateDays") {
      let idealValue = Number(elem?.max_days / 2)

      if (elem?.enjoyDays === elem?.max_days) {
        temPrd.periods[findIndex].compensateDays = 0
      } else {
        if (Number(value) <= idealValue && value !== "") {
          temPrd.periods[findIndex][param] = Math.floor(Number(value))
        } else {
          temPrd.periods[findIndex][param] = ""
        }
        let nextPeriod = temPrd.periods[findIndex + 1]
        if (!!nextPeriod){
          if (nextPeriod !== -1) {
            nextPeriod.enjoyDays = ""
            nextPeriod.compensateDays = ""
          }
        }
      }
    } else {
      if (value !== "") {
        if (Number(value) <= elem.max_days) {
          temPrd.periods[findIndex][param] = Math.floor(Number(value))
          if (Number(value) === elem?.max_days) {
            temPrd.periods[findIndex].compensateDays = 0
          } else {
            let nextPeriod = temPrd.periods[findIndex + 1]
            if (nextPeriod) {
              nextPeriod.enjoyDays = ""
              nextPeriod.compensateDays = ""
              nextPeriod.paymentValue = "0.00"
            }
          }
          temPrd.periods[findIndex].paymentValue = (((Number(periodDetailResults?.salary))/(Number(periodDetailResults?.payment_frecuency))) * (Math.floor(Number(value))))
        } else {
          temPrd.periods[findIndex][param] = ""
          temPrd.periods[findIndex].paymentValue = "0.00"
        }
      } else {
        temPrd.periods[findIndex][param] = ""
        temPrd.periods[findIndex].paymentValue = "0.00"
        let nextPeriod = temPrd.periods[findIndex + 1]
        if (!!nextPeriod) {
          if (nextPeriod !== -1) {
            nextPeriod.enjoyDays = ""
            nextPeriod.compensateDays = ""
          }
        }
      }

    }

    setPeriodDetailResults({ ...periodDetailResults, periods: temPrd.periods })
    onChangeFromDateInput()
  }

  const renderTooltipDetail = (unablePeriodIfInputsEmpty, days) => (
    <Tooltip>
      {unablePeriodIfInputsEmpty ? `Se habilitará el periodo, en cuanto la sumatoria de días del periodo anterior cubra los ${days} días` :
        "Vacaciones no disponibles"}
    </Tooltip>
  );
  const renderTooltipValue = () => (
    <Tooltip>
      Valor calculado a partir de los días disfrute
    </Tooltip>
  );

  const renderRows = (elem, ind, vacationsUnable, unablePeriod, unablePeriodIfInputsEmpty) => {
    const value = elem?.paymentValue ? elem.paymentValue === 0 ?
      convertMoneyFormat((elem?.paymentValue ?? 0)) :
      convertMoneyFormat((Number(elem?.paymentValue) ?? 0).toFixed(2))
      :
      convertMoneyFormat((0.00))

    return (<tr key={elem.id}>
      <td className={`col-3 px-3 text-start fw-bold`}
        style={{ backgroundColor: vacationsUnable && "#FCF1E1" }}
      >
        {elem?.period ?? "-"}
      </td>
      <td className="col-3 text-center px-3"
        style={{ backgroundColor: vacationsUnable && "#FCF1E1" }}
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip placement="top-end" id="button-tooltip" {...props}>
              Los días de disfrute para este periodo deben ser mayores o igual a '6' días
            </Tooltip>
          }
        >
        <div>
          <input
            key={"DateStart" + trigger}
            type="number"
            readOnly = {(elem.max_days < 6 && elem.is_available === 1) && true }
            disabled={vacationsUnable || !unablePeriod || unablePeriodIfInputsEmpty}
            onChange={(e) =>
              onChangeInputs(e.target.value, "enjoyDays", elem?.period, elem)
            }
            value={elem?.enjoyDays}
            className="register-inputs text-primary text-uppercase fw-bold "
          />
        </div>
        </OverlayTrigger>
      </td>
      <td className="col-3 text-center px-3"
        style={{ backgroundColor: vacationsUnable && "#FCF1E1" }}
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltipValue()}
        >
          <span>
            {value}
          </span>
        </OverlayTrigger>
      </td>
      <td className="col-3 text-center px-3"
        style={{ backgroundColor: vacationsUnable && "#FCF1E1" }}
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip placement="top-end" id="button-tooltip" {...props}>
              Los días compensados para este periodo deben ser menores o igual a '{Math.floor(Number(elem?.max_days / 2))}' días
            </Tooltip>
          }
        >
          <div>
            <input
              key={"DateStart" + trigger}
              type="number"
              disabled={vacationsUnable || !unablePeriod || unablePeriodIfInputsEmpty}
              step={1}
              pattern="[0-9]{10}"
              onChange={(e) =>
                onChangeInputs(e.target.value, "compensateDays", elem?.period, elem)
              }
              value={elem.compensateDays}
              className="register-inputs text-primary text-uppercase fw-bold "
            />
          </div>
        </OverlayTrigger>

      </td>
    </tr>)

  }
  const renderElement = (elem, ind,) => {
    let lengthPeriods = periodDetailResults?.periods.length
    let unablePeriod = (ind) >= (lengthPeriods - UNLOKED_NUMBER_PERIODS)
    let backUnablePeriod = (ind - 1) !== -1 ? (ind - 1) >= (lengthPeriods - UNLOKED_NUMBER_PERIODS) : false
    let backPeriod = periodDetailResults?.periods[ind - 1]
    let unablePeriodIfInputsEmpty = false
    if (unablePeriod && backUnablePeriod) {
      unablePeriodIfInputsEmpty = (backPeriod?.enjoyDays + backPeriod?.compensateDays) === backPeriod.max_days ? false : true
    }
    if (backPeriod?.max_days < 6) {
      unablePeriodIfInputsEmpty = false
    }
    const isAvailable = !!!elem.is_available
    let vacationsUnable = false
    if (elem.max_days < 6 && isAvailable) {
      vacationsUnable = true
    }
    return (vacationsUnable || unablePeriodIfInputsEmpty) ?
      (<OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltipDetail(unablePeriodIfInputsEmpty, backPeriod?.max_days)}
      >
        {renderRows(elem, ind, vacationsUnable, unablePeriod, unablePeriodIfInputsEmpty)}
      </OverlayTrigger>) :
      renderRows(elem, ind, vacationsUnable, unablePeriod, unablePeriodIfInputsEmpty)
  };

  // ANCHOR FUNCTION
  const getPeriodDetailsFunction = () => {
    getPeriodDetails({
      url: "/payroll/daysAvailable/",
      token: token,
      method: "POST",
      body: {
        "entity_account": idEnterprise,
        "created_by": idAccount,
        "job_position": inputs?.job_positionVct?.extra2,
        "leader": inputs?.leaderVct.value,
        "contract": id_contract
      },
      succesAction: (res) => {
        let tempJson = res.results
        let tempArr = []
        res.results.periods.length > 0 &&
          res.results.periods.forEach((elem) => {
            if (elem.max_days < 6 && elem.is_available === 1) {
              tempArr.push({
                ...elem, enjoyDays: elem.max_days, compensateDays: 0,
                paymentValue: ((Number(res?.results.salary))/(Number(res?.results?.payment_frecuency))* (res?.results?.days_off))
              })
            } else {
              tempArr.push(elem)
            }
          })
        tempJson.periods = tempArr
        setPeriodDetailResults(tempJson)
      }
    });
  }
  const onChangeFromDateInput = (value) => {
    if (value ?? inputs?.startDateVct !== "") {
      let enjoyDaysSum = 0
      let yearExpected = moment(today()).subtract(3, 'years').format("YYYY-MM-DD")
      periodDetailResults?.periods?.forEach((elem, ind) => {
        if (elem.enjoyDays) enjoyDaysSum += elem.enjoyDays
      })
      if ((new Date(value ?? inputs.startDateVct).getFullYear() > new Date(yearExpected).getFullYear()) && enjoyDaysSum > 0 ){
        getEndDate({
          url: "/payroll/working_holidays",
          token: token,
          objFilters:{
            "dateFrom": value ?? inputs.startDateVct,
            "days": enjoyDaysSum,
          },
          method: "GET",
          succesAction: (res) => {
            setPeriodDetailResults({ ...periodDetailResults, dateUntil: res.results })
          }
        });
      }
    }
  }

  const requestVacations = () => {
    let status = true
    let tempArr = [{ enjoyDays: 0, compensateDays: 0, period: "-" }]
    let enjoyDaysSum = 0
    let compensateDaysSum = 0
    let paymentValue = 0
    let validations = validateEmptyString([
      { field: inputs.job_positionVct.value, message: "El campo 'Cargo' es obligatorio" },
      { field: inputs.leaderVct.value, message: "El campo 'Jefe de área' es obligatorio" },
      { field: inputs.startDateVct, message: "El campo 'De la fecha' es obligatorio" },
    ]);
    let yearExpected = moment(today()).subtract(3, 'years').format("YYYY-MM-DD")

    periodDetailResults?.periods?.forEach((elem, ind) => {
      if (elem.enjoyDays) {
        enjoyDaysSum += Number(elem.enjoyDays)
        tempArr.push(elem)
        if (!!(elem.max_days > 6 && elem.is_available === 1)) {
          if (elem.enjoyDays !== "" || elem.enjoyDays === 0) {
            if (elem.enjoyDays < 6) {
              message("warning", "Intenta de nuevo", `Para el periodo ${elem?.period} los "días disfrute" deben ser mínimo 6`, undefined, true)
              return status = false
            }
          }
        }
      }
      if (elem.compensateDays) compensateDaysSum += Number(elem.compensateDays)
      if (elem.paymentValue) paymentValue += Number(elem.paymentValue)
    })

    if (enjoyDaysSum === 0 || isNaN(enjoyDaysSum)) {
      status = false
      return message("warning", "Intenta de nuevo", "El campo 'Días disfrute' es obligatorio", undefined, true)
    }
    if (enjoyDaysSum < 6) {
      status = false
      return message("warning", "Intenta de nuevo", "La sumatoria de los campos de 'Días disfrute' debe ser mínimo 6 ", undefined, true)
    }
    if (new Date(inputs.startDateVct).getFullYear() <= new Date(yearExpected).getFullYear() ){
      status = false
      return message("warning", "Intenta de nuevo", "La fecha inicial de la solicitud debe encontrarse dentro del rango de los periodos habilitados", undefined, true)
    }
    let periodLength = (tempArr[(tempArr.length - 1)])?.period.split("-")[1]
    let periodInitial = (tempArr[1])?.period.split("-")[0]
    let fullPeriod = `${periodInitial} - ${periodLength}`
    let body = {
      "created_by": idAccount,
      "dateFrom": inputs.startDateVct,
      "dateUntil": periodDetailResults?.dateUntil,
      "total_compensate_days": compensateDaysSum,
      "total_days_off": enjoyDaysSum,
      "entity_account": idEnterprise,
      "job_position": inputs.job_positionVct.extra2,
      "leader": inputs.leaderVct.value,
      "contract": id_contract,
      "period": fullPeriod,
      "paymentValue": paymentValue,
      "salary": periodDetailResults?.salary,
      "detail": periodDetailResults?.periods
    }
    if (status && validations.valid) {
      customSwaltAlert({
        icon: 'warning',
        title: `<span style=color:#01A0F6>¿Está seguro?</span>`,
        text: `Se solicitarán vacaciones`,
        confirmButtonText: `Sí, Continuar`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          sendRequestVacations({
            url: "/payroll/registerHolidays/",
            token: token,
            method: "POST",
            body: body,
            succesAction: (info) => {
              customSwaltAlert({
                title: `Solicitud enviada exitosamente`,
                // text: `Solicitud enviada `,
                icon: "success",
                confirmButtonText: 'Aceptar',
                showCancelButton: false
              })
              setInputs({ ...inputs, job_positionVct: "", leaderVct: "", type_dateMin: "", startDateVct: "" });
              setTrigger(trigger + 1)
              setPeriodDetailResults({})
            },
            doAfterException: (res) => {
              customSwaltAlert({
                title: `Intenta de nuevo`,
                text: `${res.message} `,
                icon: "warning",
                confirmButtonText: 'Aceptar',
                showCancelButton: false
              })
            }
          });
        }
      });
    }
  }

  return (
    <div className="mx-5">
      {isLoading || updateRequestVacations || periodDetailLoader || loadingTickets ? (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <ModalNew
        title="Comentarios"
        show={commentModal.show}
        // btnYesName={showModalRejected.justify !== "" && "Guardar"}
        btnNoName={"Cerrar"}
        size="400"
        btnYesEvent={null}
        btnYesDisabled={true}
        onHide={() =>
          closeCommentModal()
        }
        btnNoEvent={() =>
          closeCommentModal()
        }
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>
          Comentarios
        </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          disabled={true}
          // onChange={(e) => setCommentModal({ ...commentModal, comment: e.target.value })}
          defaultValue={commentModal.comment}
          style={{
            height: "4rem", borderRadius: "5px", border: "1.5px solid #7FADDE", padding: "0 0.05rem",
            width: "100%"
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <div className="d-flex"
      >
        <span
          style={{ marginTop: "2rem" }}
          className={`${tableStyles.f40} ${tableStyles.darkBlueDarker} ${tableStyles.title} fw-bold`}>Solicitudes</span>
      </div>

      {/* <p
        className="subtitleBlue"
        title={selectedProcess?.description}
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
      >
        {mouseIn
          ? selectedProcess?.description
          : selectedProcess?.description.length >= 45
            ? selectedProcess?.description.slice(0, 45) + "..."
            : selectedProcess?.description}
      </p> */}
      <Row className="px-2">
        <Tabs
          id="uncontrolled-tab-example"
          defaultActiveKey="permissions"
          className="mb-3 nav nav-tabs"
          style={{
            borderBottom: "1px solid #dee2e6",
          }}
        >
          {/* /* ------------------------------ PERMISSIONS ------------------------------ */}
          <Tab
            eventKey="permissions"
            title="Permisos"
            tabClassName={`text-center text-primary px-4 tabHeader col-2 ${tableStyles.w220px} ${tableStyles.bgClearBlue}`}
          >
            <Row>
              <Col className={`mt-1 rounded ${tableStyles}`} xs={4}>
                <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                  <b>• Solicitud de permisos</b>
                </h6>
                <div
                  className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}
                >
                  <Row>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Cargo
                        <span className={"text-warning"}>*</span>
                      </p>
                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"deduction" + trigger}
                        onChange={(e) => {
                          setInputs({ ...inputs, job_position: e });
                          dispatch(
                            getCandidateList({
                              eaccount: idEnterprise,
                              status: "current",
                              filtered: 1,
                              area_leader: 1,
                              area_id: e?.extra,
                            })
                          );
                        }}
                        options={formattedPositions}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Jefe de área
                        <span className={"text-warning"}>*</span>
                      </p>
                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"deduction" + trigger}
                        onChange={(e) => {
                          setInputs({ ...inputs, leader: e });
                        }}
                        options={inputs.job_position !== "" ? formattedEmployees : []}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Fecha inicial
                        <span className={"text-warning"}>*</span>
                      </p>
                      <input
                        key={"DateStart" + trigger}
                        type="date"
                        onChange={(e) =>
                          setInputs({
                            ...inputs,
                            startDate: e.target.value,
                          })
                        }
                        value={inputs.type_dateMin}
                        // min={today()}
                        className="register-inputs text-primary text-uppercase fw-bold "
                      />
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Fecha final
                        <span className={"text-warning"}>*</span>
                      </p>
                      <input
                        key={"DateEnd" + trigger}
                        type="date"
                        onChange={(e) =>
                          setInputs({
                            ...inputs,
                            endDate: e.target.value,
                          })
                        }
                        value={inputs.type_dateMin}
                        // min={today()}
                        disabled={
                          !isEmptyOrUndefined(inputs.startDate) ? false : true
                        }
                        className="register-inputs text-primary text-uppercase fw-bold "
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Tipo
                        <span className={"text-warning"}>*</span>
                      </p>
                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"deduction" + trigger}
                        onChange={(e) => {
                          setInputs({ ...inputs, type: e });
                        }}
                        options={[
                          {
                            value: "paid",
                            label: "Remunerado",
                          },
                          { value: "unpaid", label: "No remunerado" },
                        ]}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Anexar soporte
                        <span className={"text-warning"}>*</span>
                      </p>
                      <Dropzone>
                        {({ getRootProps, getInputProps }) => (
                          <section
                            className={`${IndividualStyles.registerInputs} bg-white`}
                          >
                            <div {...getRootProps()} className="d-flex">
                              <input
                                {...getInputProps()}
                                type="file"
                                name="documents"
                                className="register-inputs"
                                onChange={(e) => onChangeDoc(e)}
                                // accept={type_file}
                                multiple={false}
                              />
                              {!isEmptyOrUndefined(documents.file_name) ? (
                                <p
                                  className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsisNew} hoverPointer`}
                                >
                                  {documents.file_name}
                                </p>
                              ) : (
                                <p
                                  className={`${IndividualStyles.LabelIntoDropzone} hoverPointer`}
                                >
                                  {"Cargar documento"}
                                </p>
                              )}
                              {
                                <img
                                  src={Attach}
                                  alt="añadir"
                                  style={{ marginLeft: "auto", width: "18px" }}
                                />
                              }
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Col>
                  </Row>

                  <p className={`${tableStyles.crudModalLabel} `}>
                    Descripción
                    <span className={"text-warning"}>*</span>
                  </p>
                  <textarea
                    value={inputs.description}
                    key={"descrPerm" + trigger}
                    onChange={(e) =>
                      setInputs({ ...inputs, description: e.target.value })
                    }
                    placeholder={"Escribir..."}
                    id="txtArea"
                    rows="10"
                    cols="10"
                    style={{ height: "4rem" }}
                    className={`register-inputs text-secondary`}
                  ></textarea>
                </div>

                <div className={`customTabs__Footer mt-2 p-0`}>
                  <Button
                    className={tableStyles.btnPrimary}
                    onClick={() =>
                      handleSubmit("permission")
                    }
                  // disabled={allowSend.citations ? false : true}
                  >
                    &nbsp; Solicitar
                  </Button>
                </div>
              </Col>
              {/* /* ---------------------- VERTICAL LINE --------------------- */}
              <Col
                className=" d-flex justify-content-center"
                xs={1}
              >
                <div style={{ width: "20%" }}>&nbsp;</div>
                <hr
                  className="m-0 p-0"
                  style={{
                    border: "none",
                    borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
                    height: "60vh",
                    width: "1px",
                  }}
                ></hr>
              </Col>
              <Col
                className={`mt-4 rounded  ${tableStyles.scrollThis}`}
                style={{ overflowY: "auto" }}
                xs={7}
              >
                <h4 className={`${tableStyles.darkBlueText} pt-4 pb-2`}>
                  <b>Permisos</b>
                </h4>
                {historial?.length > 0 ? (
                  historial.map((e, i) => {
                    return (
                      <Row className="d-flex justify-content-start">
                        <Col xs={4} className={`${tableStyles.darkGrayText} `}>
                          <b
                            className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                          >
                            •&nbsp;Inicio:&nbsp;
                          </b>
                          <b
                            className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                          >
                            {convertDateToLatinFormat(e.start_date)}
                          </b>
                        </Col>

                        <Col xs={8} className={`text-secondary d-flex`}>
                          <span
                            style={{ paddingTop: "2%" }}
                            className={`${tableStyles.fw700End} cursorPointer`}
                          >
                            <span
                              className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis}`}
                            >
                              <b
                                style={{ paddingTop: "1rem" }}
                                className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                              >
                                &nbsp;
                                {`Fin: ${convertDateToLatinFormat(e.end_date)}`}
                              </b>{" "}
                              &nbsp;
                              {e.description?.length >= 45
                                ? e.description.slice(0, 45) + "..."
                                : !isEmptyOrUndefined(e.description)
                                  ? e.description
                                  : "-"}
                            </span>
                          </span>
                          <span
                            className={`${tableStyles.fw700End} cursorPointer`}
                          >
                            {/* {e.status} */}
                            <div
                              className={`rounded-pill p-1 m-1 text-center
                              ${e.status === "pending"
                                  ? "alert-warning"
                                  : e.status === "approved"
                                    ? "alert-success"
                                    : e.status === "rejected"
                                      ? "alert-danger"
                                      : e.status === "processed" &&
                                      "alert-secondary"
                                }
                              `}
                            >
                              <b
                                className={`
                               ${e.status === "pending"
                                    ? "text-warning"
                                    : e.status === "approved"
                                      ? "text-success"
                                      : e.status === "rejected"
                                        ? "text-danger"
                                        : e.status === "processed" &&
                                        "text-secondary"
                                  }
                              `}
                              >
                                {e.status === "pending"
                                  ? "Pendiente"
                                  : e.status === "approved"
                                    ? "Aprobado"
                                    : e.status === "rejected"
                                      ? "Denegado"
                                      : e.status === "processed" && "Procesado"}
                              </b>
                            </div>
                          </span>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <h6 className={`${tableStyles.darkBlueText} pt-4`}>
                    <b>Sin información</b>
                  </h6>
                )}
              </Col>
            </Row>
          </Tab>
          {/* /* -------------------------------- #ANCHOR VACATIONS ------------------------------- */}
          <Tab
            eventKey="Vacations"
            title="Vacaciones"
            tabClassName={`text-center text-primary px-4 tabHeader col-2 ${tableStyles.w220px} ${tableStyles.bgClearBlue}`}
            onClick={() =>
              setSlider({
                permissions: false,
                vacations: true,
                paymentTicket: false,
              })
            }
          >
            <Row>
              <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                <b>• Solicitud de vacaciones</b>
              </h6>
              <Col className={`mt-1 rounded ${tableStyles}`} xs={5}>
                <div
                  className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}
                >
                  <Row>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Cargo
                        <span className={"text-warning"}>*</span>
                      </p>
                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"AreaLVCt" + trigger}
                        onChange={(e) => {
                          setInputs({ ...inputs, job_positionVct: e, leaderVct: "", type_dateMin: "", startDateVct: "" });
                          setPeriodDetailResults({})
                          // if (
                          //   !isEmptyOrUndefined(inputs.job_positionVct?.extra)
                          // ) {
                          dispatch(
                            getCandidateList({
                              eaccount: idEnterprise,
                              status: "current",
                              filtered: 1,
                              area_leader: 1,
                              area_id: e?.extra,
                            })
                          );
                          // }
                        }}
                        options={formattedPositions}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Jefe de área
                        <span className={"text-warning"}>*</span>
                      </p>
                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"deduction" + trigger}
                        onChange={(e) => {
                          setInputs({ ...inputs, leaderVct: e });
                        }}
                        value={formattedEmployees.find((e) => e?.value === inputs?.leaderVct?.value) || ""}
                        options={inputs.job_positionVct !== "" ? formattedEmployees : []}
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                  </Row>
                  <Row>

                    <Col xs={6}>
                      <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} mt-2`}>
                        Días máx. disponibles para <br /> descansar
                        <span className={"text-warning"}>*</span>
                      </label>
                      <input
                        key={"DateStart" + trigger}
                        type="text"
                        value={periodDetailResults?.days_off ?? ""}
                        className="register-inputs text-primary text-uppercase fw-bold "
                        disabled={true}
                      />
                    </Col>
                    <Col xs={6}>
                      <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} mt-2`}>
                        Días mín. disponibles para <br /> descansar
                        <span className={"text-warning"}>*</span>
                      </label>
                      <input
                        key={"DateStart" + trigger}
                        type="text"
                        value={periodDetailResults?.min_days_off ?? ""}
                        className="register-inputs text-primary text-uppercase fw-bold "
                        disabled={true}
                      />
                    </Col>
                    <Col xs={6}>
                      <label className={`${tableStyles.darkBlueText} ${tableStyles.f12}  mt-2`}>
                        Días máx. disponibles para ser <br /> compensados
                        <span className={"text-warning"}>*</span>
                      </label>
                      <input
                        key={"DateStart" + trigger}
                        type="text"
                        value={periodDetailResults?.compensate_days ?? ""}
                        disabled={true}
                        className="register-inputs text-primary text-uppercase fw-bold "
                      />
                    </Col>
                    <Col xs={6}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip placement="top-end" id="button-tooltip" {...props}>
                            Días correspondientes a los periodos vencidos acorde a El artículo 488 del código sustantivo del trabajo
                          </Tooltip>
                        }
                      >
                        <label className={`${tableStyles.darkBlueText} ${tableStyles.f12}  mt-2`}>
                          <br />
                          Días vencidos
                          <span className={"text-warning"}>*</span>
                        </label>
                      </OverlayTrigger>
                      <input
                        key={"DateStart" + trigger}
                        type="text"
                        value={periodDetailResults?.expired_days ?? ""}
                        disabled={true}
                        className="register-inputs text-primary text-uppercase fw-bold "
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        De la fecha
                        <span className={"text-warning"}>*</span>
                      </p>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip placement="top-end" id="button-tooltip" {...props}>
                            La fecha inicial de la solicitud debe encontrarse dentro del rango de los periodos habilitados
                          </Tooltip>
                        }
                      >
                      <input
                        key={"DateStart" + trigger}
                        type="date"
                        onChange={(e) => {
                          setInputs({
                            ...inputs,
                            startDateVct: e.target.value,
                          })

                          onChangeFromDateInput(e.target.value)
                        }
                        }
                        value={inputs.startDateVct}
                        // min={today()}
                        className="register-inputs  fw-bold "
                      />
                      </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        A la fecha
                        <span className={"text-warning"}>*</span>
                      </p>
                      <input
                        key={"DateEnd" + trigger}
                        type="date"
                        value={periodDetailResults?.dateUntil ?? ""}
                        className="register-inputs fw-bold "
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  {inputs.startDateVct !== "" &&
                  periodDetailResults?.periods?.length > 0 &&
                    <GenericTableNew headers={renderHeaders} dark={true} lowerCase>
                      {periodDetailResults?.periods?.length > 0 &&
                        periodDetailResults?.periods?.map((el, ind) => {
                          return renderElement(el, ind);
                        })
                      }
                    </GenericTableNew>
                  }
                </div>
                <div className={`customTabs__Footer mt-2 p-0`}>
                  <Button
                    className={tableStyles.btnPrimary}
                    onClick={() =>
                      // handleSubmit("vacations")
                      requestVacations()

                    }
                  // disabled={allowSend.citations ? false : true}
                  >
                    &nbsp; Solicitar
                  </Button>
                </div>
              </Col>
              {/* /* ---------------------- VERTICAL LINE --------------------- */}
              <Col
                className=" d-flex justify-content-center"
                xs={1}
                style={{ marginTop: "-37px" }}
              >
                <div style={{ width: "20%" }}>&nbsp;</div>
                <hr
                  className="m-0 p-0"
                  style={{
                    border: "none",
                    borderLeft: "1px solid hsla(200, 10%, 50%, 100)",
                    height: "60vh",
                    width: "1px",
                  }}
                ></hr>
              </Col>
              <Col
                xs={6}
              // className={tableStyles.scrollThis}
              >
                <Row>
                  <Col xs={12}>
                    <h6
                      className={`${tableStyles.darkBlueText} mb-4 text-center`}
                    >
                      <b>Historial de vacaciones</b>
                    </h6>
                  </Col>

                  <Col
                    className={`d-flex justify-content-center ${tableStyles.scrollThis} mb-4`}
                    xs={12}
                  >
                    {/* /* -------------------------------- TIMELINE -------------------------------- */}

                    <div className={`${tableStyles.timeline}`}>
                      {vacationHistory?.length > 0 &&
                        vacationHistory?.map((e, i) => {
                          return (
                            <div className="d-flex pb-2">
                              <Col xs={3}
                                className={`${tableStyles.timeLineContainer} `}
                              >
                                <div className={`${tableStyles.content}`}>
                                  {/* TEXT IN THE LEFT  */}
                                  <b
                                    className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                                  >
                                    Inicio:{" "}
                                    {convertDateToLatinFormat(e.start_date)}
                                  </b>
                                </div>
                                <div className={`${tableStyles.content}`}>
                                  {/* TEXT IN THE LEFT  */}
                                  <b
                                    className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                                  >
                                    Fin: {convertDateToLatinFormat(e.end_date)}
                                  </b>
                                </div>
                              </Col>

                              <Col xs={7} className={`d-flex mt-5 pl-4`}>
                                {/* TEXT IN THE RIGHT SIDE*/}
                                <div className={``} style={{ width: "60%" }}>
                                  <b className={`text-secondary`}>{`${e.days_counted
                                    } ${e.days_counted > 1 ? "Días" : "Día"}`}</b>
                                  <br></br>
                                  <span className={`text-secondary`}>{`${e.type === "rested"
                                    ? "Disfrute"
                                    : "Compensación"
                                    }`}</span>
                                </div>

                                <div
                                  style={{ width: "60%" }}
                                  className={`${tableStyles.fw700End} `}
                                >
                                  {/* {e.status} */}
                                  <div
                                    className={`rounded-pill p-1 m-1 text-center

                              ${e.status === "pending"
                                        ? "alert-warning"
                                        : e.status === "approved"
                                          ? "alert-success"
                                          : e.status === "rejected"
                                            ? "alert-danger"
                                            : e.status === "processed" &&
                                            "alert-secondary"
                                      }
                              `}
                                  >
                                    <b
                                      className={`
                               ${e.status === "pending"
                                          ? "text-warning"
                                          : e.status === "approved"
                                            ? "text-success"
                                            : e.status === "rejected"
                                              ? "text-danger"
                                              : e.status === "processed" &&
                                              "text-secondary"
                                        }
                              `}
                                    >
                                      {e.status === "pending"
                                        ? "Pendiente"
                                        : e.status === "approved"
                                          ? "Aprobado"
                                          : e.status === "rejected"
                                            ? "Denegado"
                                            : e.status === "processed" &&
                                            "Procesado"}
                                    </b>
                                  </div>
                                </div>
                                {e.status === "rejected" ?
                                  <div
                                    style={{ width: "30px" }}
                                  >
                                    {e.comment !== "" &&
                                      <img alt="note" src={note}
                                        height="23px"
                                        className={`${tableStyles.svgFilterDarkBlue} cursorPointer`}
                                        onClick={() => {
                                          setCommentModal({ ...commentModal, show: true, comment: e.comment })
                                        }} />
                                    }
                                  </div> :
                                  <div
                                    style={{ width: "30px" }}
                                  ></div>
                                }

                              </Col>
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tab>
          {/* /* -------------------------------- PAYMENT TICKET ------------------------------- */}
          <Tab
            eventKey="minutes"
            title=" Volante de pago"
            tabClassName={`text-center text-primary px-4 tabHeader col-2 ${tableStyles.w220px} ${tableStyles.bgClearBlue}`}
            onClick={() =>
              setSlider({
                permissions: false,
                vacations: false,
                paymentTicket: true,
              })
            }
          >
            <Row>
              <Col className={`mt-1 rounded `} xs={6}>
                <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                  <b>• Búsqueda de volante de pago</b>
                </h6>
                <div
                  className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}
                >
                  <Row>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Mes
                        <span className={"text-warning"}>*</span>
                      </p>

                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"deduction" + trigger}
                        options={monthsOfTheYear}
                        onChange={(e) =>
                          setInputs({ ...inputs, month: e.value })
                        }
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Año
                        <span className={"text-warning"}>*</span>
                      </p>

                      <Select noOptionsMessage={() => 'No hay datos'}
                        key={"deduction" + trigger}
                        options={getFivePreviousYear().map((e) => {
                          return { value: e, label: e };
                        })}
                        onChange={(e) =>
                          setInputs({ ...inputs, year: e.value })
                        }
                        className="text-secondary"
                        placeholder={"Seleccionar..."}
                        styles={customSelectNew}
                      ></Select>
                    </Col>
                  </Row>
                </div>

                <div className={`customTabs__Footer mt-2 p-0`}>
                  <Button
                    className={tableStyles.btnPrimary}
                    onClick={() => getTicketList()}
                  >
                    &nbsp; Buscar
                  </Button>
                </div>
                <Row>
                  <Col
                    className={`mt-1 rounded m-3 ${tableStyles.scrollThis}`}
                    xs={12}
                  >
                    {ticketsList?.length > 0 ? (
                      ticketsList.map((e, i) => {
                        return (
                          <Row className="d-flex justify-content-start">
                            <Col
                              xs={12}
                              className={`${tableStyles.darkGrayText} `}
                            >
                              <b
                                className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                              >
                                • Fecha de pago : &nbsp;
                              </b>
                              <b
                                className={`${tableStyles.darkBlueText} ${tableStyles.fw700End}`}
                              >
                                {e.date}
                              </b>
                            </Col>

                            <Col xs={4} className={`text-secondary`}>
                              <span
                                onClick={() => getPdf(e.id_detail)}
                                className={`${tableStyles.fw700End} cursorPointer`}
                              >
                                <span
                                  className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis}`}
                                  title="Click para previsualizar y descargar el archivo PDF"
                                >
                                  <u>
                                    {e.description?.length >= 45
                                      ? e.description.slice(0, 45) + "..."
                                      : !isEmptyOrUndefined(e.description)
                                        ? e.description
                                        : "-"}
                                  </u>
                                </span>
                              </span>
                            </Col>
                          </Row>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xs={6} className={`mb-4`}>
                {pdfLoading ? (
                  <div className="text-center d-flex justify-content-center">
                    <div className="mx-auto " style={{ marginTop: "15rem" }}>
                      <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                ) : (
                  !isEmptyOrUndefined(base64) && (
                    <>
                      <Row className={"pt-2 d-flex justify-content-end"}>
                        <Col xs={4}>
                          <Button
                            variant="outline-primary"
                            onClick={() => setBase64("")}
                            className={tableStyles.btnSecondary}
                          >
                            Limpiar
                          </Button>
                        </Col>
                      </Row>
                      <PdfViewer
                        downloadable
                        file={`data:application/pdf;base64,${base64}`}
                      ></PdfViewer>
                    </>
                  )
                )}
              </Col>
              {/* /* -------------------------------- PDF Viwer ------------------------------- */}

              <ModalNew
                title="Detalle de volante de pago"
                show={showPdf}
                btnNoName={"Cancelar"}
                size="700"
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
              >
                <PdfViewer
                  downloadable
                  file={`data:application/pdf;base64,${base64}`}
                ></PdfViewer>
              </ModalNew>
            </Row>
          </Tab>
        </Tabs>
      </Row>
    </div>
  );
};

