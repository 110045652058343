import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { loader, message, validateEmptyString } from "../../../../helpers/helpers";
import { useGetMethod, usePostMethod } from "../../../../Hooks/useFetch";
import GenericTableScroll from "../../../Layouts/GenericTableScroll";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../../Layouts/pagination.module.scss";
import arrowIcon from "../../../../assets/img/icons/arrowDarkGrey.svg"
import Pagination from "react-js-pagination";
import { Radio } from "@material-ui/core";
import Swal from "sweetalert2";
import { responseSwal } from "../../../../helpers/responseSwal";

const Surveys = () => {
  //  SECTION STATUS
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;
  const { results: surveyListResults, load: surveyListLoader, trigger: getSurveyListMethod } = useGetMethod();
  const { load: sendAnswersSurveyLoader, trigger: sendAnswersSurveyMethod } = usePostMethod();

  const { results: surveyDetailResults, load: surveyDetailLoader, trigger: getSurveyDetailMethod } = useGetMethod();

  const [filtersProgress, setFiltersProgress] = useState({
    eaccount: idAccount,
    employee: userId,
  });


  const [surveySelected, setSurveySelected] = useState({
    id: "",
  })

  const [trigger, setTrigger] = useState(0);

  const [answers, setAnswers] = useState([])

  const [historySurveyList, setHistorySurveyList] = useState([])
  const [toRespondSurveyList, setToRespondSurveyList] = useState([])
  //  !SECTION

  // SECTION PETITIONS

  useEffect(() => {
    getSurveyListMethod({
      url: "/payroll/workenviroment",
      objFilters: { ...filtersProgress, },
      token: token,
      doAfterSuccess: (info)=>{
        let tempHistory=[]
        let tempToRes=[]
        info?.results?.length > 0 &&
        info?.results?.forEach((e)=>{
          if(e?.replied === 1 ){
            tempHistory.push(e)
          }
          if(e?.replied === 0 && e?.status === "in_progress"){
            tempToRes.push(e)
          }
        })
        setHistorySurveyList(tempHistory)
        setToRespondSurveyList(tempToRes)
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersProgress.page, trigger]);
  // !SECTION PETITIONS

  // ANCHOR  SURVEY LIST  ACTIVE TABLE FUNCTIONALITIES

  const renderHeaders = [
    <div key={1} className={`col-5 text-start`} style={{ paddingLeft: "1rem" }}>Título</div>,
    <div key={2} className={`col-3 text-center`}>      Fecha inicio
    </div>,
    <div key={3} className={`col-3 text-center`}>      Fecha fin
    </div>,
    <div key={4} className={`col-1`} style={{ paddingRight: "1rem" }}>&nbsp;&nbsp;</div>,
  ]

  const listElem = () => {
    let elemMap;
    if (toRespondSurveyList?.length > 0) {
      const elem2 = toRespondSurveyList;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return (
            <section2 key={elem.id}>
              <div className="col-5 text-start " style={{ paddingLeft: "1rem" }}>
                {elem.title ? elem.title : "-"}
              </div>
              <div className="col-3 text-center">{elem.startDate ? elem.startDate : "-"}</div>
              <div className="col-3 text-center">{elem.endDate ? elem.endDate : "-"}</div>
              {/* <div className="col-md-2 text-center">
                <div
                  className="rounded-pill p-1"
                  style={{
                    backgroundColor: elem?.background,
                  }}
                >
                  <b style={{ color: elem?.fontColor }}>{elem.statusName ? elem.statusName : "-"}</b>
                </div>
              </div> */}
              <div className="col-1 text-center">
                <img alt="arrowIcon" src={arrowIcon} className="cursorPointer" onClick={() => {
                  setSurveySelected({ ...surveySelected, id: elem.id })
                  getSurveyDetail(elem.id)
                }} />
              </div>
            </section2>
          );
        });
      }
    }else {
      elemMap = <div className="d-flex justify-content-center align-content-center h-100 align-items-center">
      <div className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}>
        <span className="align-self-center">No hay encuestas disponibles por responder</span>
      </div>
    </div>
    }
    return elemMap;
  };


  const getSurveyDetail = (id) => {
    getSurveyDetailMethod({
      url: "/payroll/workenviroment_detail",
      objFilters: {
        id: id,
        eaccount: idAccount,
      },
      doAfterSuccess: (info) => {
        let tempArr = []
        info?.results?.results?.length > 0 &&
          info?.results?.results.forEach((e) => {
            tempArr.push({
              "question": e.id,
              "type": e.type,
              "answer": "",
              "required": e.required
            },)
          })
        setAnswers(tempArr)
      },
      token: token,
    });
  }
  // ANCHOR  SURVEY HISTORY  TABLE FUNCTIONALITIES

  const renderHeadersHistory = [
    <div key={1} className={`col-5 text-start`} style={{ paddingLeft: "1rem" }}>Título</div>,
    <div key={2} className={`col-2 text-center`}>      Fecha inicio
    </div>,
    <div key={3} className={`col-2 text-center`}>      Fecha fin
    </div>,
    <div key={4} className={`col-3 text-center`}>Estado</div>,
  ]


  const listElemHistory = () => {
    let elemMap;
    if (historySurveyList?.length > 0) {
      const elem2 = historySurveyList;
      if (elem2?.length > 0) {
        elemMap = elem2.map((elem) => {
          return (
            <section2 className={``} key={elem.id}>
              <div className="col-5 text-start " style={{ paddingLeft: "1rem" }}>
                {elem.title ? elem.title : "-"}
              </div>
              <div className="col-2 text-center">{elem.startDate ? elem.startDate : "-"}</div>
              <div className="col-2 text-center">{elem.endDate ? elem.endDate : "-"}</div>
              <div className="col-3 text-center px-2">
                <div
                  className="rounded-pill p-1"
                  style={{
                    backgroundColor: elem?.background,
                  }}
                >
                  <b style={{ color: elem?.fontColor }}>{elem.statusName ? elem.statusName : "-"}</b>
                </div>
              </div>
            </section2>
          );
        });
      }
    }else {
      elemMap = <div className="d-flex justify-content-center align-content-center h-100 align-items-center">
      <div className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}>
        <span className="align-self-center">No se han respondido encuestas</span>
      </div>
    </div>
    }
    return elemMap;
  };


  const setAnswersFunction = (id, value) => {
    let tempArr = answers
    let ans = tempArr.find((e) => e.question === id)
    ans.answer = value
    let findIndex = tempArr.findIndex((e) => e.question === id)
    tempArr[findIndex] = ans
    setAnswers(tempArr)
  }

  const questionsList = () => {
    let tempArr;

    if (surveyDetailResults?.results?.results?.length > 0) {
      const temp = surveyDetailResults?.results?.results;

      if (temp.length > 0) {
        tempArr = temp.map((elem, i) => {

          let isRequired = elem?.required === 1 ? tableStyles.nmCardQuestionsRequired : tableStyles.nmCardQuestions
          return (

            <div className={`${isRequired} my-2 px-5 py-3 mt-4`} key={`card+${i}`}>
              <Row className="mb-2">
                <b className={`${tableStyles.f18} ${tableStyles.tlnTextGray}   `}> {`Pregunta No.${i + 1}`}</b>
              </Row>
              <Row className="">
                <Col xs={12}>
                  <span className={`${tableStyles.tlnTextGray} ${tableStyles.f16}`}>
                    {elem?.question}
                  </span>
                </Col>
              </Row>
              {/* ANCHOR MULTIPLE CHOICES */}
              <Row className="mt-2">
                {elem?.type === "multiple_choice" ? (
                  <>
                    {elem?.labels.map((label, ind) => {
                      return (
                        <div className="d-flex align-items-center
                        "
                          key={`${ind}+answers`}
                        >
                          <Radio
                            key={`${ind}+answer`}
                            checked={answers.find((e) => e.answer === label) ? true : false}
                            // onChange={(e) => { }}
                            onClick={() => {
                              setAnswersFunction(elem.id, label)
                            }}
                            //   value="alphabetic"
                            name="alphabetic"
                            style={{ color: "#005dbf" }}
                            // color="primary"
                            // className={"text-info"}
                            id="alphabetic"
                          />
                          <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
                            {label}
                          </span>
                          {/* <input
                            key={`${ind}+answer2`}
                            className={`${tableStyles.registerInputsNom} text-secondary ${tableStyles.outlineNone}`}
                            name="position"
                            defaultValue={label}
                            placeholder="Escribe una respuesta"
                            // onChange={(e) => setStatusMultipleChoicesAnswers("label", e.target.value, i, ind)}
                          /> */}

                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="mt-2">
                    <textarea
                      placeholder={"Escribir..."}
                      rows="30"
                      cols="55"
                      // disabled={true}
                      style={{ height: "5rem" }}
                      // value={data.description}
                      className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                      onChange={(e) => setAnswersFunction(elem.id, e.target.value)}
                    />
                  </div>
                )}
              </Row>
            </div>

          );
        })
      }
    }

    return tempArr;
  }
  const sendAnswersSurvey = () => {
    let status = true;

    answers.forEach((elem) => {
      if (elem.required === true) {
        if (elem.answer !== "") {
          status = false;
        }
      }
    });
    // let status = validationsSendAns()
    if (status) {
      Swal.fire({
        title: `<span style=color:#003f80>¿Está seguro?</span>`,
        text: `Se registrarán las respuestas`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#003f80",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Continuar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          sendAnswersSurveyMethod({
            url: "/payroll/workenviroment_saveAnswers",
            method: "POST",
            body: {
              "id": surveySelected.id,
              "idUser": userId,
              "answers": answers,
            },
            token: token,
            succesAction: (info) => {
              setTrigger(trigger + 1)
              setSurveySelected({ ...surveySelected, id: "" })
              responseSwal({
                title: `<span style=color:#005dbf>${info.title}</span>`,
                icon: "success",
              })
              // message("info", "Información", info.message);
            },
            doAfterException: (error) => {
              responseSwal({
                title: `<span style=color:#005dbf>${error.title}</span>`,
                text: `<span style=color:#005dbf>${error.message}</span>`,
                icon: "success",
              })
              message("info", "Información", error.message);
            },
          });
        }
      })

    }
  }

  return (
    <>
      {(surveyListLoader || surveyDetailLoader) && loader}
      <Row className="py-2">
        <Col xs={6} className="mt-2">
          <b className={`${tableStyles.darkBlueText} ${tableStyles.f16} my-4 text-center mt-5`}>
            Encuestas pendientes por responder
          </b>
          <GenericTableScroll
            headers={renderHeaders}
            dark={true}
            body={listElem()}
            typeHead={"2"}
            maxHeight={250}
          >
          </GenericTableScroll>
          {/* <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew> */}

          <b className={`${tableStyles.darkBlueText} ${tableStyles.f16} my-4 text-center mt-5`}>
            Historial de encuestas respondidas
          </b>
          <GenericTableScroll
            headers={renderHeadersHistory}
            dark={true}
            body={listElemHistory()}
            typeHead={"2"}
            maxHeight={250}
          >
          </GenericTableScroll>
          {/* <GenericTableNew headers={renderHeadersHistory} dark={true}>
            {listElemHistory()}
          </GenericTableNew> */}
        </Col>
        <Col xs={6} className="mt-4">
          {surveySelected.id !== "" ?
            <>
            <div style={{maxHeight: "600px", overflowY: "auto"}} className="px-4">
            {questionsList()}
            </div>
              <div className={`customTabs__Footer mt-4 ml-1`}>
                <Button onClick={() => sendAnswersSurvey()} className={tableStyles.btnPrimary}
                >
                  Enviar
                </Button>

                <Button variant="outline-primary" onClick={() => { setSurveySelected({ ...surveySelected, id: "" }) }} className={tableStyles.btnSecondary}>
                  Cancelar
                </Button>
              </div>
            </> :
            <div className="d-flex justify-content-center align-content-center h-100 align-items-center">
              <div className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}>
                <span className="align-self-center">Seleccione una encuesta</span>
              </div>
            </div>
          }
        </Col>
      </Row>
    </>
  );
};

export default Surveys;
