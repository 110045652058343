import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Close from "../../assets/img/icons/modalClose.svg";
import modalnewBack from "../../assets/img/icons/modalnewBack.svg";
import modalnewNext from "../../assets/img/icons/modalnewNext.svg";
import styles from "./modalnew.module.scss";
import tableStyles from "./tableStyle.module.scss";

function renderExtraButton(btn, index) {
  {/* poner aca botones extra con este formato.*/ }
  {/* crear un prop como este ejemplo
      extraButtons={[
          {
            type:1,
            name:"test",
            icon: extraBtnCheck,
            event: ()=>{console.log("extra event here 1")}
          }
      ]}
   */}
  let extraClass = "";
  switch (btn.type) {
    case 2:
      //fondo blanco borde azul claro
      extraClass = tableStyles.buttonExtra2
      break;
    case 3:
      //fondo azul oscuro, texto blanco
      extraClass = tableStyles.buttonExtra3
      break;
    default:
      //fondo blanco borde azul oscuro
      extraClass = tableStyles.buttonExtra1
      break;
  }

  return (!!btn.event) && (
    <Button
      key={"extraBtn" + index}
      onClick={btn.event}
      className={`${extraClass} ${styles.mr16}`}
      disabled={btn.disabled}
    >
      {!!btn.icon && <img src={btn.icon} alt="extraBtn" />}
      <p className={`m-0`}>{!!btn.name ? btn.name : "Extra"}</p>
    </Button>
  )
}



function ModalNew(props) {

  const mainContent = (
    <div className={styles.body}>
      {props.children}

      {
        props.hideFooter 
          ? ''
          : (
            <div className={styles.footer}>
        {!!props.btnYesEvent ?
          <button
            onClick={props.btnYesEvent}
            className={`${tableStyles.btnPrimary} ${styles.ml16}`}
            disabled={props.btnYesDisabled}
          >
            {props.btnYesName ? props.btnYesName : "Guardar"}
          </button>
          : ""}
        {!props.hideCancelButton && (
          <button
            //for cases where i need a different action for the modal's negative button
            //pick btnNoEvent, if not asigned, repeat the closing event
            onClick={!!props.btnNoEvent ? props.btnNoEvent : props.onHide}
            className={`${tableStyles.btnSecondary}`}
            disabled={props.btnNoDisabled}
          >
            {props.btnNoName ? props.btnNoName : "Cancelar"}
          </button>
        )}

        <div className={styles.extraButtons}>
          {
            (Array.isArray(props.extraButtons)) && (
              props.extraButtons.map((x, index) => renderExtraButton(x, index))
            )
          }
        </div>


      </div>
          )
      }
    </div>
  );

  const additionalContent = (
    <div className={styles.body}>
      {props.additionalContent}
    </div>
  );


  return (
    <Modal
      //{...props}
      //options: sm, sm2, lg and xl
      //if not asigned, default to medium md
      onHide={props.onHide}
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.container}
    >
      <Modal.Header >
        <div className={`${styles.divHeaderModal} pt-2`}>
          <h2 className={styles.maintitle} >
            {props.title}
          </h2>


          {!!props.subtitle && <h3 className={`${styles.subtitle} ${props.subtitleStyle}`} >
            {props.subtitle}
          </h3>
          }



          {(!!props.leftArrowEvent && !!props.rightArrowEvent) ?
            [<img
              key="atras"
              src={modalnewBack}
              alt="atras"
              className={styles.leftArrowIcon}
              onClick={props.leftArrowEvent}
              style={{ cursor: "pointer" }}
            />,
            <img
              key="next"
              src={modalnewNext}
              alt="next"
              className={styles.rightArrowIcon}
              onClick={props.rightArrowEvent}
            />]
            :
            <img
              src={Close}
              alt="Cerrar"
              className={styles.closeIconModal}
              onClick={props.onHide}
            />
          }

        </div>
      </Modal.Header>

      {!!props.additionalContent &&
        <Modal.Body>
          {additionalContent}
        </Modal.Body>
      }

      <Modal.Footer 
        style={props.styleMainContent ? props.styleMainContent: {}}
      >
        {mainContent}
      </Modal.Footer>


    </Modal>
  );

}

export default ModalNew;
