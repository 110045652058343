export const hasData = (variable) => {
	let isValid = false;

	if (typeof variable === 'boolean') {
		isValid = !!variable;
	}

	if (typeof variable === 'string') {
		isValid = variable.trim() !== '';
	}

	if (typeof variable === 'number') {
		isValid = variable > 0;
	}

	if (typeof variable === 'undefined') {
		isValid = false;
	}

	if (typeof variable === 'object') {
		isValid = Object.keys(variable || {}).length > 0;
	}

	if (variable === null) {
		isValid = false;
	}

	return isValid;
};