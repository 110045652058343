import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { URL_GATEWAY, API_VERSION } from "../../../../helpers/constants";
import Select from "react-select";
import { customSelectNew } from "../../../Layouts/react-select-custom";
import tableStyles from "../../../Layouts/tableStyle.module.scss";
import { getCandidateList } from "../../../../actions/partnersActions";
import { getAllJobPositions } from "../../../../actions/positionsActions";
import AdjuntarOscuro from "../../../../assets/img/icons/AdjuntarOscuro.svg";
import { isEmptyOrUndefined, today } from "../../../../helpers/helpers";
import { customSwaltAlert } from "../../../../helpers/customSwaltAlert";
import moment from "moment";


function InhabilityRegister(props) {
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const history = useHistory();
    const token = store.loginReducer.Authorization;
    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find((x) => x.functionality?.prefix === "minoreg");
    const [trigger, setTrigger] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const idLeader =store?.loginReducer?.user_data?.payrollInfo?.id_candidate
    const [formData, setFormData] = useState({
        status: 'pending',

        job_position: null,
        area_leader: null,
        type: null,
        subtype: null,
        start_date: null,
        end_date: null,
        diagnosis: null,
        support_file: null,


        filename: "",
        file_base64: "",

        employee: null,
        contract: null,
        created_by: store.loginReducer.user_data?.id,
        entity_account: store.loginReducer.currentAccount.id,
    });
    const sendPost = async (data) => {
        setIsLoading(true);
        const query = await fetch(
            `${URL_GATEWAY}${API_VERSION}/payroll/inhability/`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
        let result = await query.json();
        return result;
    }
    useEffect(() => {
        setFormData({ ...formData, area_id: "", job_position: "", area_leader: "" });
        if (!myPermission?.read) {
            history.push("/nomina/inicio");
        }
        if (props.profile === 1) {
            // dispatch(
            //     getCandidateList({
            //         eaccount: store.loginReducer.currentAccount.id,
            //         //status: "current",
            //         filtered: 1,
            //         area_leader: 1,
            //         area_id: store.loginReducer.user_data?.payrollInfo?.area_id,
            //     })
            // );
            dispatch(
                getAllJobPositions({
                    slim: 1,
                    active: 1,
                    entity_account: store.loginReducer.currentAccount.id,
                    id_candidate: store?.loginReducer?.user_data?.payrollInfo?.id_candidate
                })
            );
        }
    }, [props.profile]);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }

    const onChangeDoc = async (e) => {
        const file = e.target.files[0];
        let encode = await convertBase64(file);
        let file_base64 = encode.split("base64,")[1];
        setFormData({
            ...formData,
            file_base64: file_base64,
            filename: file.name
        });
    };


    const optionTypes = [
        { label: "Temporal / General", value: "regular" },
        { label: "Profesional / Laboral", value: "laboral" },
        { label: "Licencia de maternidad", value: "maternity_leave" },
        { label: "Licencia de paternidad", value: "paternity_leave" },
    ];
    const optionSubtypes = [
        { label: "Ambulatoria", value: "ambulatory" },
        { label: "Hospitalaria", value: "hospital" },
    ];


const [optionJobPos, setOptionJobPos] = useState([])
const [optionBoss, setOptionBoss] = useState([])
    useEffect(() => {
        let tempArr = store?.positionReducer?.jobsPositionsList?.map(jp => ({
            ...jp,
            label: jp.job_title,
            value: jp.id,
        })) ?? [];
        tempArr.unshift({label: "Seleccionar...", value: ""})
        setOptionJobPos(tempArr)

        let tempArrBoss = store?.partnersReducer?.employee_list?.map(candidate => ({
            ...candidate,
            label: candidate?.full_name,
            value: candidate?.id_candidate,
        })) ?? [];

        tempArrBoss.unshift({label: "Seleccionar...", value: ""})
        setOptionBoss(tempArrBoss)
    }, [store?.positionReducer?.jobsPositionsList, store?.partnersReducer?.employee_list])

    const uploadInhability = async () => {
        let error = "";
        let daylenght = 1;
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        if (!formData.job_position) { error = "Debe seleccionar un Cargo"; }
        if (!formData.area_leader) { error = "Debe seleccionar un jefe"; }
        if (!formData.type) { error = "Debe seleccionar un tipo de incapacidad"; }
        if (formData.type === "regular" && !formData.subtype) { error = "Debe seleccionar un tipo de incapacidad temporal"; }
        if (isEmptyOrUndefined(formData.diagnosis)) { error = "Debe escribir un diagnostico"; }
        if (formData.file_base64 === "") { error = "Debe subir un archivo de incapacidad"; }
        if (!formData.start_date) { error = "Debe seleccionar una fecha inicial"; }
        if (!formData.end_date) { error = "Debe seleccionar una fecha final"; }
        if (formData.start_date && formData.end_date) {
            const sDate = new Date(formData.start_date);
            const eDate = new Date(formData.end_date);
            if (eDate < sDate) {
                error = "La fecha de finalización debe ser mayor o igual a la fecha de inicio";
            } else {
                daylenght = Math.round(Math.abs((eDate.getTime() - sDate.getTime()) / (oneDay)));
            }
        }

        if (error != "") {
            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error,
                showCancelButton: false,
            });
            return null;
        }

        let snd = await sendPost({ ...formData, days_counted: daylenght });
        if (snd.success) {
            setFormData({
                status: 'pending',
                job_position: null,
                area_leader: null,
                type: null,
                start_date: null,
                end_date: null,
                diagnosis: null,
                support_file: null,
                filename: "",
                file_base64: "",
                employee: null,
                contract: null,
                created_by: store.loginReducer.user_data?.id,
                entity_account: store.loginReducer.currentAccount.id,
            })
            setIsLoading(false);
            setTrigger(trigger + 1);
            customSwaltAlert({
                icon: 'success',
                title: 'Datos Guardados',
                text: 'Incapacidad registrada',
                showCancelButton: false,
            });
        } else {
            setIsLoading(false);
            customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: snd.message,
                showCancelButton: false,
            });
        }
        setTrigger(trigger + 1)


    }
    return (
        <div className={`simpleTabs__wrap pb-4`}>
            {isLoading && (
                <div className="loading">
                    <Loader
                        type="Oval"
                        color="#003f80"
                        height={100}
                        width={100}
                    />
                </div>
            )}
            <div className={` col-6 `}>
                <h4 className="simpleTabs__thirdTitle" style={{ marginTop: 24, marginBottom: 7 }}>Registro de incapacidad</h4>
                <div className={tableStyles.genericGrayContainer}>


                    <div className={`d-flex`}>
                        <div className="d-flex flex-column  mr-2" style={{ flex: 1 }}>
                            <p className={tableStyles.crudModalLabel}>Cargo
                                <span className="text-danger" >*</span>
                            </p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                options={optionJobPos}
                                value={optionJobPos?.find((obj) => obj.value === formData.job_position)}
                                onChange={(e) =>{
                                    setFormData({ ...formData, job_position: e.value })
                                    if (props.profile === 1) {
                                        dispatch(
                                            getCandidateList({
                                                eaccount: store.loginReducer.currentAccount.id,
                                                status: "current",
                                                filtered: 1,
                                                area_leader: 1,
                                                area_id: e?.area_id,
                                            })
                                        );
                                    }
                                }}
                                key={trigger + "position"}
                                placeholder={"Seleccionar..."}
                                styles={customSelectNew}
                            />
                        </div>
                        <div className="d-flex flex-column" style={{ flex: 1 }}>
                            <p className={tableStyles.crudModalLabel}>Jefe de Area
                                <span className="text-danger" >*</span>
                            </p>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                options={formData.job_position !== null? optionBoss : []}
                                value={optionBoss.find((obj) => obj.value === formData?.area_leader)}
                                onChange={(e) => setFormData({ ...formData, area_leader: e.value })}
                                key={trigger + "areaBoss"}
                                placeholder={"Seleccionar..."}
                                styles={customSelectNew}
                            />
                        </div>
                    </div>


                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <p className={tableStyles.crudModalLabel}>Tipo de incapacidad
                            <span className="text-danger" >*</span>
                        </p>
                        <Select noOptionsMessage={() => 'No hay datos'}
                            options={optionTypes}
                            value={optionTypes?.find((obj) => obj.value === formData.type)}
                            onChange={(e) => setFormData({ ...formData, type: e.value })}
                            key={trigger + "inhType"}
                            placeholder={"Seleccionar..."}
                            styles={customSelectNew}
                        />
                    </div>
                    {formData.type === "regular" &&
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <p className={tableStyles.crudModalLabel}>Tipo de incapacidad temporal
                            <span className="text-danger" >*</span>
                        </p>
                        <Select noOptionsMessage={() => 'No hay datos'}
                            options={optionSubtypes}
                            value={optionSubtypes?.find((obj) => obj.value === formData.subtype)}
                            onChange={(e) => setFormData({ ...formData, subtype: e.value })}
                            key={trigger + "inhType"}
                            placeholder={"Seleccionar..."}
                            styles={customSelectNew}
                        />
                    </div>
                    }

                    <div className={`d-flex`}>
                        <div className="d-flex flex-column  mr-2" style={{ flex: 1 }}>
                            <p className={tableStyles.crudModalLabel}>Fecha inicial
                                <span className="text-danger">*</span>
                            </p>
                            <input
                                type="date"
                                className="register-inputs"
                                value={formData.start_date}
                                key={trigger + "startDate"}
                                onChange={
                                    e => setFormData({
                                        ...formData,
                                        start_date: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div className="d-flex flex-column" style={{ flex: 1 }}>
                            <p className={tableStyles.crudModalLabel}>Fecha final
                                <span className="text-danger" >*</span>
                            </p>
                            <input
                                type="date"
                                className="register-inputs"
                                value={formData.end_date}
                                key={trigger + "endDate"}
                                onChange={
                                    e => setFormData({
                                        ...formData,
                                        end_date: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <p className={tableStyles.crudModalLabel}>Diagnóstico
                            <span className="text-danger">*</span>
                        </p>
                        <textarea
                            placeholder="Escribir..."
                            className={tableStyles.newTextareaStyle}
                            value={formData.diagnosis}
                            key={trigger + "Diagnostyc"}
                            onChange={
                                e => setFormData({
                                    ...formData,
                                    diagnosis: e.target.value
                                })
                            }
                        />
                    </div>

                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <p className={tableStyles.crudModalLabel}>Incapacidad
                            <span className="text-danger" >*</span>
                        </p>
                        <label
                            htmlFor="fileupper"
                            className="register-inputs d-flex"
                        >
                            <p className="m-0" style={{ flex: 1 }} >
                                {formData.filename}
                            </p>
                            <img
                                src={AdjuntarOscuro}
                                style={{ alignSelf: "end" }}
                            />
                        </label>

                        <input
                            id="fileupper"
                            name="fileupper"
                            type="file"
                            style={{ display: "none" }}
                            onChange={onChangeDoc}
                        />
                    </div>

                </div>

                <div className="d-flex flex-row-reverse " style={{ marginTop: 16 }}>
                    <button
                        className={tableStyles.btnPrimary}
                        onClick={() => uploadInhability()}
                    >
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    )
}
export default InhabilityRegister;