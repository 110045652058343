//actions
import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { responseSwal } from "../helpers/responseSwal";
import {
  LOADING,
  GET_PROVIDERS,
  GET_COUNTRIES,
  GET_CIIU,
  GET_PROVIDER_CLASSIFICATION,
  CREATE_DRAFT_PROVIDER,
  GET_EDIT_PROVIDER,
  GET_BANKING_ENTITIES,
  GET_TAX_PAYER_TYPE,
  GET_TYPE_SERVICES,
  GET_LABORATORIES
} from "./actionTypes";
import { convertFilterToString } from "../helpers/convertToFilter";
import { customSwaltAlert } from "../helpers/customSwaltAlert";
import { useHistory } from "react-router";
import { isEmptyOrUndefined } from "../helpers";

// This function create a new provider draft!

export const createDraft = (objProvider, action) => (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  let includeFile = false;
  for (const key in objProvider) {
    if (Object.hasOwnProperty.call(objProvider, key)) {
      const element = objProvider[key];
      if (!!element && key.includes("filename")) includeFile = true;
    }
  }

  for (let i = 0; i < objProvider.extraCommercialReferences?.length; i++) {
    const element = objProvider.extraCommercialReferences[i];
    for (const key in element) {
      if (Object.hasOwnProperty.call(element, key)) {
        const item = element[key];
        if (!!item && key.includes("filename")) includeFile = true;
      }
    }
  }

  objProvider.id_account = id_account;
  objProvider.includeFile = includeFile;
  if (objProvider.signature)
    objProvider.signature = objProvider.signature.split(",")[1];
  let verifyId = getState().providerReducer.providerDraft
    ? getState().providerReducer.providerDraft[0].id
    : null;
  if (!objProvider.id && !!verifyId) {
    objProvider.id = verifyId;
  }
  let sw = false;
  if (action === "send") {
    sw = validateInputs;
  } else {
    sw = true;
  }
  if (!!sw) {
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider`, {
      method: "POST",
      body: JSON.stringify(objProvider),
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: LOADING,
          payload: false,
        });
        if (res.success) {
          dispatch({
            type: CREATE_DRAFT_PROVIDER,
            payload: res.results,
          });
          return customSwaltAlert({
            icon: "success",
            title: `${res.message}`,
            text: `${res.text}`,
            showConfirmButton: true,
            confirmButtonColor: "#fff",
            focusConfirm: false,
            confirmButtonText: `Aceptar`,
          }).then((response) => {
            if (response.isConfirmed && objProvider.create) {
              (window.location = "/administracion/proveedores") 

            }
          });
          // return customSwaltAlert({
          //   icon: "success",
          //   title: `${res.message}`,
          //   text: `${res.text}`,
          //   showConfirmButton: true,
          //   confirmButtonColor: "#fff",
          //   focusConfirm: false,
          //   confirmButtonText: `${
          //     objProvider.create
          //       ? '<a class="text-decoration-none" href="/administracion/proveedores">Aceptar</a>'
          //       : "Aceptar"
          //   }`,
          // })

        } else {
          return customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: `${res.message}`,
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
          
        }
      })
      .catch(() => {
        dispatch({
          type: LOADING,
          payload: false,
        });
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Ocurrió un error al guardar los cambios`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

      });
  }
};

const validateInputs = (objProvier) => {
  const regexNumbers = /^[0-9]*$/;
  let sw = true;

  if (objProvier.provider_type === "nautral") {
    if (String(objProvier.al_phone_lgl_rpst).length > 0) {
      if (
        !regexNumbers.test(objProvier.al_phone_lgl_rpst) ||
        String(objProvier.al_phone_lgl_rpst).length < 7
      ) {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un teléfono válido en información general`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        sw = false;
        return sw;
      }
    }
    if (String(objProvier.cellphoneLglRepresent).length > 0) {
      if (
        !regexNumbers.test(objProvier.cellphoneLglRepresent) ||
        String(objProvier.cellphoneLglRepresent).length !== 10
      ) {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un celular válido en información general`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        sw = false;
        return sw;
      }
    }
    if (
      String(objProvier.phoneCommercialReference).length > 0 &&
      objProvier.hasCommercialReferences
    ) {
      if (
        !regexNumbers.test(objProvier.phoneCommercialReference) ||
        String(objProvier.phoneCommercialReference).length < 7
      ) {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un teléfono válido en información bancaria, comerciales`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        sw = false;
        return sw;
      }
    }
    if (
      String(objProvier.cellphoneCommercialReference).length > 0 &&
      objProvier.hasCommercialReferences
    ) {
      if (
        !regexNumbers.test(objProvier.cellphoneCommercialReference) ||
        String(objProvier.cellphoneCommercialReference).length !== 10
      ) {
        
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un celular válido en información bancaria, comerciales`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        sw = false;
        return sw;
      }
    }
  } else {
    if (String(objProvier.cellphone).length > 0) {
      if (
        !regexNumbers.test(objProvier.cellphone) ||
        String(objProvier.cellphone).length !== 10
      ) {

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un celular válido en información general`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        sw = false;
        return sw;
      }
    }
    if (String(objProvier.phone).length > 0) {
      if (
        !regexNumbers.test(objProvier.phone) ||
        String(objProvier.phone).length < 7
      ) {
        
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un teléfono válido en información general`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        sw = false;
        return sw;
      }
    }
    if (String(objProvier.al_cellphone).length > 0) {
      if (
        !regexNumbers.test(objProvier.al_cellphone) ||
        String(objProvier.al_cellphone).length !== 10
      ) {
        
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un celular válido en dirección alternativa, información general`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        sw = false;
        return sw;
      }
    }
    if (String(objProvier.al_phone_lgl_rpst).length > 0) {
      if (
        !regexNumbers.test(objProvier.al_phone_lgl_rpst) ||
        String(objProvier.al_phone_lgl_rpst).length < 7
      ) {
        
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un teléfono válido en representante legal`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        sw = false;
        return sw;
      }
    }
    if (String(objProvier.cellphoneLglRepresent).length > 0) {
      if (
        !regexNumbers.test(objProvier.cellphoneLglRepresent) ||
        String(objProvier.cellphoneLglRepresent).length !== 10
      ) {
        
        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un celular válido en representante legal`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        sw = false;
        return sw;
      }
    }
    if (String(objProvier.phoneLglRepresent).length > 0) {
      if (
        !regexNumbers.test(objProvier.phoneLglRepresent) ||
        String(objProvier.phoneLglRepresent).length < 7
      ) {
        

        customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un teléfono válido en representante legal`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })

        sw = false;
        return sw;
      }
    }
    if (
      String(objProvier.phoneCommercialReference).length > 0 &&
      objProvier.hasCommercialReferences
    ) {
      if (
        !regexNumbers.test(objProvier.phoneCommercialReference) ||
        String(objProvier.phoneCommercialReference).length < 7
      ) {
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un teléfono válido en información bancaria, comerciales`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }
    }
    if (
      String(objProvier.cellphoneCommercialReference).length > 0 &&
      objProvier.hasCommercialReferences
    ) {
      if (
        !regexNumbers.test(objProvier.cellphoneCommercialReference) ||
        String(objProvier.cellphoneCommercialReference).length !== 10
      ) {
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: `Debe ingresar un celular válido en información bancaria, comerciales`,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      }
    }
  }
};
export const getProviders = (params) => (dispatch, getState) => {
  dispatch({
    type: GET_PROVIDERS,
    loading: true
  });
  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  params.id_account = id_account;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_PROVIDERS,
        payload: res.results,
        row_total: res.row_total,
        total_index: res.total_index,
        loading: false
      });
    })
    .catch(() => {
      dispatch({
        type: GET_PROVIDERS,
        payload: [],
        row_total: 0,
        total_index: 0,
        loading: false
      });

      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: "Ocurrió un error al traer la información de proveedores",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
    });
};

export const getCountries = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  // const id_account = getState().loginReducer.currentAccount.id;
  const filters = convertFilterToString(params);
  try {
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/countries?${filters}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_COUNTRIES,
          payload: res.results,
        });
      })
      .catch((error) => {
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text: error.message,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
      });
  } catch (error) {
    return console.error(error.message);
  }
};

export const getCIIU = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/ciiu`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_CIIU,
        payload: res.results,
      });
    });
};

export const getProviderClassifications = (params) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/provider/classification?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_PROVIDER_CLASSIFICATION,
        payload: res.results,
      });
    });
};

export const cleanDrafts = () => (dispatch) => {
  dispatch({
    type: CREATE_DRAFT_PROVIDER,
    payload: null,
  });
};

export const getEditProvider = (provider) => (dispatch) => {
  dispatch({
    type: GET_EDIT_PROVIDER,
    payload: provider,
  });
};

export const getBankingEntities = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/bankingEntities`, {
    headers: {
      "Conent-type": "application/json",
      accept: "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_BANKING_ENTITIES,
        payload: res.results,
      });
    })
    .catch((error) => {
      return console.error(error.message);
    });
};


export const getTaxPayerType = () => ( dispatch, getState ) => {
  const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/inventory/provider/taxpayerprovider/`,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: token,
          "Content-Type": "application/json",
        }
      }
    ).then(response => response.json())
    .then(res => {
      if (!!res.success){
        dispatch({type:GET_TAX_PAYER_TYPE, payload:res.results})
      }else{
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text:"Ocurrió un error al traer los tipos de contribuyentes",
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        
      }
    }).catch(()=>{
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text:"Ocurrió un error al traer los tipos de contribuyentes",
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      
    })
  
}

export const updateProvider = (data, doAfter=null) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({type:LOADING, payload:true});
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider/`,{
    method:"PUT",
    body:JSON.stringify(data),
    headers:{
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token
    }
  })
  .then(response => response.json())
  .then(res => {
    dispatch({type:LOADING, payload:false})
    if (res.success){
      customSwaltAlert({
        icon:"success", 
        title:`Actualizado exitosamente`,
        text:`${res.message}`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      if(doAfter){
        doAfter()
      }
    }else{
      customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text: `${res.message}`,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })

    }
  }).catch(()=>{
    dispatch({type:LOADING, payload:false})
    customSwaltAlert({
      icon: "warning",
      title: "Intenta de nuevo",
      text:`Ocurrió un error al actualizar el proveedor`,
      confirmButtonText:'Aceptar',
      showCancelButton:false
    })
  })
}


export const getListTypeServices = (provider) => ( dispatch, getState ) => {
  
  const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/medical/generals/service-types/?${!isEmptyOrUndefined(provider) ? `provider=${provider}` : ''} `,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: token,
          "Content-Type": "application/json",
        }
      }
    ).then(response => response.json())
    .then(res => {
      if (!!res.success){
        dispatch({type:GET_TYPE_SERVICES, payload:res.results})
      }else{
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text:res?.message,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        
      }
    }).catch((res)=>{
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text:res?.message,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      
    })
  
}

export const getLisLaboratories = (provider) => ( dispatch, getState ) => {
  
  const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/medical/generals/laboratories/?${!isEmptyOrUndefined(provider) ? `provider=${provider}` : ''} `,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: token,
          "Content-Type": "application/json",
        }
      }
    ).then(response => response.json())
    .then(res => {
      if (!!res.success){
        dispatch({type:GET_LABORATORIES, payload:res.results})
      }else{
        return customSwaltAlert({
          icon: "warning",
          title: "Intenta de nuevo",
          text:res?.message,
          confirmButtonText:'Aceptar',
          showCancelButton:false
        })
        
      }
    }).catch((res)=>{
      return customSwaltAlert({
        icon: "warning",
        title: "Intenta de nuevo",
        text:res?.message,
        confirmButtonText:'Aceptar',
        showCancelButton:false
      })
      
    })
  
}