import React, { useEffect, useState } from "react";
import GenericTable from "../../Layouts/GenericTableNew";
/* import tableStyles from "../../Layouts/tableStyle.module.scss"; */
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import CustomPopupExtend from "../../Popup/customPopUpExtends";
import { useGetMethod, usePostMethod } from "../../../Hooks/useFetch";
import Lupa from "../../../assets/img/icons/lupa.svg";
import Agregar from "../../../assets/img/icons/add-check.svg";
import threeDots from "../../../assets/img/icons/threeDots.svg";
import { customSelectNewDark } from "../../Layouts/react-select-custom";
import ModalNew from "../../Layouts/ModalNew";
import { loader } from "../../../helpers/helpers";
import paginationStyles from "../../Layouts/pagination.module.scss";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { customSwaltAlert } from "../../../helpers/customSwaltAlert";
function Dependencies(props) {
  const MySwal = withReactContent(Swal);
  const storage = useSelector((state) => state);
  const token = storage?.loginReducer?.Authorization;
  const idAccount = storage?.loginReducer?.currentAccount?.id;
  const idUser = storage?.loginReducer?.user_data?.id;
  const { load: dependencyCreateLoader, trigger: dependencySubmit } =
    usePostMethod();

  const [dependency, setDependency] = useState({
    show: false,
    data: {},
    isEditing: false,
    isCreating: false,
    isDetail: false,
  });
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: idAccount,
    active: undefined,
  });
  const [trigger, setTrigger] = useState(1);
  const {
    results: dependenciesListResults,
    load: dependenciesListLoader,
    trigger: getDependencies,
  } = useGetMethod();
  const myPermission =
    storage?.loginReducer?.currentAccount?.profile?.permission?.find(
      (x) => x.functionality?.prefix === "dependenci"
    );
  useEffect(() => {
    getDependencies({
      url: "/payroll/dependencies",
      objFilters: { ...filters },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);
  const searchFilters = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };
  const handleClick = () => {
    setDependency({
      ...dependency,
      show: true,
      isCreating: true,
    });
  };
  const handleSubmit = (data = null, changeStatus) => {
    
    if (changeStatus === undefined) {

      if (!dependency?.data?.description?.trim() || !dependency?.data?.title?.trim()) {
        return (
          customSwaltAlert({
            icon: "warning",
            title: "Intente de nuevo",
            text: 'Los campos están vacíos',
            showCancelButton: false
          })
        )
      }
    }

    let body;
    if (data) {
      body = {
        eaccount: idAccount,
        title: data?.title,
        description: data?.description,
        idUser: idUser,
        id: data?.id,
        active: data?.active === 1 ? 0 : 1,
      };
    } else {
      if (dependency?.isEditing) {
        body = {
          eaccount: idAccount,
          title: dependency?.data?.title,
          description: dependency?.data?.description,
          idUser: idUser,
          id: dependency?.data?.id,
          active: dependency?.data?.active,
        };
      } else {
        body = {
          eaccount: idAccount,
          title: dependency?.data?.title,
          description: dependency?.data?.description,
          idUser: idUser,
        };
      }
    }

    customSwaltAlert({
      icon: "warning",
      title: "¿Está seguro?",
      text: `Se ${changeStatus ? (data?.active ? 'deshabilitará' : 'habilitará') : dependency.isEditing || data ? 'actualizará' : "creará"} la dependencia: ${body?.title}`,
      showCancelButton: true
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        dependencySubmit({
          url: "/payroll/dependencies",
          token: token,
          noAlert: true,
          doAfterSuccess: () => {
            getDependencies({
              url: "/payroll/dependencies",
              objFilters: { page: 1, perpage: 10, eaccount: idAccount },
              token: token,
            });
            setDependency({
              data: {},
              show: false,
              isCreating: false,
              isDetail: false,
              isEditing: false,
            });
          },
          succesAction: () =>
            customSwaltAlert({
              icon: "success",
              title: `${dependency.isEditing || data ? "Actualizado" : "Creado"} exitosamente`,
              text: `Se ha ${dependency.isEditing || data ? "actualizado" : "creado"} la dependencia: ${body?.title}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false
            }),
          method: dependency?.isEditing || data ? "PUT" : "POST",
          body: body,
        });
      }
    })
  };
  const optionStatus = () => {
    let tempModules = [
      {
        value: "",
        label: "Seleccionar...",
      },
      {
        value: 1,
        label: "Habilitado",
      },
      {
        value: 0,
        label: "Deshabilitado",
      },
    ];
    return tempModules;
  };
  const handlePageChange = (val) => {
    setFilters({
      ...filters,
      page: val,
    });
  };
  const renderList = () => {
    if (Array.isArray(dependenciesListResults?.results)) {
      return dependenciesListResults?.results.map((x, index) => {
        return (
          <tr key={"renderList" + index} >
            <td className="text-start px-1">{x.title}</td>
            <td className="text-start px-1">{x.description}</td>
            <td className="text-center px-1">{x.createdAt}</td>
            <td className="text-center px-1">
              {/* <Tooltip
                title={x.active === 1 ? "Habilitado" : "Deshabilitado"}
                arrow
              > */}
              <div
                className="rounded-pill p-1"
                style={{
                  backgroundColor: x?.active === 1 ? "#FAFDF6" : "#FEF7F5",
                }}
              >
                <b style={{ color: x?.active === 1 ? "#83C036" : "#F39682" }}>{x?.active === 1
                  ? "Habilitado"
                  : "Inhabilitado"}</b>
              </div>
              {/* </Tooltip> */}
            </td>
            <td>
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                showDetails={!!myPermission.read && false}
                textDetails="Ver detalle"
                showDetailsEvent={() =>
                  setDependency({
                    ...dependency,
                    show: true,
                    isDetail: true,
                    isEditing: false,
                    isCreating: false,
                    data: x,
                  })
                }
                showEdit={!!myPermission.edit && true}
                editClickEvent={() =>
                  setDependency({
                    ...dependency,
                    show: true,
                    isDetail: false,
                    isEditing: true,
                    isCreating: false,
                    data: x,
                  })
                }
                editText="Editar"
                showEnable={true}
                isEnabled={x.active === 1}
                enableClickEvent={() => {
                  handleSubmit(x, 1);
                }}
              />
            </td>
          </tr>
        );
      });
    } else {
      return [];
    }
  };
  return (
    <>
      {(dependenciesListLoader || dependencyCreateLoader) && loader}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: "3rem" }}
      >
        <div className="d-flex">
          <h1 className={tableStyles.title}>Dependencias</h1>
        </div>
        {!!myPermission.read &&
          <>
            <Row>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Estado</p>
                <SelectComponent
                  styles={customSelectNewDark}
                  placeholder={"Seleccionar..."}
                  key={"area" + trigger}
                  value={optionStatus().filter(
                    (x) => x.value === filters?.active
                  )}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      active: e.value,
                      page: 1
                    })
                    setTrigger(trigger + 1)
                  }
                  }
                  options={optionStatus()}
                ></SelectComponent>
              </Col>

              <Col
                xs={5}
                className="d-flex align-self-end gap-1"
              >
                <input
                  className={tableStyles.SearchNew}
                  value={filters.search}
                  placeholder="Buscar..."
                  type="text"
                  onChange={(e) =>
                    setFilters({ ...filters, title: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchFilters(e)
                    }
                  }}
                ></input>
                <img
                  className="hoverPointer"
                  alt="iconSearch"
                  style={{}}
                  src={Lupa}
                  height="24px"
                  onClick={(e) => searchFilters(e)}
                />
              </Col>
              {!!myPermission?.create ? (
                <Col
                  xs={4}
                  className="align-self-end"
                >
                  <div className="d-flex justify-content-end">
                    <div
                      onClick={() => handleClick()}
                      className={tableStyles.createNomModule}
                    >
                      <b className={`mr-2`}>Crear dependencia</b>
                      <img
                        src={Agregar}
                        alt="User"
                      />
                      <div></div>
                    </div>
                  </div>
                </Col>
              ) : null}
              {/* <Col xs={5}>
          </Col> */}
            </Row>
            <GenericTable
            lowerCase
              dark={true}
              headers={[
                <th className="text-start px-1">Título</th>,
                <th className="text-start px-1">Descripción</th>,
                <th className="text-center px-1">Fecha de creación</th>,
                <th className="text-center px-1">Estado</th>,
                <th>&nbsp;</th>,
              ]}
            >
              {renderList()}
            </GenericTable>
            {!!dependenciesListResults?.rowTotal ? (
              <div className={paginationStyles.wrapper}>
                <p className={` ${paginationStyles.paginationText} darkGray`}>
                  pag. {dependenciesListResults?.rowTotal ? filters?.page : ""}
                  {" de "}
                  {Math.ceil(dependenciesListResults?.rowTotal / filters?.perpage)
                    ? Math.ceil(
                      dependenciesListResults?.rowTotal / filters?.perpage
                    )
                    : ""}{" "}
                  ({" " + dependenciesListResults?.rowTotal}
                  {" encontrados"})
                </p>
                <Pagination
                  activePage={filters?.page}
                  itemsCountPerPage={filters?.perpage}
                  totalItemsCount={dependenciesListResults?.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClassPrev={`${paginationStyles.itemClassPrev} `}
                  itemClassNext={`${paginationStyles.itemClassNext} `}
                  itemClassFirst={`${paginationStyles.itemClassFirst} `}
                  itemClassLast={`${paginationStyles.itemClassLast} `}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            ) : null}
          </>
        }
      </div>

      <ModalNew
        show={dependency.show}
        onHide={() =>
          setDependency({
            ...dependency,
            show: false,
            isDetail: false,
            isCreating: false,
            data: {},
            isEditing: false,
          })
        }
        title={
          dependency?.isDetail
            ? "Detalle de la dependencia"
            : dependency.isCreating
              ? "Nueva dependencia"
              : dependency?.isEditing
                ? "Editar la dependencia"
                : ""
        }
        btnYesName={"Aceptar"}
        btnYesEvent={dependency.isEditing ?
          !!myPermission.edit ? () => handleSubmit()
            : null :
          !!myPermission.create ? () => handleSubmit() : null}
        size={"500"}
      >
        <Row>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Título</b>
            </p>
            <input
              disabled={dependency.isDetail}
              value={dependency?.data?.title}
              onChange={(e) =>
                setDependency({
                  ...dependency,
                  data: { ...dependency.data, title: e.target.value },
                })
              }
              className="register-inputs text-secondary"
              name="position"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Descripción</b>
            </p>
            <textarea
              style={{ minHeight: "90px", maxHeight: "90px" }}
              disabled={dependency.isDetail}
              value={dependency?.data?.description}
              className="register-inputs text-secondary"
              onChange={(e) =>
                setDependency({
                  ...dependency,
                  data: { ...dependency.data, description: e.target.value },
                })
              }
              name="position"
            />
          </Col>
        </Row>
      </ModalNew>
    </>
  );
}

export default Dependencies;
